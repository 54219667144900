import ApiConstants from '../Constants/ApiConstants';
import Utility from '../Utility/Utility';
import http from './Interceptor';
import { saveAs } from 'file-saver';
import { DATE_FORMAT, DOC_TYPE } from '../Constants/Constant';
export interface PriceListApiConfig {
  SearchTerm?: string;
  Limit?: number;
  Page?: number;
  SortDir?: string;
  Sort?: string;
  SKIP_REQUEST_INTERCEPTOR?: boolean;
  Query?: string;
  HAS_VARIANTS?: boolean;
  QueryParam?: string;
}

export const defaultConfig: PriceListApiConfig = {
  SearchTerm: '',
  Limit: 10,
  Page: 0,
  SortDir: 'desc',
  Sort: 'code',
  SKIP_REQUEST_INTERCEPTOR: true,
  Query: '',
  HAS_VARIANTS: true
};

export interface TypeConfig {
  SearchTerm?: string;
  Limit?: number;
  Page?: number;
  SortDir?: string;
  Sort?: string;
  SKIP_REQUEST_INTERCEPTOR?: boolean;
}

const typeConfig: TypeConfig = {
  SearchTerm: 'a',
  Limit: 25,
  Page: 0,
  SortDir: 'asc',
  Sort: 'name',
  SKIP_REQUEST_INTERCEPTOR: true
};

class PriceListService {
  static apiConfig: PriceListApiConfig;
  static typeApiConfig: TypeConfig;

  static getPriceList() {
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
      if (this.apiConfig.Query === '') {
        let startDateString = Utility.formatDate(
          new Date(new Date().getFullYear(), 0, 1),
          DATE_FORMAT.DD_MM_YYYY
        );
        let endDateString = Utility.formatDate(
          new Date(new Date().getFullYear(), 11, 31),
          DATE_FORMAT.DD_MM_YYYY
        );
        this.apiConfig.Query =
          'fromDate=' + startDateString + ',' + 'toDate=' + endDateString + ',';
      }
    }

    const queryString: string = `?limit=${this.apiConfig.Limit}&page=${
      this.apiConfig.Page
    }&search=${this.apiConfig.SearchTerm}&query=${this.apiConfig.Query}${
      this.apiConfig.QueryParam ? this.apiConfig.QueryParam : ''
    }`;
    const finalEndpoint: string =
      ApiConstants.URL.PRICE_LIST.FETCH_PRICE_LIST + queryString;
    return http.get(`${finalEndpoint}`).catch((err: any) => {
      console.error('Error loading Price List: ', err);
      return Promise.reject(err);
    });
  }

  static getPriceListUrl(type: string) {
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }
    const queryString: string = `?limit=${this.apiConfig.Limit}&page=${
      this.apiConfig.Page
    }&search=${this.apiConfig.SearchTerm}&query=${this.apiConfig.Query}${
      this.apiConfig.QueryParam ? this.apiConfig.QueryParam : ''
    }`;
    const finalEndpoint: string =
      ApiConstants.URL.PRICE_LIST.FETCH_PRICE_LIST + queryString;

    return finalEndpoint;
  }

  static getPriceListById(payload: any): Promise<any> {
    return http.post(ApiConstants.URL.PRICE_LIST.PRICE_LIST_BY_ID, payload);
  }

  static updatePriceListStatus(id: number, payload: number) {
    const headers = {
      headers: {
        'Content-Type': 'application/json'
      }
    };
    return http
      .put(ApiConstants.URL.PRICE_LIST.SET_ACTIVE(id), payload, headers)
      .catch((err: any) => {
        console.error('Error while trying to update status: ', err);
        return Promise.reject(err);
      });
  }

  static createPriceList(payload: any) {
    const finalEndpoint: string = ApiConstants.URL.PRICE_LIST.CREATE_PRICE_LIST;
    return http.post(`${finalEndpoint}`, payload).catch((err: any) => {
      console.error('Error while trying to post status: ', err);
      return Promise.reject(err);
    });
  }
  static assignPriceList(id: number, payload: any) {
    const finalEndpoint: string =
      ApiConstants.URL.PRICE_LIST.ASSIGN_PRICE_LIST(id);
    return http.put(`${finalEndpoint}`, payload).catch((err: any) => {
      console.error('Error while trying to assign price list: ', err);
      return Promise.reject(err);
    });
  }

  static exportPriceList(startDate?: any, endDate?: any) {
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }
    const fileName = `PriceList.xlsx`;
    const endPoint = ApiConstants.URL.PRICE_LIST.EXPORT_PRICE_LIST;
    const query = `?fromDate=${startDate}&toDate=${endDate}&format=XLS&search=`;
    const finalEndPoint = endPoint + query;
    return http
      .get(`${finalEndPoint}`, {
        responseType: 'blob'
      })
      .then(
        (response: any) => {
          saveAs(response, fileName);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static updatePriceListPercent(payload: any, id: any) {
    const finalEndpoint: string = ApiConstants.URL.PRICE_LIST.UPDATE_PL_PERCENT;
    return http.put(`${finalEndpoint}/${id}`, payload).catch((err: any) => {
      console.error('Error while trying to post status: ', err);
      return Promise.reject(err);
    });
  }

  static updatePriceList(payload: any, id: number) {
    const finalEndpoint: string = ApiConstants.URL.PRICE_LIST.CREATE_PRICE_LIST;
    return http.put(`${finalEndpoint}/${id}`, payload).catch((err: any) => {
      console.error('Error while trying to update status: ', err);
      return Promise.reject(err);
    });
  }

  static getProductList() {
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }
    this.apiConfig.Limit = 25;
    const queryString: string = `?query=active=true&limit=${this.apiConfig.Limit}`;
    const finalEndpoint: string =
      ApiConstants.URL.PRICE_LIST.FETCH_PRODUCTS + queryString;
    return http.get(`${finalEndpoint}`).catch((err: any) => {
      console.error('Error loading Products: ', err);
      return Promise.reject(err);
    });
  }

  static getProductsById(productsIdList: []) {
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }
    const finalEndpoint: string =
      ApiConstants.URL.PRICE_LIST.FETCH_PRODUCTS_BY_ID;
    return http.post(`${finalEndpoint}`, productsIdList).catch((err: any) => {
      console.error('Error loading Products: ', err);
      return Promise.reject(err);
    });
  }

  static deletePriceList(id: number) {
    const finalEndpoint: string = ApiConstants.URL.PRICE_LIST.CREATE_PRICE_LIST;
    try {
      return http.delete(`${finalEndpoint}/${id}`);
    } catch (err) {
      console.error('Error deleting PRICE LIST: ', err);
      return Promise.reject(err);
    }
  }

  // Get product prices from price list
  static getPricesFromPriceList(payload: any): Promise<any> {
    return http.post(ApiConstants.URL.PRICE_LIST.PRICING, payload);
  }

  // Get last 3 prices of product
  static fetchLastPricesOfProduct(
    productCodes: string[],
    documentType: DOC_TYPE,
    contactCode: String
  ): Promise<any> {
    const payload = {
      contactCode,
      documentType,
      numberOfPricesToFetch: 3,
      productCodes
    };
    return http.post(
      ApiConstants.URL.PRICE_LIST.FETCH_PRODUCTS_UNIT_PRICES,
      payload
    );
  }

  static fetchPriceListDetails(limit = 10) {
    const url = `${ApiConstants.URL.PRICE_LIST.FETCH_PRICE_LIST}?limit=${limit}`;
    return http.get(url).catch((err: any) => {
      console.error('Error loading Price List: ', err);
      return Promise.reject(err);
    });
  }

  static fetchPriceListForContact(contactCode: string) {
    return http
      .get(
        `${ApiConstants.URL.PRICE_LIST.PURCHASE_SALES_PRICE_LIST(
          contactCode
        )}?includeAllContacts=true`
      )
      .catch((err: any) => {
        console.error('Error loading Price List: ', err);
        return Promise.reject(err);
      });
  }
}

export default PriceListService;
