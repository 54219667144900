import { INPUT_TYPE } from 'deskera-ui-library';
import {
  PRODUCE_PRODUCT_TYPE,
  PRODUCT_TYPE,
  QTY_ROUNDOFF_PRECISION,
  WIP_CONSUMPTION_TYPES
} from '../../../../../Constants/Constant';
import Utility from '../../../../../Utility/Utility';
import { ADVANCE_TRACKING } from '../../../../../Constants/Enum';

export default class ConsumeProduceHelper {
  static CONSUME_PRODUCT_GRID_COLUMNS = [
    {
      name: 'Material Name',
      key: 'productName',
      type: INPUT_TYPE.TEXT,
      width: 150
    },
    {
      name: 'Allocated Qty',
      key: 'allocatedQty',
      type: INPUT_TYPE.NUMBER,
      width: 130
    },
    {
      name: 'Consumed Qty',
      key: 'consumedQty',
      type: INPUT_TYPE.NUMBER,
      width: 130
    },
    {
      name: 'Produced Qty',
      key: 'producedQty',
      type: INPUT_TYPE.NUMBER,
      width: 130
    },
    {
      name: 'Wastage Qty',
      key: 'wastageQty',
      type: INPUT_TYPE.NUMBER,
      width: 130
    }
  ];

  static CONSUMPTION_COLUMNS = [
    {
      id: 'productName',
      key: 'productName',
      name: 'Material Name',
      type: INPUT_TYPE.TEXT,
      width: 230,
      systemField: true,
      editable: false,
      hidden: false,
      uiVisible: true,
      textAlign: 'left'
    },
    {
      id: 'productCodeToDisplayOnly',
      key: 'productCodeToDisplayOnly',
      name: 'Material Code',
      type: INPUT_TYPE.TEXT,
      width: 120,
      systemField: true,
      editable: false,
      hidden: false,
      uiVisible: true,
      textAlign: 'left'
    },
    {
      id: 'type',
      key: 'type',
      name: 'Type',
      type: INPUT_TYPE.TEXT,
      width: 120,
      systemField: true,
      editable: false,
      hidden: false,
      uiVisible: true
    },
    {
      id: 'requiredQty',
      key: 'requiredQty',
      name: 'Required Qty',
      type: INPUT_TYPE.NUMBER,
      width: 115,
      systemField: true,
      editable: false,
      hidden: false,
      uiVisible: true,
      textAlign: 'right'
    },
    {
      id: 'allocatedQty',
      key: 'allocatedQty',
      name: 'Allocated Qty',
      type: INPUT_TYPE.NUMBER,
      width: 115,
      systemField: true,
      editable: false,
      hidden: false,
      uiVisible: true,
      textAlign: 'right'
    },
    {
      id: 'uom',
      key: 'uom',
      name: 'UOM',
      type: INPUT_TYPE.TEXT,
      width: 70,
      systemField: true,
      editable: false,
      hidden: false,
      uiVisible: true,
      textAlign: 'left'
    },
    {
      id: 'consumedQty',
      key: 'consumedQty',
      name: 'Consumed Qty',
      type: INPUT_TYPE.NUMBER,
      width: 115,
      systemField: true,
      editable: false,
      hidden: false,
      uiVisible: true,
      textAlign: 'right'
    },
    {
      id: 'producedQty',
      key: 'producedQty',
      name: 'Produced Qty',
      type: INPUT_TYPE.NUMBER,
      width: 115,
      systemField: true,
      editable: false,
      hidden: false,
      uiVisible: true,
      textAlign: 'right'
    },
    {
      id: 'wastageQty',
      key: 'wastageQty',
      name: 'Wastage Qty',
      type: INPUT_TYPE.NUMBER,
      width: 115,
      systemField: true,
      editable: false,
      hidden: false,
      uiVisible: true,
      textAlign: 'right'
    }
  ];

  static CONSUMPTION_ROW = [
    {
      productName: 'Sports Kit',
      productCode: 'P-0000009',
      requiredQty: 10,
      allocatedQty: 5,
      uom: 'UNIT',
      consumedQty: 0,
      type: 'BILL_OF_MATERIALS',
      level: 0,
      producedQty: 2,
      wastageQty: 0
    },
    {
      productName: 'Tennis racket',
      productCode: 'P-0000010',
      requiredQty: 10,
      allocatedQty: 5,
      uom: 'UNIT',
      consumedQty: 1,
      type: 'TRACKED',
      level: 1,
      producedQty: 0,
      wastageQty: 1
    },
    {
      productName: 'Tennis ball',
      productCode: 'P-0000011',
      requiredQty: 20,
      allocatedQty: 10,
      uom: 'UNIT',
      consumedQty: 2,
      type: 'TRACKED',
      level: 1,
      producedQty: 0,
      wastageQty: 1
    },
    {
      productName: 'Cricket Set',
      productCode: 'P-0000012',
      requiredQty: 30,
      allocatedQty: 15,
      uom: 'UNIT',
      consumedQty: 0,
      type: 'BILL_OF_MATERIALS',
      level: 1,
      producedQty: 3,
      wastageQty: 0
    }
  ];

  static ENTER_CONSUMPTION_COLUMNS = [
    {
      id: 'productName',
      key: 'productName',
      name: 'Product Name',
      type: INPUT_TYPE.TEXT,
      width: 260,
      systemField: true,
      editable: false,
      hidden: false,
      uiVisible: true,
      textAlign: 'left'
    },
    {
      id: 'productCodeToDisplayOnly',
      key: 'productCodeToDisplayOnly',
      name: 'Product Code',
      type: INPUT_TYPE.TEXT,
      width: 200,
      systemField: true,
      editable: false,
      hidden: false,
      uiVisible: true,
      textAlign: 'left'
    },
    {
      id: 'documentDate',
      key: 'documentDate',
      name: 'Document Date',
      type: INPUT_TYPE.DATE,
      width: 200,
      systemField: true,
      editable: true,
      hidden: false,
      uiVisible: true
    },
    {
      id: 'consumptionQty',
      key: 'quantity',
      name: 'Consumption',
      type: INPUT_TYPE.NUMBER,
      width: 140,
      systemField: true,
      editable: true,
      hidden: false,
      uiVisible: true,
      textAlign: 'right'
    },
    {
      id: 'productionQty',
      key: 'quantity',
      name: 'Production',
      type: INPUT_TYPE.NUMBER,
      width: 140,
      systemField: true,
      editable: true,
      hidden: false,
      uiVisible: true,
      textAlign: 'right'
    },
    {
      id: 'wastageQty',
      key: 'quantity',
      name: 'Wastage',
      type: INPUT_TYPE.NUMBER,
      width: 140,
      systemField: true,
      editable: true,
      hidden: false,
      uiVisible: true,
      textAlign: 'right'
    },
    {
      id: 'uom',
      key: 'uom',
      name: 'UOM',
      type: INPUT_TYPE.TEXT,
      width: 140,
      systemField: true,
      editable: false,
      hidden: false,
      uiVisible: true,
      textAlign: 'left'
    },
    {
      id: 'createdDate',
      key: 'createdDate',
      name: 'Created Date',
      type: INPUT_TYPE.DATE,
      width: 200,
      systemField: true,
      editable: false,
      hidden: false,
      uiVisible: true,
      textAlign: 'left'
    },
    {
      name: 'QC Status',
      type: INPUT_TYPE.SELECT,
      width: 150,
      systemField: true,
      editable: false,
      hidden: false,
      required: true,
      uiVisible: true,
      key: 'qcStatus',
      id: 'qcStatus',
      columnCode: 'qcStatus',
      allowColumnSort: false,
      options: [
        {
          id: 'PENDING',
          name: 'Pending',
          color: 'bg-chip-red'
        },
        {
          id: 'COMPLETED',
          name: 'Done',
          color: 'mrp_bg_green1'
        }
      ]
    }
  ];

  static ENTER_SCRAP_BYPRODUCT_COLUMNS = [
    {
      id: 'productName',
      key: 'productName',
      name: 'Product Name',
      type: INPUT_TYPE.DROPDOWN,
      width: 240,
      systemField: true,
      editable: true,
      hidden: false,
      uiVisible: true,
      dropdownConfig: {
        title: 'Select Scrap/By Product',
        allowSearch: true,
        searchableKey: 'name',
        style: { minWidth: 230 },
        className: 'shadow-m',
        searchApiConfig: {},
        data: [],
        renderer: null,
        onSelect: (index: any, obj: any, rowIndex: any) => {},
        button: null
      }
    },
    {
      id: 'productCodeToDisplayOnly',
      key: 'productCodeToDisplayOnly',
      name: 'Product Code',
      type: INPUT_TYPE.TEXT,
      width: 200,
      systemField: true,
      editable: false,
      hidden: false,
      uiVisible: true,
      textAlign: 'left'
    },
    {
      id: 'documentDate',
      key: 'documentDate',
      name: 'Document Date',
      type: INPUT_TYPE.DATE,
      width: 140,
      systemField: true,
      editable: true,
      hidden: false,
      uiVisible: true
    },
    // {
    //   id: 'warehouse',
    //   key: 'warehouse',
    //   name: 'Warehouse',
    //   type: INPUT_TYPE.DROPDOWN,
    //   width: 240,
    //   systemField: true,
    //   editable: true,
    //   hidden: false,
    //   uiVisible: true,
    //   dropdownConfig: {
    //     title: 'Select Warehouse',
    //     allowSearch: true,
    //     searchableKey: 'name',
    //     style: { minWidth: 230 },
    //     className: 'shadow-m',
    //     searchApiConfig: {},
    //     data: [],
    //     renderer: null,
    //     onSelect: (index: any, obj: any, rowIndex: any) => {},
    //     button: null
    //   }
    // },
    {
      id: 'quantity',
      key: 'quantity',
      name: 'Wastage',
      type: INPUT_TYPE.NUMBER,
      width: 140,
      systemField: true,
      editable: true,
      hidden: false,
      uiVisible: true,
      textAlign: 'right'
    },
    {
      id: 'uom',
      key: 'uom',
      name: 'UOM',
      type: INPUT_TYPE.TEXT,
      width: 140,
      systemField: true,
      editable: false,
      hidden: false,
      uiVisible: true,
      textAlign: 'left'
    },
    {
      id: 'costPerUnit',
      key: 'costPerUnit',
      name: 'Cost',
      type: INPUT_TYPE.NUMBER,
      width: 140,
      systemField: true,
      editable: true,
      hidden: false,
      uiVisible: true,
      textAlign: 'right'
    },
    {
      id: 'createdDate',
      key: 'createdDate',
      name: 'Created Date',
      type: INPUT_TYPE.DATE,
      width: 140,
      systemField: true,
      editable: false,
      hidden: false,
      uiVisible: true,
      textAlign: 'left'
    }
  ];

  static ENTER_CONSUMPTION_ROW = [
    {
      productName: 'Tennis racket',
      productCode: 'P-0000010',
      date: '2022-12-22',
      warehouse: {
        name: 'Primary Warehouse'
      },
      consumptionQty: 2,
      uom: 'UNIT',
      dateStamp: '2022-12-22'
    },
    {
      productName: 'Tennis racket',
      productCode: 'P-0000010',
      date: '2022-12-22',
      warehouse: {
        name: 'Primary Warehouse'
      },
      consumptionQty: 1,
      uom: 'UNIT',
      dateStamp: '2022-12-22'
    }
  ];

  static getTypeOfProduct = (type: any) => {
    switch (type) {
      case WIP_CONSUMPTION_TYPES.CONSUMPTION:
      case WIP_CONSUMPTION_TYPES.NONE:
        return 'Consumption';
      case PRODUCT_TYPE.BILL_OF_MATERIALS:
        return 'Production';
      case WIP_CONSUMPTION_TYPES.WASTAGE:
        return 'Wastage';
      case WIP_CONSUMPTION_TYPES.SCRAP:
        return 'Scrap';
      case WIP_CONSUMPTION_TYPES.CO_PRODUCT:
        return 'Co-Product';

      default:
        break;
    }
  };

  static getTypeClassOfProduct = (type: any) => {
    switch (type) {
      case WIP_CONSUMPTION_TYPES.CONSUMPTION:
      case WIP_CONSUMPTION_TYPES.NONE:
        return 'data-grid-badge-color-1';
      case PRODUCT_TYPE.BILL_OF_MATERIALS:
        return 'data-grid-badge-color-6';
      case WIP_CONSUMPTION_TYPES.WASTAGE:
        return 'data-grid-badge-color-3';
      case WIP_CONSUMPTION_TYPES.SCRAP:
        return 'data-grid-badge-color-4';
      case WIP_CONSUMPTION_TYPES.CO_PRODUCT:
        return 'data-grid-badge-color-5';

      default:
        break;
    }
  };

  static getAllocatedQty = (item: any) => {
    let sum =
      item.warehouseInventoryData?.reduce((acc: any, whData: any) => {
        let qty = whData.quantity;
        if (item?.itemName?.advancedTracking === ADVANCE_TRACKING.SERIAL) {
          qty = Utility.getUomQuantityWithoutRoundOff(
            qty,
            item?.documentUOMSchemaDefinition
          );
        }

        return Number(acc) + Number(qty);
      }, 0) ?? 0;

    sum = Utility.roundingOff(sum, QTY_ROUNDOFF_PRECISION);

    return sum;
  };

  static getWholeQty = (warehouseInventoryData: any) => {
    let sum =
      warehouseInventoryData?.reduce((acc: any, whData: any) => {
        const qtyToConsider = Number(whData?.uomQuantity ?? whData?.quantity);
        return Number(acc) + Number(qtyToConsider);
      }, 0) ?? 0;
    return sum;
  };

  static getSubstituteAllocatedQty = (item: any, code: any) => {
    let filtered = item?.bomProductSubstitutesDetails?.filter((subs: any) => {
      return subs.productCode === code;
    });
    let totalAlloted =
      filtered?.reduce((prev: number, current: any) => {
        let totalAllotedCurrent =
          current?.warehouseInventoryData?.reduce(
            (prevValue: number, currentObj: any) => {
              return Number(prevValue) + Number(currentObj.quantity);
            },
            0
          ) || 0;
        return Number(prev) + Number(totalAllotedCurrent);
      }, 0) || 0;
    return totalAlloted;
  };

  static getConsumedOrProducedQtyCount = (lineItems: any) => {
    let sum = 0;
    if (!Utility.isEmpty(lineItems)) {
      lineItems?.forEach((item: any) => {
        if (!Utility.isEmpty(item?.warehouseInventoryData)) {
          sum += item?.warehouseInventoryData?.reduce((acc: any, obj: any) => {
            return Number(acc) + Number(obj.quantity);
          }, 0);
        }
      });
    }
    return sum ?? 0;
  };

  static getWastageQtyCount = (lineItems: any) => {
    let sum = 0;
    if (!Utility.isEmpty(lineItems)) {
      lineItems?.forEach((item: any) => {
        if (!Utility.isEmpty(item?.warehouseInventoryData)) {
          sum += item?.warehouseInventoryData?.reduce((acc: any, obj: any) => {
            return Number(acc) + Number(obj.quantity);
          }, 0);
        }
      });
    }
    return sum ?? 0;
  };

  static getConsumedQty = (item: any, rows: any) => {
    let found = rows.find((row: any) => {
      return (
        row.productCode === item?.itemName?.productId &&
        item?.produceProductType === PRODUCE_PRODUCT_TYPE.NONE &&
        row?.wipProcessTypes === WIP_CONSUMPTION_TYPES.CONSUMPTION
      );
    });
    return Utility.isNotEmpty(found?.documentUOMSchemaDefinition)
      ? found?.uomQuantity ?? 0
      : found?.quantity ?? 0;
  };

  static getSubstituteConsumedQty = (item: any, rows: any) => {
    let found = rows.find((row: any) => {
      return (
        row.productCode === item?.productCode &&
        row.wipProcessTypes === WIP_CONSUMPTION_TYPES.CONSUMPTION
      );
    });
    return found?.quantity ?? 0;
  };

  static getSubstituteWastageQty = (item: any, rows: any) => {
    let found = rows.find((row: any) => {
      return (
        row.productCode === item?.productCode &&
        row.wipProcessTypes === WIP_CONSUMPTION_TYPES.WASTAGE
      );
    });
    return found?.quantity ?? 0;
  };

  static getProducedQty = (item: any, rows: any) => {
    let found = rows.find((row: any) => {
      return (
        row.productCode === item?.itemName?.productId &&
        item?.produceProductType !== PRODUCE_PRODUCT_TYPE.NONE
      );
    });
    return found?.quantity ?? 0;
  };

  static getRemainingQty = (
    allocatedQty: number,
    consumptionItems: any,
    wastageItems: any
  ) => {
    let totalConsumptionQty = 0;
    consumptionItems?.forEach((item: any) => {
      if (!Utility.isEmpty(item?.warehouseInventoryData)) {
        totalConsumptionQty += item?.warehouseInventoryData?.reduce(
          (acc: any, obj: any) => {
            let qty = obj.quantity;
            // if (item?.itemName?.advancedTracking === ADVANCE_TRACKING.SERIAL) {
            //   qty = Utility.getUomQuantityWithoutRoundOff(
            //     qty,
            //     item?.documentUOMSchemaDefinition
            //   );
            // }
            return Number(acc) + Number(qty);
          },
          0
        );
      }
    });
    totalConsumptionQty = totalConsumptionQty ?? 0;

    let totalWastageQty = 0;
    wastageItems?.forEach((item: any) => {
      if (!Utility.isEmpty(item?.warehouseInventoryData)) {
        totalWastageQty += item?.warehouseInventoryData?.reduce(
          (acc: any, obj: any) => {
            let qty = obj.quantity;
            // if (item?.itemName?.advancedTracking === ADVANCE_TRACKING.SERIAL) {
            //   qty = Utility.getUomQuantityWithoutRoundOff(
            //     qty,
            //     item?.documentUOMSchemaDefinition
            //   );
            // }
            return Number(acc) + Number(qty);
          },
          0
        );
      }
    });
    totalWastageQty = totalWastageQty ?? 0;

    const remainingQty = allocatedQty - (totalConsumptionQty + totalWastageQty);

    return remainingQty;
  };

  static getWastageQuantity = (data: any, currentObj: any) => {
    const foundWastageProduct = data?.find((item: any) => {
      return (
        item.productCode === currentObj.itemName?.productId &&
        item.wipProcessTypes === WIP_CONSUMPTION_TYPES.WASTAGE
      );
    });

    return foundWastageProduct?.quantity ?? 0;
  };

  static isSubstituteAllotted = (substituteData: any) => {
    let data = substituteData?.find((item: any) => {
      return 'warehouseInventoryData' in item;
    });

    return !Utility.isEmpty(data);
  };

  static getProductDetailsForScrapGrid = (
    rows: any,
    code: any,
    type: any,
    isForScrap = true
  ) => {
    let scrapRows = [...rows];
    if (isForScrap) {
      scrapRows = scrapRows?.filter((row: any) => {
        return row.produceProductType !== PRODUCE_PRODUCT_TYPE.NONE;
      });
    }
    const found = scrapRows?.find((sr: any) => {
      return code === sr?.itemName?.productId;
    });

    if (type === 'productName') {
      return found?.itemName?.name;
    }

    if (type === 'uom') {
      return Utility.getUOMForStockUOMId(found?.stockUom)?.name;
    }

    if (type === 'stockUom') {
      return found?.stockUom;
    }

    if (type === 'advancedTracking') {
      return found?.itemName?.advancedTracking;
    }
    if (type === 'inventoryPrice') {
      return found?.itemName?.inventoryPrice ?? 0;
    }

    if (type === 'productCodeToDisplayOnly') {
      return found?.itemName?.documentSequenceCode ?? '';
    }
  };

  static containsNewHistoryObject = (lineItems: any) => {
    const lineItemWithUnsavedHistory = lineItems?.find((lineItem: any) => {
      return !('id' in lineItem);
    });
    return Utility.isEmpty(lineItemWithUnsavedHistory);
  };

  /**
   * @todo
   * To refactor below method & remove propsRows param,
   * as same can be derived from workOrderData
   */
  static getConsumptionRows(res: any, workOrderData: any, propsRows: any) {
    const foundFG = res?.find((item: any) => {
      return (
        item.productCode === workOrderData.productCode &&
        item.wipProcessTypes === WIP_CONSUMPTION_TYPES.PRODUCTION
      );
    });

    const foundFGForScrapByProductSum = res
      ?.filter((item: any) => {
        return (
          item.wipProcessTypes === WIP_CONSUMPTION_TYPES.SCRAP ||
          item.wipProcessTypes === WIP_CONSUMPTION_TYPES.CO_PRODUCT
        );
      })
      .reduce((acc: any, obj: any) => {
        return Number(acc) + Number(obj?.quantity ?? 0);
      }, 0);

    let consumptionRows: any[] = [
      {
        productName: workOrderData.productName,
        productCode: workOrderData.productCode,
        requiredQty: workOrderData.manufactureQuantity,
        allocatedQty: 0,
        uom: Utility.getUOMForStockUOMId(workOrderData?.product?.stockUom)
          ?.name,
        type: workOrderData?.product?.type,
        productType: workOrderData?.product?.type,
        level: 0,
        consumedQty: 0,
        producedQty: foundFG?.quantity ?? 0,
        wastageQty: foundFGForScrapByProductSum,
        advancedTracking: workOrderData?.product?.advancedTracking
      }
    ];
    if (!Utility.isEmpty(propsRows)) {
      propsRows.forEach((item: any) => {
        if (item?.produceProductType === PRODUCE_PRODUCT_TYPE.NONE) {
          consumptionRows.push({
            productName: item.itemName.name,
            productCode: item.itemName.productId,
            requiredQty: item.requiredQty,
            allocatedQty: ConsumeProduceHelper.getAllocatedQty(item),
            uom: Utility.getUOMForStockUOMId(item.stockUom)?.name,
            type: item?.produceProductType,
            productType: item.itemName?.type,
            level: 1,
            consumedQty:
              item?.produceProductType === PRODUCE_PRODUCT_TYPE.NONE
                ? ConsumeProduceHelper.getConsumedQty(item, res ?? [])
                : 0,
            producedQty:
              item?.produceProductType !== PRODUCE_PRODUCT_TYPE.NONE
                ? ConsumeProduceHelper.getProducedQty(item, res ?? [])
                : 0,
            wastageQty: ConsumeProduceHelper.getWastageQuantity(res, item),
            advancedTracking: item?.itemName?.advancedTracking
          });
        }
      });
    }
    return consumptionRows;
  }
}
