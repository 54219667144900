let de = {
    translations: {
        "component": "Komponente",
        "add": "Hinzufügen",
        "document_type": "Art des Dokuments",
        "select_theme_color": "Themenfarbe wählen",
        "company_logo": "Firmenlogo",
        "choose_file": "Datei wählen",
        "your_company_name": "Ihr Firmenname",
        "company_address": "Firmenanschrift",
        "currency": "Zahlungsmittel",
        "no": "Nein",
        "terms_&_condition": "Allgemeine Geschäftsbedingungen",
        "notes": "Anmerkungen",
        "date_format": "Datumsformat",
        "line_items": "Einzelpositionen",
        "item_name": "Artikelname",
        "bill_to": "Rechnung an",
        "ship_to": "Versand nach",
        "valid_till": "Fälligkeitsdatum",
        "due_date": "Fälligkeitsdatum",
        "date": "sdatum",
        "item_description": "Artikelbeschreibung",
        "discount": "Rabatt",
        "tax": "MwSt",
        "delete": "Löschen",
        "add_new_item": "Neues Element hinzufügen",
        "signature": "Unterschrift",
        "show_discount": "Rabatt anzeigen",
        "show_tax": "Steuer anzeigen",
        "company_name": "Name der Firma",
        "company_billing_address": "Rechnungsadresse des Unternehmens",
        "billing_address_placeholder_text_value": "Kundenname \nDie Rechnungsadresse Ihres Kunden",
        "shipping_address_placeholder_text_value": "Kundenname \nDie Lieferadresse Ihres Kunden",
        "terms_and_condition_placeholder_text": "Die Zahlung ist innerhalb von 30 Tagen fällig",
        "notes_placeholder_text": "Bitte zahlen Sie den Restbetrag rechtzeitig",
        "hide": "Ausblenden",
        "show": "Show",
        "estimate": "Schätzen",
        "quotation": "Angebot",
        "invoice": "Rechnung",
        "purchase_order": "Bestellung",
        "bills": "Rechnungen",
        "total": "Gesamt",
        "number": "Nummer",
        "edit":"Edit",
        "products": "Produkte",
        "description": "Beschreibung",
        "qty": "Menge",
        "unit_price": "Stückpreis",
        "unit price": "Stückpreis",
        "price": "Preis",
        "amount": "Menge",
        "subtotal": "Zwischensumme",
        "choose_a_template": "Wählen Sie eine Vorlage",
        "go_to_deskera": "Gehe zu Deskera",
        "print_this_document": "Drucken Sie dieses Dokument",
        "send_email_to_clients": "E-Mail an Kunden senden",
        "document_generator": "Dokumentgenerator",
        "please_wait": "Warten Sie mal...",
        "download_as_pdf": "Als PDF herunterladen)",
        "download": "Herunterladen",
        "print": "Drucken",
        "share": "Teilen",
        "try_deskera": "Versuchen Sie es mit Deskera",
        "copy_link": "Link kopieren",
        "share_on_the_web": "Im Web teilen",
        "anyone_with_the_below_link_can_view": "Jeder mit dem folgenden Link kann anzeigen",
        "send_your": "Sende dein",
        "quickly": "Schnell",
        "please_sign_up_with_deskera_to_send_your": "Bitte melden Sie sich bei Deskera an, um Ihre zu senden",
        "dont_worry_your": "Mach dir keine Sorgen, dein",
        "will_still_be_here": "wird noch hier sein.",
        "sign_up_for_free": "Melde dich kostenlos an",
        "already_a_user_Sign_in": "Schon ein Benutzer? Anmelden",
        "share_on_mail": "Auf E-Mail teilen",
        "to": "Zu",
        "subject": "Gegenstand",
        "enter_recipient_email_address": "Geben Sie die E-Mail-Adresse des Empfängers ein",
        "enter_subject_here": "Geben Sie hier den Betreff ein",
        "send": "Senden",
        "cancel": "Abbrechen",
        "edit_table": "Tabelle bearbeiten",
        "save": "Speichern",
        "buyer": "Käufer",
        "seller": "Verkäufer",
        "powered_by_deskera": "Angetrieben von Deskera",
        "details": "Einzelheiten",
        "other_comments": "Andere Kommentare",
        "bill": "Rechnung",
        "order": "Auftrag",

        "email": "Email",

        "items": "Artikel",
        "services": "Dienstleistungen",
        "hours": "Std",
        "rate": "Bewertung",
        "other": "Andere",
        "sales_tax": "Mehrwertsteuer",
        "tax_rate": "Steuersatz",
        "sales tax": "Mehrwertsteuer",
        "tax rate": "Steuersatz",

        "num": "snummer",

        "template_update_failure_message": "Einstellungen konnten nicht aktualisiert werden. Bitte versuche es erneut!",
        "template_save_failure_message": "Einstellungen konnten nicht gespeichert werden. Bitte versuche es erneut!",
        "template_update_success_message": "Vorlage erfolgreich gespeichert",
        "template_save_success_message": "Vorlage erfolgreich gespeichert",
        "additional_information": "zusätzliche Information",
        "other_options": "Andere Optionen",

        "image": "Bild",
        "text": "Text",
        "watermark": "Wasserzeichen",
        "banner": "Banner",
        "custom_field": "benutzerdefiniertes Feld",
        "component_list": "Komponentenliste",

        "email_address": "E-Mail-Addresse",
        "email_address_1": "E-Mail-Adresse",
        "is_not_valid": "ist ungültig.",

        "deskera_suites": "Deskera Suiten",
        "deskera_books": "Deskera Bücher",
        "deskera_people": "Deskera Leute",
        "deskera_sales": "Deskera Sales",
        "try_now": "Versuche es jetzt",

        "text_color": "Textfarbe",
        "image_ratio": "Bildverhältnis (%)",
        "text_size": "Textgröße",
        "opacity" : "Opacity",
        "font_style": "Schriftstil",
        "font_weight": "Schriftgewicht",
        "text_alignment": "Textausrichtung",
        "image_alignment": "Bildausrichtung",
        "image_preview": "Bildvorschau",
        "preview_banner": "Vorschau Banner",
        "banner_selection": "Bannerauswahl",
        "duplicate": "Duplikat",
        "settings": "die Einstellungen",
        "expiry": "Ablauf",
        "batch_no": "Batch No.",

        "saving": "Speichern ...",
        "save_template": "Vorlage speichern",

        "name": "Name",

        "stock_items": "Artikel auf Lager",

        "sending": "Senden ...",
        "set_as_default": "Standard festlegen",

        "no_email_subject": "Kein benutzerdefiniertes Thema gefunden !! Bitte geben Sie Ihren benutzerdefinierten Betreff ein und klicken Sie auf Vorlage speichern",

        "expense": "Aufwand",
        "deposit": "Einzahlung",
        "credit_note": "Gutschrift",
        "debit_note": "Lastschrift",
        "accounts": "Konten",
        "customer_address_placeholder_text_value": "Kundenname\nIhre Kundenadresse",
        "account_name_placeholder_text_value": "Account Code\nKontoname",
        "payment_to": "Zahlung zu",
        "from_account": "Von Account",
        "payment_from": "Zahlung von",
        "to_account": "Um Account",
        "payment_date": "Zahlungsdatum",
        "document_date": "Belegdatum",
        "account_name": "Kontoname",
        "account_description": "Konto Beschreibung",
        "tax_amount": "Steuerbetrag",
        "payment_amount": "Zahlungsbetrag",
        "line_amount": "Zeile Betrag",
        "total_tax_amount": "Total Tax Betrag",
        "contact": "Kontakt",
        "driver_name": "Fahrername",
        "transporter": "Spediteur",
        "vehicle_no": "Fahrzeugnummer",
        "lr_no": "LR No.",
        "contact_address_placeholder_text_value": "Kontaktname\nDie Adresse Ihres Kontakts",

        "printing": "Drucken..",
        "downloading": "Wird heruntergeladen..",

        "incorrect_format": "Falsches Format!",
        "few_formats_allowed": "Es sind nur JPG-, JPEG- und PNG-Dateien zulässig",
        "image_missing": "Bild fehlt!",
        "data_missing": "Fehlende Daten!",
        "select_image_to_proceed": "Bitte wählen Sie das Bild aus, um fortzufahren",
        "no_data_to_export": "Keine zu exportierenden Daten",

        "session_expired_title": "Sitzung abgelaufen!",
        "session_expired_message": "Ihre Sitzung ist abgelaufen. Bitte melden Sie sich erneut an.",
        "login": "Anmeldung",
        "generate_link_to_share": "Link abrufen",
        "burn_after_read": "Nach dem Lesen brennen",
        "generate_link": "Generieren",
        "no_never": "noch nie",
        "1_min": "1 Minute",
        "1_hr": "1 Stunde",
        "1_day": "1 Tag",
        "1_year": "1 Jahr",

        "document_expired_title": "Dokument abgelaufen!",
        "document_expired_message": "Dieses Dokument ist abgelaufen. Bitte kontaktieren Sie den Absender",

        "loading": "Wird geladen...",
        "reference_date": "Referenzdatum",
        "cheque_number": "Check Number",
        "deposit_to": "Einzahlung",

        "password": "Passwort",
        "password_empty_title": "Passwort leer!",
        "password_empty_message": "Passwort kann nicht leer sein. Bitte geben Sie ein Passwort ein und versuchen Sie es erneut!",
        "document_not_found_title": "Dokument nicht gefunden!",
        "document_incorrect_password_title": "Falsches Passwort!",
        "document_incorrect_password_message": "Bitte geben Sie das richtige Passwort ein und versuchen Sie es erneut.",
        "proceed": "Vorgehen",
        "document_enter_password_title": "Passwortgeschütztes Dokument.",
        "document_enter_password_message": "Bitte Passwort eingeben.",

        "preparing_document_email": "Dokument für E-Mail vorbereiten…",
        "preparing_document_print": "Dokument zum Drucken vorbereiten…",
        "loading_the_page": "Laden der Seite",
        "sender": "Absender",
        "reply_to": "Antwort an",
        "enter_sender_name": "Geben Sie den Absendernamen ein",
        "enter_reply_to_email": "Antwort auf E-Mail eingeben",
        "send_a_copy_to_myself_at": "Sende mir eine Kopie an",

        "payment": "Zahlung",
        "paper_format": "Format papier",
        "please_provide_paper_format": "Veuillez indiquer le format papier",
        "paper_format_popup_note": "Sie können das Papierformat weiterhin über das Einstellungsfeld aktualisieren",
        "item_number": "Artikelnummer",
        "item_code": "Artikelcode",
        "received_from": "Received From",
        "payment_details": "Zahlungsdetails",
        "sn_number": "Seriennummer",
        "document_details": "Dokumentdetails",
        "template_saved": "Vorlage gespeichert!",
        "you_design_template_has_been_saved": "Die Vorlage wurde gespeichert, Sie können dieses Fenster schließen und zur BÜCHER-Anwendung zurückkehren.",
        "you_design_template_has_been_saved_people": "Die Vorlage wurde gespeichert, Sie können dieses Fenster schließen und zur PEOPLE-Anwendung zurückkehren.",
        "go_to_books": "Gehe zu Büchern",
        "item_tax": "Produktsteuer",
        "item_discount": "Produktrabatt",
        "uom": "UOM",
        "unitPrice": "Stückpreis",
        "quantity": "Menge",
        "code": "Code",
        "machine_name": "Maschinenname",
        "technician_name": "Technikername",
        "start_date": "Startdatum",
        "schedule_id": "Zeitplan-ID",
        "end_date": "Enddatum",
        "please_select_custom_field": "Bitte wählen Sie ein benutzerdefiniertes Feld.",

        "preview_pdf": "Vorschau Pdf",

        "template_name": "Vorlagenname",
        "please_enter_template_name_to_save": "Bitte geben Sie den zu speichernden Vorlagennamen ein.",
        "custom_template": "Benutzerdefinierte Vorlage",
        "add_new_template": "Neue benutzerdefinierte Vorlage hinzufügen",
        "vendor_name": "Herstellername",

        "decimal_point": "Dezimalpunkt",
        "footer": "Fusszeile",

        "save_as": "Speichern unter",
        "save_as_new_template": "Als neue Vorlage speichern",
        "e_invoice_section": "E-Rechnung Abschnitt",
        "amount_before_tax": "Betrag vor Steuern",

        "order_no": "Bestellnummer",
        "PURCHASE_INVOICE": 'Rechnungen',
        "PURCHASE_ORDER": 'Bestellung',
        "SALES_INVOICE": "Rechnung",
        "QUOTATION": "Angebot",
        "fulfillment": "Erfüllungsauftrag",
        "ship_from": "Versand von",
        "company_address_placeholder_text_value": "Firmenname\nAdresse Ihres Unternehmens",
        "goods_receipt": "Wareneingang",
        "required_qty": "Benötigte Menge",
        "received_qty": "Empfangene Menge",
        "committed_qty": "Zugesicherte Menge",
        "warehouse_code": "Lagercode",
        "warehouse_name": "Lagername",
        "company_warehouse_name": "Firmen-/Lagername",
        "payment_footer": "Zahlung",
        "make_payment": "Zahlung ausführen",
        "receive_payment": "Zahlung erhalten",
        "deposit_advpayment": "Anzahlung",
        "expense_prepayment": "Vorauszahlung",

        "delete_template": "Vorlage löschen",
        "delete_template_message": "Sind Sie sicher, dass Sie die Vorlage löschen möchten? Nach dem Löschen ist die Vorlage nicht mehr abrufbar.",
        "delete_template_ok_label": "Ja. Löschen.",
        "delete_template_success_message": "Vorlage erfolgreich gelöscht.",
        "delete_template_failure_message": "Einstellungen konnten nicht gelöscht werden. Bitte versuchen Sie es erneut!",
        "before_tax": "Vor Steuern",
        "outstanding": "Ausstehender Betrag",

        "signature_section": "Unterschriftsabschnitt",
        "prepared_by": "Vorbereitet von",
        "approved_by": "Genehmigt von",

        "no.": 'No.',
        "#": "#",
        "serial_batch": "Seriell/Batch",
        "amount_in_words": "Betrag in Worten",
        "name_with_description": "Name",

        "invoice_number": "Rechnungsnummer",
        "bill_number": "Rechnungsnummer",
        "total_invoice_amount": "Totaler Rechnungsbetrag",
        "total_bill_amount": "Gesamtrechnungsbetrag",
        "payment_made": "Zahlung erfolgt",
        "current_due_amount": "Aktueller fälliger Betrag",
        "current_balance": "Aktueller Saldo",
        "payment_medium": "Zahlungsmethode",
        "cash": "Bargeld",
        "prepayment": "Vorauszahlung",
        "payment_label": "Zahlung",
        "receipt_label": "Quittung",
        "total_row": "Total Row",

        "journal_entry": "Journaleintrag",
        "journal_entry_number": "JE-Nr.",
        "account_code": "Kontocode",
        "credit": "Kredit",
        "debit": "Lastschrift",
        "GOOD_RECEIPT": "Wareneingang",
        "MAKE_PAYMENT": "Zahlung",
        "RECEIVE_PAYMENT": "Zahlung",
        "CREDIT_NOTE": "Gutschrift",
        "DEBIT_NOTE": "Lastschrift",
        "FULFILLMENT": "Erfüllungsauftrag",
        "SALES_RETURN": "Verkaufsrückgabe",
        "PURCHASE_RETURN": "Kauf-Rückgabe",
        "SALES_CONTRA": "Verkaufskontra",
        "PURCHASE_CONTRA": "Kauf-Kontra",
        "BANK_TRANSFER": "Banküberweisung",
        "TDS_JE": "TDS JE",
        "ADJUSTMENT": "Anpassung",
        "memo": "Memo",

        "pick_list": "Auswahlliste",
        "pack_list": "Pack Liste",
        "ship_list": "Schiffsliste",
        "invoice_or_quotation": "Rechnung/Angebot",
        "product_code": "Produktcode",
        "product_name": "Produktname",
        "barcode": "Strichcode",
        "quantity_to_pick": "Menge zur Auswahl",
        "quantity_required_for_order": "Erforderliche Menge für Bestellung",
        "picked": "Ausgewählt",
        "item_no": "Art.-Nr.",
        "unit": "Einheit",
        "packed_quantity": "Verpackte Menge",
        "warehouse_address": "Lageradresse",
        "delivery": "Lieferung",
        "carrier": "Träger",
        "number_of_carton": "Kartonanzahl",
        "weight": "Gewicht",
        "tracking_number": "Auftragsnummer",
        "package": "Paket",
        "order_number": "Bestellnummer",

        "custom_field_is_missing_title": "Benutzerdefiniertes Feld fehlt",
        "custom_field_is_missing_message": "Das benutzerdefinierte Feld fehlt in diesem Dokument. Bitte fügen Sie ein benutzerdefiniertes Feld aus dem Buch hinzu oder versuchen Sie es mit einem anderen Dokument.",

        "repeatable_header": "Wiederholbarer Tabellenkopf",
        "margin": "Rand",
        "default": "Standard",
        "none": "Keiner",
        "minimum": "Minimum",
        "large": "Groß",

        "receive_by": "Erhalten bis Datum",
        "ship_by": "Versand bis Datum",
        "additional_date": "Zusätzliches Datum",

        "header_footer_section": "Kopfzeile Fußzeilenbereich",
        "header": "Kopfzeile",
        "date_only": "Nur Datum",
        "title_only": "Nur Titel",
        "page_count_only": "Nur Seitenzahl",
        "date_title": "Datum und Titel",
        "date_page_count": "Datum und Seitenzahl",
        "title_page_count": "Titel- und Seitenzahl",
        "date_title_space": "Datum und Titel mit Leerzeichen",
        "date_page_count_space": "Datum und Seitenzählung mit Leerzeichen",
        "title_page_count_space": "Titel- und Seitenzählung mit Leerzeichen",
        "date_title_page_count": "Datum, Titel, Seitenzahl",
        "custom": "Benutzerdefiniert",
        "left": "Linke Seite",
        "center": "Center",
        "right": "Rechte Seite",
        "title": "Titel",
        "page_count": "Seitenzahl",
        "current_page_count": "Aktuelle Seitenzahl",
        "total_page_count": "Gesamtseitenzahl",
        "custom_text": "Benutzerdefinierter Text",
        "page": "Buchseite",

        "company_phone_number": "Telefonnummer des Unternehmens",
        "company_gstin": "Firma GSTIN",
        "company_state_name_and_code": "Name und Code des Unternehmensstaats",
        "payment_terms": "Zahlungsbedingungen",
        "customer_phone_number": "Kundentelefonnummer",
        "customer_gstin": "Kunden-GSTIN",
        "customer_state_name_and_code": "Name und Code des Kundenstaats",
        "print_only_message": "Dies ist ein Computer generiert",
        "hsn_or_sac": "HSN/SAC",
        "taxable_value": "Steuerpflichtiger Wert",
        "integrated_tax": "Integrierte Steuer",
        "central_tax": "Zentralsteuer",
        "state_tax": "Staatssteuer",
        "cess": "CESS",
        "state_name": "Staatsname",
        "state_code": "Code",
        "label_state_code": "Landesgesetzbuch",

        "address_format": "Adressformat",
        "address_1": "Adresse 1",
        "address_2": "Adresse 2",
        "city": "Stadt",
        "state": "Zustand",
        "postal_code": "Postleitzahl",
        "country": "Land",
        "is_apply_address_format_to_other_addresses": "Wenden Sie das aktuelle Adressformat auf alle Adressen an.",
        "product_grouping": "Produktgruppierung",
        "product_group":"Produktgruppe",
        "cheque": "Scheck",
        "bank_transfer": "Banküberweisung",
        "card": "Karte",

        "payslip": "Gehaltsabrechnung",
        "earning_statement": "Verdienstnachweis",
        "pay_period": "Zahlungszeitraum",
        "pay_date": "Zahltag",
        "ssn": "SSN",
        "taxable_marital_status": "Steuerpflichtiger Familienstand",
        "exemptions": "Ausnahmen",
        "date_of_joining": "Beitrittsdatum",
        "pf_a_c_NUMBER": "PF-A/C-Nummer",
        "esi_number": "ESI-Nummer",
        "uan_number": "UAN-Nummer",
        "employee_id": "Mitarbeiter-ID",
        "designation": "Bezeichnung",
        "employee_net_pay": "Nettolohn der Mitarbeiter",
        "employee_earnings": "Mitarbeiterverdienst",
        "allowances": "Zulagen",
        "deductions": "Abzüge",
        "employer_contribution": "Arbeitgeberbeitrag",
        "employer_cpf": "CPF . des Arbeitgebers",
        "net_salary_paid": "Bezahltes Nettogehalt",
        "employee_taxes_withhold": "Einbehalt von Arbeitnehmersteuern",
        "employer_taxes": "Arbeitgebersteuern",
        "employee_deductions": "Mitarbeiterabzüge",
        "summary": "Zusammenfassung",
        "basic_salary": "Grundgehalt",
        "net_pay": "Nettogehalt",
        "additional_earnings": "Zusatzverdienst",
        "gross_earnings": "Brutto Einkommen",
        "duration": "Dauer",
        "current": "Strom",
        "year_to_date": "Der bisherige Jahresverlauf",
        "type": "Typ",
        "pre_tax_deductions_contributions": "Vorsteuerabzüge / Beiträge",
        "taxes": "Steuern",
        "post_tax_deductions_contributions": "Nachsteuerabzüge / Beiträge",
        "net_pay_contributions": "Nettolohn / Beiträge",
        "check_amount": "Betrag prüfen",
        "payslip_auto_message": "Dies ist eine vom System generierte Gehaltsabrechnung, für die kein Siegel und keine Unterschrift erforderlich ist",
        "employee_address": "Mitarbeiteradresse",
        "field_configuration": "Feldkonfiguration",
        "table_configuration": "Tabellenkonfiguration",
        "employee_name_address": "Name und Anschrift des Mitarbeiters",
        "employee_bank_acc_number": "Bankkontonummer des Mitarbeiters",
        "header_title": "Kopfzeilentitel",
        "product_custom_field": "Benutzerdefinierte Produktfelder",
        "quantity_and_uom": "Menge und Maßeinheit",
        "includes_product_custom_field": "Enthält benutzerdefinierte Produktfelder",
        "combines_product_custom_field": "Kombiniert benutzerdefinierte Produktfelder",
        "serial_number": "Seriennummer",
        "batch_number": "Chargennummer",
        "manufacturing_date": "Herstellungsdatum",
        "expiry_date": "Verfallsdatum",
        "override_theme_color": "Designfarbe überschreiben",
        "override_global_color": "Globale Farbe überschreiben",
        "contact_number": "Telefonnummer",
        "contact_code": "Kontakt-Code",
        "blank": "Leer",
        "pay_to": "Bezahlen an",
        "payroll_check": "Gehaltsabrechnung",
        "pay_to_the": "ZAHLEN SIE AN DIE",
        "order_of": "BESTELLUNG VON",
        "dollars": "DOLLAR",
        "payroll_period": "Gehaltsabrechnung für den Zeitraum vom",
        "repeatable_header_footer": "Wiederholbare Kopf- und Fußzeile",
        "repeatable_footer": "Wiederholbare Fußzeile",
        "includes_hsn_or_sac_code": "Enthält HSN/SAC-Code",
        "eway_bill_no":  "Eway Bill Nr",
        "eway_bill_date": "Eway Bill Datum",
        "place_of_supply":'Ort der Lieferung',
        "destination_of_supply":"Bestimmungsort der Lieferung",
        "source_of_supply":"Versorgungsquelle",
        "subTable_configuration": "Untertabellenkonfiguration",
        "additional_table_configuration": "Zusätzliche Tabellenkonfiguration",
        "select_new_type": "Wählen Sie Neuer Typ",
        "qr_code": "QR-Code",
        "qr_string": "QR-String",
        "generated_qr": "QR generiert",
        "qr_code_with_text": "QR-Code mit Text",
        "image_with_text": "Bild mit Text",
        "qr_information": "QR-Informationen",
        "additional_info": "Zusätzliche Information",
        "global_discount": "Globaler Rabatt",
        "pre_tax": "Vorsteuer",
        "post_tax": "Nachsteuer",
        "includes_uom_schema": "Enthält UOM-Schema",
        "table": "Tisch",
        "font": "Schriftart",
        "table_row_height": "Tabellenzeilenhöhe",
        "padding_horizontal": "Horizontale Polsterung",
        "padding_vertical": "Polsterung vertikal",
        "original": "Original",
        "original_for_recipient": "Original für den Empfänger",
        "duplicate_supply_of_goods": "Duplikat (Warenlieferung)",
        "duplicate_for_transporter": "Duplikat für Transporter",
        "duplicate_supply_of_service": "Duplikat (Dienstleistung)",
        "duplicate_for_supplier": "Duplikat für Lieferanten",
        "triplicate": "Verdreifachen",
        "triplicate_for_supplier": "Dreifach für den Lieferanten",
        "position": "Position",
        "middle": "Mitte",
        "sample_watermark": "Beispielwasserzeichen",
        "extra_copy": "Zusätzliche Kopie",
        "quadruplicate": "Vierfach",
        "MAX_IMG_UPLOAD_ALERT": "Die Bildgröße darf 500 KB nicht überschreiten",
        "system_default": "Systemfehler",
        "qr_resize": "QR-Größenänderung",
        "top": "Top",
        "bottom": "Unten",
        "click_here_to_pay": "Klicken Sie hier, um zu bezahlen",
        "generated_message": "Generierte Nachricht",
        "sales_order": "Verkaufsauftrag",
        "help": "Hilfe",
        "includes_account_custom_field": "Enthält benutzerdefinierte Felder des Kontos",
        "combines_account_custom_field": "Kombiniert benutzerdefinierte Felder des Kontos",
        "account_custom_field": "Benutzerdefinierte Felder des Kontos",
        "selection": "Selektion",
        "alias_name_address": "Alias ​​Name und Adresse",
        "nric_fin_wpn": "NRIC/FIN/WPN",
        "paid_days": "Bezahlte Tage",
        "unpaid_days": "Unbezahlte Tage",
        "payslip_overtime": "Im Laufe der Zeit",
        "payslip_LOP": "Bezahlungsverlust",
        "outstanding_balance": "Offener Betrag",
        "outstanding_balance_includes_current": "Ausstehender Saldo (einschließlich aktuell)",
        "top_section_above_table": "Oberer Abschnitt über der Tabelle",
        "is_group_additional_charges_tax": "Ob Gruppenzuschlag Steuer",
        "supplier_invoice_no": "Lieferantenrechnung Nr",
        "default_template": "Standardvorlage",
        "contact_name": "Kontaktname",
        "current_date": "Aktuelles Datum",
        "e_way_bill_summary": "Eway Bill Zusammenfassung",
        "e_way_bill_detail": "Eway Bill-Detail",
        "table_column_alignment": "Ausrichtung der Tabellenspalte",
        "table_column_vertical_alignment": "Vertikal ausrichten",
        "earnings_configuration": "Ertragskonfiguration",
        "label_empty_field_message": "Die Feldbezeichnung darf nicht leer sein, bitte geben Sie einen Wert ein, um fortzufahren.",
        "product_table": "Produkttabelle",
        "rounding_off": "Abrundung",
        "work_order": "Arbeitsauftrag",
        "order_prep_completed": "Auftragsvorbereitung abgeschlossen",
        "barcode_settings": "Barcode-Einstellungen",
        "barcode_resize": "Barcode-Größenänderung",
        "comments": "Kommentare",
        "delivery_date": "Lieferdatum",
        "estimated_time": "Geschätzte Zeit",
        "actual_quantity": "Tatsächliche Menge",
        "manufacture_quantity": "Herstellungsmenge",
        "parent_work_order": "Übergeordneter Arbeitsauftrag",
        "stock_transfer": "Bestandsübertragung",
        "source": "Quelle",
        "destination": "Ziel",
        "source_address": "Quelladresse",
        "destination_address": "Zieladresse",
        "transfer_date": "Übertragungsdatum",
        "verify_date": "Verifikationsdatum",
        "reason": "Grund",
        "warehouse": "Lagerhaus",
        "stock_adjustment": "Bestandsanpassung",
        "source_warehouse_name": "Name des Quelllagers",
        "source_warehouse_code": "Code des Quelllagers",
        "destination_warehouse_name": "Name des Ziel-Lagers",
        "destination_warehouse_code": "Code des Ziel-Lagers",
        "product": "Produkt",
        "show_product": "Produkt anzeigen",
        "hide_product_with_substitute": "Produkt mit Ersatz verbergen",
        "substitute": "Ersatz",
        "show_all_substitute": "Alle Ersatzprodukte anzeigen",
        "show_non_zero_substitute": "Zeige zugewiesene Ersatzprodukte",
        "hide_substitute": "Ersatz ausblenden",
        "combines_line_item_global_discount": "Kombinieren Sie den globalen Rabatt für Einzelposten",
        "unit_price_before_discount": "Stückpreis vor Rabatt",
        "unit_price_after_discount": "Stückpreis nach Rabatt",
        "discount_per_unit": "Rabatt pro Einheit",
        "show_all": "Alle anzeigen",
        "percentage": "Prozentsatz",
        "cogs": "Kosten der verkauften Waren",
        "na": "NA",
        "con_number": "Kundenbestellnummer",
        "document_label": "Barcode-Etikett",
        "expected_delivery_date": "Voraussichtliches Lieferdatum",
        "transaction_type": "Transaktionstyp",
        "transaction_no": "Transaktionsnummer",
        "status": "Status",
        "productCode":"Product Code",
        "remark" : "Remark",
        "qc_document": "QC-Dokument",
        "linked_po_so": "Verknüpfte Bestellung/Verkaufsauftrag",
        "approver": "Genehmiger",
        "sample_size": "Stichprobengröße",
        "number_of_qty_passed": "Anzahl der akzeptierten Stücke",
        "number_of_qty_rejected": "Anzahl der abgelehnten Stücke",
        "final_remark": "Abschließende Bemerkung",
        "value": "Wert",
        "exchange_conversion": "Exchange Conversione",
        "contact_address": "Kontakt Adresse",
        "cheque_fields": "Scheckfelder",
        "cheque_details": "Scheckdetails",
        "sales_return": "Rückgabe von Verkäufen",
        "fulfilled_quantity": "Erfüllte Menge",
        "returned_quantity": "Zurückgegebene Menge",
        "parent_linked_document_no": "Übergeordnetes Dokument Nr",
        "parent_linked_document_type": "Übergeordneter Dokumenttyp",
        "swap_address": "Adresse tauschen",
        "vendor_address": "Lieferantenadresse",
        "job_work_out_order": "Arbeitsauftrag außer Haus",
        "machine":"Maschinen scheduler",
        "kind_of_packing": "Anzahl und Art der Verpackungen",
        "due_on": "Fällig am",
        "per": "Pro",
        "voucher_no": "Belegnummer",
        "dated": "Datum",
        "supplier_ref_order_no": "Lieferantenreferenz-/Auftragsnummer",
        "mode_term_of_payment": "Zahlungsart-/Bedingungen",
        "dispatch_doc_no": "Versanddokumentnummer",
        "dispatched_through": "Versandart",
        "duration_of_process": "Dauer des Prozesses",
        "nature_of_processing": "Art der Verarbeitung",
        "company_pan": "Firmen-PAN",
        "authorised_signatory": "Bevollmächtigter Unterzeichner",
        "additional_charges": "Zusätzliche Kosten",
        "contact_no": "Kontakt Nr",
        "supplier_inv_no": "Lieferanten-Inv.-Nr",
        "basic_salary_wo_lop": "Grundgehalt ohne Gehaltseinbußen",
        "cost": "Kosten",
        "stock_request": "Bestandsanforderung",
        "target_warehouse_name": "Ziel-Lager",
        "target_warehouse_code": "Ziel-Lagercode",
        "selected_row": "Ausgewählte Reihe",
        "selected_rack": "Ausgewähltes Regal",
        "selected_bin": "Ausgewählter Behälter",
        "requested_qty": "Angeforderte Menge",
        "created_by": "Erstellt von",
        "approved_date": "Genehmigungsdatum",
        "stock_issue": "Bestandsausgabe",
        "gate_entry": "Sicherheitseingang",
        "issued_quantity": "Ausgegebene Menge",
        "bom_product_table": "Stückliste-Produkttabelle",
        "list": "Liste",
        "is_multi_product_table": "Mehrprodukttabelle",
        "is_show_optional_group": "Optionale Produktgruppe",
        "tax_registration_number": "Steuernummer",
        "tax_payer_id": "Steuernummer des Steuerzahlers",
        "wo_qty": "Arbeitsauftragsmenge",
        "adhoc_items_message": "* steht für Ad-hoc-Artikel",
        "production_checklist": "Produktions-Checkliste",
        "product_description": "Produktbeschreibung",
        "total_quantity": "Gesamtmenge",
        "job_status": "Auftragsstatus",
        "qc_status": "QC-Status",
        "show_job_status": "Jobkartenstatus anzeigen",
        "show_instruction": "Anweisung anzeigen",
        "optional": "Optional",
        "document_title": "Dokumenttitel",
        "barcode_for_row_rack_bin": "Barcode für Reihe/Regal/Bin",
        "show_tagged_bin_warehouse": "Markiertes Behälterlager",
        "hsn_sac_tax_table": "HSN/SAC Steuertabelle",
        "approval_section": "Genehmigungsbereich",
        "border": "Rahmen",
        "spacing": "Abstand",
        "checked_by": "Geprüft von",
        "authorized_by": "Autorisiert von",
        "word_spacing": "Wortabstand",
        "page_count_settings": "Seiteneinstellungen",
        "single_digit": "Einzelne Ziffer",
        "page_with_numeric": "Seite mit Nummer",
        "page_x_of_total_page": "Seite x von y",
        "page_x_of_total_page_slash": "Seite x / y",
        "service_requisition": "Serviceanforderung",
        "service_description": "Leistungsbeschreibung",
        "sc_no":"SC-Nr",
        "sc_date":"SC-Datum",
        "sc_type":"SC-Typ",
        "delivery_order_doc_and_date": "Lieferscheinnummer und Datum",
        "e_way_bill_doc_and_date": "Frachtbriefnummer und Datum",
        "party_order_doc_and_date": "Bestellnummer und Datum der Partei",
        "mode_of_transport": "Transportart",
        "bank_details": "Bankdaten",
        "bank_name": "Bankname",
        "company_seal": "Firmensiegel",
        "regd_office": "Hauptsitz",
        "sub_total_in_words": "Zwischensumme in Worten",
        "rounding_off_in_words": "Rundung in Worten",
        "outstanding_balance_in_words": "Offener Saldo in Worten",
        "outstanding_balance_includes_current_in_words": "Offener Saldo (einschließlich aktuell) in Worten",
        "total_tax_amount_in_words": "Gesamtsteuerbetrag in Worten",
        "before_tax_in_words": "Vor Steuer in Worten",
        "additional_charges_in_words": "Zusätzliche Gebühren in Worten",
        "global_discount_in_words": "Globaler Rabatt in Worten",
        "cgst_in_words": "CGST in Worten",
        "igst_in_words": "IGST in Worten",
        "sgst_in_words": "SGST in Worten",
        "person_name": "Personenname",
        "store_officer": "Lageroffizier",
        "department_holder": "Abteilungsleiter",
        "purchase_department": "Einkaufsabteilung",
        "issue_by": "Ausgestellt von",
        "paper_orientation": "Papierausrichtung",
        "landscape": "Querformat",
        "portrait": "Hochformat",
        "purchase_requisition": " Bestellanforderung",
        "phone": " Telefon",
        "requisition_qty": " Anforderungsmenge",
        "stock_qty": " Lagermenge",
        "prev_purchase_rate": " Vorher. Kaufpreis",
        "scheduled_date": " Geplantes Datum",
        "estimate_rate": " Schätzpreis",
        "estimate_value": " Schätzwert",
        "cost_center": " Kostenstelle",
        "reserved": " Reserviert",
        "division": " Aufteilung",
        "department_name": " Abteilungsname",
        "indent_no": " Gedankenstrich Nr",
        "indent_date": " Datum einrücken",
        "indent_type": " Einrückungstyp",
        "project": " Projekt",
        "authorised_date": " Autorisiertes Datum",
        "requested_by": " Angefordert von",
        "for": " Für",
        "authorised_by": " Authorisiert von",
        "available_qty": "Verfügbare Menge",
        "service_order": " Serviceauftrag",
        "gst_no": " GST-Nr",
        "gstin_no": " GSTIN-Nr",
        "pan_no": " PAN-Nr",
        "cin_no": " CIN-Nr",
        "cont_person": " Fortsetzung Person",
        "order_date": " Auftragsdatum",
        "shipment_address": " Lieferadresse",
        "prepared_date_time": "Vorbereitete Datum und Uhrzeit",
        "only": "Nur",
        "material_receipt_note": "Materialeingangsbeleg",
        "mrn_no": "MRN-Nr",
        "authorized": "Autorisiert",
        "inspected_by": "Geprüft durch",
        "store_manager": "Filialleiter",
        "hod": "H.O.D",
        "account_manager": "Account-Manager",
        "po_bill_no_date": "Bestellnr./Datum\nRechnungsnr./Datum",
        "short_excess_rejected_qty": "Unter-/Überschuss-/Abgelehnte Menge.",
        "accepted_qty": "Akzeptierte Menge.",
        "basic_amt": "Grundbetrag",
        "charges": "Gebühren",
        "last_po": "Letzte Bestellung",
        "cgst": "CGST",
        "sgst": "SGST",
        "igst": "IGST",
        "gst": "GST",
        "total_amount": "Gesamtmenge",
        "total_passed_amount": "Insgesamt bestandener Betrag",
        "inspection_required": "Inspektion erforderlich",
        "completed": "Abgeschlossen",
        "location_detail": "Standortdetails",
        "transporter_detail": "Transporteurdetails",
        "item_detail": "Artikeldetails",
        "qc_inspection": "QC-Inspektion",
        "work_order_no": "Arbeitsauftragsnummer",
        "work_order_date": "Arbeitsauftragsdatum",
        "work_order_due_date": "Fälligkeitsdatum des Arbeitsauftrags",
        "work_order_status": "Arbeitsauftragsstatus",
        "edit_table_columns": "Tabellenspalten bearbeiten",
        "incoming": "Eingehend",
        "final": "Endgültig",
        "label": "Etikett",
        "both": "Beides",
        "inspection_report": "Inspektionsbericht",
        "inspection_date": "Datum",
        "parts": "Teile",
        "reworks": "Nacharbeiten",
        "final_inspection": "Endkontrolle",
        "product_listing": "Produktliste",
        "paper_margin": "Papiermarge",
        "is_using_custom_margin": "Verwendet benutzerdefinierte Marge",
        "soa_customer": "Kontoauszug",
        "soa_vendor": "Kontoauszug - Lieferant",
        "company_reg_no": "Firmenregisternummer",
        "gst_reg_no": "USt-IdNr.",
        "from_date": "Ab Datum",
        "as_of_date": "Zum Datum",
        "to_date": "Bis Datum",
        "document_number": "Dokumentnummer",
        "particular": "Besonderheit",
        "amount_in_base": "Betrag in Basiswährung",
        "balance_in_base": "Saldo in Basiswährung",
        "ifsc_code": "IFSC-Code",
        "purchase_request_for_quotes": "Anfrage für Angebot",
        "purchase_inward_quotation": "Eingehendes Kaufangebot",
        "rfq_no": "Angebotsanfrage-Nr.",
        "show_zero": "Null anzeigen",
        "show_total": "Gesamt anzeigen",
        "draft_watermark": "Entwurfs-Wasserzeichen",
        "draft": "Entwurf",
        "rotation": "Drehung",
        "invoice_summary": "Rechnungszusammenfassung",
        "bill_summary": "Rechnungszusammenfassung",
        "amount_due": "Fälliger Betrag",
        "total_pending_balance": "Gesamtausstehender Saldo",
        "serial_batch_custom_fields": "Benutzerdefinierte Felder für Seriencharge",
    }
};

export default de
