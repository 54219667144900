import { WorkOrderHelper } from '../Components/Mrp/WorkOrder/WorkOrderHelper';
import {
  DOCUMENT_STATUS,
  DOC_TYPE,
  FULFILLMENT_STATUS,
  FULFILLMENT_TYPE,
  PAYMENT_STATUS
} from '../Constants/Constant';
import Utility, { isDocumentLocked } from '../Utility/Utility';
import { isTabletView } from '../Utility/ViewportSizeUtils';

export enum SELL_CONFIG_BTN {
  EDIT = 'edit',
  DELETE = 'delete',
  CONVERT = 'convert',
  FULFILL = 'fulfill',
  FULFILL_RECORD = 'fulfill_record',
  NEW = 'new',
  RECURRING = 'recurring',
  COPY = 'copy',
  GET_PAID = 'get_paid',
  OFFSET_PAYMENT = 'offset_payment',
  PAYMENT_RECORD = 'payment_record',
  IMPORT = 'import',
  EXPORT = 'export',
  RETURN = 'return',
  RETURN_RECORDS = 'return_records',
  VIEW = 'view',
  GENERATE_EWAY_BILL = 'generate_eway_bill',
  PRINT = 'print',
  EMAIL = 'email',
  DESIGN = 'design',
  ATTACHMENT = 'attachment',
  SEND_INVOICE_NOW = 'send_invoice_now',
  EINVOICE = 'eInvoice',
  WORK_ORDER = 'work_order',
  LINKED_RECORDS = 'linked_records'
}

export default class SellConfig {
  static showActionButton = (
    btn: SELL_CONFIG_BTN,
    document: any,
    documentType: DOC_TYPE
  ) => {
    let showBtn = true;
    switch (btn) {
      case SELL_CONFIG_BTN.EDIT:
        if (
          document.status === DOCUMENT_STATUS.CLOSED ||
          document.status === DOCUMENT_STATUS.PROCESSED
        ) {
          showBtn = false;
        } else if (
          document['linkedSalesInvoices'] &&
          document['linkedSalesInvoices'].length > 0
        ) {
          showBtn = false;
        } else if (
          documentType === DOC_TYPE.QUOTE &&
          document['linkedSalesOrders'] &&
          document['linkedSalesOrders'].length > 0
        ) {
          showBtn = false;
        } else if (
          documentType === DOC_TYPE.SALES_ORDER &&
          document['linkedSalesInvoices'] &&
          document['linkedSalesInvoices'].length > 0
        ) {
          showBtn = false;
        } else if (
          documentType === DOC_TYPE.SALES_ORDER &&
          isDocumentLocked({ ...document, documentType: DOC_TYPE.SALES_ORDER })
        ) {
          showBtn = false;
        } else if (
          document.fulfillmentStatus &&
          document.fulfillmentStatus !== FULFILLMENT_STATUS.UNFULFILLED
        ) {
          showBtn = false;
        } else if (
          document.fulfillmentType &&
          (document.fulfillmentType === FULFILLMENT_TYPE.PICK_PACK_SHIP ||
            document.fulfillmentType === FULFILLMENT_TYPE.DROP_SHIP)
        ) {
          showBtn = false;
        } else if (
          documentType === DOC_TYPE.INVOICE &&
          document.paymentStatus &&
          document.paymentStatus !== PAYMENT_STATUS.PENDING
        ) {
          showBtn = false;
        } else if (
          documentType === DOC_TYPE.INVOICE &&
          document['openingInvoice']
        ) {
          showBtn = false;
        } else if (
          Utility.isSalesDocument(document) &&
          document['reservedStock']
        ) {
          showBtn = false;
        } else if (
          document.documentDate ||
          document.salesInvoiceDate ||
          document.salesOrderDate
        ) {
          let res = Utility.validationForClosedDate(document);
          showBtn = !res.isDisable;
        }
        if (document.totalAmount === 0 && document.status === 'OPEN') {
          showBtn = true;
        }
        break;
      case SELL_CONFIG_BTN.DELETE:
        if (
          document['linkedSalesInvoices'] &&
          document['linkedSalesInvoices'].length > 0
        ) {
          showBtn = false;
        } else if (
          documentType === DOC_TYPE.QUOTE &&
          document['linkedSalesOrders'] &&
          document['linkedSalesOrders'].length > 0
        ) {
          showBtn = false;
        } else if (
          documentType === DOC_TYPE.SALES_ORDER &&
          ((document['linkedSalesInvoices'] &&
            document['linkedSalesInvoices'].length > 0) ||
            document['fulfillmentType'] === FULFILLMENT_TYPE.PICK_PACK_SHIP)
        ) {
          showBtn = false;
        } else if (
          Utility.isSalesDocument(document) &&
          document['reservedStock'] &&
          !document.isDraftDocument
        ) {
          showBtn = false;
        } else if (
          (document.documentDate ||
            document.salesInvoiceDate ||
            document.salesOrderDate) &&
          document?.isDraftDocument !== true
        ) {
          let res = Utility.validationForClosedDate(document);
          showBtn = !res.isDisable;
        }
        break;
      case SELL_CONFIG_BTN.CONVERT:
        const nonPendingProductsInQuote = Utility.getNonPendingsItems(
          document.quotationItemDtoList ? document.quotationItemDtoList : []
        );
        const nonPendingProductsInSalesOrder = Utility.getNonPendingsItems(
          document.salesOrderItems ? document.salesOrderItems : []
        );
        if (
          documentType === DOC_TYPE.QUOTE &&
          (document.linkedSalesInvoices?.length > 0 ||
            document.linkedSalesOrders?.length > 0) &&
          nonPendingProductsInQuote?.length ===
            document.quotationItemDtoList?.length
        ) {
          showBtn = false;
        } else if (
          documentType === DOC_TYPE.SALES_ORDER &&
          document.linkedSalesInvoices?.length > 0 &&
          nonPendingProductsInSalesOrder?.length ===
            document.salesOrderItems?.length
        ) {
          showBtn = false;
        } else if (
          documentType === DOC_TYPE.QUOTE &&
          document.status === DOCUMENT_STATUS.PROCESSED
        ) {
          showBtn = false;
        } else if (
          documentType === DOC_TYPE.SALES_ORDER &&
          (document.status === DOCUMENT_STATUS.PROCESSED ||
            document.status === DOCUMENT_STATUS.CLOSED)
        ) {
          showBtn = false;
        } else if (
          documentType === DOC_TYPE.SALES_ORDER &&
          document.fulfillmentStatus === FULFILLMENT_STATUS.PENDING_FOR_APPROVAL
        ) {
          showBtn = false;
        }
        break;
      case SELL_CONFIG_BTN.FULFILL:
        if (
          (documentType === DOC_TYPE.QUOTE ||
            documentType === DOC_TYPE.SALES_ORDER) &&
          (document.status === DOCUMENT_STATUS.PROCESSED ||
            document.status === DOCUMENT_STATUS.CLOSED)
        ) {
          showBtn = false;
        } else if (
          document.fulfillmentStatus === FULFILLMENT_STATUS.FULLY_FULFILLED ||
          document.fulfillmentStatus === FULFILLMENT_STATUS.PENDING_FOR_APPROVAL
        ) {
          showBtn = false;
        }

        break;
      case SELL_CONFIG_BTN.FULFILL_RECORD:
        if (document.fulfillmentStatus === FULFILLMENT_STATUS.UNFULFILLED) {
          showBtn = false;
        }
        break;
      case SELL_CONFIG_BTN.NEW:
      case SELL_CONFIG_BTN.RECURRING:
      case SELL_CONFIG_BTN.COPY:
        break;
      case SELL_CONFIG_BTN.GET_PAID:
        if (document.paymentStatus === PAYMENT_STATUS.RECEIVED) {
          showBtn = false;
        }
        break;
      case SELL_CONFIG_BTN.OFFSET_PAYMENT:
        if (document.openingInvoice) {
          showBtn = false;
        }
        break;
      case SELL_CONFIG_BTN.PAYMENT_RECORD:
        if (document.paymentStatus === PAYMENT_STATUS.PENDING) {
          showBtn = false;
        }
        break;

      case SELL_CONFIG_BTN.IMPORT:
        if (isTabletView()) {
          showBtn = false;
        }
        break;
      case SELL_CONFIG_BTN.EXPORT:
        if (isTabletView()) {
          showBtn = false;
        }
        break;
      case SELL_CONFIG_BTN.RETURN:
      case SELL_CONFIG_BTN.RETURN_RECORDS:
        if (
          document.fulfillmentStatus === FULFILLMENT_STATUS.UNFULFILLED ||
          (document.isPartialInvoice &&
            document.fulfillmentStatus === FULFILLMENT_STATUS.UNFULFILLED) ||
          document.fulfillmentType === FULFILLMENT_TYPE.DROP_SHIP
        ) {
          showBtn = false;
        } else if (
          documentType === DOC_TYPE.SALES_ORDER &&
          document.fulfillmentStatus === FULFILLMENT_STATUS.PENDING_FOR_APPROVAL
        ) {
          showBtn = false;
        }
        break;
      case SELL_CONFIG_BTN.WORK_ORDER:
        let hasItemToBuild = WorkOrderHelper.soHasRemainingQtyToBuild(document);
        let soConvertedFromdWO =
          WorkOrderHelper.isSOConvertedFromWorkOrder(document);
        if (
          (!Utility.isMRP() ||
            !Utility.isEmpty(document?.linkedWorkOrderDocuments)) &&
          documentType === DOC_TYPE.SALES_ORDER &&
          (!hasItemToBuild || soConvertedFromdWO)
        ) {
          showBtn = false;
        }
        const atLeastOneLinkedDocIsWO = document?.linkedDocuments?.some(
          (doc: any) => doc.documentType === DOC_TYPE.WORK_ORDER
        );
        if (
          (!Utility.isMRP() || !Utility.isEmpty(document?.linkedDocuments)) &&
          atLeastOneLinkedDocIsWO &&
          documentType === DOC_TYPE.INVOICE
        ) {
          showBtn = false;
        }
        break;
      case SELL_CONFIG_BTN.LINKED_RECORDS:
        if (document.isDraftDocument) {
          showBtn = false;
        }
        break;
      case SELL_CONFIG_BTN.DESIGN:
        if (isTabletView()) {
          showBtn = false;
        }
        break;
    }
    return showBtn;
  };

  static defaultSellConfigSettings = {
    [DOC_TYPE.QUOTE]: {
      showTotalInBaseCurrency: false,
      filterDate: {
        start: new Date(),
        end: new Date()
      }
    },
    [DOC_TYPE.INVOICE]: {
      showTotalInBaseCurrency: false,
      filterDate: {
        start: new Date(),
        end: new Date()
      }
    },
    [DOC_TYPE.SALES_ORDER]: {
      showTotalInBaseCurrency: false,
      filterDate: {
        start: new Date(),
        end: new Date()
      }
    },
    CLOSED: {
      showTotalInBaseCurrency: false,
      filterDate: {
        start: new Date(),
        end: new Date()
      }
    }
  };
}
