let hi = {
    translations: {
        "component": "अंग",
        "add": "जोड़ना",
        "document_type": "दस्तावेज़ का प्रकार",
        "select_theme_color": "थीम रंग का चयन करें",
        "company_logo": "कंपनी का लोगो",
        "choose_file": "फ़ाइल का चयन",
        "your_company_name": "आपके संगठन का नाम",
        "company_address": "कंपनी का पता",
        "currency": "मुद्रा",
        "no": "नहीं",
        "terms_&_condition": "नियम एवं शर्तें",
        "notes": "टिप्पणियाँ",
        "date_format": "डेटा प्रारूप",
        "line_items": "लाइन आइटम",
        "item_name": "वस्तु का नाम",
        "bill_to": "बिल प्राप्तकर्ता",
        "ship_to": "यहां भेजें",
        "valid_till": "तक मान्य है",
        "due_date": "नियत तारीख",
        "date": " दिनांक",
        "item_description": "वस्तु वर्णन",
        "discount": "छूट",
        "tax": "कर",
        "delete": "हटाएं",
        "add_new_item": "नए सामान को जोड़ो",
        "signature": "हस्ताक्षर",
        "show_discount": "छूट दिखाएँ",
        "show_tax": "कर दिखाओ",
        "company_name": "कंपनी का नाम",
        "company_billing_address": "कंपनी का बिलिंग पता",
        "billing_address_placeholder_text_value": "ग्राहक का नाम \nग्राहक का बिलिंग पता",
        "shipping_address_placeholder_text_value": "ग्राहक का नाम \nग्राहक का शिपिंग पता",
        "terms_and_condition_placeholder_text": "भुगतान 30 दिनों के भीतर होने वाला है",
        "notes_placeholder_text": "कृपया समय के भीतर शेष राशि का भुगतान करें",
        "hide": "छिपाना",
        "show": "प्रदर्शन",
        "estimate": "आकलन",
        "quotation": "उद्धरण",
        "invoice": "बीजक",
        "purchase_order": "क्रय आदेश",
        "bills": "विधेयकों",
        "total": "संपूर्ण",
        "number": "संख्या",

        "edit": "संपादित करें",
        "products": "उत्पादों",
        "description": "विवरण",
        "qty": "मात्रा",
        "unit_price": "यूनिट मूल्य",
        "unit price": "यूनिट मूल्य",
        "price": "कीमत",
        "amount": "रकम",
        "subtotal": "उप-योग",

        "choose_a_template": "एक टेम्पलेट चुनें",
        "go_to_deskera": "गोटो डेस्करा",
        "print_this_document": "इस दस्तावेज़ को प्रिंट करें",
        "send_email_to_clients": "ग्राहकों को ई-मेल भेजें",

        "document_generator": "दस्तावेज़ जेनरेटर",
        "please_wait": "कृपया प्रतीक्षा करें...",
        "download_as_pdf": "(.Pdf) के रूप में डाउनलोड करें",
        "download": "डाउनलोड",
        "print": "छाप",
        "share": "शेयर",
        "try_deskera": "डेस्करा आज़माएं",
        "copy_link": "प्रतिरूप जोड़ना",
        "share_on_the_web": "वेब पर साझा करें",
        "anyone_with_the_below_link_can_view": "नीचे दिए गए लिंक के साथ कोई भी देख सकता है",

        "send_your": "अपना भेजें",
        "quickly": "जल्दी से",
        "please_sign_up_with_deskera_to_send_your": "अपने भेजने के लिए कृपया डेस्करा के साथ साइन अप करें",
        "dont_worry_your": "चिंता मत करो, तुम्हारी",
        "will_still_be_here": "अभी भी यहीं रहेगा।",
        "sign_up_for_free": "मुफ्त में साइन अप",
        "already_a_user_Sign_in": "पहले से ही एक उपयोगकर्ता? साइन इन करें",

        "share_on_mail": "ईमेल पर साझा करें",
        "to": "सेवा",
        "subject": "विषय",
        "enter_recipient_email_address": "प्राप्तकर्ता का ईमेल पता दर्ज करें",
        "enter_subject_here": "विषय यहाँ दर्ज करें",
        "send": "संदेश",
        "cancel": "रद्द करना",

        "edit_table": "तालिका संपादित करें",
        "save": "सहेजें",
        "buyer": "क्रेता",
        "seller": "विक्रेता",
        "powered_by_deskera": "डेस्करा द्वारा संचालित",
        "details": "विवरण",
        "other_comments": "अन्य टिप्पणी",
        "bill": "विधेयक",
        "order": "गण",

        "email": "ईमेल",

        "items": "आइटम",
        "services": "सेवाएं",
        "hours": "घंटे",
        "rate": "मूल्यांकन करें",
        "other": "अन्य",
        "sales_tax": "विक्री कर",
        "tax_rate": "कर दर",
        "sales tax": "विक्री कर",
        "tax rate": "कर दर",

        "num": " संख्या",
        "template_update_failure_message": "सेटिंग्स अपडेट नहीं की जा सकीं। कृपया पुन: प्रयास करें!",
        "template_save_failure_message": "सेटिंग्स को सहेज नहीं सका। कृपया पुन: प्रयास करें!",
        "template_update_success_message": "टेम्पलेट सफलतापूर्वक सहेजा गया",
        "template_save_success_message": "टेम्पलेट सफलतापूर्वक सहेजा गया",
        "additional_information": "अतिरिक्त जानकारी",
        "other_options": "अन्य विकल्प",

        "image": "छवि",
        "text": "टेक्स्ट",
        "watermark": "वाटर-मार्क",
        "banner": "बैनर",
        "custom_field": "कस्टम फील्ड",
        "component_list": "घटक सूची",

        "email_address": "ईमेल",
        "email_address_1": "ईमेल",
        "is_not_valid": "मान्य नहीं है।",

        "deskera_suites": "डेस्करा सूट",
        "deskera_books": "डेस्करा बुक्स",
        "deskera_people": "डेस्करा लोग",
        "deskera_sales": "डेस्करा सेल्स",
        "try_now": "अब कोशिश करो",


        "text_color": "लिखावट का रंग",
        "image_ratio": "छवि अनुपात (%)",
        "text_size": "शब्दों का आकर",
        "opacity": "अस्पष्टता",
        "font_style": "फ़ॉन्ट शैली",
        "font_weight": "फ़ॉन्ट वजन",
        "text_alignment": "पाठ्य संरेखण",
        "image_alignment": "छवि संरेखण",
        "image_preview": "छवि पूर्वावलोकन",
        "preview_banner": "बैनर का पूर्वावलोकन करें",
        "banner_selection": "बैनर चयन",
        "duplicate": "डुप्लिकेट",
        "settings": "समायोजन",
        "expiry": "एक्सपायरी",
        "batch_no": "बैच नंबर",

        "saving": "सहेजा जा रहा है ...",
        "save_template": "टेम्पलेट सहेजें",
        "name": "नाम",

        "stock_items": "स्टॉक आइटम",

        "sending": "भेजना...",
        "set_as_default": "सेट डिफ़ॉल्ट",

        "no_email_subject": "कोई कस्टम विषय नहीं मिला !! कृपया अपना कस्टम विषय लिखें और सेव टेम्प्लेट पर दबाएँ",

        "expense": "व्यय",
        "deposit": "जमा",
        "credit_note": "क्रेडिट नोट",
        "debit_note": "डेबिट नोट",
        "accounts": "अकाउंट्स",
        "customer_address_placeholder_text_value": "ग्राहक का नाम\nग्राहक का पता",
        "account_name_placeholder_text_value": "खाता कोड\nनया नाम",
        "payment_to": "भुगतान करने के लिए",
        "from_account": "अकाउंट से",
        "payment_from": "भुगतान से",
        "to_account": "टू अकाउंट",
        "payment_date": "भुगतान तिथि",
        "document_date": "दस्तावेज़ तिथि",
        "account_name": "खाता नाम",
        "account_description": "खाता विवरण",
        "tax_amount": "कर राशि",
        "payment_amount": "भुगतान राशि",
        "line_amount": "लाइन राशि",
        "total_tax_amount": "कुल कर राशि",
        "contact": "संपर्क करें",
        "driver_name": "चालक का नाम",
        "transporter": "परिवाहक",
        "vehicle_no": "वाहन संख्या",
        "lr_no": "LR No.",
        "contact_address_placeholder_text_value": "संपर्क नाम\nसंपर्क के पते पर जाएं",

		"printing": "मुद्रण..",
        "downloading": "डाउनलोडिंग..",

        "incorrect_format": "गलत प्रारूप!",
        "few_formats_allowed": "केवल jpg, jpeg, png फ़ाइलों की अनुमति है",
        "image_missing": "छवि गायब!",
        "data_missing": "खोए आँकड़े!",
        "select_image_to_proceed": "कृपया आगे बढ़ने के लिए चित्र का चयन करें",
        "no_data_to_export": "निर्यात करने के लिए कोई डेटा नहीं",

        "session_expired_title": "सत्र समाप्त हुआ!",
        "session_expired_message": "आपका सत्र समाप्त हो गया है। कृपया फिर भाग लें।",
        "login": "लॉग इन करें",
        "generate_link_to_share": "लिंक प्राप्त करें",
        "burn_after_read": "पढ़ने के बाद जलाएं",
        "generate_link": "उत्पन्न",
        "no_never": "कभी नहीँ",
        "1_min": "1 मिनट",
        "1_hr": "1 घंटा",
        "1_day": "1 दिन",
        "1_year": "1 साल",

        "document_expired_title": "दस्तावेज़ समाप्त हो गया!",
        "document_expired_message": "यह दस्तावेज़ समाप्त हो गया है। कृपया प्रेषक से संपर्क करें",

        "loading": "लोड हो रहा है...",
        "reference_date": "संदर्भ तिथि",
        "cheque_number": "चेक नंबर",
        "deposit_to": "डिपॉज़िट टू",

        "password": "पासवर्ड",
        "password_empty_title": "पासवर्ड खाली!",
        "password_empty_message": "पासवर्ड खाली नहीं हो सकता। कृपया एक पासवर्ड दर्ज करें और फिर से कोशिश करें!",
        "document_not_found_title": "दस्तावेज़ नहीं मिला!",
        "document_incorrect_password_title": "गलत पासवर्ड!",
        "document_incorrect_password_message": "कृपया सही पासवर्ड दर्ज करें और पुनः प्रयास करें।",
        "proceed": "बढ़ना",
        "document_enter_password_title": "पासवर्ड संरक्षित दस्तावेज़।",
        "document_enter_password_message": "कृपया पासवर्ड दर्ज करें।",

        "preparing_document_email": "ईमेल के लिए दस्तावेज़ तैयार हो रहा है...",
        "preparing_document_print": "प्रिंट के लिए दस्तावेज़ तैयार हो रहा है...",
        "loading_the_page": "पेज लोड हो रहा है",
        "sender": "प्रेषक",
        "reply_to": "उत्तर दें",
        "enter_sender_name": "प्रेषक का नाम दर्ज करें",
        "enter_reply_to_email": "ईमेल में उत्तर दें दर्ज करें",
        "send_a_copy_to_myself_at": "अपने आप को एक प्रति भेजें",

        "payment": "भुगतान",
        "paper_format": "पेपर फॉर्मेट",
        "please_provide_paper_format": "कृपया पेपर प्रारूप प्रदान करें",
        "paper_format_popup_note": "आप अभी भी सेटिंग पैनल से पेपर प्रारूप को अपडेट कर सकते हैं",
        "item_number": "आइटम नंबर",
        "item_code": "आइटम कोड",
        "received_form": "प्राप्त फ़ॉर्म",
        "payment_details": "भुगतान विवरण",
        "sn_number": "सीरियल नंबर",
        "document_details": "दस्तावेज़ का विवरण",
        "template_saved": "टेम्पलेट सहेजा गया!",
        "you_design_template_has_been_saved": "टेम्प्लेट सहेज लिया गया है, आप इस विंडो को बंद कर सकते हैं और बुक्स एप्लिकेशन पर वापस जा सकते हैं।",
        "you_design_template_has_been_saved_people": "टेम्पलेट सहेज लिया गया है, आप इस विंडो को बंद कर सकते हैं और लोग आवेदन पर वापस जा सकते हैं।",
        "go_to_books": "गोटो बुक्स",
        "item_tax": "उत्पाद कर",
        "item_discount": "उत्पाद छूट",
        "uom": "इकाई",
        "unitPrice": "यूनिट मूल्य",
        "quantity": "मात्रा",
        "code": "कोड",
        "please_select_custom_field": "कृपया कस्टम फ़ील्ड चुनें।",
        "machine_name": "मशीन का नाम",
        "technician_name": "तकनीशियन का नाम",
        "start_date": "शुरुआत तिथि",
        "schedule_id": "अनुसूची आईडी",
        "end_date": "समाप्ति तिथि",
        "preview_pdf": "पूर्वावलोकन पीडीएफ",

        "template_name": "टेम्पलेट नाम",
        "please_enter_template_name_to_save": "कृपया सहेजने के लिए टेम्प्लेट नाम दर्ज करें।",
        "custom_template": "कस्टम टेम्पलेट",
        "add_new_template": "नया कस्टम टेम्पलेट जोड़ें",
        "vendor_name": "विक्रेता का नाम",

        "decimal_point": "दशमलव बिंदु",
        "footer": "पाद लेख",

        "save_as": "इस रूप में सेव करें",
        "save_as_new_template": "नए टेम्पलेट के रूप में सहेजें",
        "e_invoice_section": "ई-चालान अनुभाग",
        "amount_before_tax": "राशि टैक्स से पहले",

        "order_no": "ऑर्डर नंबर",
        "PURCHASE_INVOICE": 'विधेयकों',
        "PURCHASE_ORDER": 'गण',
        "SALES_INVOICE": "बीजक",
        "QUOTATION": "उद्धरण",
        "fulfillment": "पूर्ति",
        "ship_from": "शिप फ्रॉम",
        "company_address_placeholder_text_value": "कंपनी का नाम\n आपकी कंपनी का पता",
        "goods_receipt": "माल रसीद",
        "required_qty": "आवश्यक मात्रा",
        "received_qty": "प्राप्त मात्रा",
        "committed_qty": "प्रतिबद्ध मात्रा",
        "warehouse_code": "वेयरहाउस कोड",
        "warehouse_name": "गोदाम का नाम",
        "company_warehouse_name": "कंपनी/गोदाम का नाम",
        "payment_footer": "पेमेंट",
        "make_payment": "भुगतान करें",
        "receive_payment": "भुगतान प्राप्त करें",
        "deposit_advpayment": "पूर्व जमा धनराशि",
        "expense_prepayment": "प्रीपेमेंट",

        "delete_template": "टेम्पलेट हटाएं",
        "delete_template_message": "क्या आप वाकई टेम्पलेट को हटाना चाहते हैं? एक बार इसे हटाने के बाद, टेम्पलेट पुनर्प्राप्त करने योग्य नहीं है।",
        "delete_template_ok_label": "हां। इसे हटाएं।",
        "delete_template_success_message": "टेम्पलेट सफलतापूर्वक हटाया गया।",
        "delete_template_failure_message": "सेटिंग हटाई नहीं जा सकी। कृपया पुन: प्रयास करें!",
        "before_tax": "कर पूर्वx",
        "outstanding": "बकाया राशि",

        "signature_section": "हस्ताक्षर अनुभाग",
        "prepared_by": "द्वारा तैयार",
        "approved_by": "द्वारा स्वीकृत",

        "no.": 'No.',
        "#": "#",
        "serial_batch": "सीरियल/बैच",
        "amount_in_words": "राशि शब्दों में",
        "name_with_description": "नाम",

        "invoice_number": "चालान संख्या",
        "bill_number": "बिल नंबर",
        "total_invoice_amount": "कुल चालान राशि",
        "total_bill_amount": "कुल बिल राशि",
        "payment_made": "भुगतान किया गया",
        "current_due_amount": "वर्तमान देय राशि",
        "current_balance": "करंट बैलेंस",
        "payment_medium": "भुगतान माध्यम",
        "cash": "नकद",
        "prepayment": "पूर्व भुगतान",
        "payment_label": "भुगतान",
        "receipt_label": "रसीद",
        "total_row": "Tटोटल रो",

        "journal_entry": "जर्नल एंट्री",
        "journal_entry_number": "जेई नं.",
        "account_code": "खाता कोड",
        "credit": "क्रेडिट",
        "debit": "डेबिट",
        "GOOD_RECEIPT": "माल रसीद",
        "MAKE_PAYMENT": "पेमेंट",
        "RECEIVE_PAYMENT": "पेमेंट",
        "CREDIT_NOTE": "क्रेडिट नोट",
        "DEBIT_NOTE": "डेबिट नोट",
        "FULFILLMENT": "पूर्ति",
        "SALES_RETURN": "बिक्री रिटर्न",
        "PURCHASE_RETURN": "खरीदारी वापसी",
        "SALES_CONTRA": "बिक्री कॉन्ट्रा",
        "PURCHASE_CONTRA": "कॉन्ट्रा खरीदें",
        "BANK_TRANSFER": "बैंक स्थानांतरण",
        "TDS_JE": "टीडीएस जेई",
        "ADJUSTMENT": "समायोजन",
        "memo": "ज्ञापन",

        "pick_list": "सूची उठाओ",
        "pack_list": "बंधे हूँओ की सूची",
        "ship_list": "जहाज सूची",
        "invoice_or_quotation": "चालान / कोटेशनn",
        "product_code": "उत्पाद कोड",
        "product_name": "प्रोडक्ट का नाम",
        "barcode": "बारकोड",
        "quantity_to_pick": "लेने के लिए मात्रा",
        "quantity_required_for_order": "आदेश के लिए आवश्यक मात्रा",
        "picked": "उठाया",
        "item_no": "मद संख्या।",
        "unit": "इकाई",
        "packed_quantity": "पैक की गई मात्रा",
        "warehouse_address": "गोदाम का पता",
        "delivery": "वितरण",
        "carrier": "वाहक",
        "number_of_carton": "कार्टन की संख्या",
        "weight": "वज़न",
        "tracking_number": "ट्रैकिंग नंबर",
        "package": "पैकेज",
        "order_number": "क्रम संख्या",

        "custom_field_is_missing_title": "कस्टम फ़ील्ड अनुपलब्ध है",
        "custom_field_is_missing_message": "इस दस्तावेज़ से कस्टम फ़ील्ड अनुपलब्ध है। कृपया पुस्तक से कस्टम फ़ील्ड जोड़ें या अन्य दस्तावेज़ के साथ प्रयास करें।",

        "repeatable_header": "रिपीटेबल टेबल हैडर",
        "margin": "हाशिया",
        "default": "चूक जाना",
        "none": "कोई नहीं",
        "minimum": "न्यूनतम",
        "large": "बड़ा",

        "receive_by": "तिथि के अनुसार प्राप्त करें",
        "ship_by": "तिथि के अनुसार शिप करें",
        "additional_date": "अतिरिक्त तिथि",

        "header_footer_section": "हैडर फूटर सेक्शन",
        "header": "heहैडर",
        "date_only": "केवल तिथि",
        "title_only": "केवल शीर्षक",
        "page_count_only": "केवल पृष्ठ संख्या",
        "date_title": "दिनांक और शीर्षक",
        "date_page_count": "दिनांक और पृष्ठ गणना",
        "title_page_count": "शीर्षक और पृष्ठ गणना",
        "date_title_space": "अंतरिक्ष के साथ दिनांक और शीर्षक",
        "date_page_count_space": "अंतरिक्ष के साथ दिनांक और पृष्ठ गणना",
        "title_page_count_space": "स्पेस के साथ टाइटल और पेज काउंट",
        "date_title_page_count": "दिनांक, शीर्षक, पृष्ठ संख्या",
        "custom": "रीति",
        "left": "बाईं तरफ",
        "center": "केंद्र",
        "right": "दाईं ओर",
        "title": "शीर्षक",
        "page_count": "पृष्ठ संख्या",
        "current_page_count": "वर्तमान पृष्ठ गणना",
        "total_page_count": "कुल पृष्ठ संख्या",
        "custom_text": "प्रचलित पाठ",
        "page": "पृष्ठ",

        "company_phone_number": "कंपनी फोन नंबर",
        "company_gstin": "कंपनी जीएसटीआईएन",
        "company_state_name_and_code": "कंपनी राज्य का नाम और कोड",
        "payment_terms": "भुगतान की शर्तें",
        "customer_phone_number": "ग्राहक फोन नंबर",
        "customer_gstin": "ग्राहक जीएसटीआईएन",
        "customer_state_name_and_code": "ग्राहक राज्य का नाम और कोड",
        "print_only_message": "यह एक कंप्यूटर जनित है",
        "hsn_or_sac": "एचएसएन/सैक",
        "taxable_value": "कर योग्य मूल्य",
        "integrated_tax": "एकीकृत कर",
        "central_tax": "केंद्रीय कर",
        "state_tax": "राज्य कर",
        "cess": "उपकर",
        "state_name": "राज्य का नाम",
        "state_code": "कोड",
        "label_state_code": "राज्य कोड",

        "address_format": "पता प्रारूप",
        "address_1": "पता 1",
        "address_2": "पता द्वितीय",
        "city": "शहर",
        "state": "राज्य",
        "postal_code": "डाक कोड",
        "country": "देश",
        "is_apply_address_format_to_other_addresses": "सभी पतों पर वर्तमान पता प्रारूप लागू करें।",
        "product_grouping": "उत्पाद समूहन",
        "product_group":"उत्पाद समूह",
        "cheque": "चेक",
        "bank_transfer": "बैंक हस्तांतरण",
        "card": "कार्ड",

        "payslip": "वेतन पर्ची",
        "earning_statement": "कमाई का बयान",
        "pay_period": "भुगतान की अवधि",
        "pay_date": "वेतन दिवस",
        "ssn": "एसएसएन",
        "taxable_marital_status": "कर योग्य वैवाहिक स्थिति",
        "exemptions": "छूट",
        "date_of_joining": "शामिल होने की तिथि",
        "pf_a_c_NUMBER": "पीएफ खाता संख्या",
        "esi_number": "ईएसआई नंबर",
        "uan_number": "यूएएन नंबर",
        "employee_id": "कर्मचारी आयडी",
        "designation": "पद",
        "employee_net_pay": "कर्मचारी शुद्ध वेतन",
        "employee_earnings": "कर्मचारी आय",
        "allowances": "भत्ता",
        "deductions": "कटौती",
        "employer_contribution": "नियोक्ता का योगदान",
        "employer_cpf": "नियोक्ता का सीपीएफ",
        "net_salary_paid": "शुद्ध वेतन भुगतान",
        "employee_taxes_withhold": "कर्मचारी कर रोक",
        "employer_taxes": "नियोक्ता कर",
        "employee_deductions": "कर्मचारी कटौती",
        "summary": "सारांश",
        "basic_salary": "मूल वेतन",
        "net_pay": "कुल भुगतान",
        "additional_earnings": "अतिरिक्त कमाई",
        "gross_earnings": "कुल कमाई",
        "duration": "अवधि",
        "current": "वर्तमान",
        "year_to_date": "साल से दिन",
        "type": "प्रकार",
        "pre_tax_deductions_contributions": "कर पूर्व कटौती / योगदान",
        "taxes": "करों",
        "post_tax_deductions_contributions": "कर-पश्चात कटौती/योगदान",
        "net_pay_contributions": "शुद्ध वेतन / योगदान",
        "check_amount": "चेक राशि",
        "payslip_auto_message": "यह एक सिस्टम जनरेटेड पेस्लिप है जिसके लिए मुहर और हस्ताक्षर की आवश्यकता नहीं है",
        "employee_address": "कर्मचारी का पता",
        "field_configuration": "फ़ील्ड कॉन्फ़िगरेशन",
        "table_configuration": "तालिका विन्यास",
        "employee_name_address": "कर्मचारी का नाम और पता",
        "employee_bank_acc_number": "कर्मचारी का बैंक खाता संख्या",
        "header_title": "हैडर शीर्षक",
        "product_custom_field": "उत्पाद कस्टम फ़ील्ड",
        "quantity_and_uom": "मात्रा और यूओएम",
        "includes_product_custom_field": "उत्पाद कस्टम फ़ील्ड शामिल हैं",
        "combines_product_custom_field": "उत्पाद कस्टम फ़ील्ड को जोड़ती है",
        "serial_number": "क्रमिक संख्या",
        "batch_number": "बैच संख्या",
        "manufacturing_date": "निर्माण की तारीख",
        "expiry_date": "समाप्ति तिथि",
        "override_theme_color": "थीम रंग ओवरराइड करें",
        "override_global_color": "वैश्विक रंग ओवरराइड करें",
        "contact_number": "फ़ोन नंबर",
        "contact_code": "संपर्क कोड",
        "blank": "रिक्त",
        "pay_to": "को अदा",
        "payroll_check": "पेरोल चेक",
        "pay_to_the": "उन्हें भुगतान करें",
        "order_of": "आदेश के",
        "dollars": "डॉलर",
        "payroll_period": "की अवधि के लिए पेरोल",
        "repeatable_header_footer": "रिपीटेबल हैडर फूटर",
        "repeatable_footer": "दोहराने योग्य पाद लेख",
        "includes_hsn_or_sac_code": "एचएसएन/सैक कोड शामिल है",
        "eway_bill_no":  "ईवे बिल नंबर",
        "eway_bill_date": "ईवे बिल की तारीख",
        "place_of_supply":'आपूर्ति का स्थान',
        "destination_of_supply":"आपूर्ति का गंतव्य",
        "source_of_supply":"आपूर्ति के स्रोत",
        "subTable_configuration": "उप-तालिका विन्यास",
        "additional_table_configuration": "अतिरिक्त तालिका विन्यास",
        "qr_code": "क्यूआर कोड",
        "select_new_type": "नया प्रकार चुनें",
        "qr_string": "क्यूआर स्ट्रिंग",
        "generated_qr": "उत्पन्न क्यूआर",
        "qr_code_with_text": "टेक्स्ट के साथ क्यूआर कोड",
        "image_with_text": "पाठ के साथ छवि",
        "qr_information": "क्यूआर सूचना",
        "additional_info": "अतिरिक्त जानकारी",
        "global_discount": "वैश्विक छूट",
        "pre_tax": "पूर्व कर",
        "post_tax": "कर पश्चात",
        "includes_uom_schema": "UOM स्कीमा शामिल है",
        "table": "मेज",
        "font": "फ़ॉन्ट",
        "table_row_height": "तालिका पंक्ति ऊँचाई",
        "padding_horizontal": "पैडिंग क्षैतिज",
        "padding_vertical": "पैडिंग वर्टिकल",
        "original": "मूल",
        "original_for_recipient": "प्राप्तकर्ता के लिए मूल",
        "duplicate_supply_of_goods": "डुप्लीकेट (माल की आपूर्ति)",
        "duplicate_for_transporter": "ट्रांसपोर्टर के लिए डुप्लिकेट",
        "duplicate_supply_of_service": "डुप्लिकेट (सेवा की आपूर्ति)",
        "duplicate_for_supplier": "आपूर्तिकर्ता के लिए डुप्लिकेट",
        "triplicate": "तीन प्रतियों",
        "triplicate_for_supplier": "आपूर्तिकर्ता के लिए तीन प्रतियों",
        "position": "पद",
        "middle": "मध्य",
        "sample_watermark": "नमूना वॉटरमार्क",
        "extra_copy": "अतिरिक्त प्रति",
        "quadruplicate": "चार प्रतियों",
        "MAX_IMG_UPLOAD_ALERT": "इमेगा आकार 500 KB से अधिक नहीं हो सकता",
        "system_default": "प्रणालीगत चूक",
        "qr_resize": "क्यूआर आकार बदलें",
        "top": "ऊपर",
        "bottom": "नीचे",
        "click_here_to_pay": "पेमेंट करने के लिए यहां क्लिक करें",
        "generated_message": "जनरेट किया गया संदेश",
        "sales_order": "बिक्री आदेश",
        "help": "मदद करना",
        "includes_account_custom_field": "खाता कस्टम फ़ील्ड शामिल हैं",
        "combines_account_custom_field": "खाता कस्टम फ़ील्ड को संयोजित करता है",
        "account_custom_field": "खाता कस्टम फ़ील्ड",
        "selection": "चयन",
        "alias_name_address": "उपनाम नाम और पता",
        "nric_fin_wpn": "NRIC/FIN/WPN",
        "paid_days": "भुगतान किए गए दिन",
        "unpaid_days": "अवैतनिक दिन",
        "payslip_overtime": "अधिक समय तक",
        "payslip_LOP": "वेतन का नुकसान",
        "outstanding_balance": "बकाया राशि",
        "outstanding_balance_includes_current": "बकाया राशि (वर्तमान सहित)",
        "top_section_above_table": "तालिका के ऊपर शीर्ष अनुभाग",
        "is_group_additional_charges_tax": "क्या समूह अधिभार कर",
        "supplier_invoice_no": "आपूर्तिकर्ता चालान संख्या",
        "default_template": "पूर्व उपस्थित नमूना",
        "contact_name": "संपर्क नाम",
        "current_date": "आज की तारीख",
        "e_way_bill_summary": "ईवे बिल सारांश",
        "e_way_bill_detail": "ईवे बिल विवरण",
        "table_column_alignment": "तालिका स्तंभ संरेखण",
        "table_column_vertical_alignment": "लंबवत संरेखण",
        "earnings_configuration": "आय विन्यास",
        "label_empty_field_message": "फ़ील्ड लेबल खाली नहीं हो सकता, कृपया आगे बढ़ने के लिए इसमें कुछ मान दर्ज करें।",
        "product_table": "उत्पाद तालिका",
        "rounding_off": "समाप्त करना",
        "work_order": "कार्य आदेश",
        "order_prep_completed": "ऑर्डर की तैयारी पूर्ण हुई",
        "barcode_settings": "बारकोड सेटिंग",
        "barcode_resize": "बारकोड आकार बदलें",
        "comments": "टिप्पणियाँ",
        "delivery_date": "वितरण तिथि",
        "estimated_time": "अनुमानित समय",
        "actual_quantity": "वास्तविक मात्रा",
        "manufacture_quantity": "निर्माण मात्रा",
        "parent_work_order": "मुख्य कार्य आदेश",
        "stock_transfer": "स्टॉक स्थानांतरण",
        "source": "स्रोत",
        "destination": "गंतव्य",
        "source_address": "स्रोत पता",
        "destination_address": "गंतव्य पता",
        "transfer_date": "स्थानांतरण तिथि",
        "verify_date": "सत्यापन तिथि",
        "reason": "कारण",
        "warehouse": "गोदाम",
        "stock_adjustment": "स्टॉक समायोजन",
        "source_warehouse_name": "स्रोत गोदाम का नाम",
        "source_warehouse_code": "स्रोत गोदाम कोड",
        "destination_warehouse_name": "गंतव्य गोदाम का नाम",
        "destination_warehouse_code": "गंतव्य गोदाम कोड",
        "product": "उत्पाद",
        "show_product": "उत्पाद दिखाएं",
        "hide_product_with_substitute": "उपस्थित विकल्प के साथ उत्पाद छिपाएं",
        "substitute": "प्रतिस्थापित",
        "show_all_substitute": "सभी प्रतिस्थापित दिखाएं",
        "show_non_zero_substitute": "आवंटित प्रतिस्थापित दिखाएं",
        "hide_substitute": "प्रतिस्थापित छिपाएं",
        "combines_line_item_global_discount": "लाइन आइटम वैश्विक डिस्काउंट को एकीकृत करें",
        "unit_price_before_discount": "छूट से पहले इकाई मूल्य",
        "unit_price_after_discount": "छूट के बाद इकाई मूल्य",
        "discount_per_unit": "प्रति यूनिट छूट",
        "show_all": "सभी दिखाएं",
        "percentage": "प्रतिशत",
        "cogs": "बेचे गए माल की कीमत",
        "na": "NA",
        "con_number": "ग्राहक आदेश संख्या",
        "document_label": "बारकोड लेबल",
        "expected_delivery_date": "अपेक्षित वितरण तिथि",
        "transaction_type": "लेन-देन प्रकार",
        "transaction_no": "लेन-देन संख्या",
        "status": "स्थिति",
        "qc_document": "क्वालिटी कंट्रोल दस्तावेज़",
        "linked_po_so": "लिंक्ड PO/SO",
        "approver": "मंजूर करने वाला",
        "sample_size": "नमूना आकार",
        "number_of_qty_passed": "गुजरी मात्रा की संख्या",
        "number_of_qty_rejected": "गिरायी मात्रा की संख्या",
        "final_remark": "अंतिम टिप्पणी",
        "value": "मूल्य",
        "exchange_conversion": "विनिमय रूपांतरण",
        "contact_address": "संपर्क पता",
        "cheque_fields": "चेक क्षेत्र",
        "cheque_details": "चेक विवरण",
        "sales_return": "बिक्री वापसी",
        "fulfilled_quantity": "पूर्ण की गई मात्रा",
        "returned_quantity": "वापसी की गई मात्रा",
        "parent_linked_document_no": "मूल दस्तावेज़ संख्या",
        "parent_linked_document_type": "मूल दस्तावेज़ प्रकार",
        "swap_address": "पता बदलें",
        "vendor_address": "विक्रेता का पता",
        "job_work_out_order": "काम आउट ऑर्डर",
        "machine":"मशीन अनुसूची",
        "kind_of_packing": "पैकेज की संख्या और प्रकार",
        "due_on": "को समाप्त होने की तारीख",
        "per": "प्रति",
        "voucher_no": "वाउचर नंबर",
        "dated": "तारीख",
        "supplier_ref_order_no": "प्रदाता का संदर्भ/आदेश संख्या",
        "mode_term_of_payment": "भुगतान का तरीका/शर्तें",
        "dispatch_doc_no": "पाठवी गई दस्तावेज़ संख्या",
        "dispatched_through": "पाठवी गई रास्ता",
        "duration_of_process": "प्रक्रिया की अवधि",
        "nature_of_processing": "प्रक्रिया का प्रकृति",
        "company_pan": "कंपनी पैन",
        "authorised_signatory": "अधिराज्य प्रमाणित",
        "additional_charges": "अतिरिक्त जिम्मेदारी",
        "contact_no": "संपर्क नंबर",
        "supplier_inv_no": "आपूर्तिकर्ता आमंत्रण संख्या",
        "basic_salary_wo_lop": "वेतन हानि के बिना मूल वेतन",
        "cost": "लागत",
        "stock_request": "स्टॉक अनुरोध",
        "target_warehouse_name": "लक्ष्य गोदाम का नाम",
        "target_warehouse_code": "लक्ष्य गोदाम कोड",
        "selected_row": "चयनित पंक्ति",
        "selected_rack": "चयनित रैक",
        "selected_bin": "चयनित बिन",
        "requested_qty": "अनुरोधित मात्रा",
        "created_by": "द्वारा बनाई गई",
        "approved_date": "स्वीकृति तिथि",
        "stock_issue": "सुरक्षा द्वार प्रवेश",
        "gate_entry": "Security Gate Entry",
        "issued_quantity": "जारी की गई मात्रा",
        "bom_product_table": "सामग्री का विवरण; उत्पाद तालिका",
        "list": "सूची",
        "is_multi_product_table": "बहु-उत्पाद तालिका",
        "is_show_optional_group": "वैकल्पिक उत्पाद समूह",
        "tax_registration_number": "कर पंजीयन संख्या",
        "tax_payer_id": "कर भरने वाले की पहचान",
        "wo_qty": "कार्य आदेश की मात्रा",
        "adhoc_items_message": "* AdHoc वस्तुओं का प्रतिनिधित्व करता है",
        "production_checklist": "उत्पादन चेकलिस्ट",
        "product_description": "उत्पाद विवरण",
        "total_quantity": "कुल मात्रा",
        "job_status": "कार्य स्थिति",
        "qc_status": "QC स्थिति",
        "show_job_status": "कार्य प्रमाण पत्र की स्थिति दिखाएं",
        "show_instruction": "निर्देश दिखाएं",
        "optional": "वैकल्पिक",
        "document_title": "दस्तावेज़ शीर्षक",
        "barcode_for_row_rack_bin": "पंक्ति/रैक/बिन के लिए बारकोड",
        "show_tagged_bin_warehouse": "टैग किए गए बिन गोदाम",
        "hsn_sac_tax_table": "HSN/SAC कर तालिका",
        "approval_section": "मंजूरी खंड",
        "border": "सीमा",
        "spacing": "अंतर",
        "checked_by": "द्वारा जांच किया गया",
        "authorized_by": "द्वारा अधिकृत",
        "word_spacing": "शब्द अंतराल",
        "page_count_settings": "पृष्ठ संख्या सेटिंग्स",
        "single_digit": "एकल अंक",
        "page_with_numeric": "संख्यात्मक पृष्ठ",
        "page_x_of_total_page": "पृष्ठ x कुल पृष्ठों में से",
        "page_x_of_total_page_slash": "पृष्ठ x / कुल पृष्ठ",
        "service_requisition": "सेवा मांग",
        "service_description": "सेवा विवरण",
        "sc_no":"एससी नं",
        "sc_date":"एससी तिथि",
        "sc_type":"एससी प्रकार",
        "delivery_order_doc_and_date": "डिलीवरी ऑर्डर नंबर और तारीख",
        "e_way_bill_doc_and_date": "ई-वे बिल नंबर और तारीख",
        "party_order_doc_and_date": "पार्टी ऑर्डर नंबर और तारीख",
        "mode_of_transport": "परिवहन का तरीका",
        "bank_details": "बैंक विवरण",
        "bank_name": "बैंक का नाम",
        "company_seal": "कंपनी की मुहर",
        "regd_office": "पंजीकृत कार्यालय",
        "sub_total_in_words": "शब्दों में उप-योग",
        "rounding_off_in_words": "शब्दों में राउंडिंग ऑफ",
        "outstanding_balance_in_words": "शब्दों में बकाया शेष राशि",
        "outstanding_balance_includes_current_in_words": "शब्दों में बकाया शेष (वर्तमान सहित)",
        "total_tax_amount_in_words": "शब्दों में कुल कर राशि",
        "before_tax_in_words": "शब्दों में कर से पहले",
        "additional_charges_in_words": "अतिरिक्त शुल्क शब्दों में",
        "global_discount_in_words": "शब्दों में वैश्विक छूट",
        "cgst_in_words": "शब्दों में सीजीएसटी",
        "igst_in_words": "शब्दों में आईजीएसटी",
        "sgst_in_words": "शब्दों में एसजीएसटी",
        "person_name": "व्यक्ति का नाम",
        "store_officer": "भंडार अधिकारी",
        "department_holder": "विभागाध्यक्ष",
        "purchase_department": "खरीद विभाग",
        "issue_by": "जारीकर्ता",
        "paper_orientation": "कागज की दिशा",
        "landscape": "लैंडस्केप",
        "portrait": "पोर्ट्रेट",
        "purchase_requisition": " परचेज़ रेक्विज़ीशन",
        "phone": " फ़ोन",
        "requisition_qty": " मांग मात्रा",
        "stock_qty": " स्टॉक मात्रा",
        "prev_purchase_rate": " पिछला. खरीद दर",
        "scheduled_date": " निर्धारित तिथि",
        "estimate_rate": " अनुमानित दर",
        "estimate_value": " अनुमानित मूल्य",
        "cost_center": " लागत केंद्र",
        "reserved": " सुरक्षित",
        "division": " विभाजन",
        "department_name": " विभाग का नाम",
        "indent_no": " इंडेंट नं",
        "indent_date": " इंडेंट दिनांक",
        "indent_type": " इंडेंट प्रकार",
        "project": " परियोजना",
        "authorised_date": " अधिकृत तिथि",
        "requested_by": " द्वारा अनुरोध किया गया",
        "for": " के लिए",
        "authorised_by": " द्वारा अधिकृत",
        "available_qty": "उपलब्ध मात्रा",
        "service_order": " सेवा आदेश",
        "gst_no": " जीएसटी नं",
        "gstin_no": " जीएसटीआईएन नंबर",
        "pan_no": " पैन नंबर",
        "cin_no": " सीआईएन नंबर",
        "cont_person": " जारी. व्यक्ति",
        "order_date": " आर्डर की तारीख",
        "shipment_address": " प्रेषण का पता",
        "prepared_date_time": "तैयार तारीख और समय",
        "only": "केवल",
        "completed": "पूरा",
        "location_detail": "स्थान का विवरण",
        "transporter_detail": "परिवहनकर्ता का विवरण",
        "item_detail": "आइटम का विवरण",
        "material_receipt_note": "सामग्री रसीद नोट",
        "mrn_no": "एमआरएन नंबर",
        "authorized": "अधिकार दिया गया",
        "inspected_by": "द्वारा निरीक्षण",
        "store_manager": "स्टोर प्रबंधक",
        "hod": "एच.ओ.डी",
        "account_manager": "खाता प्रबंधक",
        "po_bill_no_date": "पीओ नंबर/दिनांक\nबिल नंबर/दिनांक",
        "short_excess_rejected_qty": "कम/अतिरिक्त/अस्वीकृत मात्रा।",
        "accepted_qty": "स्वीकृत मात्रा.",
        "basic_amt": "मूल राशि",
        "charges": "प्रभार",
        "last_po": "अंतिम पीओ",
        "cgst": "सीजीएसटी",
        "sgst": "एसजीएसटी",
        "igst": "आईजीएसटी",
        "gst": "जीएसटी",
        "total_amount": "कुल राशि",
        "total_passed_amount": "कुल पारित राशि",
        "inspection_required": "निरीक्षण आवश्यक",
        "qc_inspection": "क्यूसी निरीक्षण",
        "work_order_no": "कार्य आदेश संख्या",
        "work_order_date": "कार्य आदेश तिथि",
        "work_order_due_date": "कार्य आदेश की नियत तिथि",
        "work_order_status": "कार्य आदेश की स्थिति",
        "edit_table_columns": "तालिका के कॉलम संपादित करें",
        "incoming": "आने वाला",
        "final": "अंतिम",
        "label": "लेबल",
        "both": "दोनों",
        "inspection_report": "निरीक्षण रिपोर्ट",
        "inspection_date": "तारीख",
        "parts": "पुर्जे",
        "reworks": "फिर से काम",
        "final_inspection": "अंतिम निरीक्षण",
        "product_listing": "उत्पाद सूची",
        "paper_margin": "कागज का मार्जिन",
        "is_using_custom_margin": "कस्टम मार्जिन का उपयोग कर रहा है",
        "soa_customer": "लेखों का विवरण",
        "soa_vendor": "लेखों का विवरण - विक्रेता",
        "company_reg_no": "कंपनी पंजीकरण संख्या",
        "gst_reg_no": "जीएसटी पंजीकरण संख्या",
        "from_date": "तिथि से",
        "as_of_date": "तिथि तक",
        "to_date": "तिथि तक",
        "document_number": "दस्तावेज़ संख्या",
        "particular": "विवरण",
        "amount_in_base": "आधार में राशि",
        "balance_in_base": "आधार में शेष राशि",
        "ifsc_code": "आईएफएससी कोड",
        "purchase_request_for_quotes": "कोटेशन के लिए अनुरोध",
        "purchase_inward_quotation": "खरीद इनवर्ड कोटेशन",
        "rfq_no": "आरएफक्यू नंबर",
        "show_zero": "शून्य दिखाएँ",
        "show_total": "कुल दिखाएँ",
        "draft_watermark": "मसौदा वॉटरमार्क",
        "draft": "मसौदा",
        "rotation": "घुमाव",
        "invoice_summary": "चालान सारांश",
        "bill_summary": "बिल सारांश",
        "amount_due": "देय राशि",
        "total_pending_balance": "कुल बकाया राशि",
        "serial_batch_custom_fields": "क्रमांक बैच कस्टम फ़ील्ड",
    }
};

export default hi
