import {
  DKButton,
  DKIcons,
  DKLabel,
  INPUT_TYPE,
  DKDataGrid
} from 'deskera-ui-library';
import React, { useEffect, useState } from 'react';
import { useAppSelector } from '../../Redux/Hooks';
import Utility from '../../Utility/Utility';
import NewReportService, { NewReportConfig } from '../../Services/DebitReport';
import DateFormatService from '../../Services/DateFormat';
import {
  BOOKS_DATE_FORMAT,
  QTY_ROUNDOFF_PRECISION,
  TRACKING_TYPE
} from '../../Constants/Constant';
import RouteManager, { PAGE_ROUTES } from '../../Managers/RouteManager';
import ReportGridHolder from '../../Components/Reports/Financial/ReportGridHolder';
import { activeTenantInfo } from '../../Redux/Slices/AuthSlice';

const ExpandedFulillmentTable: React.FC<any> = (props) => {
  const [gridRowData, setGridRowData] = useState<any[]>([]);
  const [tableData, setTableData] = useState<any>();
  const [realTableData, setRealTableData] = useState<any>();

  const tenantInfo = useAppSelector(activeTenantInfo);
  const [colConfig, setColConfig] = useState<any>([]);

  useEffect(() => {
    let configToShow = [];
    if (props.type === TRACKING_TYPE.SERIAL) {
      configToShow = defaultColumnsForSerial;
    } else if (props.type === TRACKING_TYPE.BATCH) {
      configToShow = defaultColumnsForBatch;
    } else if (props.type === TRACKING_TYPE.NONE) {
      configToShow = defaultColumnsForNone;
    }
    if (!Utility.isEmpty(tenantInfo)) {
      let rrbEnabled = tenantInfo?.additionalSettings?.ROW_RACK_BIN?.filter(
        (item: any) => item?.enable
      );
      if (Utility.isEmpty(rrbEnabled) || !rrbEnabled?.length) {
        configToShow = configToShow.filter(
          (config: any) =>
            config.columnCode !== 'row' &&
            config.columnCode !== 'rack' &&
            config.columnCode !== 'bin'
        );
      }
    }
    setColConfig(configToShow);
  }, [props, tenantInfo]);

  let defaultColumnsForBatch: any = [
    {
      name: 'Warehouse',
      type: 'text',
      index: 3,
      options: null,
      required: false,
      width: 150,
      editable: false,
      hidden: false,
      uiVisible: true,
      systemField: true,
      columnCode: 'warehouse',
      key: 'warehouse'
    },
    {
      name: 'Batch Number',
      type: 'text',
      index: 3,
      options: null,
      required: false,
      width: 120,
      editable: false,
      hidden: false,
      uiVisible: true,
      systemField: true,
      columnCode: 'trackingNumber',
      key: 'trackingNumber'
    },
    {
      name: 'Row',
      type: 'text',
      index: 3,
      options: null,
      required: false,
      width: 70,
      editable: false,
      hidden: false,
      uiVisible: true,
      systemField: true,
      columnCode: 'row',
      key: 'row'
    },
    {
      name: 'Rack',
      type: 'text',
      index: 3,
      options: null,
      required: false,
      width: 70,
      editable: false,
      hidden: false,
      uiVisible: true,
      systemField: true,
      columnCode: 'rack',
      key: 'rack'
    },
    {
      name: 'Bin',
      type: 'text',
      index: 3,
      options: null,
      required: false,
      width: 70,
      editable: false,
      hidden: false,
      uiVisible: true,
      systemField: true,
      columnCode: 'bin',
      key: 'bin'
    },
    {
      name: 'Manufactured Date',
      type: 'text',
      index: 3,
      options: null,
      required: false,
      width: 150,
      editable: false,
      hidden: false,
      uiVisible: true,
      systemField: true,
      columnCode: 'mfgDate',
      key: 'mfgDate'
    },
    {
      name: 'Expiry Date',
      type: 'text',
      index: 3,
      options: null,
      required: false,
      width: 100,
      editable: false,
      hidden: false,
      uiVisible: true,
      systemField: true,
      columnCode: 'expiryDate',
      key: 'expiryDate'
    },
    {
      name: 'Quantity Used',
      type: 'number',
      index: 3,
      options: null,
      required: false,
      width: 140,
      editable: false,
      hidden: false,
      uiVisible: true,
      systemField: true,
      columnCode: 'qtyUsed',
      key: 'qtyUsed'
    }
  ];
  let defaultColumnsForSerial: any = [
    {
      name: 'Warehouse',
      type: 'text',
      index: 3,
      options: null,
      required: false,
      width: 200,
      editable: false,
      hidden: false,
      uiVisible: true,
      systemField: true,
      columnCode: 'warehouse',
      key: 'warehouse'
    },
    {
      name: 'Serial Number',
      type: 'text',
      index: 3,
      options: null,
      required: false,
      width: 140,
      editable: false,
      hidden: false,
      uiVisible: true,
      systemField: true,
      columnCode: 'trackingNumber',
      key: 'trackingNumber'
    },
    {
      name: 'Quantity Used',
      type: 'number',
      index: 3,
      options: null,
      required: false,
      width: 180,
      editable: false,
      hidden: false,
      uiVisible: true,
      systemField: true,
      columnCode: 'qtyUsed',
      key: 'qtyUsed'
    },
    {
      name: 'Row',
      type: 'text',
      index: 3,
      options: null,
      required: false,
      width: 130,
      editable: false,
      hidden: false,
      uiVisible: true,
      systemField: true,
      columnCode: 'row',
      key: 'row'
    },
    {
      name: 'Rack',
      type: 'text',
      index: 3,
      options: null,
      required: false,
      width: 130,
      editable: false,
      hidden: false,
      uiVisible: true,
      systemField: true,
      columnCode: 'rack',
      key: 'rack'
    },
    {
      name: 'Bin',
      type: 'text',
      index: 3,
      options: null,
      required: false,
      width: 130,
      editable: false,
      hidden: false,
      uiVisible: true,
      systemField: true,
      columnCode: 'bin',
      key: 'bin'
    }
  ];
  let defaultColumnsForNone: any = [
    {
      name: 'Warehouse',
      type: 'text',
      index: 3,
      options: null,
      required: false,
      width: 220,
      editable: false,
      hidden: false,
      uiVisible: true,
      systemField: true,
      columnCode: 'warehouse',
      key: 'warehouse'
    },
    {
      name: 'Quantity Used',
      type: 'number',
      index: 3,
      options: null,
      required: false,
      width: 180,
      editable: false,
      hidden: false,
      uiVisible: true,
      systemField: true,
      columnCode: 'qtyUsed',
      key: 'qtyUsed'
    },
    {
      name: 'Row',
      type: 'text',
      index: 3,
      options: null,
      required: false,
      width: 150,
      editable: false,
      hidden: false,
      uiVisible: true,
      systemField: true,
      columnCode: 'row',
      key: 'row'
    },
    {
      name: 'Rack',
      type: 'text',
      index: 3,
      options: null,
      required: false,
      width: 150,
      editable: false,
      hidden: false,
      uiVisible: true,
      systemField: true,
      columnCode: 'rack',
      key: 'rack'
    },
    {
      name: 'Bin',
      type: 'text',
      index: 3,
      options: null,
      required: false,
      width: 150,
      editable: false,
      hidden: false,
      uiVisible: true,
      systemField: true,
      columnCode: 'bin',
      key: 'bin'
    }
  ];

  const getRowData = () => {
    let rows: any = [];
    if (props.type === TRACKING_TYPE.BATCH) {
      let sinkConversionFactor = 1;
      if (!Utility.isEmpty(props?.data?.documentUOMSchemaDefinition)) {
        sinkConversionFactor =
          props?.data?.documentUOMSchemaDefinition?.sinkConversionFactor;
      }
      props?.data?.advancedTrackingFulfilmentData?.forEach((lineItem: any) => {
        rows.push({
          warehouse: lineItem.warehouseName,
          trackingNumber: lineItem.serialBatchNumber,
          row: lineItem.rowName,
          rack: lineItem.rackName,
          bin: lineItem.binName,
          mfgDate: DateFormatService.getFormattedDateString(
            lineItem.manufacturingDate,
            BOOKS_DATE_FORMAT['DD-MM-YYYY']
          ),
          expiryDate: DateFormatService.getFormattedDateString(
            lineItem.expiryDate,
            BOOKS_DATE_FORMAT['DD-MM-YYYY']
          ),
          availableQty: lineItem.batchSize,
          qtyUsed: props?.data?.documentUOMSchemaDefinition
            ? Utility.roundingOff(
                Utility.getUomQuantity(
                  parseFloat(lineItem.qtyToFulfil),
                  props?.data?.documentUOMSchemaDefinition
                ),
                QTY_ROUNDOFF_PRECISION
              )
            : Utility.roundingOff(lineItem.qtyToFulfil, QTY_ROUNDOFF_PRECISION)
        });
      });
    }
    if (props.type === TRACKING_TYPE.SERIAL) {
      props?.data?.advancedTrackingFulfilmentData?.forEach((lineItem: any) => {
        rows.push({
          warehouse: lineItem.warehouseName,
          trackingNumber: lineItem.serialBatchNumber,
          availableQty: lineItem.batchSize,
          qtyUsed: lineItem.qtyToFulfil,
          row: lineItem.rowName,
          rack: lineItem.rackName,
          bin: lineItem.binName
        });
      });
    }
    if (props.type === TRACKING_TYPE.NONE) {
      props?.data?.warehouseInventoryData?.forEach((lineItem: any) => {
        rows.push({
          warehouse: lineItem.warehouseName,
          availableQty: lineItem.availableQty,
          qtyUsed: lineItem.quantity,
          row: lineItem.rowName,
          rack: lineItem.rackName,
          bin: lineItem.binName
        });
      });
    }
    return rows;
  };

  return (
    <div className="p-2 bg-gray1">
      {getRowData().length > 0 && (
        <DKDataGrid
          title=""
          needShadow={false}
          needColumnIcons={false}
          width={930}
          needBorder={true}
          needTrailingColumn={true}
          allowBulkOperation={false}
          allowColumnSort={false}
          filterData={[]}
          allowColumnDelete={false}
          allowRowEdit={true}
          allowColumnEdit={false}
          allowFilter={false}
          allowColumnAdd={false}
          allowBottomRowAdd={false}
          allowSearch={false}
          allowShare={false}
          rows={getRowData()}
          columns={colConfig}
        />
      )}
      {getRowData().length === 0 && (
        <div className="row align-items-center justify-content-center">
          <DKLabel text="No Data Available.Please Add tracking details to see data." />
        </div>
      )}
    </div>
  );
};
export default ExpandedFulillmentTable;
