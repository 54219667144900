import { INPUT_TYPE, DKIcons, showAlert } from 'deskera-ui-library';
import { Store } from '../../../../Redux/Store';
import Utility, { deepClone } from '../../../../Utility/Utility';
import { JOB_CARD_STATUS } from '../../Constants/MRPColumnConfigs';
import DateFormatService from '../../../../Services/DateFormat';
import { BOOKS_DATE_FORMAT, DATE_FORMAT } from '../../../../Constants/Constant';

export enum JC_PROCESS_TYPE {
  CONSUMPTION = 'CONSUMPTION',
  PRODUCTION = 'PRODUCTION',
  PROCESSING = 'PROCESSING'
}

export enum Job_Card_Keys {
  RESOURCES = 'resources',
  ITEMS = 'items',
  QC_STATUS = 'qcStatus',
  QC_NEEDED = 'qcNeeded',
  PROCESS_TYPE = 'processType',
  TOTAL_ASSIGNED_QTY = 'totalAssignedQtys',
  TOTAL_PRODUCED_QTY = 'totalProducedQtys',
  DEPENDENCY = 'jobCardDependency',
  PLANNED_TIME = 'plannedTime',
  JOB_CARD_DEPENDENCY = 'jobCardDependency',
  OPERATION_ID = 'operationId',
  JOB_CARD_CODE = 'jobCardCode',
  STATUS = 'status',
  WORKSTATION_ID = 'workstationId',
  FINISHED_QUANTITY = 'finishedQuantity',
  JOB_CARD_DATE = 'jobCardDate',
  PLANNED_START_DATE = 'plannedStartDate',
  PLANNED_END_DATE = 'plannedEndDate'
}

export class JobCardListPresenter {
  isLinkedInventory =
    Store.getState().authInfo.currentTenantInfo.data.additionalSettings
      .LINK_INVENTORY_JOB_CARDS;
  JobCardListColumns = [
    {
      name: 'Sr. No.',
      key: 'jobCardCode',
      width: 100
    },
    {
      name: 'Operation',
      key: 'operationId',
      width: 110
    },
    {
      name: 'Dependent On',
      key: 'jobCardDependency',
      width: 110
    },
    {
      name: 'Job Status',
      key: 'status',
      width: 120
    },
    {
      name: 'Duration',
      key: 'plannedTime',
      width: 100
    },
    {
      name: 'Start Date',
      key: 'plannedStartDate',
      width: 100
    },
    {
      name: 'End Date',
      key: 'plannedEndDate',
      width: 100
    }
  ];
  LinkedInventoryColumns = [
    {
      name: 'Process type',
      key: 'processType',
      width: 100,
      textAlign: 'left'
    },
    {
      name: 'Items',
      key: 'items',
      width: 100,
      textAlign: 'left'
    },
    {
      name: 'Qc Needed',
      key: 'qcNeeded',
      width: 100,
      textAlign: 'center'
    },
    {
      name: 'QC Status',
      key: 'qcStatus',
      width: 100,
      textAlign: 'left'
    },
    {
      name: 'Total Assigned',
      key: 'totalAssignedQtys',
      width: 100,
      textAlign: 'right'
    },
    {
      name: 'Total Produced',
      key: 'totalProducedQtys',
      width: 100,
      textAlign: 'right'
    }
  ];

  JobCardListColumns_Grid = [
    {
      name: 'Sr. No.',
      index: 0,
      type: INPUT_TYPE.TEXT,
      width: 150,
      systemField: true,
      editable: false,
      hidden: false,
      required: true,
      uiVisible: true,
      textAlign: 'left',
      key: 'jobCardCode',
      id: 'jobCardCode',
      columnCode: 'jobCardCode'
    },
    {
      name: 'Operation',
      index: 0,
      type: INPUT_TYPE.DROPDOWN,
      width: 180,
      systemField: true,
      editable: true,
      hidden: false,
      required: false,
      uiVisible: true,
      dropdownConfig: {
        title: '',
        allowSearch: true,
        searchableKey: 'name',
        style: { minWidth: 150 },
        className: 'shadow-m',
        searchApiConfig: {},
        data: [],
        renderer: null,
        onSelect: (index: any, obj: any, rowIndex: any) => {}
      },
      textAlign: 'left',
      key: 'operationId',
      id: 'operationId',
      columnCode: 'operationId'
    },
    {
      name: 'Dependent On',
      type: INPUT_TYPE.DROPDOWN,
      width: 180,
      systemField: true,
      editable: true,
      hidden: false,
      required: true,
      uiVisible: true,
      dropdownConfig: {
        title: '',
        allowSearch: false,
        searchableKey: '',
        style: { minWidth: 150 },
        className: 'shadow-m',
        searchApiConfig: null,
        data: [],
        renderer: null,
        onSelect: (index: any, obj: any, rowIndex: any) => {}
      },
      id: 'jobCardDependency',
      key: 'jobCardDependency',
      columnCode: 'jobCardDependency'
    },
    {
      name: 'Job Status',
      index: 0,
      type: INPUT_TYPE.DROPDOWN,
      width: 160,
      systemField: true,
      editable: false,
      hidden: false,
      required: true,
      uiVisible: true,
      textAlign: 'left',
      key: 'status',
      id: 'status',
      columnCode: 'status',
      dropdownConfig: null
    },
    {
      name: 'Duration',
      index: 0,
      type: INPUT_TYPE.TEXT,
      width: 100,
      systemField: true,
      editable: false,
      hidden: false,
      required: true,
      uiVisible: true,
      textAlign: 'left',
      key: 'plannedTime',
      id: 'plannedTime',
      columnCode: 'plannedTime'
    },
    {
      name: 'Start Date',
      index: 0,
      type: INPUT_TYPE.TEXT,
      width: 100,
      systemField: true,
      editable: false,
      hidden: false,
      required: true,
      uiVisible: true,
      textAlign: 'left',
      key: 'plannedStartDate',
      id: 'plannedStartDate',
      columnCode: 'plannedStartDate'
    },
    {
      name: 'End Date',
      index: 0,
      type: INPUT_TYPE.TEXT,
      width: 100,
      systemField: true,
      editable: false,
      hidden: false,
      required: true,
      uiVisible: true,
      textAlign: 'left',
      key: 'plannedEndDate',
      id: 'plannedEndDate',
      columnCode: 'plannedEndDate'
    }
  ];

  LinkedInventoryColumns_Grid = [
    {
      name: 'Process Type',
      index: 0,
      type: INPUT_TYPE.DROPDOWN,
      width: 150,
      systemField: true,
      editable: true,
      hidden: false,
      required: true,
      uiVisible: true,
      key: 'processType',
      id: 'processType',
      columnCode: 'processType',
      dropdownConfig: {
        title: '',
        allowSearch: false,
        searchableKey: '',
        style: { minWidth: 150 },
        className: 'shadow-m',
        searchApiConfig: null,
        data: [],
        renderer: null,
        onSelect: (index: any, obj: any, rowIndex: any) => {}
      }
    },
    {
      name: 'Items',
      type: INPUT_TYPE.DROPDOWN,
      width: 150,
      systemField: true,
      editable: true,
      hidden: false,
      required: true,
      uiVisible: true,
      dropdownConfig: {
        title: '',
        allowSearch: false,
        searchableKey: '',
        style: { minWidth: 150 },
        className: 'shadow-m',
        searchApiConfig: null,
        data: [],
        renderer: null,
        onSelect: (index: any, obj: any, rowIndex: any) => {}
      },
      id: 'items',
      key: 'items',
      columnCode: 'items'
    },
    {
      name: 'QC Needed',
      type: INPUT_TYPE.DROPDOWN,
      width: 120,
      systemField: true,
      editable: true,
      hidden: false,
      required: true,
      uiVisible: true,
      dropdownConfig: {
        title: '',
        allowSearch: false,
        searchableKey: '',
        style: { minWidth: 120 },
        className: 'shadow-m',
        searchApiConfig: null,
        data: [],
        renderer: null,
        onSelect: (index: any, obj: any, rowIndex: any) => {}
      },
      id: 'qcNeeded',
      key: 'qcNeeded',
      columnCode: 'qcNeeded'
    },
    {
      name: 'QC Status',
      index: 0,
      type: INPUT_TYPE.DROPDOWN,
      width: 160,
      systemField: true,
      editable: false,
      hidden: false,
      required: true,
      uiVisible: true,
      dropdownConfig: null,
      key: 'qcStatus',
      id: 'qcStatus',
      columnCode: 'qcStatus'
    },
    {
      name: 'Total Assigned',
      index: 0,
      type: INPUT_TYPE.NUMBER,
      width: 100,
      systemField: true,
      editable: false,
      hidden: false,
      required: true,
      uiVisible: true,
      textAlign: 'right',
      key: 'totalAssignedQtys',
      id: 'totalAssignedQtys',
      columnCode: 'totalAssignedQtys'
    },
    {
      name: 'Total Produced',
      index: 0,
      type: INPUT_TYPE.NUMBER,
      width: 100,
      systemField: true,
      editable: false,
      hidden: false,
      required: true,
      uiVisible: true,
      textAlign: 'right',
      key: 'totalProducedQtys',
      id: 'totalProducedQtys',
      columnCode: 'totalProducedQtys'
    }
  ];

  constructor() {}

  getColumnConfigs() {
    let result = [...this.JobCardListColumns_Grid];
    if (this.isLinkedInventory) {
      result.splice(4, 0, ...(this.LinkedInventoryColumns_Grid as any));
    }
    return result;
  }

  getJobCardMappingForRow = (row: any, queriedJobCards: any) => {
    const currentJCFound = queriedJobCards?.find((resItem: any) => {
      return row?.id === resItem?.id;
    });
    const jcDependentCode =
      currentJCFound?.jobcardDependency?.jobcardDependencyList?.[0];
    const dependentOnJCFound = queriedJobCards?.find((resItem: any) => {
      return resItem?.jobCardCode === jcDependentCode;
    });

    return {
      allJCDetails: queriedJobCards,
      currentJCDependentOn: dependentOnJCFound,
      currentJCDetails: currentJCFound
    };
  };
}

export function getRearrangedJCWithUpdatedDependencies(
  jobcards: any[],
  fromIndex: number,
  toIndex: number
) {
  // Deep copy of jobcards array
  jobcards = deepClone(jobcards);

  const oldUnModifiedJCs = deepClone(jobcards);

  // Return from here checking all the conditions
  if (!validateJCArrangement(jobcards, fromIndex, toIndex)) {
    return oldUnModifiedJCs;
  }

  // Check if there are any existing dependencies
  const hasDependencies = jobcards.some(
    (jobcard) => jobcard.jobcardDependency?.jobcardDependencyList?.length > 0
  );

  // Move the jobcard within the array
  const [movedJobCard] = jobcards.splice(fromIndex, 1);
  jobcards.splice(toIndex, 0, movedJobCard);

  // If there are no dependencies, simply return the reordered job cards
  if (!hasDependencies) {
    return jobcards;
  }

  // Determine the range of job cards to update
  const start = Math.min(fromIndex, toIndex);
  const end = Math.max(fromIndex, toIndex);

  // Save the initial dependencies
  const initialDependencies = jobcards?.map((jobcard: any) => ({
    jobCardCode: jobcard?.jobCardCode,
    dependencies: jobcard?.jobcardDependency?.jobcardDependencyList || []
  }));

  // Update dependencies for the moved job card and its neighbors
  if (toIndex > 0) {
    jobcards[toIndex] = {
      ...jobcards[toIndex],
      jobcardDependency: {
        ...jobcards[toIndex]?.jobcardDependency,
        jobcardDependencyList: [jobcards[toIndex - 1].jobCardCode]
      }
    };
  }

  if (toIndex < jobcards.length - 1) {
    jobcards[toIndex + 1] = {
      ...jobcards[toIndex + 1],
      jobcardDependency: {
        ...jobcards[toIndex + 1]?.jobcardDependency,
        jobcardDependencyList: [jobcards[toIndex].jobCardCode]
      }
    };
  }

  const jobCardDependentOnMovedJC = oldUnModifiedJCs?.find((itemJC: any) => {
    return (
      movedJobCard.jobCardCode ===
      itemJC?.jobcardDependency?.jobcardDependencyList?.[0]
    );
  });

  // make dependent on of 1st jc as blank
  if (toIndex === 0) {
    jobcards[toIndex] = {
      ...jobcards[toIndex],
      jobcardDependency: {
        ...jobcards[toIndex]?.jobcardDependency,
        jobcardDependencyList: []
      }
    };
  }

  // 1. make jobcard that was dependent on moved jc as dependent on prev one
  jobcards = jobcards?.map((jobcard, index) => {
    if (jobcard?.jobCardCode === jobCardDependentOnMovedJC?.jobCardCode) {
      const prevJCIsProcessing =
        Utility.isNotEmpty(jobcards[index - 1]) &&
        jobcards[index - 1]?.jobCardLinkDetails?.[0]?.processType ===
          JC_PROCESS_TYPE.PROCESSING;
      jobcard = {
        ...jobcard,
        jobcardDependency: {
          ...jobcard?.jobcardDependency,
          jobcardDependencyList: prevJCIsProcessing
            ? [jobcards[index - 1]?.jobCardCode]
            : []
        }
      };
    }
    return jobcard;
  });

  return jobcards;
}

const validateJCArrangement = (
  jobCardList: any,
  fromIndex: number,
  toIndex: number
) => {
  let unModifiedJCs = deepClone(jobCardList);
  let jobCardArr = deepClone(jobCardList);

  const prevJC = unModifiedJCs[toIndex - 1]; // previous JC of the new shifted JC
  const nextJC = unModifiedJCs[toIndex]; // next JC of the new shifted JC
  const [movedJobCard] = unModifiedJCs.splice(fromIndex, 1);
  const qcPresentAndNotYetCompletedORQcNotAllowed =
    (prevJC?.jobCardLinkDetails?.[0]?.qcNeeded &&
      prevJC?.jobCardLinkDetails?.[0]?.status !== 'COMPLETE') ||
    !prevJC?.jobCardLinkDetails?.[0]?.qcNeeded;

  if (
    movedJobCard?.status === JOB_CARD_STATUS.COMPLETED ||
    Utility.isNotEmpty(
      nextJC?.jobCardLinkDetails?.[0]?.itemWarehouseInventoryData
    ) ||
    Utility.isNotEmpty(
      movedJobCard?.jobCardLinkDetails?.[0]?.itemWarehouseInventoryData
    )
  ) {
    return false;
  }

  if (!qcPresentAndNotYetCompletedORQcNotAllowed) {
    return false;
  }

  if (Utility.isEmpty(prevJC) || Utility.isEmpty(nextJC)) {
    return true;
  }

  return true;
};

export const getDependentOnJCCodes = (jcList: any[]) => {
  let jcCodes: any[] = [];

  jcList?.forEach((jc: any) => {
    jc?.jobcardDependency?.jobcardDependencyList?.forEach(
      (dependentOnCode: any) => {
        jcCodes.push(dependentOnCode);
      }
    );
  });

  return jcCodes;
};

export const getAddNewJCInWOPayload = (
  workOrderData: any,
  newOperationAdded: any,
  workstations: any
) => {
  const wkFound = workstations?.content?.find(
    (workstation: any) =>
      workstation.id === newOperationAdded?.defaultWorkstation
  );
  const checkOperationTimeType = Utility.checkTimeType(
    newOperationAdded?.operationTime || 0
  );

  let payload = {
    fgquantityTransferredRawMaterials: null,
    jobCardDate: DateFormatService.getDateStrFromDate(
      new Date(),
      BOOKS_DATE_FORMAT['YYYY-MM-DD']
    ),
    jobCardTimeLogs: [],
    operationId: newOperationAdded?.id,
    requestedQuantity: null,
    // sequenceFormat: '66839fa504db02c9987367b4',
    status: JOB_CARD_STATUS.OPEN,
    totalCompletedQuantity: null,
    workOrderCode: workOrderData?.workOrderCode,
    plannedTime: Utility.convertMinutesInFormat(
      newOperationAdded?.operationTime || 0,
      checkOperationTimeType?.value
    ),
    actualTime: '',
    operatorDetails: null,
    workOrderItemCode: workOrderData?.productCode,
    workOrderItemName: workOrderData?.productName,
    operatorCostDetails: null, //TODO
    operationCostDetails: null, //TODO
    workstationId: wkFound?.id,
    instructions: newOperationAdded?.instructions
      ? Utility.decodeHTML(newOperationAdded?.instructions || '')
      : ' ',
    salesOrderCustomerOrderNumber:
      workOrderData?.workOrderSourceDetails?.[0]?.salesOrderCustomerOrderNumber,
    jobcardDependency: {
      jobcardDependencyList: []
    },
    plannedStartDate: Utility.formatDate(
      new Date(),
      DATE_FORMAT.DD_MM_YYYYTHH_MM_SS
    ),
    plannedEndDate: Utility.formatDate(
      new Date(),
      DATE_FORMAT.DD_MM_YYYYTHH_MM_SS
    ),
    workstationDetails: wkFound
  };

  return payload;
};

export const addNewJobcardRowInWOForm = (
  isInsertedInBetween: boolean = false
) => {
  const newRow = {
    jobCardCode: '',
    operationId: '',
    jobCardDependency: null,
    status: JOB_CARD_STATUS.OPEN,
    plannedTime: 0,
    plannedStartDate: new Date(),
    plannedEndDate: new Date(),
    processType: null,
    items: null,
    qcNeeded: false,
    qcStatus: '',
    totalAssignedQtys: 0,
    totalProducedQtys: 0,
    rowButtons: [],
    rowContextMenu: [],
    isNewRow: true,
    addedInBetween: isInsertedInBetween,
    jobCardLinkDetails: [],
    invalidFields: ['operationId']
  };
  return newRow;
};

export const isAnyUnsavedOperationAddedInWO = (operationList: any[]) => {
  const filteredNewOps = operationList?.filter((op: any) => op?.isNewRow);

  return filteredNewOps?.length > 0;
};

export const updateStartAndEndDatesAfterJCReorder = (jobCards: any) => {
  // Create a map of job cards by jobCardCode for easier access
  let jobCardMap: any = {};
  jobCards.forEach((jobCard: any) => {
    jobCardMap[jobCard.jobCardCode] = jobCard;
  });

  // Function to update dependent job card dates
  function updateDependentDates(
    currentIterationJCCode: any,
    previousJobCardCode: any
  ) {
    let currentIterationJC = jobCardMap[currentIterationJCCode];
    let previousJobCard = jobCardMap[previousJobCardCode];

    let currentJCsOldStartDate = new Date(currentIterationJC?.plannedStartDate);
    let currentJCsOldEndDate = new Date(currentIterationJC?.plannedEndDate);

    let previousEndDate = new Date(previousJobCard.plannedEndDate);
    currentIterationJC.plannedStartDate = new Date(previousEndDate);

    let currentIterationJCStartDate = new Date(
      currentIterationJC.plannedStartDate
    );

    let duration =
      (currentJCsOldEndDate as any) - (currentJCsOldStartDate as any);
    let newEndDate = new Date(currentIterationJCStartDate.getTime() + duration);
    currentIterationJC.plannedEndDate = new Date(newEndDate);
  }

  // Iterate through job cards and update dates based on dependencies
  jobCards.forEach((jobCard: any) => {
    if (
      jobCard?.jobcardDependency &&
      jobCard?.jobcardDependency?.jobcardDependencyList?.length > 0
    ) {
      jobCard?.jobcardDependency?.jobcardDependencyList?.forEach(
        (dependency: any) => {
          updateDependentDates(jobCard.jobCardCode, dependency);
        }
      );
    }
  });

  // Update all planned start and end dates to the desired format
  jobCards = jobCards.map((jobCard: any) => {
    jobCard.plannedStartDate = Utility.formatDate(
      new Date(jobCard.plannedStartDate),
      DATE_FORMAT.DD_MM_YYYYTHH_MM_SS
    );
    jobCard.plannedEndDate = Utility.formatDate(
      new Date(jobCard.plannedEndDate),
      DATE_FORMAT.DD_MM_YYYYTHH_MM_SS
    );

    return jobCard;
  });

  return jobCards;
};

export const getJCListInvalidColumns = (
  gridRow: any,
  allRows: any[],
  isJCCompletedOrCancelled: boolean
) => {
  let invalidColumns: any[] = [];

  let jcsDependentOnCurrentRow =
    allRows?.filter((itemRow: any) => {
      return (
        itemRow?.jobcardDependency?.jobcardDependencyList?.[0] ===
        gridRow?.jobCardCode
      );
    }) ?? [];

  if (
    gridRow?.jobcardDependency?.jobcardDependencyList?.length > 0 ||
    jcsDependentOnCurrentRow?.length > 0
  ) {
    if (
      gridRow?.jobCardLinkDetails?.length <= 0 &&
      Utility.isEmpty(gridRow?.localProcessType) &&
      !isJCCompletedOrCancelled
    ) {
      invalidColumns.push('processType');
    }
  }
  if (
    ((gridRow?.jobcardDependency?.jobcardDependencyList?.length > 0 &&
      gridRow?.jobCardLinkDetails?.length <= 0) ||
      (gridRow?.jobCardLinkDetails?.length <= 0 &&
        jcsDependentOnCurrentRow?.length > 0)) &&
    !isJCCompletedOrCancelled
  ) {
    invalidColumns.push('items');
  }

  return invalidColumns;
};

export const getNonEditableColumnsForJCRow = (
  row: any,
  isStatusCompleteOrCancelled: boolean
) => {
  if (isStatusCompleteOrCancelled) {
    return [
      'operationId',
      'processType',
      'items',
      'qcNeeded',
      'jobCardDependency'
    ];
  } else {
    if (row?.isNewRow) {
      return ['processType', 'items', 'qcNeeded', 'jobCardDependency'];
    } else if (
      Utility.isNotEmpty(
        row?.jobCardLinkDetails?.[0]?.itemWarehouseInventoryData
      )
    ) {
      return [
        'operationId',
        'processType',
        'items',
        'qcNeeded',
        'jobCardDependency'
      ];
    } else if (
      Utility.isNotEmpty(row?.jobCardLinkDetails?.[0]) &&
      Utility.isEmpty(row?.jobCardLinkDetails?.[0]?.itemWarehouseInventoryData)
    ) {
      return ['operationId', 'processType'];
    } else {
      return ['operationId'];
    }
  }
};

export const currentRowDependentByAnyJC = (row: any, allJCs: any) => {
  const thatRow = allJCs?.find((item: any) => {
    return (
      item?.jobcardDependency?.jobcardDependencyList?.[0] === row?.jobCardCode
    );
  });
  return thatRow;
};

export const validateProcessTypeChange = (
  processType: any,
  currentRow: any,
  allRows: any[]
) => {
  const currentRowDependentByJC = currentRowDependentByAnyJC(
    currentRow,
    allRows
  );

  if (
    currentRowDependentByJC?.jobCardLinkDetails?.[0]?.processType ===
      JC_PROCESS_TYPE.PROCESSING &&
    processType.key === JC_PROCESS_TYPE.CONSUMPTION
  ) {
    showAlert(
      'Please note',
      'This process type is not allowed here, as this job card is dependent by some other which has type as processing.'
    );
    return false;
  }

  return true;
};

export const allowRowDragInJobCardList = (
  invalidFieldsNumber: any,
  rows: any
) => {
  return (
    Utility.isProcessManufacturingAndAdhocEnabledForMRP() &&
    invalidFieldsNumber === 0
  );
};
