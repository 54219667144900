import {
  DKButton,
  DKDataGrid,
  DKIcon,
  DKIcons,
  DKLabel,
  DKLine,
  TOAST_TYPE,
  Toggle,
  showAlert,
  showToast,
  DKInput,
  INPUT_TYPE
} from 'deskera-ui-library';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ApiConstants from '../../../../Constants/ApiConstants';
import {
  DOC_TYPE,
  DOC_TYPE_TO_ATTACHMENT_MAP,
  ENTITY_TYPE_ATTACHMENT,
  INPUT_VIEW_DIRECTION,
  PRODUCE_PRODUCT_TYPE,
  PRODUCT_TYPE
} from '../../../../Constants/Constant';
import { useAppSelector, useAppDispatch } from '../../../../Redux/Hooks';
import { activeTenantInfo } from '../../../../Redux/Slices/AuthSlice';
import { selectUOMs } from '../../../../Redux/Slices/CommonDataSlice';
import {
  addOperations,
  selectOperations
} from '../../../../Redux/Slices/MRP/OperationSlice';
import MRPProductsService, {
  MRPProductsAPIConfig
} from '../../../../Services/MRP/MRPProducts';
import NumberFormatService from '../../../../Services/NumberFormat';
import Utility, { deepClone } from '../../../../Utility/Utility';
import {
  ADDITIONAL_COST_CONFT,
  BY_PRODUCTS_COL_CONF,
  COMPONENT_PRODUCTS_COL_CONF,
  OPERATION_COST_CONFT
} from '../../Constants/MRPColumnConfigs';
import { ProductSubstitutePopup } from '../ProductSubstitutePopup';
import {
  BomConfigurationModel,
  BomOperation,
  ComponentProduct,
  NewBomConfigurationModel
} from './BomConfigurationModel';
import useViewModel from './BomDetailsTabViewModel';
import BomVersionHistory from './BomVersionHistory';
import { PROCESS_TYPES } from '../../Constants/MRPEnums';
import { getStatusChipColor } from '../../WorkOrder/WorkOrderHelper';
import OperationsService from '../../../../Services/MRP/Operations';
import AttachmentService from '../../../../Services/Attachment';
import { triggerDownload } from '../../../ImportExport/utility/ExportData';

export default function BOMDetailsTabProduct(props: any) {
  const tenantInfo = useAppSelector(activeTenantInfo);
  const [product, setProduct] = useState(props.product);
  const isEdit = props.product && props.product.id ? true : false;
  const { t, i18n } = useTranslation();
  const uomList = useAppSelector(selectUOMs);
  const [ComponentProductColumnConfig, setComponentProductColumnConfig] =
    useState(COMPONENT_PRODUCTS_COL_CONF);
  const [ByProductColumnConfig, setByProductColumnConfig] =
    useState(BY_PRODUCTS_COL_CONF);
  const [productsData, setProductsData] = useState<any[]>([]);
  const operationsList = useAppSelector(selectOperations);
  const [operationColumnConfig, setOperationColumnConfig] =
    useState(OPERATION_COST_CONFT);
  const TITLES = {
    COMPONENT_PRODUCT: 'Component Products',
    BY_PRODUCT: 'By-Products',
    ADDITIONAL_COST: 'Additional Cost',
    OPERATIONS: 'Operations'
  };
  const [showSubstituteProductView, setShowSubstituteProductView] =
    useState(false);
  const [selectedComponentProductIndex, setSelectedComponentProductIndex] =
    useState<any>();
  const [selectedProductUOMS, setSelectedProductUOMS] = useState<any>();

  const [bomMetaDetails, setBomMetaDetails] = useState<any[]>(
    props?.product?.bomMetaDetailsList
      ? [...props?.product?.bomMetaDetailsList]?.map(
          (item: BomConfigurationModel) => {
            return {
              ...item,
              bomProductsConfiguration: item?.bomProductsConfiguration?.filter(
                (pro: any) =>
                  pro.produceProductType !== PRODUCE_PRODUCT_TYPE.SCRAP &&
                  pro.produceProductType !== PRODUCE_PRODUCT_TYPE.CO_PRODUCT
              ),
              byProducts: item?.bomProductsConfiguration?.filter(
                (pro: any) =>
                  pro.produceProductType === PRODUCE_PRODUCT_TYPE.SCRAP ||
                  pro.produceProductType === PRODUCE_PRODUCT_TYPE.CO_PRODUCT
              ),
              bomOperationsConfiguration:
                item?.bomOperationsConfiguration?.sort(
                  (a: any, b: any) => a?.sequenceNumber - b?.sequenceNumber
                )
            };
          }
        )
      : [{ ...NewBomConfigurationModel, isDefault: true }]
  );

  const [expandedIndexes, setExpandedIndexes] = useState<number[]>([0]);

  const [
    isSubstituteBeingEditedForInUsedProduct,
    setIsSubstituteBeingEditedForInUsedProduct
  ] = useState(false);
  const [currentConfiguration, setCurrentConfiguration] = useState<any>(null);
  const [showBomVersionURL, setshowBomVersionURL] = useState<any>(undefined);
  const [showMoreOptionsIndex, setShowMoreOptionsIndex] = useState(-1);
  const [routingTemplatePopup, setRoutingTemplatePopup] = useState<number>(-1);
  const [attachments, setAttachments] = useState<any>([]);
  const routingTemplatePopupRef = useRef<HTMLDivElement>(null);
  const dispatch = useAppDispatch();

  const {
    // updateBomMetaObject,
    getComponentProducts,
    onRowUpdate,
    dragAndDropOperation,
    copyDependentByQC,
    copyDependentByItem,
    copyDependentOnItem,
    copyDependentOnQC,
    getDependentOpIds,
    updateNonDependentItems,
    addComponentProduct,
    addScrapCoSubProduct,
    addAdditionalCost,
    addAnOperations,
    removeComponent,
    removeScrapCoProduct,
    removeAdditionalCost,
    removeOperation,
    updateDefaultConfig,
    addMoreConfig,
    deleteConfigurationAtIndex,
    updateBOMName,
    updatedCurrentEditableIndex,
    updateSubstituteForComponentProduct,
    updateActiveStatusForConfigAtIndex,
    getCurrentSelectedConfig,
    getCurrentEditableIndex,
    getUnchangedProduct,
    addOperationsFromTemplate,
    addAttachment,
    setAttachment,
    deleteAttachment,
    insertComponentProduct,
    insertOperation
  } = useViewModel(deepClone(bomMetaDetails));

  useEffect(() => {
    updateComponentProductColumnConfig();
    updateByProductColumnConfig();
    updateOperationConfig();
    loadProducts();
    updateOperationsFromProps();
    fetchAttachments();
  }, []);

  const loadProducts = () => {
    let previousQueryString = MRPProductsService.apiConfig?.Query;
    const config: MRPProductsAPIConfig = {
      ...MRPProductsService.apiConfig,
      Page: 0,
      SearchTerm: '',
      Query: 'active=true,hasVariants=false',
      Limit: 20,
      IsVariant: false
    };
    try {
      MRPProductsService.apiConfig = config;
      MRPProductsService.getProductsByPage(
        Utility.getCountryApiExtension()
      ).then((data: any) => {
        MRPProductsService.apiConfig.Query = previousQueryString;
        setProductsData(data.content);
      });
    } catch (err) {
      MRPProductsService.apiConfig.Query = previousQueryString;
      console.error('Error loading products: ', err);
    }
  };

  useEffect(() => {
    props.onChange({ ...product });
    return () => {};
  }, [product]);

  useEffect(() => {
    let copyProduct = { ...product };
    let bomConfig = [...getComponentProducts()];
    bomConfig = bomConfig?.map((item: BomConfigurationModel) => {
      let copyItem = { ...item };
      copyItem.bomProductsConfiguration = (
        copyItem?.bomProductsConfiguration ?? []
      ).concat(copyItem?.byProducts ?? []);
      delete copyItem.byProducts;
      return copyItem;
    });
    copyProduct.bomMetaDetailsList = bomConfig;
    setProduct(copyProduct);
    props.onChange({ ...copyProduct });
    // update column config
    updateComponentProductColumnConfig();
    updateByProductColumnConfig();
    updateOperationConfig();
  }, [getComponentProducts(), selectedProductUOMS]);

  const updateOperationsFromProps = () => {
    if (props.operationListEditMode?.length) {
      const opListIds = operationsList.content?.map((op: any) => op.id);
      const operationsToAdd = props.operationListEditMode.filter(
        (op: any) => !opListIds.includes(op.id)
      );
      if (operationsToAdd?.length) {
        dispatch(addOperations([...operationsToAdd]));
      }
    }
  };

  const getSelectedUOMValue = (id: any, list: any[]) => {
    let filtered = list?.find((item: any) => item.id === id);
    if (!Utility.isEmpty(filtered)) {
      return filtered.name;
    } else {
      return '';
    }
  };

  const fetchAttachments = () => {
    const entityIdArr: any = [];
    props?.product?.bomMetaDetailsList?.forEach((bom: any) => {
      entityIdArr.push(bom.id);
    });

    AttachmentService.getAllAttachmentsByEntityId(
      entityIdArr,
      ENTITY_TYPE_ATTACHMENT['PRODUCT_BOM_DETAILS']
    ).then((attachmentList: any) => {
      setAttachment(attachmentList);
      setAttachments(attachmentList);
    });
  };

  const getFilteredProducts = (data: any) => {
    let bomConfigsArray = getComponentProducts() ?? [];
    let componentProducts: ComponentProduct[] =
      bomConfigsArray[getCurrentEditableIndex()]?.bomProductsConfiguration ??
      [];
    let selectedProductIds = componentProducts?.map(
      (current: any) => current.itemId
    );
    if (product?.id) {
      selectedProductIds.push(product.id);
    }
    if (props.isReplaceFlow) {
      selectedProductIds = [
        ...selectedProductIds,
        ...props.selectedProductsToIgnore
      ];
    }
    let filtered =
      data?.filter(
        (prodItem: any) =>
          prodItem.active &&
          !selectedProductIds?.includes(prodItem.id) &&
          !selectedProductIds?.includes(prodItem.productId) &&
          prodItem?.hasVariants === false
      ) || [];
    return filtered;
  };

  const filterProductDataForBy = (data: any) => {
    let bomConfigsArray = getComponentProducts() ?? [];
    let byProducts: ComponentProduct[] =
      bomConfigsArray[getCurrentEditableIndex()]?.byProducts ?? [];
    let selectedProductIds = byProducts?.map((current: any) => current.itemId);
    if (product?.id) {
      selectedProductIds.push(product.id);
    }
    let filtered =
      data?.filter(
        (prodItem: any) =>
          prodItem.active &&
          !selectedProductIds?.includes(prodItem.id) &&
          prodItem.hasVariants === false
      ) || [];
    return filtered;
  };

  const setUomsForSelectedProduct = (product: any) => {
    let productCopy = { ...product };
    let uoms = [];
    let filtered = uomList?.filter((uom: any) => {
      const keyToCompare =
        productCopy.defaultStockUom ??
        productCopy?.uomSchemaDto?.baseUOM?.id ??
        productCopy?.stockUom;
      return uom.id === keyToCompare;
    });
    if (!Utility.isEmpty(filtered)) {
      uoms.push({ ...filtered[0], isBaseUom: true });
    }
    if (
      !Utility.isEmpty(productCopy) &&
      !Utility.isEmpty(productCopy.uomSchemaDto)
    ) {
      // uoms[0] = { ...uoms[0], isBaseUom: true };
      let processedUoms = productCopy.uomSchemaDto.uomSchemaDefinitions.map(
        (uomSchema: any) => {
          let filteredFromList = uomList?.filter(
            (uom: any) => uom.id === uomSchema.sinkUOM
          );
          let name = '';
          if (filteredFromList && filteredFromList.length > 0) {
            name = filteredFromList[0].name;
          }
          return {
            ...uomSchema,
            name: name,
            id: uomSchema?.sinkUOM,
            isBaseUom: false
          };
        }
      );
      uoms = uoms.concat(processedUoms);
    }
    setSelectedProductUOMS(uoms);
  };

  const updateComponentProductColumnConfig = () => {
    let config: any = [...ComponentProductColumnConfig];
    config.forEach((conf: any) => {
      switch (conf.key) {
        case 'product':
          conf.dropdownConfig.data = getFilteredProducts(productsData);
          conf.dropdownConfig.searchApiConfig.getUrl = (search: any) => {
            let endPoint =
              ApiConstants.URL.BASE +
              `products?search=${search}&limit=20&page=0&query=active=true,hasVariants=false`;
            return endPoint;
          };

          conf.dropdownConfig.searchApiConfig.dataParser = (response: any) => {
            return getFilteredProducts(response?.content);
          };
          conf.dropdownConfig.renderer = (index: any, obj: any) => {
            return (
              <div className="column parent-width">
                <DKLabel className="row text-left" text={obj.name} />
                <DKLabel
                  className="text-left text-gray fs-s"
                  text={`Number: ${
                    obj.documentSequenceCode ? obj.documentSequenceCode : ''
                  }`}
                />
                {obj.hsnOrSacCode && (
                  <DKLabel
                    className="text-left text-gray fs-s"
                    text={`HSN/SAC: ${
                      obj.hsnOrSacCode ? obj.hsnOrSacCode : ''
                    }`}
                  />
                )}
              </div>
            );
          };
          break;
        case 'uom':
          conf.dropdownConfig.data = selectedProductUOMS;
          conf.dropdownConfig.renderer = (index: any, obj: any) => {
            const name = obj?.name || '-';
            return name?.toUpperCase();
          };
          conf.formatter = (data: any) => {
            return !Utility.isEmpty(
              getSelectedUOMValue(data?.rowData?.stockUom, uomList)
            )
              ? getSelectedUOMValue(data?.rowData?.stockUom, uomList)
              : '-';
          };
          break;
        case 'cost':
          conf.formatter = (data: any) => {
            return data?.rowData?.productType === PRODUCT_TYPE.NON_TRACKED
              ? Utility.amountFormatter(
                  data?.rowData?.cost || 0,
                  tenantInfo.currency
                )
              : '-';
          };
          break;
        case 'quantity':
          conf.formatter = (data: any) => {
            let quantity = 0;
            quantity = data?.rowData?.quantity || 0;
            return data?.rowData?.productType === PRODUCT_TYPE.NON_TRACKED
              ? '-'
              : quantity;
          };
      }
    });
    setComponentProductColumnConfig(config);
  };

  const updateByProductColumnConfig = () => {
    let config: any = [...ByProductColumnConfig];
    config.forEach((conf: any) => {
      switch (conf.key) {
        case 'product':
          conf.dropdownConfig.data = filterProductDataForBy(productsData);
          conf.dropdownConfig.searchApiConfig.getUrl = (search: any) => {
            let endPoint =
              ApiConstants.URL.BASE +
              `products?search=${search}&limit=20&page=0&query=type!NONTRACKED,active=true,hasVariants=false`;
            return endPoint;
          };

          conf.dropdownConfig.searchApiConfig.dataParser = (response: any) => {
            return filterProductDataForBy(response?.content);
          };
          conf.dropdownConfig.renderer = (index: any, obj: any) => {
            return (
              <div className="column parent-width">
                <DKLabel className="row text-left" text={obj.name} />
                <DKLabel
                  className="text-left text-gray fs-s"
                  text={`Number: ${
                    obj.documentSequenceCode ? obj.documentSequenceCode : ''
                  }`}
                />
                {obj.hsnOrSacCode && (
                  <DKLabel
                    className="text-left text-gray fs-s"
                    text={`HSN/SAC: ${
                      obj.hsnOrSacCode ? obj.hsnOrSacCode : ''
                    }`}
                  />
                )}
              </div>
            );
          };
          break;
        case 'uom':
          conf.formatter = (data: any) => {
            return !Utility.isEmpty(
              getSelectedUOMValue(data?.rowData?.stockUom, uomList)
            )
              ? getSelectedUOMValue(data?.rowData?.stockUom, uomList)
              : '';
          };
          break;
      }
    });
    setByProductColumnConfig(config);
  };

  const getFilteredOperations = (response: any[]) => {
    let bomConfigsArray = getComponentProducts() ?? [];
    let operations =
      bomConfigsArray[getCurrentEditableIndex()]?.bomOperationsConfiguration ??
      [];
    let selectedOperation =
      operations?.reduce((prev: any, current: any) => {
        return [...prev, current.operationId];
      }, []) || [];
    let filtered =
      response?.filter(
        (operation: any) => !selectedOperation.includes(operation.id)
      ) || [];
    return filtered;
  };

  const getFilteredOperationsForDependency = (
    response: any[],
    currentOperation: any
  ) => {
    let bomConfigsArray = getComponentProducts() ?? [];
    let operations =
      bomConfigsArray[getCurrentEditableIndex()]?.bomOperationsConfiguration ??
      [];
    let selectedOperation =
      operations?.reduce((prev: any, current: any) => {
        return [...prev, current?.operationId];
      }, []) || [];
    let filtered: any[] = [];
    const dependencyUsed: any = [];
    operations.forEach((op: any, index: any) => {
      if (op.operationDependency?.opDependencyList?.length) {
        dependencyUsed.push(op.operationDependency.opDependencyList[0]);
      }
    });

    filtered =
      operations?.filter(
        (operation: any) =>
          !Utility.isEmpty(operation?.operationName) &&
          selectedOperation.includes(operation?.operationId) &&
          currentOperation?.rowData?.operationId !== operation?.operationId &&
          !dependencyUsed.includes(operation.operationId)
      ) || [];

    filtered.unshift({ operationName: 'None' });

    return filtered ?? [];
  };

  const updateOperationConfig = (currentOperation?: any, rows?: any[]) => {
    let config: any = [...operationColumnConfig];
    config.forEach((conf: any) => {
      switch (conf.key) {
        case 'operation':
          let operationsArray = operationsList?.content
            ? operationsList?.content
            : [];
          conf.dropdownConfig.data =
            operationsArray.length > 0
              ? getFilteredOperations(operationsArray)
              : [];
          conf.dropdownConfig.searchApiConfig.getUrl = (search: any) => {
            return `${ApiConstants.URL.BASE}mrp/operation/search?limit=25&search=${search}&page=0&sort=createdOn&sortDir=desc`;
          };
          conf.dropdownConfig.searchApiConfig.dataParser = (response: any) => {
            return getFilteredOperations(response?.content);
          };
          conf.dropdownConfig.renderer = (index: any, obj: any) => {
            return <DKLabel className="text-left" text={obj.name} />;
          };
          break;
        case 'costPerHour':
          conf.renderer = (data: any) => {
            return (
              <div className="row justify-content-end">
                <DKLabel
                  style={{ width: conf.width }}
                  text={Utility.getAmoutBlockForGrid(
                    tenantInfo.currency,
                    `${data?.rowData?.costPerHour || 0}`
                  )}
                />
              </div>
            );
          };
          break;
        case 'fixedRate':
          conf.renderer = (data: any) => {
            return (
              <div className="row justify-content-end">
                <DKLabel
                  style={{ width: conf.width }}
                  text={Utility.getAmoutBlockForGrid(
                    tenantInfo.currency,
                    `${data?.rowData?.fixedRate || 0}`
                  )}
                />
              </div>
            );
          };
          break;
        case 'totalCost':
          conf.renderer = (data: any) => {
            const digit = data?.rowData?.totalCost ?? 0;
            return (
              <div className="row justify-content-end">
                <DKLabel
                  style={{ width: conf.width }}
                  text={
                    Utility.getAmoutBlockForGrid(tenantInfo.currency, digit) ||
                    '0'
                  }
                />
              </div>
            );
          };
          break;
        case 'operationTime':
          conf.renderer = (data: any) => {
            return (
              <div className="row">
                <DKLabel
                  style={{ width: conf.width, textAlign: 'right' }}
                  text={NumberFormatService.getNumber(
                    data?.rowData?.operationTime ?? 0
                  )}
                />
              </div>
            );
          };
          break;
        case 'opDependency':
          conf.formatter = (data: any) => {
            return (
              data?.rowData?.opDependency?.name ??
              data?.rowData?.opDependency?.operationName ??
              ''
            );
          };
          let operationsArrayList = operationsList?.content
            ? operationsList?.content
            : [];
          conf.dropdownConfig.searchApiConfig = null;
          conf.dropdownConfig.searchableKey = 'operationName';
          conf.dropdownConfig.data =
            getFilteredOperationsForDependency(
              operationsArrayList,
              currentOperation
            ) ?? [];
          conf.dropdownConfig.renderer = (index: any, obj: any) => {
            return (
              <DKLabel className="fs-r" text={`${obj?.operationName ?? ''}`} />
            );
          };
          break;
        case 'product':
          conf.formatter = (data: any) => {
            return data?.rowData?.product?.itemName ?? '';
          };

          if (currentOperation?.rowData?.processType) {
            let consumedItems =
              rows
                ?.filter(
                  (operation: any) =>
                    operation.productCode &&
                    (operation.processType === 'CONSUMPTION' ||
                      operation.processType === 'PRODUCTION')
                )
                .map((operation: any) => operation.productCode) || [];
            let opDependency =
              currentOperation.rowData.operationDependency?.opDependencyList
                ?.length &&
              rows?.find((opItem: any) =>
                currentOperation.rowData.operationDependency?.opDependencyList.includes(
                  opItem.operationId
                )
              );

            switch (currentOperation?.rowData?.processType) {
              case 'PRODUCTION':
                conf.dropdownConfig.data =
                  currentOperation.rowData.productOptions?.filter(
                    (pItem: any) =>
                      pItem.productType === PRODUCT_TYPE.BILL_OF_MATERIALS &&
                      !consumedItems.includes(pItem.productCode) &&
                      (!opDependency ||
                        opDependency.productCode === pItem.productCode)
                  );
                break;
              case 'CONSUMPTION':
              case 'PROCESSING':
                conf.dropdownConfig.data =
                  currentOperation.rowData.productOptions?.filter(
                    (pItem: any) =>
                      !consumedItems.includes(pItem.productCode) &&
                      (!opDependency ||
                        opDependency.productCode === pItem.productCode)
                  );
                break;
            }
          } else {
            conf.dropdownConfig.data = [];
          }

          break;
        case 'processType':
          let processTypes = Object.entries(PROCESS_TYPES).map((item) => ({
            value: item[0],
            label: item[1]
          }));
          conf.formatter = (data: any) => {
            return (
              (data?.rowData?.processType &&
                PROCESS_TYPES[
                  data.rowData.processType as keyof typeof PROCESS_TYPES
                ]) ||
              ''
            );
          };
          conf.dropdownConfig.data = processTypes;
          break;
        case 'qcNeeded':
          if (
            Utility.isEmpty(currentOperation?.rowData?.processType) ||
            Utility.isEmpty(currentOperation?.rowData?.productCode) ||
            currentOperation?.rowData?.processType === 'PROCESSING'
          ) {
            conf.editable = false;
          } else {
            conf.editable = true;
          }
          conf.renderer = ({ rowData }: any) => {
            return (
              <DKLabel
                style={{ width: 80 }}
                text={rowData?.qcNeeded === true ? 'Yes' : 'No'}
                className={`${getStatusChipColor(
                  rowData?.qcNeeded === true ? 'completed' : ''
                )}`}
              />
            );
          };
          conf.dropdownConfig.renderer = (index: number, item: any) => (
            <DKLabel
              key={index}
              style={{ width: 90 }}
              text={item}
              className={`${getStatusChipColor(
                item === 'Yes' ? 'completed' : ''
              )}`}
            />
          );
          break;
      }
    });

    if (!tenantInfo?.additionalSettings.LINK_INVENTORY_JOB_CARDS) {
      config = config.filter(
        (col: any) => !['product', 'processType', 'qcNeeded'].includes(col.key)
      );
    }

    setOperationColumnConfig(config);
  };

  const getHeaderButton = (
    title: string,
    currentConfigIndex: number,
    disableEditing: boolean
  ) => {
    switch (title) {
      case TITLES.COMPONENT_PRODUCT:
        return [
          {
            title: '+ Add Item',
            className: 'text-app-color ml-m text-app-color p-0 fw-m',
            onClick: () => {
              if (disableEditing) {
                return;
              }
              updatedCurrentEditableIndex(currentConfigIndex);
              addComponentProduct();
            }
          }
        ];
      case TITLES.BY_PRODUCT:
        return [
          {
            title: '+ Add Item',
            className: 'text-app-color ml-m text-app-color p-0 fw-m',
            onClick: () => {
              if (disableEditing) {
                return;
              }
              updatedCurrentEditableIndex(currentConfigIndex);
              addScrapCoSubProduct();
            }
          }
        ];
      case TITLES.ADDITIONAL_COST:
        return [
          {
            title: '+ Add Item',
            className: 'text-app-color ml-m text-app-color p-0 fw-m',
            onClick: () => {
              if (disableEditing) {
                return;
              }
              updatedCurrentEditableIndex(currentConfigIndex);
              addAdditionalCost();
            }
          }
        ];
      case TITLES.OPERATIONS:
        return [
          {
            title: '+ Add from Template',
            className: 'text-app-color text-app-color p-0 fw-m',
            onClick: () => {
              if (disableEditing) {
                return;
              }
              showRoutingTemplatePopup(currentConfigIndex);
            }
          },
          {
            title: '+ Add Item',
            className: 'text-app-color text-app-color p-0 fw-m',
            onClick: () => {
              if (disableEditing) {
                return;
              }
              updatedCurrentEditableIndex(currentConfigIndex);
              addAnOperations();
            }
          }
        ];
    }
  };

  const onDragRowEndCallback = (fromIndex: number, toIndex: number) => {
    dragAndDropOperation(fromIndex, toIndex);
  };

  const checkIfInvalidFieldsPresent = (rows: any) => {
    let returnValue = false;
    rows.forEach((op: any) => {
      if (op.invalidFields?.length) {
        returnValue = true;
      }
    });
    return returnValue;
  };

  const getGrid = (
    title: string,
    rows: any[],
    columns: any[],
    currentConfigIndex: number,
    disableEditing: boolean,
    allowRowDrag = false
  ) => {
    let allowDrag = allowRowDrag;
    if (allowDrag && checkIfInvalidFieldsPresent(rows)) {
      allowDrag = false;
    }
    return (
      <DKDataGrid
        tableName={title}
        title={`<span class="fs-m text-app-color">${title}</span>`}
        filterTableName={title}
        needColumnIcons={false}
        allowRowEdit={!disableEditing ? true : false}
        allowColumnEdit={false}
        allowSort={false}
        allowRowAdd={true}
        allowColumnAdd={false}
        allowColumnDelete={false}
        allowColumnShift={false}
        allowColumnSort={false}
        allowSearch={false}
        buttons={
          disableEditing
            ? []
            : getHeaderButton(title, currentConfigIndex, disableEditing)
        }
        allowDataExport={true}
        columns={columns}
        rows={rows}
        currentPage={1}
        totalPageCount={1}
        onRowUpdate={(data: any) => {
          if (disableEditing) {
            return;
          }
          updatedCurrentEditableIndex(currentConfigIndex);
          onRowUpdate(title, data);
        }}
        allowBulkOperation={false}
        onRowSelect={(data: any) => {}}
        width={734}
        //contextMenu={title === TITLES.OPERATIONS ? getContextMenu() : []}
        needTrailingColumn={true}
        onAllRowSelect={(data: any) => {}}
        allowRowDrag={allowDrag}
        onDragRowEnd={(fromIndex: number, toIndex: number) => {
          onDragRowEndCallback(fromIndex, toIndex);
        }}
        onRowClick={(data: any) => {
          updatedCurrentEditableIndex(currentConfigIndex);
          setSelectedComponentProductIndex(data?.rowIndex);
          updateComponentProductColumnConfig();
          updateByProductColumnConfig();
          updateOperationConfig(data, rows);

          if (data?.columnData?.id === 'uom') {
            setUomsForSelectedProduct(data?.rowData);
          }
        }}
      />
    );
  };

  const getComponentRow = (
    configuration: BomConfigurationModel,
    currentConfigIndex: number,
    disableEditing: boolean
  ) => {
    const copyConfiguration = deepClone(configuration);
    return (
      configuration?.bomProductsConfiguration?.map(
        (item: any, index: number) => {
          let rowButtonsArray = disableEditing
            ? []
            : [
                {
                  title: '',
                  className: 'p-0',
                  icon: DKIcons.ic_delete,
                  onClick: () => {
                    if (disableEditing) {
                      return;
                    }
                    updatedCurrentEditableIndex(currentConfigIndex);
                    removeComponent(index);
                  }
                }
              ];

          if (item.productType !== PRODUCT_TYPE.NON_TRACKED) {
            rowButtonsArray.push({
              title: `${
                item.bomProductSubstitutesDetails
                  ? `(${item.bomProductSubstitutesDetails.length})`
                  : ''
              }`,
              className: `p-0 text-app-color`,
              icon: DKIcons.ic_product,
              onClick: () => {
                updatedCurrentEditableIndex(currentConfigIndex);
                setSelectedComponentProductIndex(index);
                setShowSubstituteProductView(true);
                setCurrentConfiguration(copyConfiguration);

                if (disableEditing) {
                  setIsSubstituteBeingEditedForInUsedProduct(true);
                }
              }
            });
          }

          let rowContextMenuArr: any[] = [];
          if (!disableEditing) {
            rowContextMenuArr.push({
              title: 'Insert component product',
              onClick: (data: any) => {
                insertComponentProduct(data?.rowIndex, currentConfigIndex);
              }
            });
          }

          return {
            ...item,
            nonEditableColumns:
              item.productType === PRODUCT_TYPE.NON_TRACKED
                ? ['quantity']
                : ['cost'],
            rowButtons: rowButtonsArray,
            rowContextMenu: rowContextMenuArr
          };
        }
      ) || []
    );
  };

  const getScrapCoSubProductRow = (
    configuration: BomConfigurationModel,
    currentConfigIndex: number,
    disableEditing: boolean
  ) => {
    return (
      configuration?.byProducts?.map((item: any, index: number) => {
        return {
          ...item,
          rowButtons: disableEditing
            ? []
            : [
                {
                  title: '',
                  className: 'p-0',
                  icon: DKIcons.ic_delete,
                  onClick: () => {
                    if (disableEditing) {
                      return;
                    }
                    updatedCurrentEditableIndex(currentConfigIndex);
                    removeScrapCoProduct(index);
                  }
                }
              ]
        };
      }) || []
    );
  };

  const getComponentProductsView = (
    configuration: BomConfigurationModel,
    index = 0,
    disableEditing: boolean
  ) => {
    return (
      <div className="column parent-width">
        {!Utility.isEmpty(configuration?.bomProductsConfiguration) &&
          getGrid(
            TITLES.COMPONENT_PRODUCT,
            getComponentRow(configuration, index, disableEditing),
            ComponentProductColumnConfig,
            index,
            disableEditing
          )}
        {(Utility.isEmpty(configuration?.bomProductsConfiguration) ||
          configuration?.bomProductsConfiguration?.length === 0) && (
          <DKButton
            onClick={() => {
              if (disableEditing) {
                return;
              }
              updatedCurrentEditableIndex(index);
              addComponentProduct();
            }}
            title={t(
              `PRODUCTS.DIALOG.PRODUCT.VARIANTS.ADD_A_COMPONENT_PRODUCT`
            )}
            className={`text-app-color`}
            style={{
              paddingLeft: 0
            }}
          />
        )}
      </div>
    );
  };

  const getScrapOrCoSubPrdocutsView = (
    configuration: BomConfigurationModel,
    index = 0,
    disableEditing: boolean
  ) => {
    return (
      <div className="column parent-width">
        {!Utility.isEmpty(configuration?.byProducts) &&
          getGrid(
            TITLES.BY_PRODUCT,
            getScrapCoSubProductRow(configuration, index, disableEditing),
            ByProductColumnConfig,
            index,
            disableEditing
          )}
        {(Utility.isEmpty(configuration?.byProducts) ||
          configuration?.byProducts?.length === 0) && (
          <DKButton
            onClick={() => {
              if (disableEditing) {
                return;
              }
              updatedCurrentEditableIndex(index);
              addScrapCoSubProduct();
            }}
            title={`+ Add a By-Product`}
            className={`text-app-color`}
            style={{
              paddingLeft: 0
            }}
          />
        )}
      </div>
    );
  };

  const getAdditionalCostRows = (
    configuration: BomConfigurationModel,
    currentConfigindex: number,
    disableEditing: boolean
  ) => {
    return (
      configuration?.bomAddCostConfiguration?.map(
        (item: any, index: number) => {
          return {
            ...item,
            rowButtons: disableEditing
              ? []
              : [
                  {
                    title: '',
                    className: 'p-0',
                    icon: DKIcons.ic_delete,
                    onClick: () => {
                      if (disableEditing) {
                        return;
                      }
                      updatedCurrentEditableIndex(currentConfigindex);
                      removeAdditionalCost(index);
                    }
                  }
                ]
          };
        }
      ) || []
    );
  };

  const getAdditionalCostView = (
    configuration: BomConfigurationModel,
    index = 0,
    disableEditing: boolean
  ) => {
    return (
      <div className="column parent-width">
        {!Utility.isEmpty(configuration?.bomAddCostConfiguration) &&
          getGrid(
            TITLES.ADDITIONAL_COST,
            getAdditionalCostRows(configuration, index, disableEditing),
            ADDITIONAL_COST_CONFT,
            index,
            disableEditing
          )}
        {(Utility.isEmpty(configuration?.bomAddCostConfiguration) ||
          configuration?.bomAddCostConfiguration?.length === 0) && (
          <DKButton
            onClick={() => {
              if (disableEditing) {
                return;
              }
              updatedCurrentEditableIndex(index);
              addAdditionalCost();
            }}
            title={t(`PRODUCTS.DIALOG.PRODUCT.VARIANTS.ADD_AN_ADDITIONAL_COST`)}
            className={`text-app-color`}
            style={{
              paddingLeft: 0
            }}
          />
        )}
      </div>
    );
  };

  const needContextMenu = (bomOperationsConfiguration: any, index: any) => {
    let returnValue = false;
    if (
      bomOperationsConfiguration[index].operationDependency?.opDependencyList
        ?.length
    ) {
      returnValue = true;
    }
    bomOperationsConfiguration.forEach((row: any, rowIndex: any) => {
      if (
        row.operationDependency?.opDependencyList?.includes(
          bomOperationsConfiguration[index]?.operationId
        )
      ) {
        returnValue = true;
      }
    });
    return returnValue;
  };

  const getOperationsRows = (
    configuration: BomConfigurationModel,
    currentConfigIndex: number,
    disableEditing: boolean
  ) => {
    return (
      configuration?.bomOperationsConfiguration?.map(
        (item: any, index: number) => {
          let opList: any = operationsList?.content;
          let rowContextMenuArr: any[] = [];

          if (!disableEditing) {
            rowContextMenuArr.push({
              title: 'Insert operation',
              icon: DKIcons.ic_plus,
              onClick: (data: any) => {
                insertOperation(data?.rowIndex, currentConfigIndex);
              }
            });
          }

          if (item.processType === 'PROCESSING') {
            rowContextMenuArr.push({
              title: 'Copy items to dependents',
              icon: DKIcons.ic_copy,
              onClick: (data: any) => {
                copyDependentByItem(data);
                copyDependentOnItem(data);
              }
            });
            rowContextMenuArr.push({
              title: 'Copy items to Non-dependents',
              icon: DKIcons.ic_copy,
              onClick: (data: any, index: any) => {
                const dependentArray = getDependentOpIds(data);
                updateNonDependentItems(
                  dependentArray,
                  data?.rowData?.product,
                  data.rowIndex
                );
              }
            });
          }

          if (!Utility.isEmpty(operationsList?.content)) {
            let matchedOperation = opList?.filter(
              (operationData: any) => operationData.id === item.operationId
            );
            let matchedOperationDependency = opList?.filter(
              (operationData: any) =>
                operationData.id ===
                item?.operationDependency?.opDependencyList?.[0]
            );
            if (!Utility.isEmpty(matchedOperation)) {
              let opTime = 0;
              if (
                item?.operationTime !== undefined &&
                item?.operationTime !== null &&
                +item?.operationTime >= 0
              ) {
                opTime = +item?.operationTime;
              } else {
                opTime = +matchedOperation[0]?.operationTime ?? 0;
              }
              const totalCostCalculated =
                matchedOperation[0]?.costPerHour *
                  Utility.calculateMinutesInHours(
                    matchedOperation[0]?.operationTime
                  ) +
                  matchedOperation[0]?.fixedRate ?? 0;

              const product =
                (item.productCode &&
                  configuration.bomProductsConfiguration?.find(
                    (pItem: any) => pItem.productCode === item.productCode
                  )) ||
                null;

              if (
                needContextMenu(
                  configuration?.bomOperationsConfiguration,
                  index
                ) &&
                Utility.isProcessManufacturingEnabled()
              ) {
                rowContextMenuArr.push({
                  title: 'Copy QC to dependents',
                  icon: DKIcons.ic_copy,
                  onClick: (data: any) => {
                    copyDependentByQC(data);
                    copyDependentOnQC(data);
                  }
                });
              }

              let copyItem = {
                ...item,
                ...matchedOperation[0],
                totalCost: totalCostCalculated ?? 0,
                operationTime: opTime,
                opDependency:
                  matchedOperationDependency?.[0] ?? item?.opDependency,
                product: product,
                productOptions: configuration.bomProductsConfiguration,
                rowContextMenu: rowContextMenuArr,
                rowButtons: disableEditing
                  ? []
                  : [
                      {
                        title: '',
                        className: 'p-0',
                        icon: DKIcons.ic_delete,
                        onClick: () => {
                          if (disableEditing) {
                            return;
                          }
                          updatedCurrentEditableIndex(currentConfigIndex);
                          removeOperation(index);
                        }
                      }
                    ]
              };

              return copyItem;
            }
          }

          const totalCostCalc =
            item?.costPerHour *
              Utility.calculateMinutesInHours(item?.operationTime ?? 0) +
            item?.fixedRate;
          return {
            ...item,
            totalCost: totalCostCalc ?? 0,
            rowContextMenu: rowContextMenuArr,
            rowButtons: [
              {
                title: '',
                className: 'p-0',
                icon: DKIcons.ic_delete,
                onClick: () => {
                  removeOperation(index);
                }
              }
            ]
          };
        }
      ) || []
    );
  };

  const handleClickOutside = (event: Event) => {
    if (
      routingTemplatePopupRef &&
      !routingTemplatePopupRef.current?.contains(event.target as Node)
    ) {
      setRoutingTemplatePopup(-1);
      document.removeEventListener('click', handleClickOutside, true);
    }
  };

  const showRoutingTemplatePopup = (currentConfigIndex: number) => {
    setRoutingTemplatePopup(currentConfigIndex);
    updatedCurrentEditableIndex(currentConfigIndex);
    document.addEventListener('click', handleClickOutside, true);
  };

  const onSelectRoutingTemplate = async (template: any) => {
    setRoutingTemplatePopup(-1);
    document.removeEventListener('click', handleClickOutside, true);

    if (template?.operations?.length) {
      const operationIds = operationsList.content?.map((op: any) => op.id);

      const operationIdsToFetch = template.operations
        .map((opItem: any) => opItem.operationId)
        .filter((id: any) => !operationIds.includes(id));

      let newOperationsList = [...operationsList.content];

      if (operationIdsToFetch.length) {
        let response = await OperationsService.getOperationsById(
          operationIdsToFetch
        );

        if (response?.length) {
          newOperationsList.push(...response);
          dispatch(addOperations([...response]));
        }
      }

      let bomOperations: BomOperation[] = [];
      template.operations.forEach((opItem: any) => {
        let operation = newOperationsList.find(
          (op: any) => op.id === opItem.operationId
        );
        if (operation) {
          let opDependency =
            opItem.opDependencyList?.[0] &&
            newOperationsList.find(
              (op: any) => op.id === opItem.opDependencyList[0]
            );

          let newOp: BomOperation = {
            ...opItem,
            operationName: operation.name,
            costPerHour: operation.costPerHour || 0,
            fixedRate: operation.fixedRate || 0,
            totalCost:
              ((opItem.operationTime || operation.operationTime) / 60) *
                operation.costPerHour || 0,
            operationTime: opItem.operationTime || operation.operationTime,
            opDependency: opDependency
              ? {
                  operationName: opDependency.name,
                  operationId: opDependency.id
                }
              : null,
            operationDependency: {
              opDependencyList: opDependency ? [opDependency.id] : []
            }
          };

          bomOperations.push(newOp);
        }
      });
      addOperationsFromTemplate(bomOperations);
    }
  };

  const uploadAttachmentToAWS = (file: File, index: any) => {
    const moduleType = DOC_TYPE_TO_ATTACHMENT_MAP[DOC_TYPE['PRODUCT_DOCUMENT']];
    AttachmentService.attachmentConfig = {
      Module: moduleType
    };
    if (file.size && file.size / (1024 * 1024) > 5) {
      showAlert(
        'Attachment size limit exceeded',
        'It seems the file size is more than 5 MB, Please compress the file and try again.'
      );
      return;
    }

    AttachmentService.uploadAttachment(file)
      .then((res) => {
        updatedCurrentEditableIndex(index);
        addAttachment(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const triggerAttachmentUpload = (index: any) => {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.addEventListener('change', (e) => {
      const target = e.target as HTMLInputElement;
      target?.files &&
        Array.from(target.files).forEach((file: File) =>
          uploadAttachmentToAWS(file, index)
        );
    });
    input.click();
  };

  const getAttachmentSection = (
    configuration: BomConfigurationModel,
    index = 0,
    disableEditing: boolean
  ) => {
    return (
      <div className="row parent-width mt-2">
        {getAttachments(configuration.attachments, index)}
      </div>
    );
  };

  const triggerAttachmentDownload = (
    attachmentId: any,
    attachmentName: string
  ) => {
    AttachmentService.downloadAttachment(attachmentId)
      .then((absolutePath) => {
        triggerDownload(null, attachmentName, absolutePath);
      })
      .catch(() => {});
  };

  const removeAttachment = (attachmentId: any, index: any) => {
    AttachmentService.deleteAttachment(attachmentId)
      .then((res) => {
        updatedCurrentEditableIndex(index);
        deleteAttachment(attachmentId);
      })
      .catch(() => {});
  };

  const getAttachments = (attachments: any, index: any) => {
    return (
      <div className="row justify-content-start flex-wrap gap-2">
        <DKButton
          title={
            <>
              + Attach Files
              <span className="text-gray fw-r ml-s">(Max 5MB)</span>
            </>
          }
          className={`${'text-app-color'} mt-r fw-m`}
          onClick={() => triggerAttachmentUpload(index)}
          style={{ paddingLeft: 0 }}
        />
        {attachments?.map((attachment: any) => (
          <div
            className="row border-m border-radius-s p-h-r p-v-s mr-r bg-gray0 overflow-hidden width-auto"
            key={attachment.attachmentId}
            style={{ maxWidth: '16rem' }}
          >
            <DKIcon
              src={DKIcons.ic_document}
              className="ic-s cursor-pointer"
              onClick={() => {
                triggerAttachmentDownload(
                  attachment.attachmentId,
                  attachment.attachmentFileName
                );
              }}
            />
            <div
              className="ml-s w-auto truncate cursor-pointer border-none py-2"
              onClick={() => {
                triggerAttachmentDownload(
                  attachment.attachmentId,
                  attachment.attachmentFileName
                );
              }}
            >
              {attachment.attachmentFileName}
            </div>
            <DKIcon
              src={DKIcons.ic_delete}
              className="ic-s ml-l cursor-pointer"
              onClick={() => removeAttachment(attachment.attachmentId, index)}
            />
          </div>
        ))}
      </div>
    );
  };

  const getOperationsView = (
    configuration: BomConfigurationModel,
    index = 0,
    disableEditing: boolean
  ) => {
    return (
      <div className="column parent-width" style={{ position: 'relative' }}>
        {!Utility.isEmpty(configuration?.bomOperationsConfiguration) &&
          getGrid(
            TITLES.OPERATIONS,
            getOperationsRows(configuration, index, disableEditing),
            operationColumnConfig,
            index,
            disableEditing,
            true
          )}
        {routingTemplatePopup === index && (
          <div
            ref={routingTemplatePopupRef}
            style={{
              position: 'absolute',
              right: 0,
              zIndex: 5,
              width: 150
            }}
          >
            <DKInput
              title={''}
              autoFocus={true}
              direction={INPUT_VIEW_DIRECTION.VERTICAL}
              type={INPUT_TYPE.DROPDOWN}
              readOnly={props.isViewMode}
              canValidate={false}
              onChange={(value: any) => onSelectRoutingTemplate(value)}
              options={[]}
              formatter={(value: any) => value.name}
              dropdownConfig={{
                style: { minWidth: 230 },
                className: 'shadow-m',
                title: 'Select template',
                allowSearch: true,
                searchableKey: 'name',
                canEdit: false,
                canDelete: false,
                data: [],
                searchApiConfig: {
                  getUrl: (search: any) => {
                    return `${ApiConstants.URL.BASE}mrp/routingtemplate/search?limit=25&search=${search}&page=0&sort=createdOn&sortDir=desc`;
                  },
                  dataParser: (response: any) => response?.content
                },
                renderer: (index: any, data: any) => {
                  return <div className="text-align-left">{data.name}</div>;
                }
              }}
            />
          </div>
        )}

        {(Utility.isEmpty(configuration?.bomOperationsConfiguration) ||
          configuration?.bomOperationsConfiguration?.length === 0) && (
          <DKButton
            onClick={() => {
              if (disableEditing) {
                return;
              }
              updatedCurrentEditableIndex(index);
              addAnOperations();
            }}
            title={'+ Add an Operation'}
            className={`text-app-color`}
            style={{
              paddingLeft: 0
            }}
          />
        )}
      </div>
    );
  };

  const getStatusBadge = (configuration: any) => {
    let options: { [key: string]: string } = {
      Inactive: 'data-grid-badge-color-4 text-red',
      Active: 'data-grid-badge-color-6 text-green'
    };
    let status = configuration?.active ? 'Active' : 'Inactive';
    return (
      <DKLabel
        text={status}
        className={`p-h-s p-v-xs border-radius-s ${options[status]}`}
      />
    );
  };

  const getDefaultBadge = (configuration: any) => {
    return (
      <DKLabel
        text={'Default'}
        className={`p-h-s p-v-xs border-radius-s data-grid-badge-color-7 text-blue`}
      />
    );
  };

  const getMoreOptions = () => {
    let configuration = componentRearranged?.[showMoreOptionsIndex];
    const disableEditing = props?.usedBoms?.includes(configuration?.code);
    const elementHeight = 25;
    return (
      <div
        className="column position-absolute p-2 gap-2 right-2 top-7 border shadow border-radius-m bg-white z-index-3"
        style={{ width: 150 }}
      >
        {!props.isBulkUpdated && (
          <div
            className="row justify-content-between"
            style={{ height: elementHeight }}
          >
            <DKLabel text="Active" />
            <Toggle
              className="ml-2 box-content"
              isSelected={configuration?.active}
              color="bg-button"
              isOn={configuration?.active}
              onChange={() => {
                // this method will update the value and return true item if isDefault
                let isDefault =
                  updateActiveStatusForConfigAtIndex(showMoreOptionsIndex);
                if (isDefault) {
                  showAlert(
                    'Error',
                    'Default product cannot be marked as inactive'
                  );
                }
              }}
            />
          </div>
        )}
        {!props.isBulkUpdated && (
          <div
            className="row justify-content-between"
            style={{ height: elementHeight }}
          >
            <DKLabel text="Default" />
            <Toggle
              className="ml-2 box-content"
              isSelected={configuration.isDefault}
              color="bg-button"
              isOn={configuration.isDefault}
              onChange={() => {
                if (configuration?.active) {
                  updateDefaultConfig(showMoreOptionsIndex);
                }
              }}
            />
          </div>
        )}
        {getComponentProducts()?.length > 1 && (
          <DKButton
            title={'Delete'}
            icon={DKIcons.ic_delete}
            style={{
              paddingLeft: 0,
              width: '100%',
              height: elementHeight
            }}
            className="row-reverse justify-content-between ic-s opacity-60 row"
            onClick={() => {
              if (disableEditing) {
                return;
              }
              let isDefault = deleteConfigurationAtIndex(showMoreOptionsIndex);
              if (isDefault) {
                showAlert('Error', 'Default product cannot be deleted.');
              }
            }}
          />
        )}
        {!props.isBulkUpdated && (
          <DKButton
            icon={DKIcons.ic_copy}
            title={'Copy'}
            style={{
              paddingLeft: 0,
              width: '100%',
              height: elementHeight
            }}
            className="row-reverse justify-content-between ic-s opacity-60 row"
            onClick={() => {
              let copyConfig = { ...configuration };
              delete copyConfig.id;
              delete copyConfig.name;
              delete copyConfig.code;
              copyConfig.isDefault = false;
              addMoreConfig(copyConfig);
            }}
          />
        )}
        {configuration?.version && (
          <DKButton
            className={'text-blue underline'}
            title={`Version - ${configuration?.version}`}
            style={{ maxWidth: 150, padding: 0, height: elementHeight }}
            onClick={() => {
              let endURL = `metaCode=${
                configuration.code
              }&metaName=${encodeURIComponent(
                configuration.name ?? ''
              )}&productCode=${props.product.productId}`;
              setshowBomVersionURL(endURL);
            }}
          />
        )}
      </div>
    );
  };

  const componentRearranged = getComponentProducts()?.sort((a, b) => {
    if (a.active && !b.active) {
      return -1;
    } else if (!a.active && b.active) {
      return 1;
    } else {
      return 0;
    }
  });
  return (
    <div
      className="column parent-width"
      onClick={() => {
        setShowMoreOptionsIndex(-1);
      }}
    >
      {!Utility.isEmpty(props?.usedBoms) && (
        <div className="row justify-content-start gap-2">
          <DKIcon
            src={DKIcons.ic_info}
            className="ic-s"
            style={{ padding: 0, opacity: 0.6 }}
          />
          <DKLabel text="Used BOMS cannot be edited." />
        </div>
      )}
      {componentRearranged?.map(
        (configuration: BomConfigurationModel, index: number) => {
          const disableEditing = props?.usedBoms?.includes(configuration?.code);
          return (
            <div className="column parent-width mt-m border-m border-radius-s p-2 shadow">
              <div className="row justify-content-between pb-3 cursor-hand">
                <div className="row align-items-end">
                  <DKButton
                    icon={
                      expandedIndexes?.includes(index)
                        ? DKIcons.ic_arrow_up2
                        : DKIcons.ic_arrow_down2
                    }
                    className="row justify-content-center ic-s bg-gray1 border-m"
                    style={{
                      width: 25,
                      height: 25,
                      borderRadius: 4
                    }}
                    onClick={() => {
                      let expandedIndexesCopy = [...expandedIndexes];
                      if (expandedIndexes?.includes(index)) {
                        expandedIndexesCopy = expandedIndexesCopy.filter(
                          (item: any) => item !== index
                        );
                      } else {
                        expandedIndexesCopy.push(index);
                      }
                      setExpandedIndexes(expandedIndexesCopy);
                    }}
                  />
                  {props.isBulkUpdated || props.isReplaceFlow ? (
                    <DKLabel
                      className="ml-m mb-xs"
                      text="Add BOM elements for selected BOMS"
                    />
                  ) : (
                    <input
                      className="outline-none ml-2 fw-m fs-m"
                      value={configuration?.name}
                      placeholder={'Enter BOM name'}
                      onFocus={() => {
                        updatedCurrentEditableIndex(index);
                      }}
                      onBlur={(e: any) => {
                        const existingNameFound = getComponentProducts()
                          ?.filter(
                            (compProduct: any, compProductIndex: number) => {
                              return (
                                compProductIndex !== getCurrentEditableIndex()
                              );
                            }
                          )
                          ?.find((compProduct: any) => {
                            return compProduct?.name === e.target.value;
                          });
                        if (!Utility.isEmpty(existingNameFound)) {
                          showToast(
                            'This value already exists',
                            TOAST_TYPE.FAILURE
                          );
                          updateBOMName('', index);
                        }
                      }}
                      onChange={(e: any) => {
                        if (disableEditing) {
                          return;
                        }
                        updateBOMName(e.target.value, index);
                      }}
                      style={{
                        borderBottom:
                          (configuration &&
                            configuration?.name &&
                            configuration?.name?.length > 250) ||
                          (configuration?.name?.length === 0 &&
                            props.saveButtonTapped)
                            ? 'solid 1px red'
                            : 'solid 1px rgb(235,235,235)',
                        paddingBottom: 4
                      }}
                    />
                  )}
                  {disableEditing && (
                    <DKLabel
                      text="(In use)"
                      className="text-gray"
                      style={{
                        width: 50,
                        fontSize: 12,
                        marginLeft: 2
                      }}
                    />
                  )}
                </div>
                {!props.isBulkUpdated && !props.isReplaceFlow && (
                  <div className="row justify-content-end gap-2">
                    {getStatusBadge(configuration)}
                    {configuration?.isDefault && getDefaultBadge(configuration)}
                    {!props.isFromAdHoc && (
                      <div className="column position-relative overflow-visible">
                        <DKButton
                          title=""
                          icon={DKIcons.ic_more_vertical}
                          onClick={(event: any) => {
                            event.stopPropagation();
                            if (showMoreOptionsIndex === index) {
                              setShowMoreOptionsIndex(-1);
                            } else {
                              setShowMoreOptionsIndex(index);
                            }
                          }}
                        />
                        {showMoreOptionsIndex === index && getMoreOptions()}
                      </div>
                    )}
                  </div>
                )}
              </div>
              {expandedIndexes?.includes(index) && (
                <div className="column parent-width">
                  {getComponentProductsView(
                    configuration,
                    index,
                    disableEditing
                  )}
                  {!props.isReplaceFlow && <DKLine className="mt-s mb-s" />}
                  {!props.isReplaceFlow &&
                    getScrapOrCoSubPrdocutsView(
                      configuration,
                      index,
                      disableEditing
                    )}
                  {!props.isReplaceFlow && <DKLine className="mt-s mb-s" />}
                  {!props.isReplaceFlow &&
                    getAdditionalCostView(configuration, index, disableEditing)}
                  {!props.isReplaceFlow && <DKLine className="mt-s mb-s" />}
                  {!props.isReplaceFlow &&
                    getOperationsView(configuration, index, disableEditing)}
                  {!props.isReplaceFlow &&
                    getAttachmentSection(configuration, index, disableEditing)}
                </div>
              )}
            </div>
          );
        }
      )}
      {!props.isReplaceFlow && !props.isBulkUpdated && !props.isFromAdHoc && (
        <DKButton
          onClick={() => {
            addMoreConfig();
          }}
          title={'+ Add BOM'}
          className={`mt-2 bg-button mt-2 text-white fw-m`}
          style={{}}
        />
      )}
      {showSubstituteProductView && (
        <ProductSubstitutePopup
          product={product}
          selectedProductId={
            getCurrentSelectedConfig()?.bomProductsConfiguration?.[
              selectedComponentProductIndex
            ]?.productCode ?? ''
          }
          selectedProductsIds={
            getCurrentSelectedConfig()?.bomProductsConfiguration?.[
              selectedComponentProductIndex
            ]?.['bomProductSubstitutesDetails'] || []
          }
          onSave={(selectedProducts: any) => {
            updateSubstituteForComponentProduct(
              selectedComponentProductIndex,
              selectedProducts
            );

            setShowSubstituteProductView(false);
            setSelectedComponentProductIndex(undefined);
          }}
          onClose={() => {
            setShowSubstituteProductView(false);
            setSelectedComponentProductIndex(undefined);
          }}
          restrictClick={isSubstituteBeingEditedForInUsedProduct}
          currentConfiguration={getUnchangedProduct(
            props?.initialProductWithNoCurrentChange,
            selectedComponentProductIndex,
            currentConfiguration
          )}
        />
      )}
      {!Utility.isEmpty(showBomVersionURL) && (
        <BomVersionHistory
          urlEndPoint={showBomVersionURL}
          onCancel={() => {
            setshowBomVersionURL(undefined);
          }}
        />
      )}
    </div>
  );
}
