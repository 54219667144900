import {
  DKButton,
  DKDataGrid,
  DKIcon,
  DKIcons,
  DKLabel,
  DKSpinner,
  DKTooltipWrapper,
  INPUT_TYPE,
  showAlert
} from 'deskera-ui-library';
import { useEffect, useState } from 'react';
import ic_warning_red from '../../Assets/Icons/ic_warning_red.png';
import {
  DOC_TYPE,
  MODULE_TYPE,
  QTY_ROUNDOFF_PRECISION,
  ROW_RACK_BIN_CONSTANT
} from '../../Constants/Constant';
import { useAppSelector } from '../../Redux/Hooks';
import { activeTenantInfo } from '../../Redux/Slices/AuthSlice';
import ProductService from '../../Services/Product';
import Utility, { deepClone } from '../../Utility/Utility';
import { DynamicPopupWrapper } from '../PopupWrapper';
import WarehouseManagementHelper from './WarehouseManagementHelper';
const rrbColumnRenderer = ({ rowData }: any, value: string, column: string) => {
  if (rowData?.invalidFields?.includes(column)) {
    let errorMessage = `Row with selected ${column} already exist`;
    if (!value) {
      errorMessage = `${column} is required, can not be empty`;
    }
    return (
      <div className="row justify-content-between">
        <DKLabel text={`${value || ''}`} />
        <DKTooltipWrapper content={errorMessage} tooltipClassName="">
          <div className="row">
            <DKIcon
              src={ic_warning_red}
              className="ic-xs ml-s cursor-hand"
              onClick={() => {}}
            />
          </div>
        </DKTooltipWrapper>
      </div>
    );
  }
  return value || '';
};
const COLUMNS = [
  {
    id: 'warehouse',
    key: 'warehouse',
    name: 'Warehouse',
    type: INPUT_TYPE.DROPDOWN,
    width: 190,
    systemField: true,
    editable: true,
    hidden: false,
    required: true,
    uiVisible: true,
    renderer: ({ rowData }: any) =>
      rrbColumnRenderer({ rowData }, rowData?.warehouseName, 'warehouse'),
    dropdownConfig: {
      title: 'Select Warehouse',
      allowSearch: false,
      searchableKey: '',
      style: {},
      className: 'shadow-m',
      searchApiConfig: null,
      data: [],
      renderer: null,
      onSelect: (index: any, obj: any, rowIndex: any) => {},
      button: null
    }
  },
  {
    id: 'row',
    columnCode: 'row',
    key: 'row',
    name: 'Select Row',
    type: INPUT_TYPE.DROPDOWN,
    width: 140,
    systemField: true,
    editable: true,
    hidden: false,
    required: true,
    uiVisible: true,
    renderer: ({ rowData }: any) =>
      rrbColumnRenderer({ rowData }, rowData?.row?.rowName, 'row'),
    dropdownConfig: {
      title: 'Select Row',
      allowSearch: true,
      searchableKey: 'name',
      style: { minWidth: 230 },
      className: 'shadow-m',
      searchApiConfig: null,
      data: [],
      renderer: null,
      onSelect: (index: any, obj: any, rowIndex: any) => {},
      button: null
    }
  },
  {
    id: 'rack',
    columnCode: 'rack',
    key: 'rack',
    name: 'Select Rack',
    type: INPUT_TYPE.DROPDOWN,
    width: 140,
    systemField: true,
    editable: true,
    hidden: false,
    required: true,
    uiVisible: true,
    renderer: ({ rowData }: any) =>
      rrbColumnRenderer({ rowData }, rowData?.rack?.rackName, 'rack'),
    dropdownConfig: {
      title: 'Select Rack',
      allowSearch: true,
      searchableKey: 'name',
      style: { minWidth: 230 },
      className: 'shadow-m',
      searchApiConfig: null,
      data: [],
      renderer: null,
      onSelect: (index: any, obj: any, rowIndex: any) => {},
      button: null
    }
  },
  {
    id: 'bin',
    columnCode: 'bin',
    key: 'bin',
    name: 'Select Bin',
    type: INPUT_TYPE.DROPDOWN,
    width: 140,
    systemField: true,
    editable: true,
    hidden: false,
    required: true,
    uiVisible: true,
    renderer: ({ rowData }: any) =>
      rrbColumnRenderer({ rowData }, rowData?.bin?.binName, 'bin'),
    dropdownConfig: {
      title: 'Select Bin',
      allowSearch: true,
      searchableKey: 'name',
      style: { minWidth: 230 },
      className: 'shadow-m',
      searchApiConfig: null,
      data: [],
      renderer: null,
      onSelect: (index: any, obj: any, rowIndex: any) => {},
      button: null
    }
  },
  {
    id: 'availableQty',
    key: 'availableQty',
    name: 'Available Qty',
    type: INPUT_TYPE.NUMBER,
    width: 110,
    systemField: true,
    editable: false,
    hidden: false,
    uiVisible: true,
    textAlign: 'right'
  },
  {
    id: 'quantity',
    key: 'quantity',
    name: 'Allocated Qty',
    type: INPUT_TYPE.NUMBER,
    width: 110,
    systemField: true,
    editable: true,
    hidden: false,
    uiVisible: true,
    textAlign: 'right',
    renderer: (obj: any) => {
      if (obj?.rowData?.invalidFields?.includes('quantity')) {
        let errorMessage = ``;
        if (
          Number(obj.rowData.quantity) <
            Number(obj.rowData?.existingReservedQuantity) &&
          Number(obj.rowData.quantity) -
            Number(obj.rowData?.existingReservedQuantity) !==
            0
        ) {
          errorMessage = `Quantity should be greater than or equal to previously allocated quantity (${Number(
            obj.rowData?.existingReservedQuantity
          )})`;
        } else {
          if (obj?.rowData?.availableQty === 0) {
            errorMessage = `Available Quantity is not sufficient.`;
          } else {
            const newAllocatedQty =
              obj?.rowData['quantity'] -
              (obj.rowData?.existingReservedQuantity || 0);
            if (newAllocatedQty > obj?.rowData?.availableQty) {
              errorMessage = `Quantity should be less than or equal to available quantity (${obj.rowData.availableQty})`;
            }
          }
        }
        return (
          <div className="row justify-content-between">
            <DKTooltipWrapper content={errorMessage} tooltipClassName="">
              <div className="row">
                <DKIcon
                  src={ic_warning_red}
                  className="ic-xs  ml-s cursor-hand"
                  onClick={() => {}}
                />
              </div>
            </DKTooltipWrapper>

            <DKLabel
              text={`${Utility.roundingOff(
                Number(obj.value),
                QTY_ROUNDOFF_PRECISION
              )}`}
            />
          </div>
        );
      } else {
        return (
          <div className="row justify-content-between">
            <div></div>
            <DKLabel
              text={`${Utility.roundingOff(
                Number(obj.value),
                QTY_ROUNDOFF_PRECISION
              )}`}
            />
          </div>
        );
      }
    }
  }
];

const NoneTrackedAssignment = (props: any) => {
  //constants
  const productCode = props.details?.pid ?? props.details?.productCode ?? '';
  const isEditWithoutSaveMode = !Utility.isEmpty(
    props?.details?.warehouseInventoryData
  );
  //common states
  const [updating, setUpdating] = useState(false);
  const [saveApiLoading, setSaveApiLoading] = useState(false);
  const [lineItems, setLineItems] = useState<any>(
    isEditWithoutSaveMode
      ? []
      : [
          {
            allowToDelete: true
          }
        ]
  );
  const [columns, setColumns] = useState(COLUMNS ?? []);
  const [commonWarehouseByProduct, setCommonWarehouseProducts] = useState<any>(
    []
  );
  const [productDetails, setProductDetails] = useState<any>();
  //selectors
  const tenantInfo = useAppSelector(activeTenantInfo);

  //effects
  useEffect(() => {
    fetchProductDetailsByID(productCode);
    fetchProductDetails();
  }, []);

  const fetchProductDetails = () => {
    ProductService.getProductsByProductIds([props.details?.productCode])
      .then((res: any) => {
        setProductDetails(res?.[0]);
      })
      .catch((err: any) => {});
  };

  useEffect(() => {
    if (
      Utility.isEmpty(commonWarehouseByProduct) ||
      Utility.isEmpty(productDetails)
    ) {
      return;
    }
    updateConfig(null, null);
    if (
      !Utility.isEmpty(props?.details?.warehouseInventoryData) &&
      !Utility.isEmpty(commonWarehouseByProduct) &&
      !props?.isQCFlow
    ) {
      let updatedLineItems = [...lineItems];
      props?.details?.warehouseInventoryData?.forEach((item1: any) => {
        let whFound = commonWarehouseByProduct?.find((wh: any) => {
          return wh.code === item1.warehouseCode;
        });

        if (whFound) {
          const rrbDtoForProduct =
            WarehouseManagementHelper.getUniqueRRBDtoFromProductCode(
              whFound?.rowRackBinProductAvailableQuantityDtos,
              productCode
            );
          let availableProductQty =
            getAvailableQtyConsideringDocReservedQty(whFound);
          let availableQuantity = !Utility.isEmpty(rrbDtoForProduct)
            ? getAvailableQuantity(
                rrbDtoForProduct,
                item1?.row?.rowCode,
                item1?.rack?.rackCode,
                item1?.bin?.binCode,
                whFound
              )
            : Utility.getUomQuantity(
                availableProductQty,
                props?.details?.documentUOMSchemaDefinition
              );

          updatedLineItems.push({
            ...item1,
            warehouseName: whFound?.name,
            warehouse: whFound,
            quantity: item1?.quantity,
            row: {
              rowCode: item1?.row?.rowCode ?? item1?.rowCode,
              rowName: item1?.row?.rowName ?? item1?.rowName
            },
            rack: {
              rackCode: item1?.rack?.rackCode ?? item1?.rackCode,
              rackName: item1?.rack?.rackName ?? item1?.rackName
            },
            bin: {
              binCode: item1?.bin?.binCode ?? item1?.binCode,
              binName: item1?.bin?.binName ?? item1?.binName
            },
            availableQty: availableQuantity,
            allowToDelete: isRowItemDeletable(item1)
          });
        }
      });
      updatedLineItems = updatedLineItems.filter(
        (value, index, self) =>
          index ===
          self.findIndex(
            (t) =>
              t.warehouseCode === value.warehouseCode &&
              t.binCode === value.binCode &&
              t.rackCode === value.rackCode &&
              t.rowCode === value.rowCode
          )
      );
      setLineItems([...updatedLineItems]);
    } else if (
      !Utility.isEmpty(props?.details?.warehouseInventoryData) &&
      props?.isQCFlow &&
      !Utility.isEmpty(commonWarehouseByProduct)
    ) {
      let updatedLineItems = [...lineItems];
      props?.details?.warehouseInventoryData?.forEach((item1: any) => {
        let whFound = commonWarehouseByProduct?.find((wh: any) => {
          return wh.code === item1.warehouseCode;
        });
        updatedLineItems.push({
          ...item1,
          warehouseName: item1?.warehouseName,
          warehouse: whFound,
          warehouseCode: item1?.warehouseCode,
          quantity: item1?.quantity,
          row: {
            rowCode: item1?.rowCode,
            rowName: item1?.rowName
          },
          rack: {
            rackCode: item1?.rackCode,
            rackName: item1?.rackName
          },
          bin: {
            binCode: item1?.binCode,
            binName: item1?.binName
          },
          availableQty: item1.quantity,
          allowToDelete: isRowItemDeletable(item1)
        });
        // }
      });
      setLineItems([...updatedLineItems]);
    } else {
      if (
        props?.details?.warehouseInventoryData &&
        props?.details?.warehouseInventoryData?.length > 0
      ) {
        return;
      }
      let availableQty = 0;
      let wareHouseDefaultData = getDefaultActiveWarehouse();
      let rrbDtoForProduct =
        WarehouseManagementHelper.getUniqueRRBDtoFromProductCode(
          wareHouseDefaultData?.rowRackBinProductAvailableQuantityDtos,
          productCode
        );

      let availableProductQty =
        getAvailableQtyConsideringDocReservedQty(wareHouseDefaultData);

      availableQty = !Utility.isEmpty(rrbDtoForProduct)
        ? getAvailableQuantity(
            rrbDtoForProduct,
            null,
            null,
            null,
            wareHouseDefaultData
          )
        : props?.details?.documentUOMSchemaDefinition
        ? Utility.getUomQuantity(
            availableProductQty,
            props?.details?.documentUOMSchemaDefinition
          )
        : props?.isQCFlow
        ? props?.details?.warehouseInventoryData?.[0]?.quantity ??
          props.details.availableProductQty
        : availableProductQty;
      let updatedLineItems = [];

      if (
        Utility.isBinAllocationMandatory() &&
        props?.taggedWarehouse &&
        WarehouseManagementHelper.isRRBEnabledForWarehouse(
          props?.taggedWarehouse
        )
      ) {
        if (
          !Utility.isEmpty(
            wareHouseDefaultData?.['rowRackBinProductAvailableQuantityAllDtos']
          ) &&
          Utility.isEmpty(props?.details?.warehouseInventoryData)
        ) {
          let updatedLineItems = [];
          updatedLineItems.push({
            warehouseCode: wareHouseDefaultData?.code,
            warehouseName: wareHouseDefaultData?.name,
            warehouse: wareHouseDefaultData,
            quantity: 0,
            row: {
              rowCode: rrbDtoForProduct?.[0]?.rowCode,
              rowName: rrbDtoForProduct?.[0]?.rowName
            },
            rack: {
              rackCode: rrbDtoForProduct?.[0]?.rackCode,
              rackName: rrbDtoForProduct?.[0]?.rackName
            },
            bin: {
              binCode: rrbDtoForProduct?.[0]?.binCode,
              binName: rrbDtoForProduct?.[0]?.binName
            },
            availableQty: !Utility.isEmpty(
              wareHouseDefaultData['rowRackBinProductAvailableQuantityAllDtos']
            )
              ? getAvailableQuantity(
                  wareHouseDefaultData[
                    'rowRackBinProductAvailableQuantityAllDtos'
                  ],
                  rrbDtoForProduct?.[0]?.rowCode,
                  rrbDtoForProduct?.[0]?.rackCode,
                  rrbDtoForProduct?.[0]?.binCode
                )
              : 0,
            allowToDelete: true
          });
          setLineItems(updatedLineItems);
        }
      } else if (!props?.isQCFlow) {
        updatedLineItems.push({
          warehouseCode: wareHouseDefaultData?.code,
          warehouseName: wareHouseDefaultData?.name,
          warehouse: wareHouseDefaultData,
          row: null,
          rack: null,
          bin: null,
          availableQty: availableQty,
          quantity: 0,
          allowToDelete: true,
          invalidFields: !Utility.isEmpty(rrbDtoForProduct)
            ? ['row', 'rack', 'bin']
            : []
        });
        setLineItems([...updatedLineItems]);
      }
    }
  }, [commonWarehouseByProduct, productDetails]);

  const getAllocatedQuantity = (selectedWarehouse?: any) => {
    const currentId = props.details.id;
    const productCode = props.details.productCode;
    const warehouseCode = selectedWarehouse?.code;

    // Check if it's an edit case
    const isEditCase = props.details.multipleWarehouseData.some(
      (element: any) => element.id === currentId
    );

    if (isEditCase) {
      // For edit case, find all entries with the same productCode and warehouseCode, excluding the current ID
      const relevantEntries = props.details.multipleWarehouseData.filter(
        (element: any) =>
          element.productCode === productCode &&
          element.warehouseCode === warehouseCode &&
          element.warehouseCode === warehouseCode &&
          element.id !== currentId
      );

      // Calculate the total allocated quantity for the selected warehouse and product code
      const totalAllocatedQty = relevantEntries.reduce(
        (total: number, element: any) => total + (element.quantity ?? 0),
        0
      );

      // Return available quantity as allocated quantity + previously allocated quantity
      return totalAllocatedQty;
    } else {
      // For non-edit case, just return the allocated quantity
      const totalAllocatedQty = props.details.multipleWarehouseData
        .filter(
          (element: any) =>
            element.productCode === productCode &&
            element.warehouseCode === warehouseCode
        )
        .reduce(
          (total: number, element: any) => total + (element.quantity ?? 0),
          0
        );

      return totalAllocatedQty;
    }
  };

  const getAvailableQtyConsideringDocReservedQty = (
    wareHouseDefaultData: any
  ) => {
    //check by warehouse

    //minus available quantity here
    let availableProductQty =
      wareHouseDefaultData?.productAvailableQuantity?.[productCode] ?? 0;
    if (
      props?.isMrpFlow ||
      Utility.isEmpty(props?.details.reservedQuantitiesData)
    ) {
      availableProductQty =
        availableProductQty -
        (wareHouseDefaultData?.productReservedQuantity?.[productCode] ?? 0);

      if (!Utility.isEmpty(props.details.multipleWarehouseData)) {
        availableProductQty =
          availableProductQty - getAllocatedQuantity(wareHouseDefaultData);
      }
    } else {
      const prodReservedQtyInWH =
        wareHouseDefaultData?.productReservedQuantity?.[productCode] ?? 0;
      let prodReservedQtyInDoc = 0;

      if (!Utility.isEmpty(props?.details.reservedQuantitiesData)) {
        const prodReservedQtyInfoInDoc =
          props?.details.reservedQuantitiesData?.find(
            (resData: any) =>
              wareHouseDefaultData?.code === resData?.warehouseCode &&
              productCode === resData?.productCode
          );

        if (!Utility.isEmpty(prodReservedQtyInfoInDoc)) {
          prodReservedQtyInDoc = prodReservedQtyInfoInDoc?.reservedQuantity;
        }
      }

      availableProductQty =
        availableProductQty - prodReservedQtyInWH + prodReservedQtyInDoc;
      //   if (!Utility.isEmpty(props.details.multipleWarehouseData)) {
      //     // availableProductQty =
      //   }
    }
    return availableProductQty;
  };

  //ui methods
  const fetchProductDetailsByID = (productIds: any) => {
    setUpdating(true);
    ProductService.fetchWarehouseProductsByID([productIds])
      .then((response: any) => {
        setUpdating(false);
        const productCodes = [productCode];
        let productWarehouseData = [];
        if (props.taggedWarehouse) {
          productWarehouseData = (response?.warehouses ?? []).filter(
            (wh: any) => wh.code === props.taggedWarehouse?.code
          );
          if (Utility.isBinAllocationMandatory()) {
            const taggedRRBDetails =
              productWarehouseData?.[0]?.rowRackBinProductAvailableQuantityDtos?.filter(
                (RRBItem: any) =>
                  RRBItem.rowCode == props.taggedWarehouse?.rowCode &&
                  RRBItem.rackCode == props.taggedWarehouse?.rackCode &&
                  RRBItem.binCode == props.taggedWarehouse?.binCode
              );
            productWarehouseData = [
              {
                ...productWarehouseData[0],
                rowRackBinProductAvailableQuantityAllDtos:
                  productWarehouseData[0][
                    'rowRackBinProductAvailableQuantityDtos'
                  ],
                rowRackBinProductAvailableQuantityDtos: taggedRRBDetails
              }
            ];
          }
        } else {
          productWarehouseData = response?.warehouses ?? [];
        }
        let commonWarehouses = productWarehouseData.filter(
          (warehouse: any) => {
            let availableProductCodes = Object.keys(
              warehouse.productAvailableQuantity
            );
            if (!props?.isQCFlow) {
              availableProductCodes.forEach((keyString: any) => {
                if (!warehouse.productAvailableQuantity[keyString]) {
                  delete warehouse.productAvailableQuantity[keyString];
                }
              });
            }
            availableProductCodes = Object.keys(
              warehouse.productAvailableQuantity
            );
            if (props.targetWarehouse) {
              if (
                WarehouseManagementHelper.isRRBEnabledForWarehouse(warehouse)
              ) {
                return productCodes.every(
                  (code) =>
                    availableProductCodes.includes(code) &&
                    warehouse.code !== props.targetWarehouse
                );
              } else {
                return productCodes.every((code) =>
                  availableProductCodes.includes(code)
                );
              }
            } else {
              return productCodes.every((code) =>
                availableProductCodes.includes(code)
              );
            }
          }
        );

        if (props && props.isStockIssue && !Utility.isEmpty(props.targetWarehouse))    {
            if (commonWarehouses && commonWarehouses.length > 0)    {
                commonWarehouses = commonWarehouses.filter((warehouse: any) => warehouse.code !== props.targetWarehouse)
            }
        }

        if (props?.isMrpFlow && props?.filterNormalData) {
          let updatedCommonWarehouseByProduct: any[] =
            filterWHData(commonWarehouses);
          updatedCommonWarehouseByProduct =
            updatedCommonWarehouseByProduct?.filter((obj1: any) =>
              props?.filteredWarehouseInventoryData?.warehouseInventoryData.some(
                (obj2: any) => obj2.warehouseCode === obj1.code
              )
            );

          let updatedLineItems = [...lineItems];
          props?.details?.warehouseInventoryData?.forEach((item1: any) => {
            let whFound = updatedCommonWarehouseByProduct?.find((wh: any) => {
              return wh.code === item1.warehouseCode;
            });

            if (whFound) {
              const rrbDtoForProduct =
                WarehouseManagementHelper.getUniqueRRBDtoFromProductCode(
                  whFound?.rowRackBinProductAvailableQuantityDtos,
                  productCode
                );
              updatedLineItems.push({
                ...item1,
                warehouse: whFound,
                quantity: item1?.quantity,
                row: {
                  rowCode: item1?.row?.rowCode,
                  rowName: item1?.row?.rowName
                },
                rack: {
                  rackCode: item1?.rack?.rackCode,
                  rackName: item1?.rack?.rackName
                },
                bin: {
                  binCode: item1?.bin?.binCode,
                  binName: item1?.bin?.binName
                },
                availableQty: !Utility.isEmpty(rrbDtoForProduct)
                  ? getAvailableQuantity(
                      rrbDtoForProduct,
                      item1?.row?.rowCode,
                      item1?.rack?.rackCode,
                      item1?.bin?.binCode
                    )
                  : whFound?.productAvailableQuantity[productCode],
                allowToDelete: isRowItemDeletable(item1)
              });
            }
          });

          setCommonWarehouseProducts(updatedCommonWarehouseByProduct);
          setLineItems([...updatedLineItems]);
          return;
        }
        setCommonWarehouseProducts(commonWarehouses ?? []);
      })
      .catch((err: any) => {
        setUpdating(false);
      });
  };
  const getExistingWHInventoryData = (row: any) => {
    const { existingWarehouseInventoryData } = props;
    return existingWarehouseInventoryData?.find(
      (existingData: any) =>
        existingData.warehouseCode == row.warehouseCode &&
        existingData.rowCode == row.rowCode &&
        existingData.rackCode == row.rackCode &&
        existingData.binCode == row.binCode
    );
  };
  const filterWHData = (allWarehouseData: any[]) => {
    return allWarehouseData?.map((commonWHItem: any) => {
      const filteredObj =
        props?.filteredWarehouseInventoryData?.warehouseInventoryData?.filter(
          (filteredItem: any) => {
            return commonWHItem.code === filteredItem.warehouseCode;
          }
        );

      if (!Utility.isEmpty(filteredObj)) {
        commonWHItem = {
          ...commonWHItem,
          productAvailableQuantity: {
            ...commonWHItem.productAvailableQuantity,
            [props?.details.productCode]: filteredObj?.reduce(
              (prev: any, curr: any) => {
                return prev + curr.quantity;
              },
              0
            )
          },
          rowRackBinProductAvailableQuantityDtos: filteredObj?.map(
            (item1: any) => {
              return {
                ...item1,
                productCode: props?.details.productCode,
                availableQuantity: item1?.quantity
              };
            }
          )
        };
      }
      return commonWHItem;
    });
  };
  const isRowItemDeletable = (row: any) => {
    return Utility.isEmptyObject(getExistingWHInventoryData(row));
  };
  const validate = (updatedItems?: any[]) => {
    let updatedItemsArr = updatedItems ?? lineItems;
    let flag = true;

    const sumOfQuantities = Utility.roundingOff(
      updatedItemsArr?.reduce((acc: any, item: any) => {
        return Number(item?.quantity ?? 0) + Number(acc ?? 0);
      }, 0) ?? 0,
      QTY_ROUNDOFF_PRECISION
    );

    const objWithInvalidfield = updatedItemsArr?.find((item: any) => {
      return !Utility.isEmpty(item?.invalidFields);
    });

    if (
      props?.taggedWarehouse &&
      Utility.isBinAllocationMandatory() &&
      WarehouseManagementHelper.isRRBEnabledForWarehouse(props?.taggedWarehouse)
    ) {
      let checkBin = Utility.isNotEmpty(
        props?.taggedWarehouse?.warehouseBinInfos
      );
      let checkRow = Utility.isNotEmpty(
        props?.taggedWarehouse?.warehouseRowInfos
      );
      let checkRack = Utility.isNotEmpty(
        props?.taggedWarehouse?.warehouseRackInfos
      );
      let invalidData;
      if (checkRow) {
        invalidData = updatedItemsArr?.find((item: any) => {
          if (Utility.isNotEmpty(props?.taggedWarehouse?.warehouseRowInfos)) {
            return Utility.isEmpty(item?.row);
          }
        });
        if (checkRack) {
          invalidData = updatedItemsArr?.find((item: any) => {
            if (
              Utility.isNotEmpty(props?.taggedWarehouse?.warehouseRackInfos)
            ) {
              return Utility.isEmpty(item?.rack) || Utility.isEmpty(item?.row);
            }
          });
        }
        if (checkRack && checkBin) {
          invalidData = updatedItemsArr?.find((item: any) => {
            if (
              Utility.isNotEmpty(props?.taggedWarehouse?.warehouseRackInfos)
            ) {
              return (
                Utility.isEmpty(item?.rack) ||
                Utility.isEmpty(item?.row) ||
                Utility.isEmpty(item?.bin)
              );
            }
          });
        }
        if (checkRow && checkBin) {
          invalidData = updatedItemsArr?.find((item: any) => {
            if (
              Utility.isNotEmpty(props?.taggedWarehouse?.warehouseRackInfos)
            ) {
              return Utility.isEmpty(item?.row) || Utility.isEmpty(item?.bin);
            }
          });
        }
      }
      if (checkRack) {
        invalidData = updatedItemsArr?.find((item: any) => {
          if (Utility.isNotEmpty(props?.taggedWarehouse?.warehouseRackInfos)) {
            return Utility.isEmpty(item?.rack);
          }
        });
        if (checkBin) {
          invalidData = updatedItemsArr?.find((item: any) => {
            if (Utility.isNotEmpty(props?.taggedWarehouse?.warehouseBinInfos)) {
              return Utility.isEmpty(item?.bin);
            }
          });
        }
      }
      if (checkBin) {
        invalidData = updatedItemsArr?.find((item: any) => {
          if (Utility.isNotEmpty(props?.taggedWarehouse?.warehouseBinInfos)) {
            return Utility.isEmpty(item?.bin);
          }
        });
      }
      if (Utility.isNotEmpty(invalidData)) {
        showAlert('Error', 'Please add RRB details');
        flag = false;
      }
    }

    const reqQty = props?.details?.documentUOMSchemaDefinition
      ? Utility.getUomQuantityWithoutRoundOff(
          props?.details?.pendingQuantity,
          props?.details?.documentUOMSchemaDefinition
        )
      : Utility.roundingOff(
          props?.details?.pendingQuantity,
          QTY_ROUNDOFF_PRECISION
        );

    if (
      sumOfQuantities > reqQty &&
      !Utility.isSellsToleranceSettingsEnabled(
        props.parentDocumentType,
        props.parentDocumentDetails
      )
    ) {
      const { isInvoiceLinked, isQuotationLinked, isReservedQuantity } =
        Utility.getSellsLinkedDocument(
          props.parentDocumentType,
          props.parentDocumentDetails
        );
      let message = 'Quantity used cannot be more than required quantity.';
      if (isInvoiceLinked || isQuotationLinked) {
        message = `Quantity used cannot be more than required quantity as the Invoice/${
          Utility.isIndiaOrganisation() ? 'Quotation' : 'Estimates'
        } is already generated for this document.`;
      } else if (isReservedQuantity) {
        message =
          'Quantity used cannot be more than required quantity in case of reserved quantity.';
      }
      showAlert('Oops!', message);
      flag = false;
    }

    if (sumOfQuantities === 0) {
      flag = false;
    }

    if (
      !Utility.isEmpty(objWithInvalidfield) ||
      Utility.isEmpty(updatedItemsArr)
    ) {
      flag = false;
    }

    return flag;
  };

  const onSave = (updatedItems?: any[]) => {
    if (validate(updatedItems)) {
      if (Utility.isEmpty(updatedItems)) {
        let updatedLineItems = lineItems.map((item: any) => ({
          ...item, // Spread existing properties of each item
          id: props?.details?.id, // Add or overwrite the 'id' property
          productCode: props.details.productCode
        }));
        props.onSave(updatedLineItems);
      } else {
        let updatedLineItems = updatedItems?.map((item: any) => ({
          ...item, // Spread existing properties of each item
          id: props?.details?.id, // Add or overwrite the 'id' property
          productCode: props.details.productCode
        }));
        props.onSave(updatedLineItems);
      }
    }
  };

  const getAvailableQuantity = (
    dto: any = [],
    rowCode = null,
    rackCode = null,
    binCode = null,
    warehouse?: any
  ) => {
    let availableQty = dto.reduce((accumulator: any, currentValue: any) => {
      if (
        (!rowCode || currentValue.rowCode === rowCode) &&
        (!rackCode || currentValue.rackCode === rackCode) &&
        (!binCode || currentValue.binCode === binCode)
      ) {
        if (
          props?.isMrpFlow ||
          Utility.isEmpty(props?.details.reservedQuantitiesData)
        ) {
          return (
            accumulator +
            (currentValue?.availableQuantity ?? 0) -
            (currentValue.reservedQuantity ?? 0)
          );
        } else {
          const currentProdQty = currentValue?.availableQuantity ?? 0;
          const prodReservedQtyInWH = currentValue?.reservedQuantity ?? 0;
          const prodReservedQtyInfoInDoc =
            props?.details.reservedQuantitiesData?.find(
              (resData: any) =>
                resData.productCode === productCode &&
                resData?.warehouseCode == warehouse?.code &&
                resData.rowCode == rowCode &&
                resData.rackCode == rackCode &&
                resData.binCode == binCode
            );
          let prodReservedQtyInDoc = 0;
          if (!Utility.isEmpty(prodReservedQtyInfoInDoc)) {
            prodReservedQtyInDoc =
              prodReservedQtyInfoInDoc?.reservedQuantity ?? 0;
          }
          const productQuantity =
            currentProdQty - prodReservedQtyInWH + prodReservedQtyInDoc;
          return accumulator + productQuantity;
        }
      } else {
        return accumulator;
      }
    }, 0);

    availableQty =
      props?.details?.documentUOMSchemaDefinition && !props?.filterNormalData
        ? Utility.getUomQuantity(
            availableQty,
            props?.details?.documentUOMSchemaDefinition
          )
        : availableQty;
    let allocatedQuantityInOtherRows =
      props?.details?.multipleWarehouseData?.reduce(
        (accumulator: number, currentValue: any) => {
          if (
            (!rowCode || currentValue.rowCode === rowCode) &&
            (!rackCode || currentValue.rackCode === rackCode) &&
            (!binCode || currentValue.binCode === binCode) &&
            (!warehouse || warehouse?.code === currentValue.warehouseCode) &&
            props?.details?.productCode === currentValue.productCode &&
            props?.details?.id !== currentValue.id
          ) {
            return accumulator + currentValue.quantity;
          } else {
            return accumulator;
          }
        },
        0
      );
    return availableQty - (allocatedQuantityInOtherRows || 0);
  };

  const getRowRackBinEnable = () => {
    let ROW_RACK_BIN = tenantInfo?.additionalSettings?.ROW_RACK_BIN;
    return ROW_RACK_BIN?.length > 0 ? true : false;
  };

  const updateConfig = (
    selectedWarehouse: any = null,
    selectedRow: any = null,
    selectedRack: any = null,
    selectedBin: any = null
  ) => {
    let config = [...columns];
    let updatedConfig: any = [];

    let ROW_RACK_BIN = tenantInfo?.additionalSettings?.ROW_RACK_BIN;

    config?.forEach((col: any) => {
      if (
        col.key.toUpperCase() === ROW_RACK_BIN_CONSTANT.ROW ||
        col.key.toUpperCase() === ROW_RACK_BIN_CONSTANT.RACK ||
        col.key.toUpperCase() === ROW_RACK_BIN_CONSTANT.BIN
      ) {
        const isRRBEnabled = ROW_RACK_BIN?.find((i: any) => {
          return i.name === col.key.toUpperCase() && i.enable;
        });
        if (isRRBEnabled) {
          col = {
            ...col,
            name: isRRBEnabled?.label
          };
          updatedConfig.push(col);
        }
      } else {
        updatedConfig.push(col);
      }
    });

    updatedConfig.forEach((conf: any) => {
      //mainDto
      const rrbDtoForProduct =
        WarehouseManagementHelper.getUniqueRRBDtoFromProductCode(
          selectedWarehouse?.rowRackBinProductAvailableQuantityDtos,
          productCode
        );
      //rows
      const uniqueRows = WarehouseManagementHelper.getUniqueRows(
        rrbDtoForProduct ?? [],
        selectedRack?.rackCode,
        selectedBin?.binCode
      );
      //racks
      const uniqueRacks = WarehouseManagementHelper.getUniqueRacks(
        rrbDtoForProduct ?? [],
        selectedRow?.rowCode ?? '',
        selectedBin?.binCode ?? ''
      );
      //bins
      const uniqueBins = WarehouseManagementHelper.getDtoFiltered(
        WarehouseManagementHelper.getUniqueBins(
          rrbDtoForProduct ?? [],
          selectedRow?.rowCode ?? '',
          selectedRack?.rackCode ?? ''
        ),
        'binCode'
      );

      switch (conf.key) {
        case 'warehouse':
          conf.formatter = (obj: any) => {
            return obj?.rowData?.warehouse?.name ?? '';
          };
          conf.dropdownConfig.data = commonWarehouseByProduct ?? [];
          conf.dropdownConfig.renderer = (index: any, obj: any) => {
            return obj?.name ?? '';
          };
          conf.dropdownConfig.searchApiConfig = null;
          conf.editable = props?.isQCFlow ? false : true;
          return;

        case 'row':
          conf.name =
            ROW_RACK_BIN?.find((rrb: any) => {
              return rrb.name === conf.key?.toUpperCase();
            })?.label ?? '';
          conf.formatter = (obj: any) => {
            return obj?.rowData?.row?.rowName ?? '';
          };
          conf.dropdownConfig.data = uniqueRows ?? [];
          conf.dropdownConfig.renderer = (index: any, obj: any) => {
            return obj?.rowCode ? (
              <DKLabel text={`${obj?.rowName} (${obj?.rowCode})`} />
            ) : null;
          };
          conf.editable = props?.isQCFlow ? false : true;
          return;

        case 'rack':
          conf.name =
            ROW_RACK_BIN?.find((rrb: any) => {
              return rrb.name === conf.key?.toUpperCase();
            })?.label ?? '';
          conf.formatter = (obj: any) => {
            return obj?.rowData?.rack?.rackName ?? '';
          };
          conf.dropdownConfig.data = uniqueRacks ?? [];
          conf.dropdownConfig.renderer = (index: any, obj: any) => {
            return obj?.rackCode ? (
              <DKLabel text={`${obj?.rackName} (${obj?.rackCode})`} />
            ) : (
              ''
            );
          };
          conf.editable = props?.isQCFlow ? false : true;
          return;

        case 'bin':
          conf.name =
            ROW_RACK_BIN?.find((rrb: any) => {
              return rrb.name === conf.key?.toUpperCase();
            })?.label ?? '';
          conf.formatter = (obj: any) => {
            return obj?.rowData?.bin?.binName ?? '';
          };
          conf.dropdownConfig.data = uniqueBins ?? [];
          conf.dropdownConfig.renderer = (index: any, obj: any) => {
            return obj?.binCode ? (
              <DKLabel text={`${obj?.binName} (${obj?.binCode})`} />
            ) : (
              ''
            );
          };
          conf.editable = props?.isQCFlow ? false : true;
          return;

        case 'availableQty':
          conf.formatter = (obj: any) => {
            return Utility.roundingOff(
              obj?.rowData?.availableQty ?? 0,
              QTY_ROUNDOFF_PRECISION
            );
          };
          return;

        case 'quantity':
          conf.formatter = (obj: any) => {
            return Utility.roundingOff(
              obj?.rowData?.quantity ?? 0,
              QTY_ROUNDOFF_PRECISION
            );
          };
          return;

        default:
          break;
      }
    });

    setColumns([...updatedConfig]);
  };

  const onRowUpdate = async (data: any) => {
    let updatedLineItems = deepClone(lineItems);
    let invalidFields: any = [];

    if (data?.columnKey === 'warehouse') {
      updatedLineItems[data?.rowIndex]['warehouse'] =
        data?.rowData['warehouse'];
      updatedLineItems[data?.rowIndex]['warehouseName'] =
        data?.rowData['warehouse']?.name;
      updatedLineItems[data?.rowIndex]['warehouseCode'] =
        data?.rowData['warehouse']?.code;
      updatedLineItems[data?.rowIndex]['row'] = null;
      updatedLineItems[data?.rowIndex]['rack'] = null;
      updatedLineItems[data?.rowIndex]['bin'] = null;
      updatedLineItems[data?.rowIndex]['quantity'] = 0;
      updatedLineItems[data?.rowIndex]['availableQty'] = 0;
    }

    if (data?.columnKey === 'row') {
      updatedLineItems[data?.rowIndex]['row'] = data?.rowData['row'];
      updatedLineItems[data?.rowIndex]['rowCode'] =
        data?.rowData['row']?.rowCode;
      updatedLineItems[data?.rowIndex]['rowName'] =
        data?.rowData['row']?.rowName;
      updatedLineItems[data?.rowIndex]['rack'] = null;
      updatedLineItems[data?.rowIndex]['bin'] = null;
      updatedLineItems[data?.rowIndex]['quantity'] = 0;
    }

    if (data?.columnKey === 'rack') {
      updatedLineItems[data?.rowIndex]['rack'] = data?.rowData['rack'];
      updatedLineItems[data?.rowIndex]['rackCode'] =
        data?.rowData['rack']?.rackCode;
      updatedLineItems[data?.rowIndex]['rackName'] =
        data?.rowData['row']?.rackName;
      updatedLineItems[data?.rowIndex]['bin'] = null;
      updatedLineItems[data?.rowIndex]['quantity'] = 0;
    }

    if (data?.columnKey === 'bin') {
      updatedLineItems[data?.rowIndex]['bin'] = data?.rowData['bin'];
      updatedLineItems[data?.rowIndex]['binCode'] =
        data?.rowData['bin']?.binCode;
      updatedLineItems[data?.rowIndex]['binName'] =
        data?.rowData['row']?.binName;
      updatedLineItems[data?.rowIndex]['quantity'] = 0;
    }

    if (data?.columnKey === 'quantity') {
      updatedLineItems[data?.rowIndex]['quantity'] = Utility.roundingOff(
        data?.rowData['quantity'],
        QTY_ROUNDOFF_PRECISION
      );
    }

    const rrbDtoForProduct =
      props?.taggedWarehouse &&
      Utility.isBinAllocationMandatory() &&
      WarehouseManagementHelper.isRRBEnabledForWarehouse(props?.taggedWarehouse)
        ? WarehouseManagementHelper.getUniqueRRBDtoFromProductCode(
            data?.rowData?.warehouse?.rowRackBinProductAvailableQuantityAllDtos,
            productCode
          )
        : WarehouseManagementHelper.getUniqueRRBDtoFromProductCode(
            data?.rowData?.warehouse?.rowRackBinProductAvailableQuantityDtos,
            productCode
          );
    //rows
    const uniqueRows = await WarehouseManagementHelper.getUniqueRows(
      rrbDtoForProduct ?? [],
      data?.rowData['rack']?.rackCode,
      data?.rowData['bin']?.binCode
    );
    //racks
    const uniqueRacks = await WarehouseManagementHelper.getUniqueRacks(
      rrbDtoForProduct ?? [],
      data?.rowData['row']?.rowCode,
      data?.rowData['bin']?.binCode
    );
    //bins
    const uniqueBins = await WarehouseManagementHelper.getUniqueBins(
      rrbDtoForProduct ?? [],
      data?.rowData['row']?.rowCode,
      data?.rowData['rack']?.rackCode
    );

    if (!Utility.isEmpty(rrbDtoForProduct)) {
      updatedLineItems[data?.rowIndex]['availableQty'] = getAvailableQuantity(
        rrbDtoForProduct,
        data?.rowData['row']?.rowCode,
        data?.rowData['rack']?.rackCode,
        data?.rowData['bin']?.binCode,
        data?.rowData?.warehouse
      );
    } else {
      const availableQtyConsideringDocReservedQty =
        getAvailableQtyConsideringDocReservedQty(data?.rowData?.warehouse);
      const availableQty = props?.details?.documentUOMSchemaDefinition
        ? Utility.getUomQuantity(
            availableQtyConsideringDocReservedQty,
            props?.details?.documentUOMSchemaDefinition
          )
        : availableQtyConsideringDocReservedQty;
      updatedLineItems[data?.rowIndex]['availableQty'] = props?.isQCFlow
        ? updatedLineItems[data?.rowIndex]['availableQty']
        : availableQty;
    }

    if (
      !invalidFields?.includes('row') ||
      !invalidFields?.includes('rack') ||
      !invalidFields?.includes('bin')
    ) {
      if (!Utility.isEmpty(rrbDtoForProduct)) {
        updatedLineItems[data?.rowIndex]['availableQty'] = getAvailableQuantity(
          rrbDtoForProduct,
          data?.rowData['row']?.rowCode,
          data?.rowData['rack']?.rackCode,
          data?.rowData['bin']?.binCode,
          data?.rowData['warehouse']
        );
      } else {
        const availableQtyConsideringDocReservedQty =
          getAvailableQtyConsideringDocReservedQty(data?.rowData?.warehouse);
        const availableQty = props?.details?.documentUOMSchemaDefinition
          ? Utility.getUomQuantity(
              availableQtyConsideringDocReservedQty,
              props?.details?.documentUOMSchemaDefinition
            )
          : availableQtyConsideringDocReservedQty;
        updatedLineItems[data?.rowIndex]['availableQty'] = props?.isQCFlow
          ? updatedLineItems[data?.rowIndex]['availableQty']
          : availableQty;
      }
    }
    updatedLineItems[data?.rowIndex]['invalidFields'] = getInvalidFields({
      row: updatedLineItems[data?.rowIndex],
      rows: uniqueRows || [],
      racks: uniqueRacks || [],
      bins: uniqueBins || [],
      index: data?.rowIndex
    });

    updateConfig(
      data?.rowData['warehouse'],
      data?.rowData['row'],
      data?.rowData['rack'],
      data?.rowData['bin']
    );
    setLineItems([...updatedLineItems]);
  };

  const getInvalidFields = ({
    row,
    index,
    rows,
    racks,
    bins
  }: any): string[] => {
    const invalidFields: string[] = [];
    if (
      Utility.isEmpty(rows) &&
      Utility.isEmpty(racks) &&
      Utility.isEmpty(bins)
    ) {
      const indexOfExisting = lineItems.findIndex(
        (lineItem: any) => lineItem?.warehouseCode === row?.warehouse?.code
      );
      if (row?.warehouse?.code) {
        if (indexOfExisting !== -1 && indexOfExisting !== index) {
          invalidFields.push('warehouse');
        }
      }
    }
    if (
      row.warehouse &&
      !Utility.isEmpty(rows) &&
      Utility.isEmpty(racks) &&
      Utility.isEmpty(bins)
    ) {
      const indexOfExisting = lineItems.findIndex(
        (lineItem: any) =>
          lineItem?.warehouseCode === row?.warehouse?.code &&
          lineItem?.rowCode === row?.rowCode
      );
      if (
        row?.warehouse?.code &&
        indexOfExisting !== -1 &&
        indexOfExisting !== index
      ) {
        invalidFields.push('row');
      }
    } else {
      if (!Utility.isEmpty(rows) && Utility.isEmpty(row?.rowCode)) {
        invalidFields.push('row');
      }
    }
    if (
      row.warehouse &&
      !Utility.isEmpty(rows) &&
      !Utility.isEmpty(racks) &&
      Utility.isEmpty(bins)
    ) {
      const indexOfExisting = lineItems.findIndex(
        (lineItem: any) =>
          lineItem?.warehouseCode === row?.warehouse?.code &&
          lineItem?.rowCode === row?.rowCode &&
          lineItem?.rackCode === row?.rackCode
      );
      if (
        row?.warehouse?.code &&
        indexOfExisting !== -1 &&
        indexOfExisting !== index
      ) {
        invalidFields.push('rack');
      }
    } else {
      if (!Utility.isEmpty(racks) && Utility.isEmpty(row?.rackCode)) {
        invalidFields.push('rack');
      }
    }
    if (
      row.warehouse &&
      !Utility.isEmpty(rows) &&
      !Utility.isEmpty(racks) &&
      !Utility.isEmpty(bins)
    ) {
      const indexOfExisting = lineItems.findIndex(
        (lineItem: any) =>
          lineItem?.warehouseCode === row?.warehouse?.code &&
          lineItem?.rowCode === row?.rowCode &&
          lineItem?.rackCode === row?.rackCode &&
          lineItem?.binCode === row?.binCode
      );
      if (
        row?.warehouse?.code &&
        indexOfExisting !== -1 &&
        indexOfExisting !== index
      ) {
        invalidFields.push('bin');
      } else {
        if (!Utility.isEmpty(bins) && Utility.isEmpty(row?.binCode)) {
          invalidFields.push('bin');
        }
      }
    }
    if (invalidFields.length === 0) {
      const reservedQty = getExistingWHInventoryData(row)?.quantity || 0;
      row['existingReservedQuantity'] = reservedQty;
      const newAllocatedQty = row['quantity'] - reservedQty;
      if (newAllocatedQty < 0 && newAllocatedQty !== 0) {
        invalidFields.push('quantity');
      } else {
        if (
          newAllocatedQty > row['availableQty'] &&
          !tenantInfo.allowNegativeInventory
        ) {
          invalidFields.push('quantity');
        }
      }
    }
    return invalidFields;
  };

  //render methods
  const getHeader = () => {
    return (
      <div className="row justify-content-between p-h-r p-v-s bg-gray1 rounded-tl rounded-tr">
        <div className="row justify-content-start pop-header-drag-handle">
          <DKLabel
            text={props?.details?.product?.name ?? ''}
            className="fw-b fs-l"
          />
          <DKLabel
            text={
              props?.details?.documentSequenceCode
                ? `(${props?.details?.documentSequenceCode ?? ''})`
                : ''
            }
            className="text-gray ml-s"
          />
          {updating && <DKSpinner iconClassName="ic-s ml-s" />}
        </div>
        <div className="row width-auto">
          <DKButton
            title={'Cancel'}
            className="bg-white border-m"
            onClick={() => {
              !saveApiLoading && props.onCancel();
            }}
          />
          <div
            className={`row border-radius-m ml-r ${
              saveApiLoading
                ? ' bg-gray1 border-m pr-2'
                : ' bg-button text-white'
            }`}
          >
            <DKButton
              title={'Save'}
              className=""
              onClick={() => {
                onSave();
              }}
            />
            {saveApiLoading && <DKSpinner iconClassName="ic-s" />}
          </div>
        </div>
      </div>
    );
  };

  const getUniqueWHCodes = (arr: any[]) => {
    let unique = [];
    let distinct = [];
    for (let i = 0; i < arr.length; i++) {
      if (!unique[arr[i].warehouseCode]) {
        distinct.push(arr[i].warehouseCode);
        unique[arr[i].warehouseCode] = 1;
      }
    }
    return distinct;
  };

  const allocateAutomatically = () => {
    const { existingWarehouseInventoryData = [] } = props;
    let existingWHCodes = getUniqueWHCodes(existingWarehouseInventoryData);
    let copyWarehouseData: any[] = [];
    [...existingWHCodes].forEach((whCode: string) => {
      const whByProduct = [...commonWarehouseByProduct].find(
        (wh: any) => wh.code === whCode
      );
      if (!Utility.isEmpty(whByProduct)) {
        copyWarehouseData.push(whByProduct);
      }
    });
    const remainingWH = [...commonWarehouseByProduct].filter(
      (wh: any) => !existingWHCodes.includes(wh.code)
    );
    copyWarehouseData = [...copyWarehouseData, ...remainingWH];
    if (props?.isMrpFlow && props?.filterNormalData) {
      copyWarehouseData = filterWHData(copyWarehouseData);
    }
    const existingTotalAllocatedQty = existingWarehouseInventoryData.reduce(
      (prev: number, next: any) => prev + next.quantity,
      0
    );

    let result: any[] = [];
    let totalAllocatedQuantity = 0;
    const productCode = props.details?.pid ?? props.details?.productCode ?? '';
    const requiredQuantity = props?.details.documentUOMSchemaDefinition
      ? Utility.getUomQuantityWithoutRoundOff(
          props?.details?.pendingQuantity,
          props?.details.documentUOMSchemaDefinition
        )
      : props?.details?.pendingQuantity;
    let localRequiredQuantity = requiredQuantity - existingTotalAllocatedQty;

    copyWarehouseData?.every((warehouse: any) => {
      let availableQuantity = 0;
      if (props?.isMrpFlow && props?.filterNormalData) {
        availableQuantity =
          warehouse?.productAvailableQuantity?.[productCode] || 0;
      } else {
        availableQuantity = getAvailableQtyConsideringDocReservedQty(warehouse);
        availableQuantity = props?.details?.documentUOMSchemaDefinition
          ? Utility.getUomQuantityWithoutRoundOff(
              availableQuantity,
              props?.details?.documentUOMSchemaDefinition
            )
          : availableQuantity;
      }
      let resultItem = {
        warehouseCode: warehouse.code,
        warehouseName: warehouse.name,
        availableQty: availableQuantity,
        quantity: 0,
        invalidFields: [],
        id: props?.details?.id
      };

      const gridDataCopy = [...lineItems];
      const existingLineIndexInGrid = gridDataCopy.findIndex(
        (data: any) => data.warehouse?.code == resultItem.warehouseCode
      );

      const existingLineInventoryData =
        existingLineIndexInGrid !== -1
          ? getExistingWHInventoryData(gridDataCopy[existingLineIndexInGrid])
          : null;

      if (
        existingLineIndexInGrid !== -1 &&
        !Utility.isEmpty(existingLineInventoryData)
      ) {
        resultItem = { ...gridDataCopy[existingLineIndexInGrid] };
        resultItem.quantity = existingLineInventoryData?.quantity;
        totalAllocatedQuantity += resultItem.quantity;
      }

      resultItem.invalidFields = [];

      if (totalAllocatedQuantity < requiredQuantity) {
        let differenceOfRequired = requiredQuantity - totalAllocatedQuantity;
        if (differenceOfRequired > 0) {
          let availableQuantityInWarehouse = 0;
          if (props?.isMrpFlow && props?.filterNormalData) {
            availableQuantityInWarehouse =
              warehouse?.productAvailableQuantity?.[productCode] || 0;
          } else {
            availableQuantityInWarehouse =
              getAvailableQtyConsideringDocReservedQty(warehouse);
          }
          if (
            props?.details?.documentUOMSchemaDefinition &&
            !props?.filterNormalData
          ) {
            availableQuantityInWarehouse =
              Utility.getUomQuantityWithoutRoundOff(
                availableQuantityInWarehouse ?? 0,
                props?.details?.documentUOMSchemaDefinition
              );
          }
          if (availableQuantityInWarehouse > 0) {
            if (differenceOfRequired > availableQuantityInWarehouse) {
              if (Utility.isEmpty(existingLineInventoryData)) {
                resultItem.quantity = availableQuantityInWarehouse;
                totalAllocatedQuantity =
                  totalAllocatedQuantity + availableQuantityInWarehouse;
              } else {
                let qtyToAllocate =
                  availableQuantityInWarehouse > localRequiredQuantity
                    ? localRequiredQuantity
                    : availableQuantityInWarehouse;
                localRequiredQuantity -= qtyToAllocate;
                resultItem.quantity = qtyToAllocate + resultItem.quantity;
                totalAllocatedQuantity += qtyToAllocate;
              }
              result.push(resultItem);
            } else {
              if (Utility.isEmpty(existingLineInventoryData)) {
                resultItem.quantity = differenceOfRequired;
                totalAllocatedQuantity =
                  totalAllocatedQuantity + differenceOfRequired;
              } else {
                let qtyToAllocate =
                  differenceOfRequired > localRequiredQuantity
                    ? localRequiredQuantity
                    : differenceOfRequired;
                localRequiredQuantity -= qtyToAllocate;
                resultItem.quantity = qtyToAllocate + resultItem.quantity;
                totalAllocatedQuantity += qtyToAllocate;
              }
              result.push(resultItem);
            }
          } else {
            if (!Utility.isEmpty(existingLineInventoryData)) {
              result.push(resultItem);
            }
          }
          return true;
        }
      } else {
        if (!Utility.isEmpty(existingLineInventoryData)) {
          result.push(resultItem);
        }
        return false;
      }
    });
    onSave(result);
  };

  const allocateAutomaticallyRRB = () => {
    const { existingWarehouseInventoryData = [] } = props;
    let existingWHCodes = getUniqueWHCodes(existingWarehouseInventoryData);
    let copyWarehouseData: any[] = [];
    [...existingWHCodes].forEach((whCode: string) => {
      const whByProduct = [...commonWarehouseByProduct].find(
        (wh: any) => wh.code === whCode
      );
      if (!Utility.isEmpty(whByProduct)) {
        copyWarehouseData.push(whByProduct);
      }
    });
    const remainingWH = [...commonWarehouseByProduct].filter(
      (wh: any) => !existingWHCodes.includes(wh.code)
    );
    copyWarehouseData = [...copyWarehouseData, ...remainingWH];

    if (props?.isMrpFlow && props?.filterNormalData) {
      copyWarehouseData = filterWHData(copyWarehouseData);
    }
    const existingTotalAllocatedQty = existingWarehouseInventoryData.reduce(
      (prev: number, next: any) => prev + next.quantity,
      0
    );
    let result: any[] = [];
    let totalAllocatedQuantity = 0;
    const productCode = props.details?.pid ?? props.details?.productCode ?? '';
    const requiredQuantity = props?.details.documentUOMSchemaDefinition
      ? Utility.getUomQuantityWithoutRoundOff(
          props?.details?.pendingQuantity,
          props?.details.documentUOMSchemaDefinition
        )
      : props?.details?.pendingQuantity;
    let localRequiredQuantity = requiredQuantity - existingTotalAllocatedQty;

    const productAvailableQuantityInfo = copyWarehouseData.reduce(
      (prev: any[], current: any) => {
        let rrbDtoForProduct = current?.rowRackBinProductAvailableQuantityDtos;
        if (rrbDtoForProduct?.length) {
          rrbDtoForProduct = rrbDtoForProduct
            ?.filter(
              (item: any) =>
                item.productCode === productCode && item.availableQuantity > 0
            )
            .map((item: any) => ({
              ...item,
              warehouseCode: current.code,
              warehouseName: current.name
            }));
        } else {
          let qtyAvailability =
            Utility.isBinAllocationMandatory() && props?.taggedWarehouse
              ? Utility.isNotEmpty(rrbDtoForProduct)
              : true;
          if (qtyAvailability) {
            rrbDtoForProduct = [
              {
                availableQuantity:
                  current?.productAvailableQuantity?.[productCode],
                binCode: null,
                binName: null,
                productCode: productCode,
                rackCode: null,
                rackName: null,
                reservedQuantity:
                  current?.productReservedQuantity?.[productCode],
                rowCode: null,
                rowName: null,
                warehouseCode: current.code,
                warehouseName: current.name
              }
            ];
          }
        }
        return [...prev, ...rrbDtoForProduct];
      },
      []
    );

    if (productAvailableQuantityInfo.length) {
      productAvailableQuantityInfo?.every((rrbDtoForProduct: any) => {
        const warehouse = copyWarehouseData?.find(
          (x: any) => x.code === rrbDtoForProduct.warehouseCode
        );

        const row = rrbDtoForProduct;
        const rack = rrbDtoForProduct;
        const bin = rrbDtoForProduct;

        let availableQuantity = getAvailableQuantity(
          [rrbDtoForProduct],
          row?.rowCode,
          rack?.rackCode,
          bin?.binCode,
          warehouse
        );

        if (props?.isMrpFlow && props?.filterNormalData) {
          availableQuantity = rrbDtoForProduct.availableQuantity;
        }

        let resultItem = {
          id: props.details.id,
          warehouse: warehouse,
          warehouseCode: warehouse.code,
          warehouseName: warehouse.name,
          availableQty: availableQuantity,
          quantity: 0,
          row: row,
          rowCode: row.rowCode,
          rowName: row.rowName,
          rack: rack,
          rackCode: rack.rackCode,
          rackName: rack.rackName,
          bin: bin,
          binCode: bin.binCode,
          binName: bin.binName,
          invalidFields: []
        };

        const gridDataCopy = [...lineItems];
        const existingLineIndexInGrid = gridDataCopy.findIndex(
          (data: any) =>
            data.warehouse?.code == resultItem.warehouseCode &&
            data.rowCode == resultItem.rowCode &&
            data.rackCode == resultItem.rackCode &&
            data.binCode == resultItem.binCode
        );

        const existingLineInventoryData =
          existingLineIndexInGrid !== -1
            ? getExistingWHInventoryData(gridDataCopy[existingLineIndexInGrid])
            : null;

        if (
          existingLineIndexInGrid !== -1 &&
          !Utility.isEmpty(existingLineInventoryData)
        ) {
          resultItem = { ...gridDataCopy[existingLineIndexInGrid] };
          resultItem.quantity = existingLineInventoryData?.quantity;
          totalAllocatedQuantity += resultItem.quantity;
        }

        resultItem.invalidFields = [];

        if (totalAllocatedQuantity < requiredQuantity) {
          let differenceOfRequired = requiredQuantity - totalAllocatedQuantity;
          if (differenceOfRequired > 0) {
            let availableQuantityInWarehouse = availableQuantity ?? 0;
            if (availableQuantityInWarehouse > 0) {
              if (differenceOfRequired > availableQuantityInWarehouse) {
                if (Utility.isEmpty(existingLineInventoryData)) {
                  resultItem.quantity = availableQuantityInWarehouse;
                  totalAllocatedQuantity =
                    totalAllocatedQuantity + availableQuantityInWarehouse;
                } else {
                  let qtyToAllocate =
                    availableQuantityInWarehouse > localRequiredQuantity
                      ? localRequiredQuantity
                      : availableQuantityInWarehouse;
                  localRequiredQuantity -= qtyToAllocate;
                  resultItem.quantity = qtyToAllocate + resultItem.quantity;
                  totalAllocatedQuantity += qtyToAllocate;
                }
                result.push(resultItem);
              } else {
                if (Utility.isEmpty(existingLineInventoryData)) {
                  resultItem.quantity = differenceOfRequired;
                  totalAllocatedQuantity =
                    totalAllocatedQuantity + differenceOfRequired;
                } else {
                  let qtyToAllocate =
                    differenceOfRequired > localRequiredQuantity
                      ? localRequiredQuantity
                      : differenceOfRequired;
                  localRequiredQuantity -= qtyToAllocate;
                  resultItem.quantity = qtyToAllocate + resultItem.quantity;
                  totalAllocatedQuantity += qtyToAllocate;
                }
                result.push(resultItem);
              }
            } else {
              if (!Utility.isEmpty(existingLineInventoryData)) {
                result.push(resultItem);
              }
            }
            return true;
          }
        } else {
          if (!Utility.isEmpty(existingLineInventoryData)) {
            result.push(resultItem);
          }
          return false;
        }
      });
      onSave(result);
    } else {
      if (
        !(
          Utility.isBinAllocationMandatory() &&
          props?.taggedWarehouse &&
          WarehouseManagementHelper.isRRBEnabledForWarehouse(
            props?.taggedWarehouse
          )
        )
      ) {
        allocateAutomatically();
      }
      if (
        Utility.isBinAllocationMandatory() &&
        props?.taggedWarehouse &&
        WarehouseManagementHelper.isRRBEnabledForWarehouse(
          props?.taggedWarehouse
        )
      ) {
        showAlert(
          'Warning',
          'Quantity is not available in selected row rack bin'
        );
      }
    }
  };

  const getHeaderView = () => {
    const requiredQty = Utility.roundingOff(
      props?.details.documentUOMSchemaDefinition
        ? Utility.getUomQuantity(
            props?.details?.pendingQuantity,
            props?.details.documentUOMSchemaDefinition
          )
        : props?.details?.pendingQuantity,
      QTY_ROUNDOFF_PRECISION
    );

    const sumofUsedQuantities =
      lineItems?.reduce((acc: any, item: any) => {
        return Number(item?.quantity ?? 0) + Number(acc ?? 0);
      }, 0) ?? 0;
    const allocatedQty = Utility.roundingOff(
      sumofUsedQuantities ?? 0,
      QTY_ROUNDOFF_PRECISION
    );

    const uom = props?.details?.documentUOMSchemaDefinition
      ? props?.details?.documentUOMSchemaDefinition?.name
      : Utility.getUOMForStockUOMId(props?.details?.product?.stockUom)?.name;

    return (
      <div className="column parent-width justify-content-end">
        <div className="row parent-width justify-content-end mt-m gap-2">
          <div className="column align-items-end gap-2">
            <DKLabel text="Required Qty:" className="fw-b" />
            <DKLabel text="Allocated Qty:" className="fw-b" />
          </div>
          <div className="column align-items-end gap-2">
            <div className="row align-items-end gap-1 ">
              <DKLabel text={`${requiredQty}`} className="" />
              <DKLabel
                style={{
                  height: 15
                }}
                text={` ${uom}`}
                className="fs-s fw-m text-gray "
              />
            </div>
            <div className="row align-items-end gap-1 ">
              <DKLabel text={`${allocatedQty}`} className="" />
              <DKLabel
                style={{
                  height: 15
                }}
                text={` ${uom}`}
                className="fs-s fw-m text-gray "
              />
            </div>
          </div>
        </div>
        <div className="row justify-content-end gap-2">
          {!props?.isQCFlow && props.module === MODULE_TYPE.SELL && (
            <DKButton
              title="Auto Allocate"
              style={{ paddingRight: 0 }}
              onClick={() => {
                let rrbEnabled =
                  tenantInfo?.additionalSettings?.ROW_RACK_BIN?.filter(
                    (item: any) => item?.enable
                  );
                if (!rrbEnabled) {
                  allocateAutomatically();
                } else {
                  allocateAutomaticallyRRB();
                }
              }}
              className="text-blue underline"
            />
          )}
        </div>
      </div>
    );
  };

  const getRowButtons = (lineItem: any) => {
    let buttons: any[] = [];

    if (lineItem?.allowToDelete) {
      buttons.push({
        icon: DKIcons.ic_delete,
        className: 'ic-xs',
        onClick: (data: any) => {
          const updatedLineItems = [...lineItems];
          updatedLineItems.splice(data?.rowIndex, 1);
          setLineItems([...updatedLineItems]);
        }
      });
    }

    return buttons;
  };

  const getRequiredQuantity = () => {
    const requiredQty =
      Utility.roundingOff(
        props?.details.documentUOMSchemaDefinition
          ? Utility.getUomQuantity(
              props?.details?.pendingQuantity,
              props?.details.documentUOMSchemaDefinition
            )
          : props?.details?.pendingQuantity,
        QTY_ROUNDOFF_PRECISION
      ) || 0;
    return requiredQty;
  };

  const getDefaultActiveWarehouse = () => {
    let filteredWarehouses = [...commonWarehouseByProduct]?.filter(
      (war: any) =>
        war?.productAvailableQuantity?.[props.details.productCode] >=
        getRequiredQuantity()
    );
    let selectedWarehouse = filteredWarehouses?.find(
      (warehouse: any) =>
        warehouse.code === productDetails?.inventory?.warehouseCode
    );
    if (Utility.isEmpty(selectedWarehouse)) {
      selectedWarehouse = commonWarehouseByProduct?.find(
        (warehouse: any) => warehouse.primary
      );
    }
    return selectedWarehouse ?? commonWarehouseByProduct[0];
  };

  const getGridView = () => {
    let height = window.innerHeight - 200;
    return (
      <div
        className="column parent-width overflow-scroll hide-scroll-bar"
        style={{ maxHeight: height }}
      >
        <DKDataGrid
          title=""
          needShadow={true}
          needColumnIcons={false}
          needBorder={true}
          needTrailingColumn={true}
          allowBulkOperation={false}
          allowColumnSort={false}
          filterData={[]}
          allowColumnDelete={false}
          allowRowEdit={true}
          allowColumnEdit={false}
          allowFilter={false}
          allowColumnAdd={false}
          allowBottomRowAdd={false}
          allowSearch={false}
          allowShare={false}
          rows={[...deepClone(lineItems)]?.map((lineItem: any) => {
            return {
              ...lineItem,
              rowButtons: getRowButtons(lineItem),
              rowContextMenu: null,
              nonEditableColumns: lineItem?.allowToDelete
                ? []
                : ['warehouse', 'rack', 'bin', 'row']
            };
          })}
          columns={[
            ...columns,
            {
              id: 'action',
              key: 'action',
              name: 'Actions',
              type: INPUT_TYPE.BUTTON,
              width: 80,
              options: []
            }
          ]}
          onRowUpdate={(data: any) => {
            onRowUpdate(data);
          }}
          onRowClick={(data: any) => {
            updateConfig(data?.rowData?.warehouse, data?.rowData?.row);
          }}
        />
      </div>
    );
  };

  const getAddButton = () => {
    return (
      <DKButton
        title={`+ Add Row`}
        onClick={() => {
          if (props.disableAddRow && lineItems.length === 1) {
            return;
          }
          let updatedLineItems = [...lineItems];
          updatedLineItems.push({
            warehouse: '',
            row: null,
            rack: null,
            bin: null,
            availableQty: 0,
            quantity: 0,
            allowToDelete: true
          });
          setLineItems([...updatedLineItems]);
        }}
        className={`fw-m position-relative ${
          props.disableAddRow && lineItems.length === 1
            ? 'text-gray'
            : 'text-blue'
        } `}
        style={{
          paddingTop: 0,
          paddingLeft: 0,
          width: 100,
          marginBottom: 12,
          marginTop: -10
        }}
      />
    );
  };

  return (
    <DynamicPopupWrapper>
      <div className="transparent-background" style={{ zIndex: 9998 }}>
        <div
          className="popup-window"
          style={{
            maxWidth: '98%',
            width: getRowRackBinEnable() ? 950 : 590,
            maxHeight: '95%',
            minHeight: 350,
            padding: 0,
            overflowY: 'hidden'
          }}
        >
          {getHeader()}
          <div className="parent-size p-h-m overflow-y-auto1">
            {getHeaderView()}
            <div className="column p-h-m parent-size hide-scroll-bar position-relative">
              {getGridView()}
              {getAddButton()}
            </div>
          </div>
        </div>
      </div>
    </DynamicPopupWrapper>
  );
};

export default NoneTrackedAssignment;
