import CustomFieldManager from "../../Manager/CustomFieldManager"
import Utility, { convertDateFromERPFormatToDocFormat, getConvertedDate } from "../../Utilities/Utility"
import AdditionalChargeParser from "../AdditionalChargeParser"
import Address from "../Address"
import AddressParser, { AddressType, OwnerType } from "../AddressParser"
import DataParser from "../DataParser"
import LineItemsParser from "../LineItemsParser"

export default class JobWorkOutOrderParser {
    static parseData(dataFromAPI, templateDataToReturn) {
        
        const jobWorkOrder = this.getJobWorkOutOrderResponse(dataFromAPI)
        
        if (jobWorkOrder && jobWorkOrder !== null) {
            let customFields = jobWorkOrder.customField
            templateDataToReturn.customFields = customFields

            CustomFieldManager.setCustomFields(templateDataToReturn.customFields)
            templateDataToReturn = DataParser.assignCustomFieldValue(templateDataToReturn)

            templateDataToReturn.date = ''
            if (jobWorkOrder.receiveByDate && jobWorkOrder.receiveByDate !== null) {
                templateDataToReturn.date = getConvertedDate(convertDateFromERPFormatToDocFormat(jobWorkOrder.receiveByDate), templateDataToReturn.dateFormat)
            }

            templateDataToReturn.voucherNo = '';
            if (jobWorkOrder.documentSequenceCode && jobWorkOrder.documentSequenceCode !== null) {
                templateDataToReturn.voucherNo = jobWorkOrder.documentSequenceCode
            }


            templateDataToReturn.orderNo = '';
            if (jobWorkOrder.linkedDocuments && jobWorkOrder.linkedDocuments !== null) {
                if(jobWorkOrder.linkedDocuments.length > 0) {
                    const linkDoc = jobWorkOrder.linkedDocuments[0]
                    templateDataToReturn.orderNo = linkDoc ? linkDoc.documentSequenceCode : ''
                }
            }

            templateDataToReturn.termOfPayment = '';
            if (jobWorkOrder.contactDto && jobWorkOrder.contactDto !== null) {
                templateDataToReturn.termOfPayment = jobWorkOrder.contactDto.paymentTermCode ? jobWorkOrder.contactDto.paymentTermCode : ''

                if (jobWorkOrder.contactDto.paymentTermDetails && jobWorkOrder.contactDto.paymentTermDetails !== null) {
                    if (jobWorkOrder.contactDto.paymentTermDetails.termName && jobWorkOrder.contactDto.paymentTermDetails.termName !== null) {
                        templateDataToReturn.termOfPayment = jobWorkOrder.contactDto.paymentTermDetails.termName;
                    }
                }
            }

            templateDataToReturn.dispatchStatus = '';
            if (jobWorkOrder.dispatchStatus && jobWorkOrder.dispatchStatus !== null) {
                templateDataToReturn.dispatchStatus = jobWorkOrder.dispatchStatus

                if(templateDataToReturn.dispatchStatus.includes('_')) {
                    templateDataToReturn.dispatchStatus = templateDataToReturn.dispatchStatus.replace('_', ' ')
                }
            }

            templateDataToReturn.dispatchDocNo = ''
            if (jobWorkOrder.stockTransferDocumentSeqCodes && jobWorkOrder.stockTransferDocumentSeqCodes !== null) {
                if (jobWorkOrder.stockTransferDocumentSeqCodes.length > 0) {
                    if (jobWorkOrder.stockTransferDocumentSeqCodes.length === 1) {
                        templateDataToReturn.dispatchDocNo = jobWorkOrder.stockTransferDocumentSeqCodes[0]
                    }
                    else {
                        templateDataToReturn.dispatchDocNo = jobWorkOrder.stockTransferDocumentSeqCodes.join(', ')
                    }
                }
            }

            templateDataToReturn.destination = '';
            templateDataToReturn.dispatchedThrough = '';
            templateDataToReturn.durationOfProcess = '';
            templateDataToReturn.natureOfProcess = '';
            templateDataToReturn.companyPAN = ''

            //ship from
            var contactName = jobWorkOrder.contactDto?.name ? jobWorkOrder.contactDto?.name : ''
            templateDataToReturn.companyName = contactName
            if (jobWorkOrder.shipFrom && jobWorkOrder.shipFrom !== null) {
                var fromAddressObj = new Address('', jobWorkOrder.shipFrom, jobWorkOrder.contactDto.pan, jobWorkOrder.contactDto.gstin, jobWorkOrder.contactDto.contactNumber, undefined, OwnerType.contact)
                templateDataToReturn.fromObj = fromAddressObj
                templateDataToReturn = AddressParser.assignAddressObjToStringForNonArabic(undefined, AddressType.from, templateDataToReturn)
            }

            //bill to 
            var tenantName = dataFromAPI.tenantInfo?.name ? dataFromAPI.tenantInfo?.name : ''
            if (jobWorkOrder.billTo && jobWorkOrder.billTo !== null) {
                var clientBillToAddressObj = new Address(tenantName, jobWorkOrder.billTo, dataFromAPI.tenantInfo.pan, dataFromAPI.tenantInfo.gstin, dataFromAPI.tenantInfo.contactNumber, undefined, OwnerType.tenant)
                templateDataToReturn.clientBillToAddressObj = clientBillToAddressObj
                templateDataToReturn = AddressParser.assignAddressObjToStringForNonArabic(undefined, AddressType.billTo, templateDataToReturn)
            }

            //ship to
            if (jobWorkOrder.shipTo && jobWorkOrder.shipTo !== null) {
                var clientShipToAddressObj = new Address(tenantName, jobWorkOrder.shipTo, dataFromAPI.tenantInfo.pan, dataFromAPI.tenantInfo.gstin, dataFromAPI.tenantInfo.contactNumber, undefined, OwnerType.tenant)
                templateDataToReturn.clientShipToAddressObj = clientShipToAddressObj
                templateDataToReturn = AddressParser.assignAddressObjToStringForNonArabic(undefined, AddressType.shipTo, templateDataToReturn)
            }


            if (jobWorkOrder.totalAmount && jobWorkOrder.totalAmount !== null) {
                dataFromAPI.total = jobWorkOrder.totalAmount
            }

            //currency
            if (jobWorkOrder.currencyCode && jobWorkOrder.currencyCode !== null) {
                templateDataToReturn.currencyName = jobWorkOrder.currencyCode
                templateDataToReturn.currency = Utility.getCurrencySymbolFromCode(jobWorkOrder.currencyCode)
            }

            //rounding off
            templateDataToReturn.roundOff = jobWorkOrder.roundOffAmountInDocumentCurrency

            //Additional charge and global
            templateDataToReturn = AdditionalChargeParser.getAdditionalCharge(jobWorkOrder, templateDataToReturn)
            
            //line items
            templateDataToReturn.lineItems = LineItemsParser.getJobOutWorkOrder(jobWorkOrder)
        }

        return templateDataToReturn
    }


    static getJobWorkOutOrderResponse(dataFromApi) {
        if (dataFromApi.jobWorkOutDetail !== undefined && dataFromApi.jobWorkOutDetail !== null) {
            return dataFromApi.jobWorkOutDetail
        }
        return undefined
    }
}
