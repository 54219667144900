import { INPUT_TYPE, DKIcons } from 'deskera-ui-library';
import { DocumentConfigUtility } from '../Utility/DocumentConfigUtility';
import ProductService, { ProductAPIConfig } from '../Services/Product';
import TaxService, { defaultConfig as TaxAPIConfig } from '../Services/Tax';
import ApiConstants from '../Constants/ApiConstants';
import { DOC_TYPE, TAX_SYSTEM } from '../Constants/Constant';
import {
  getTenantTaxSystem,
  isSalesOrderVisible,
  isRevRecVisible
} from '../SharedComponents/DocumentForm/NewDocumentHelper';
import AccountsService, { AccountAPIConfig } from '../Services/Accounts';
import { Store } from '../Redux/Store';
import AdditionalChargeService, {
  AdditionalChargeAPIConfig
} from '../Services/AdditionalCharges';
import { DK_INPUT_TYPE, IColumn, IColumnType } from '../Models/Table';
import { MACHINE_SCHEDULE_ADHOC_TABLE } from '../Components/Mrp/Constants/TableConstant';
import { MACHINE_CONSUME } from '../Services/MachineMaterialConsumptionService';

export class DocumentConfigManager {
  static docConfigs: any = {
    [DOC_TYPE.MACHINE_MATERIAL_CONSUMPTION]: [
      {
        id: 'productId',
        key: 'productId',
        name: 'Product Id',
        type: INPUT_TYPE.DROPDOWN,
        width: 200,
        systemField: true,
        editable: true,
        hidden: false,
        required: true,
        uiVisible: true,
        formatter: DocumentConfigUtility.productFormatterDocument,
        dropdownConfig: {
          title: 'Select Product',
          allowSearch: true,
          searchableKey: 'name',
          style: { minWidth: 250 },
          className: 'shadow-m width-auto',
          searchApiConfig: {
            getUrl: null,
            dataParser: DocumentConfigUtility.productDataParser,
            debounceTime: 300
          },
          data: [],
          renderer: DocumentConfigUtility.productRenderer,
          onSelect: (index: any, obj: any, rowIndex: any) => {},
          button: {
            title: '+ Add New',
            className: 'bg-button text-white',
            onClick: () => {}
          }
        }
      },
      {
        id: 'description',
        key: 'description',
        name: 'Description',
        type: INPUT_TYPE.TEXT,
        width: 200,
        systemField: true,
        editable: true,
        hidden: false,
        uiVisible: true
      },
      {
        id: 'quantity',
        key: 'quantity',
        name: 'Quantity',
        type: INPUT_TYPE.NUMBER,
        width: 210,
        systemField: true,
        editable: true,
        hidden: false,
        uiVisible: true,
        textAlign: 'right',
        renderer: DocumentConfigUtility.quantityRenderer
      },
      {
        id: 'uomInternal',
        key: 'uomInternal',
        name: 'UOM',
        type: INPUT_TYPE.DROPDOWN,
        width: 190,
        systemField: true,
        editable: true,
        hidden: false,
        uiVisible: true,
        formatter: DocumentConfigUtility.uomFormatter,
        dropdownConfig: {
          title: 'Select UOM',
          allowSearch: true,
          searchableKey: 'name',
          style: { minWidth: 150 },
          className: 'shadow-m width-auto',
          data: [],
          renderer: DocumentConfigUtility.uomOptionRenderer,
          onSelect: (index: any, obj: any, rowIndex: any) => {}
        }
      },
      {
        id: 'unitPrice',
        key: 'unitPrice',
        name: 'Unit Price',
        type: INPUT_TYPE.NUMBER,
        width: 190,
        systemField: true,
        editable: true,
        hidden: false,
        uiVisible: true,
        textAlign: 'right'
      },
      {
        id: 'amount',
        key: 'amount',
        name: 'Amount',
        type: INPUT_TYPE.NUMBER,
        width: 190,
        systemField: true,
        editable: false,
        hidden: false,
        uiVisible: true,
        textAlign: 'right'
      },
      {
        id: 'remark',
        key: 'remark',
        name: 'Remark',
        type: DK_INPUT_TYPE.TEXT,
        width: 190,
        systemField: true,
        editable: true,
        hidden: false,
        uiVisible: true,
        textAlign: 'right'
      },
      {
        id: 'consume',
        key: 'consume',
        name: 'Consume',
        type: DK_INPUT_TYPE.TEXT,
        width: 200,
        systemField: true,
        editable: false,
        hidden: false,
        required: false,
        uiVisible: true
      },
      {
        key: 'actions',
        name: 'Actions',
        type: DK_INPUT_TYPE.BUTTON,
        options: [],
        width: 240
      }
    ],
    [DOC_TYPE.REQUISITION]: [
      {
        id: 'product',
        key: 'product',
        name: 'Product',
        type: INPUT_TYPE.DROPDOWN,
        width: 250,
        systemField: true,
        editable: true,
        hidden: false,
        required: true,
        uiVisible: true,
        formatter: DocumentConfigUtility.productFormatterDocument,
        dropdownConfig: {
          title: 'Select Product',
          allowSearch: true,
          searchableKey: 'name',
          style: { minWidth: 250 },
          className: 'shadow-m width-auto',
          searchApiConfig: {
            getUrl: null,
            dataParser: DocumentConfigUtility.productDataParser,
            debounceTime: 300
          },
          data: [],
          renderer: DocumentConfigUtility.productRenderer,
          onSelect: (index: any, obj: any, rowIndex: any) => {},
          button: {
            title: '+ Add New',
            className: 'bg-button text-white',
            onClick: () => {}
          }
        }
      },
      {
        id: 'documentSequenceCode',
        key: 'documentSequenceCode',
        name: 'Product Code',
        type: INPUT_TYPE.TEXT,
        width: 160,
        systemField: true,
        editable: false,
        hidden: false,
        uiVisible: true
      },
      {
        id: 'description',
        key: 'productDescription',
        name: 'Description',
        type: INPUT_TYPE.TEXT,
        width: 250,
        systemField: true,
        editable: true,
        hidden: false,
        uiVisible: true
      },
      {
        id: 'quantity',
        key: 'productQuantity',
        name: 'Quantity',
        type: INPUT_TYPE.NUMBER,
        width: 230,
        systemField: true,
        editable: true,
        hidden: false,
        uiVisible: true,
        textAlign: 'right',
        renderer: DocumentConfigUtility.quantityRenderer
      },
      {
        id: 'uom',
        key: 'uom',
        name: 'UOM',
        type: INPUT_TYPE.DROPDOWN,
        width: 250,
        systemField: true,
        editable: true,
        hidden: false,
        uiVisible: true,
        formatter: DocumentConfigUtility.uomFormatter,
        dropdownConfig: {
          title: 'Select UOM',
          allowSearch: true,
          searchableKey: 'name',
          style: { minWidth: 250 },
          className: 'shadow-m width-auto',
          data: [],
          renderer: DocumentConfigUtility.uomOptionRenderer,
          onSelect: (index: any, obj: any, rowIndex: any) => {}
        }
      }
    ],

    [DOC_TYPE.INVOICE]: [
      {
        id: 'product',
        key: 'product',
        name: 'Product',
        type: INPUT_TYPE.DROPDOWN,
        width: 156,
        systemField: true,
        editable: true,
        hidden: false,
        required: true,
        uiVisible: true,
        formatter: DocumentConfigUtility.productFormatterDocument,
        dropdownConfig: {
          title: 'Select Product',
          allowSearch: true,
          searchableKey: 'name',
          style: { minWidth: 230 },
          className: 'shadow-m width-auto',
          searchApiConfig: {
            getUrl: null,
            dataParser: DocumentConfigUtility.productDataParser,
            debounceTime: 300
          },
          data: [],
          renderer: DocumentConfigUtility.productRenderer,
          onSelect: (index: any, obj: any, rowIndex: any) => {},
          button: {
            title: '+ Add New',
            className: 'bg-button text-white',
            onClick: () => {}
          }
        }
      },
      {
        id: 'documentSequenceCode',
        key: 'documentSequenceCode',
        name: 'Product Code',
        type: INPUT_TYPE.TEXT,
        width: 160,
        systemField: true,
        editable: false,
        hidden: false,
        uiVisible: true
      },
      {
        id: 'description',
        key: 'productDescription',
        name: 'Description',
        type: INPUT_TYPE.TEXT,
        width: 160,
        systemField: true,
        editable: true,
        hidden: false,
        uiVisible: true
      },
      {
        id: 'quantity',
        key: 'productQuantity',
        name: 'Quantity',
        type: INPUT_TYPE.NUMBER,
        width: 130,
        systemField: true,
        editable: true,
        hidden: false,
        uiVisible: true,
        textAlign: 'right',
        renderer: (data: any) =>
          DocumentConfigUtility.quantityRenderer(data, true)
      },
      {
        id: 'availableQtyByUOM',
        key: 'availableQtyByUOM',
        name: 'Available Qty',
        type: INPUT_TYPE.NUMBER,
        width: 180,
        systemField: true,
        editable: false,
        hidden: false,
        uiVisible: true,
        textAlign: 'right',
        renderer: DocumentConfigUtility.quantityRenderer
      },
      {
        id: 'uom',
        key: 'uom',
        name: 'UOM',
        type: INPUT_TYPE.DROPDOWN,
        width: 78,
        systemField: true,
        editable: true,
        hidden: false,
        uiVisible: true,
        formatter: DocumentConfigUtility.uomFormatter,
        dropdownConfig: {
          title: 'Select UOM',
          allowSearch: true,
          searchableKey: 'name',
          style: { minWidth: 150 },
          className: 'shadow-m width-auto',
          data: [],
          renderer: DocumentConfigUtility.uomOptionRenderer,
          onSelect: (index: any, obj: any, rowIndex: any) => {}
        }
      },
      {
        id: 'price',
        key: 'unitPrice',
        name: 'Price',
        type: INPUT_TYPE.NUMBER,
        width: 141,
        systemField: true,
        editable: true,
        allowNegativeNumber: true,
        hidden: false,
        uiVisible: true,
        textAlign: 'right',
        formatter: ({ value }: any) => value,
        dropdownConfig: {
          title: 'Price History',
          data: [],
          renderer: DocumentConfigUtility.historicalUnitPriceRenderer
        }
      },
      {
        id: 'basePrice',
        key: 'basePrice',
        name: 'Base Price',
        type: INPUT_TYPE.NUMBER,
        width: 141,
        systemField: true,
        editable: true,
        hidden: false,
        uiVisible: true,
        textAlign: 'right',
        formatter: ({ value }: any) => value
      },
      {
        id: 'allocationType',
        key: 'allocationType',
        name: 'Allocation Type',
        type: INPUT_TYPE.TEXT,
        width: 141,
        systemField: true,
        editable: false,
        hidden: false,
        uiVisible: true,
        textAlign: 'right',
        formatter: ({ value }: any) => value
        // dropdownConfig: {
        //   title: '-',
        //   data: [],
        //   renderer: DocumentConfigUtility.unitPriceRenderer
        // }
      },
      {
        id: 'discount',
        key: 'discount',
        name: 'Discount',
        type: INPUT_TYPE.TEXT,
        width: 100,
        systemField: true,
        editable: true,
        hidden: false,
        uiVisible: true,
        textAlign: 'right'
      },
      {
        id: 'tax',
        key: 'tax',
        name: 'Tax',
        type: INPUT_TYPE.DROPDOWN,
        width: 100,
        systemField: true,
        editable: true,
        hidden: false,
        uiVisible: true,
        renderer: DocumentConfigUtility.taxRenderer,
        dropdownConfig: {
          title: 'Select Tax',
          allowSearch: true,
          searchableKey: 'name',
          style: { minWidth: 150 },
          className: 'shadow-m width-auto',
          searchApiConfig: {
            getUrl: null,
            dataParser: null,
            debounceTime: 300
          },
          data: [],
          renderer: DocumentConfigUtility.taxOptionRenderer,
          onSelect: (index: any, obj: any, rowIndex: any) => {},
          button: {
            title: '+ Add New',
            className: 'bg-button text-white',
            onClick: () => {}
          }
        }
      },
      {
        id: 'taxAmount',
        key: 'taxAmount',
        name: 'Tax',
        type: INPUT_TYPE.NUMBER,
        width: 100,
        systemField: true,
        editable: true,
        hidden: false,
        uiVisible: true,
        textAlign: 'right'
      },
      {
        id: 'amount',
        key: 'totalAmount',
        name: 'Amount',
        type: INPUT_TYPE.NUMBER,
        width: 150,
        systemField: true,
        editable: false,
        hidden: false,
        uiVisible: true,
        textAlign: 'right'
      },
      {
        id: 'lineRefNo',
        key: 'lineRefNo',
        name: 'Line Ref Number',
        type: INPUT_TYPE.TEXT,
        width: 160,
        systemField: true,
        editable: false,
        hidden: false,
        uiVisible: true
      }
    ],
    [DOC_TYPE.REV_ARR]: [
      {
        id: 'itemCode',
        key: 'itemCode',
        name: 'Revenue Elements',
        type: INPUT_TYPE.TEXT,
        width: 160,
        systemField: true,
        editable: true,
        hidden: false,
        uiVisible: true
      },
      {
        id: 'status',
        key: 'status',
        name: 'Status',
        type: INPUT_TYPE.TEXT,
        width: 160,
        systemField: true,
        editable: true,
        hidden: false,
        uiVisible: true
      },
      {
        id: 'productName',
        key: 'productName',
        name: 'Product',
        type: INPUT_TYPE.TEXT,
        width: 160,
        systemField: true,
        editable: true,
        hidden: false,
        uiVisible: true
      },
      {
        id: 'quantity',
        key: 'quantity',
        name: 'Quantity',
        type: INPUT_TYPE.NUMBER,
        width: 160,
        systemField: true,
        editable: true,
        hidden: false,
        uiVisible: true
      },
      {
        id: 'salesAmountInBase',
        key: 'salesAmountInBase',
        name: 'Sales Amount',
        type: INPUT_TYPE.TEXT,
        width: 160,
        systemField: true,
        editable: true,
        hidden: false,
        textAlign: 'right',
        uiVisible: true
      },
      {
        id: 'basePrice',
        key: 'basePrice',
        name: 'Base Price',
        type: INPUT_TYPE.TEXT,
        width: 160,
        systemField: true,
        editable: true,
        hidden: false,
        textAlign: 'right',
        uiVisible: true
      },
      {
        id: 'discount',
        key: 'discount',
        name: 'Discount',
        type: INPUT_TYPE.TEXT,
        width: 160,
        systemField: true,
        editable: true,
        hidden: false,
        textAlign: 'right',
        uiVisible: true
      },
      {
        id: 'revAmountInBase',
        key: 'revAmountInBase',
        name: 'Revenue Amount',
        type: INPUT_TYPE.TEXT,
        width: 160,
        systemField: true,
        editable: true,
        hidden: false,
        textAlign: 'right',
        uiVisible: true
      },
      {
        id: 'revAllocationRatio',
        key: 'revAllocationRatio',
        name: 'Revenue Allocation Ratio',
        type: INPUT_TYPE.TEXT,
        width: 160,
        systemField: true,
        editable: true,
        hidden: false,
        uiVisible: true
      },
      {
        id: 'revRecRuleName',
        key: 'revRecRuleName',
        name: 'Revenue Recognition Rule',
        type: INPUT_TYPE.TEXT,
        width: 160,
        systemField: true,
        editable: true,
        hidden: false,
        uiVisible: true
      },
      {
        id: 'revRecForecastRuleName',
        key: 'revRecForecastRuleName',
        name: 'Revenue Recognition Forecast Rule',
        type: INPUT_TYPE.TEXT,
        width: 160,
        systemField: true,
        editable: true,
        hidden: false,
        uiVisible: true
      },
      {
        id: 'forecastStartDate',
        key: 'forecastStartDate',
        name: 'Forecast Start Date',
        type: INPUT_TYPE.TEXT,
        width: 160,
        systemField: true,
        editable: true,
        hidden: false,
        uiVisible: true
      },
      {
        id: 'forecastEndDate',
        key: 'forecastEndDate',
        name: 'Forecast End Date',
        type: INPUT_TYPE.TEXT,
        width: 160,
        systemField: true,
        editable: true,
        hidden: false,
        uiVisible: true
      },
      {
        id: 'createRevenuePlansOn',
        key: 'createRevenuePlansOn',
        name: 'Create Revenue Plan On',
        type: INPUT_TYPE.TEXT,
        width: 160,
        systemField: true,
        editable: true,
        hidden: false,
        uiVisible: true
      },
      {
        id: 'deferralAccountName',
        key: 'deferralAccountName',
        name: 'Deferral Account',
        type: INPUT_TYPE.TEXT,
        width: 160,
        systemField: true,
        editable: true,
        hidden: false,
        uiVisible: true
      },
      {
        id: 'recognitionAccountName',
        key: 'recognitionAccountName',
        name: 'Recognition Account',
        type: INPUT_TYPE.TEXT,
        width: 160,
        systemField: true,
        editable: true,
        hidden: false,
        uiVisible: true
      },
      {
        id: 'calculatedFairValueAmount',
        key: 'calculatedFairValueAmount',
        name: 'Calculated Fair Value Amount',
        type: INPUT_TYPE.TEXT,
        width: 160,
        systemField: true,
        editable: true,
        hidden: false,
        textAlign: 'right',
        uiVisible: true
      },
      {
        id: 'baseFairValue',
        key: 'baseFairValue',
        name: 'Base Fair Value',
        type: INPUT_TYPE.TEXT,
        width: 160,
        systemField: true,
        editable: true,
        hidden: false,
        textAlign: 'right',
        uiVisible: true
      },
      {
        id: 'allocationType',
        key: 'allocationType',
        name: 'Allocation Type',
        type: INPUT_TYPE.TEXT,
        width: 160,
        systemField: true,
        editable: true,
        hidden: false,
        uiVisible: true
      },
      {
        id: 'deliveryStatus',
        key: 'deliveryStatus',
        name: 'Delivery Status',
        type: INPUT_TYPE.TEXT,
        width: 160,
        systemField: true,
        editable: true,
        hidden: false,
        uiVisible: true
      }
    ],
    [DOC_TYPE.QUOTE]: [
      {
        id: 'product',
        key: 'product',
        name: 'Product',
        type: INPUT_TYPE.DROPDOWN,
        width: 156,
        systemField: true,
        editable: true,
        hidden: false,
        required: true,
        uiVisible: true,
        formatter: DocumentConfigUtility.productFormatterDocument,
        dropdownConfig: {
          title: 'Select Product',
          allowSearch: true,
          searchableKey: 'name',
          style: { minWidth: 230 },
          className: 'shadow-m width-auto',
          searchApiConfig: {
            getUrl: null,
            dataParser: DocumentConfigUtility.productDataParser,
            debounceTime: 300
          },
          data: [],
          renderer: DocumentConfigUtility.productRenderer,
          onSelect: (index: any, obj: any, rowIndex: any) => {},
          button: {
            title: '+ Add New',
            className: 'bg-button text-white',
            onClick: () => {}
          }
        }
      },
      {
        id: 'documentSequenceCode',
        key: 'documentSequenceCode',
        name: 'Product Code',
        type: INPUT_TYPE.TEXT,
        width: 160,
        systemField: true,
        editable: false,
        hidden: false,
        uiVisible: true
      },
      {
        id: 'description',
        key: 'productDescription',
        name: 'Description',
        type: INPUT_TYPE.TEXT,
        width: 160,
        systemField: true,
        editable: true,
        hidden: false,
        uiVisible: true
      },
      {
        id: 'quantity',
        key: 'productQuantity',
        name: 'Quantity',
        type: INPUT_TYPE.NUMBER,
        width: 130,
        systemField: true,
        editable: true,
        hidden: false,
        uiVisible: true,
        textAlign: 'right',
        renderer: (data: any) =>
          DocumentConfigUtility.quantityRenderer(data, true)
      },
      {
        id: 'availableQtyByUOM',
        key: 'availableQtyByUOM',
        name: 'Available Qty',
        type: INPUT_TYPE.NUMBER,
        width: 180,
        systemField: true,
        editable: false,
        hidden: false,
        uiVisible: true,
        textAlign: 'right',
        renderer: DocumentConfigUtility.quantityRenderer
      },
      {
        id: 'basePrice',
        key: 'basePrice',
        name: 'Base Price',
        type: INPUT_TYPE.NUMBER,
        width: 141,
        systemField: true,
        editable: true,
        hidden: false,
        uiVisible: true,
        textAlign: 'right',
        formatter: ({ value }: any) => value
      },
      {
        id: 'allocationType',
        key: 'allocationType',
        name: 'Allocation Type',
        type: INPUT_TYPE.TEXT,
        width: 141,
        systemField: true,
        editable: false,
        hidden: false,
        uiVisible: true,
        textAlign: 'right',
        formatter: ({ value }: any) => value
        // dropdownConfig: {
        //   title: '-',
        //   data: [],
        //   renderer: DocumentConfigUtility.unitPriceRenderer
        // }
      },
      {
        id: 'uom',
        key: 'uom',
        name: 'UOM',
        type: INPUT_TYPE.DROPDOWN,
        width: 78,
        systemField: true,
        editable: true,
        hidden: false,
        uiVisible: true,
        formatter: DocumentConfigUtility.uomFormatter,
        dropdownConfig: {
          title: 'Select UOM',
          allowSearch: true,
          searchableKey: 'name',
          style: { minWidth: 150 },
          className: 'shadow-m width-auto',
          data: [],
          renderer: DocumentConfigUtility.uomOptionRenderer,
          onSelect: (index: any, obj: any, rowIndex: any) => {}
        }
      },
      {
        id: 'price',
        key: 'unitPrice',
        name: 'Price',
        type: INPUT_TYPE.NUMBER,
        width: 141,
        systemField: true,
        editable: true,
        hidden: false,
        uiVisible: true,
        allowNegativeNumber: true,
        textAlign: 'right',
        formatter: ({ value }: any) => value,
        dropdownConfig: {
          title: 'Price History',
          data: [],
          renderer: DocumentConfigUtility.historicalUnitPriceRenderer
        }
      },
      {
        id: 'basePrice',
        key: 'basePrice',
        name: 'Base Price',
        type: INPUT_TYPE.NUMBER,
        width: 141,
        systemField: true,
        editable: true,
        hidden: false,
        uiVisible: true,
        textAlign: 'right',
        formatter: ({ value }: any) => value
      },
      {
        id: 'allocationType',
        key: 'allocationType',
        name: 'Allocation Type',
        type: INPUT_TYPE.TEXT,
        width: 141,
        systemField: true,
        editable: false,
        hidden: false,
        uiVisible: true,
        textAlign: 'right',
        formatter: ({ value }: any) => value
        // dropdownConfig: {
        //   title: '-',
        //   data: [],
        //   renderer: DocumentConfigUtility.unitPriceRenderer
        // }
      },
      {
        id: 'discount',
        key: 'discount',
        name: 'Discount',
        type: INPUT_TYPE.TEXT,
        width: 100,
        systemField: true,
        editable: true,
        hidden: false,
        uiVisible: true,
        textAlign: 'right'
      },
      {
        id: 'tax',
        key: 'tax',
        name: 'Tax',
        type: INPUT_TYPE.DROPDOWN,
        width: 100,
        systemField: true,
        editable: true,
        hidden: false,
        uiVisible: true,
        renderer: DocumentConfigUtility.taxRenderer,
        dropdownConfig: {
          title: 'Select Tax',
          allowSearch: true,
          searchableKey: 'name',
          style: { minWidth: 150 },
          className: 'shadow-m width-auto',
          searchApiConfig: {
            getUrl: null,
            dataParser: null,
            debounceTime: 300
          },
          data: [],
          renderer: DocumentConfigUtility.taxOptionRenderer,
          onSelect: (index: any, obj: any, rowIndex: any) => {},
          button: {
            title: '+ Add New',
            className: 'bg-button text-white',
            onClick: () => {}
          }
        }
      },
      {
        id: 'taxAmount',
        key: 'taxAmount',
        name: 'Tax',
        type: INPUT_TYPE.NUMBER,
        width: 100,
        systemField: true,
        editable: true,
        hidden: false,
        uiVisible: true,
        textAlign: 'right'
      },
      {
        id: 'amount',
        key: 'totalAmount',
        name: 'Amount',
        type: INPUT_TYPE.NUMBER,
        width: 150,
        systemField: true,
        editable: false,
        hidden: false,
        uiVisible: true,
        textAlign: 'right'
      }
    ],
    [DOC_TYPE.SALES_ORDER]: [
      {
        id: 'product',
        key: 'product',
        name: 'Product',
        type: INPUT_TYPE.DROPDOWN,
        width: 156,
        systemField: true,
        editable: true,
        hidden: false,
        required: true,
        uiVisible: true,
        formatter: DocumentConfigUtility.productFormatterDocument,
        dropdownConfig: {
          title: 'Select Product',
          allowSearch: true,
          searchableKey: 'name',
          style: { minWidth: 230 },
          className: 'shadow-m width-auto',
          searchApiConfig: {
            getUrl: null,
            dataParser: DocumentConfigUtility.productDataParser,
            debounceTime: 300
          },
          data: [],
          renderer: DocumentConfigUtility.productRenderer,
          onSelect: (index: any, obj: any, rowIndex: any) => {},
          button: {
            title: '+ Add New',
            className: 'bg-button text-white',
            onClick: () => {}
          }
        }
      },
      {
        id: 'documentSequenceCode',
        key: 'documentSequenceCode',
        name: 'Product Code',
        type: INPUT_TYPE.TEXT,
        width: 160,
        systemField: true,
        editable: false,
        hidden: false,
        uiVisible: true
      },
      {
        id: 'description',
        key: 'productDescription',
        name: 'Description',
        type: INPUT_TYPE.TEXT,
        width: 160,
        systemField: true,
        editable: true,
        hidden: false,
        uiVisible: true
      },
      {
        id: 'quantity',
        key: 'productQuantity',
        name: 'Quantity',
        type: INPUT_TYPE.NUMBER,
        width: 130,
        systemField: true,
        editable: true,
        hidden: false,
        uiVisible: true,
        textAlign: 'right',
        renderer: (data: any) =>
          DocumentConfigUtility.quantityRenderer(data, true)
      },
      {
        id: 'uom',
        key: 'uom',
        name: 'UOM',
        type: INPUT_TYPE.DROPDOWN,
        width: 78,
        systemField: true,
        editable: true,
        hidden: false,
        uiVisible: true,
        formatter: DocumentConfigUtility.uomFormatter,
        dropdownConfig: {
          title: 'Select UOM',
          allowSearch: true,
          searchableKey: 'name',
          style: { minWidth: 150 },
          className: 'shadow-m width-auto',
          data: [],
          renderer: DocumentConfigUtility.uomOptionRenderer,
          onSelect: (index: any, obj: any, rowIndex: any) => {}
        }
      },
      {
        id: 'price',
        key: 'unitPrice',
        name: 'Price',
        type: INPUT_TYPE.NUMBER,
        width: 141,
        systemField: true,
        editable: true,
        hidden: false,
        uiVisible: true,
        allowNegativeNumber: true,
        textAlign: 'right',
        formatter: ({ value }: any) => value,
        dropdownConfig: {
          title: 'Price History',
          data: [],
          renderer: DocumentConfigUtility.historicalUnitPriceRenderer
        }
      },
      {
        id: 'basePrice',
        key: 'basePrice',
        name: 'Base Price',
        type: INPUT_TYPE.NUMBER,
        width: 141,
        systemField: true,
        editable: true,
        hidden: false,
        uiVisible: true,
        textAlign: 'right',
        formatter: ({ value }: any) => value
      },
      {
        id: 'allocationType',
        key: 'allocationType',
        name: 'Allocation Type',
        type: INPUT_TYPE.TEXT,
        width: 141,
        systemField: true,
        editable: false,
        hidden: false,
        uiVisible: true,
        textAlign: 'right',
        formatter: ({ value }: any) => value
        // dropdownConfig: {
        //   title: '-',
        //   data: [],
        //   renderer: DocumentConfigUtility.unitPriceRenderer
        // }
      },
      {
        id: 'discount',
        key: 'discount',
        name: 'Discount',
        type: INPUT_TYPE.TEXT,
        width: 100,
        systemField: true,
        editable: true,
        hidden: false,
        uiVisible: true,
        textAlign: 'right'
      },
      {
        id: 'tax',
        key: 'tax',
        name: 'Tax',
        type: INPUT_TYPE.DROPDOWN,
        width: 100,
        systemField: true,
        editable: true,
        hidden: false,
        uiVisible: true,
        renderer: DocumentConfigUtility.taxRenderer,
        dropdownConfig: {
          title: 'Select Tax',
          allowSearch: true,
          searchableKey: 'name',
          style: { minWidth: 150 },
          className: 'shadow-m width-auto',
          searchApiConfig: {
            getUrl: null,
            dataParser: null,
            debounceTime: 300
          },
          data: [],
          renderer: DocumentConfigUtility.taxOptionRenderer,
          onSelect: (index: any, obj: any, rowIndex: any) => {},
          button: {
            title: '+ Add New',
            className: 'bg-button text-white',
            onClick: () => {}
          }
        }
      },
      {
        id: 'taxAmount',
        key: 'taxAmount',
        name: 'Tax',
        type: INPUT_TYPE.NUMBER,
        width: 100,
        systemField: true,
        editable: true,
        hidden: false,
        uiVisible: true,
        textAlign: 'right'
      },
      {
        id: 'amount',
        key: 'totalAmount',
        name: 'Amount',
        type: INPUT_TYPE.NUMBER,
        width: 150,
        systemField: true,
        editable: false,
        hidden: false,
        uiVisible: true,
        textAlign: 'right'
      },
      {
        id: 'qtyOrdered',
        key: 'qtyOrdered',
        name: 'Quantity Ordered',
        type: INPUT_TYPE.NUMBER,
        width: 150,
        systemField: true,
        editable: false,
        hidden: false,
        uiVisible: true
      },
      {
        id: 'availableQtyByUOM',
        key: 'availableQtyByUOM',
        name: 'Available Qty',
        type: INPUT_TYPE.NUMBER,
        width: 160,
        systemField: true,
        editable: false,
        hidden: false,
        uiVisible: true
      },
      {
        id: 'pickedQty',
        key: 'pickedQty',
        name: 'Picked Quantity',
        type: INPUT_TYPE.NUMBER,
        width: 160,
        systemField: true,
        editable: false,
        hidden: false,
        uiVisible: true
      },
      {
        id: 'packedQty',
        key: 'packedQty',
        name: 'Packed Quantity',
        type: INPUT_TYPE.NUMBER,
        width: 160,
        systemField: true,
        editable: false,
        hidden: false,
        uiVisible: true
      },
      {
        id: 'shippedFulfilledQty',
        key: 'shippedFulfilledQty',
        name: 'Shipped/Fulfilled Quantity',
        type: INPUT_TYPE.NUMBER,
        width: 190,
        systemField: true,
        editable: false,
        hidden: false,
        uiVisible: true
      },
      {
        id: 'backorderQty',
        key: 'backorderQty',
        name: 'Back Ordered Quantity',
        type: INPUT_TYPE.NUMBER,
        width: 180,
        systemField: true,
        editable: false,
        hidden: false,
        uiVisible: true
      },
      {
        id: 'invoicedQty',
        key: 'invoicedQty',
        name: 'Invoiced Quantity',
        type: INPUT_TYPE.NUMBER,
        width: 160,
        systemField: true,
        editable: false,
        hidden: false,
        uiVisible: true
      },
      {
        id: 'qtyConvertedToWo',
        key: 'qtyConvertedToWo',
        name: 'Work Order Quantity',
        type: INPUT_TYPE.NUMBER,
        width: 165,
        systemField: false,
        editable: false,
        hidden: false,
        uiVisible: true
      },
      {
        id: 'lineRefNo',
        key: 'lineRefNo',
        name: 'Line Ref Number',
        type: INPUT_TYPE.TEXT,
        width: 160,
        systemField: true,
        editable: false,
        hidden: false,
        uiVisible: true
      }
    ],
    [DOC_TYPE.BILL]: [
      {
        id: 'product',
        key: 'product',
        name: 'Product',
        type: INPUT_TYPE.DROPDOWN,
        width: 156,
        systemField: true,
        editable: true,
        hidden: false,
        required: true,
        uiVisible: true,
        formatter: DocumentConfigUtility.productFormatterDocument,
        dropdownConfig: {
          title: 'Select Product',
          allowSearch: true,
          searchableKey: 'name',
          style: { minWidth: 230 },
          className: 'shadow-m width-auto',
          searchApiConfig: {
            getUrl: null,
            dataParser: DocumentConfigUtility.productDataParser,
            debounceTime: 300
          },
          data: [],
          renderer: DocumentConfigUtility.productRenderer,
          onSelect: (index: any, obj: any, rowIndex: any) => {},
          button: {
            title: '+ Add New',
            className: 'bg-button text-white',
            onClick: () => {}
          }
        }
      },
      {
        id: 'documentSequenceCode',
        key: 'documentSequenceCode',
        name: 'Product Code',
        type: INPUT_TYPE.TEXT,
        width: 160,
        systemField: true,
        editable: false,
        hidden: false,
        uiVisible: true
      },
      {
        id: 'description',
        key: 'productDescription',
        name: 'Description',
        type: INPUT_TYPE.TEXT,
        width: 160,
        systemField: true,
        editable: true,
        hidden: false,
        uiVisible: true
      },
      {
        id: 'quantity',
        key: 'productQuantity',
        name: 'Quantity',
        type: INPUT_TYPE.NUMBER,
        width: 130,
        systemField: true,
        editable: true,
        hidden: false,
        uiVisible: true,
        textAlign: 'right',
        renderer: DocumentConfigUtility.quantityRenderer
      },
      {
        id: 'uom',
        key: 'uom',
        name: 'UOM',
        type: INPUT_TYPE.DROPDOWN,
        width: 78,
        systemField: true,
        editable: true,
        hidden: false,
        uiVisible: true,
        formatter: DocumentConfigUtility.uomFormatter,
        dropdownConfig: {
          title: 'Select UOM',
          allowSearch: true,
          searchableKey: 'name',
          style: { minWidth: 150 },
          className: 'shadow-m width-auto',
          data: [],
          renderer: DocumentConfigUtility.uomOptionRenderer,
          onSelect: (index: any, obj: any, rowIndex: any) => {}
        }
      },
      {
        id: 'price',
        key: 'unitPrice',
        name: 'Price',
        type: INPUT_TYPE.NUMBER,
        width: 141,
        systemField: true,
        editable: true,
        allowNegativeNumber: true,
        hidden: false,
        uiVisible: true,
        textAlign: 'right',
        formatter: ({ value }: any) => value,
        dropdownConfig: {
          title: 'Price History',
          data: [],
          renderer: DocumentConfigUtility.historicalUnitPriceRenderer
        }
      },
      {
        id: 'discount',
        key: 'discount',
        name: 'Discount',
        type: INPUT_TYPE.TEXT,
        width: 100,
        systemField: true,
        editable: true,
        hidden: false,
        uiVisible: true,
        textAlign: 'right'
      },
      {
        id: 'tax',
        key: 'tax',
        name: 'Tax',
        type: INPUT_TYPE.DROPDOWN,
        width: 100,
        systemField: true,
        editable: true,
        hidden: false,
        uiVisible: true,
        renderer: DocumentConfigUtility.taxRenderer,
        dropdownConfig: {
          title: 'Select Tax',
          allowSearch: true,
          searchableKey: 'name',
          style: { minWidth: 150 },
          className: 'shadow-m width-auto',
          searchApiConfig: {
            getUrl: null,
            dataParser: null,
            debounceTime: 300
          },
          data: [],
          renderer: DocumentConfigUtility.taxOptionRenderer,
          onSelect: (index: any, obj: any, rowIndex: any) => {},
          button: {
            title: '+ Add New',
            className: 'bg-button text-white',
            onClick: () => {}
          }
        }
      },
      {
        id: 'taxAmount',
        key: 'taxAmount',
        name: 'Tax',
        type: INPUT_TYPE.NUMBER,
        width: 100,
        systemField: true,
        editable: true,
        hidden: false,
        uiVisible: true,
        textAlign: 'right'
      },
      {
        id: 'amount',
        key: 'totalAmount',
        name: 'Amount',
        type: INPUT_TYPE.NUMBER,
        width: 150,
        systemField: true,
        editable: false,
        hidden: false,
        uiVisible: true,
        textAlign: 'right'
      },
      {
        id: 'lineRefNo',
        key: 'lineRefNo',
        name: 'Line Ref Number',
        type: INPUT_TYPE.TEXT,
        width: 160,
        systemField: true,
        editable: false,
        hidden: false,
        uiVisible: true
      }
    ],
    [DOC_TYPE.ORDER]: [
      {
        id: 'product',
        key: 'product',
        name: 'Product',
        type: INPUT_TYPE.DROPDOWN,
        width: 156,
        systemField: true,
        editable: true,
        hidden: false,
        required: true,
        uiVisible: true,
        formatter: DocumentConfigUtility.productFormatterDocument,
        dropdownConfig: {
          title: 'Select Product',
          allowSearch: true,
          searchableKey: 'name',
          style: { minWidth: 230 },
          className: 'shadow-m width-auto',
          searchApiConfig: {
            getUrl: null,
            dataParser: DocumentConfigUtility.productDataParser,
            debounceTime: 300
          },
          data: [],
          renderer: DocumentConfigUtility.productRenderer,
          onSelect: (index: any, obj: any, rowIndex: any) => {},
          button: {
            title: '+ Add New',
            className: 'bg-button text-white',
            onClick: () => {}
          }
        }
      },
      {
        id: 'documentSequenceCode',
        key: 'documentSequenceCode',
        name: 'Product Code',
        type: INPUT_TYPE.TEXT,
        width: 160,
        systemField: true,
        editable: false,
        hidden: false,
        uiVisible: true
      },
      {
        id: 'description',
        key: 'productDescription',
        name: 'Description',
        type: INPUT_TYPE.TEXT,
        width: 160,
        systemField: true,
        editable: true,
        hidden: false,
        uiVisible: true
      },
      {
        id: 'quantity',
        key: 'productQuantity',
        name: 'Quantity',
        type: INPUT_TYPE.NUMBER,
        width: 130,
        systemField: true,
        editable: true,
        hidden: false,
        uiVisible: true,
        textAlign: 'right',
        renderer: DocumentConfigUtility.quantityRenderer
      },
      {
        id: 'receivedQuantity',
        key: 'receivedQuantity',
        name: 'Received Quantity',
        type: INPUT_TYPE.NUMBER,
        width: 110,
        systemField: true,
        editable: false,
        hidden: false,
        uiVisible: true,
        textAlign: 'right',
        renderer: DocumentConfigUtility.receivedAndBilledQtyRenderer
      },
      {
        id: 'balanceReceivedQuantity',
        key: 'balanceReceivedQuantity',
        name: 'Balance Received Quantity',
        type: INPUT_TYPE.NUMBER,
        width: 110,
        systemField: true,
        editable: false,
        hidden: false,
        uiVisible: true,
        textAlign: 'right',
        renderer: DocumentConfigUtility.receivedAndBilledQtyRenderer
      },
      {
        id: 'billedQuantity',
        key: 'billedQuantity',
        name: 'Billed Quantity',
        type: INPUT_TYPE.NUMBER,
        width: 110,
        systemField: true,
        editable: false,
        hidden: false,
        uiVisible: true,
        textAlign: 'right',
        renderer: DocumentConfigUtility.receivedAndBilledQtyRenderer
      },
      {
        id: 'balanceBilledQuantity',
        key: 'balanceBilledQuantity',
        name: 'Balance Billed Quantity',
        type: INPUT_TYPE.NUMBER,
        width: 110,
        systemField: true,
        editable: false,
        hidden: false,
        uiVisible: true,
        textAlign: 'right',
        renderer: DocumentConfigUtility.receivedAndBilledQtyRenderer
      },
      {
        id: 'uom',
        key: 'uom',
        name: 'UOM',
        type: INPUT_TYPE.DROPDOWN,
        width: 78,
        systemField: true,
        editable: true,
        hidden: false,
        uiVisible: true,
        formatter: DocumentConfigUtility.uomFormatter,
        dropdownConfig: {
          title: 'Select UOM',
          allowSearch: true,
          searchableKey: 'name',
          style: { minWidth: 150 },
          className: 'shadow-m width-auto',
          data: [],
          renderer: DocumentConfigUtility.uomOptionRenderer,
          onSelect: (index: any, obj: any, rowIndex: any) => {}
        }
      },
      {
        id: 'price',
        key: 'unitPrice',
        name: 'Price',
        type: INPUT_TYPE.NUMBER,
        width: 141,
        systemField: true,
        editable: true,
        hidden: false,
        allowNegativeNumber: true,
        uiVisible: true,
        textAlign: 'right',
        formatter: ({ value }: any) => value,
        dropdownConfig: {
          title: 'Price History',
          data: [],
          renderer: DocumentConfigUtility.historicalUnitPriceRenderer
        }
      },
      {
        id: 'discount',
        key: 'discount',
        name: 'Discount',
        type: INPUT_TYPE.TEXT,
        width: 100,
        systemField: true,
        editable: true,
        hidden: false,
        uiVisible: true,
        textAlign: 'right'
      },
      {
        id: 'tax',
        key: 'tax',
        name: 'Tax',
        type: INPUT_TYPE.DROPDOWN,
        width: 100,
        systemField: true,
        editable: true,
        hidden: false,
        uiVisible: true,
        renderer: DocumentConfigUtility.taxRenderer,
        dropdownConfig: {
          title: 'Select Tax',
          allowSearch: true,
          searchableKey: 'name',
          style: { minWidth: 150 },
          className: 'shadow-m width-auto',
          searchApiConfig: {
            getUrl: null,
            dataParser: null,
            debounceTime: 300
          },
          data: [],
          renderer: DocumentConfigUtility.taxOptionRenderer,
          onSelect: (index: any, obj: any, rowIndex: any) => {},
          button: {
            title: '+ Add New',
            className: 'bg-button text-white',
            onClick: () => {}
          }
        }
      },
      {
        id: 'taxAmount',
        key: 'taxAmount',
        name: 'Tax',
        type: INPUT_TYPE.NUMBER,
        width: 100,
        systemField: true,
        editable: true,
        hidden: false,
        uiVisible: true,
        textAlign: 'right'
      },
      {
        id: 'amount',
        key: 'totalAmount',
        name: 'Amount',
        type: INPUT_TYPE.NUMBER,
        width: 150,
        systemField: true,
        editable: false,
        hidden: false,
        uiVisible: true,
        textAlign: 'right'
      }
    ],
    [DOC_TYPE.FA_BILL]: [
      {
        id: 'fixedAssetGroup',
        key: 'fixedAssetGroup',
        name: 'Fixed Asset Group',
        type: INPUT_TYPE.DROPDOWN,
        width: 200,
        systemField: true,
        editable: true,
        hidden: false,
        required: true,
        uiVisible: true,
        formatter: DocumentConfigUtility.productFormatterDocument,
        dropdownConfig: {
          title: 'Select Fixed Asset Group',
          allowSearch: true,
          searchableKey: 'name',
          style: { minWidth: 230 },
          className: 'shadow-m width-auto',
          searchApiConfig: {
            getUrl: null,
            dataParser: DocumentConfigUtility.fixedAssetGroupDataParser,
            debounceTime: 300
          },
          data: [],
          renderer: DocumentConfigUtility.fixedAssetGroupRenderer,
          onSelect: (index: any, obj: any, rowIndex: any) => {},
          button: {}
        }
      },
      {
        id: 'quantity',
        key: 'productQuantity',
        name: 'Quantity',
        type: INPUT_TYPE.NUMBER,
        width: 130,
        systemField: true,
        editable: true,
        hidden: false,
        uiVisible: true,
        textAlign: 'right',
        renderer: DocumentConfigUtility.quantityRenderer
      },
      {
        id: 'price',
        key: 'unitPrice',
        name: 'Price',
        type: INPUT_TYPE.NUMBER,
        width: 141,
        systemField: true,
        editable: true,
        hidden: false,
        uiVisible: true,
        allowNegativeNumber: true,
        textAlign: 'right',
        formatter: ({ value }: any) => value,
        dropdownConfig: {
          title: 'Price History',
          data: [],
          renderer: DocumentConfigUtility.historicalUnitPriceRenderer
        }
      },
      {
        id: 'discount',
        key: 'discount',
        name: 'Discount',
        type: INPUT_TYPE.TEXT,
        width: 100,
        systemField: true,
        editable: true,
        hidden: false,
        uiVisible: true,
        textAlign: 'right'
      },
      {
        id: 'tax',
        key: 'tax',
        name: 'Tax',
        type: INPUT_TYPE.DROPDOWN,
        width: 100,
        systemField: true,
        editable: true,
        hidden: false,
        uiVisible: true,
        renderer: DocumentConfigUtility.faTaxRenderer,
        dropdownConfig: {
          title: 'Select Tax',
          allowSearch: true,
          searchableKey: 'name',
          style: { minWidth: 150 },
          className: 'shadow-m width-auto',
          searchApiConfig: {
            getUrl: null,
            dataParser: null,
            debounceTime: 300
          },
          data: [],
          renderer: DocumentConfigUtility.taxOptionRenderer,
          onSelect: (index: any, obj: any, rowIndex: any) => {},
          button: {
            title: '+ Add New',
            className: 'bg-button text-white',
            onClick: () => {}
          }
        }
      },
      {
        id: 'taxAmount',
        key: 'taxAmount',
        name: 'Tax',
        type: INPUT_TYPE.NUMBER,
        width: 100,
        systemField: true,
        editable: true,
        hidden: false,
        uiVisible: true,
        textAlign: 'right'
      },
      {
        id: 'amount',
        key: 'totalAmount',
        name: 'Amount',
        type: INPUT_TYPE.NUMBER,
        width: 150,
        systemField: true,
        editable: false,
        hidden: false,
        uiVisible: true,
        textAlign: 'right'
      }
    ],
    [DOC_TYPE.FA_ORDER]: [
      {
        id: 'fixedAssetGroup',
        key: 'fixedAssetGroup',
        name: 'Fixed Asset Group',
        type: INPUT_TYPE.DROPDOWN,
        width: 194,
        systemField: true,
        editable: true,
        hidden: false,
        required: true,
        uiVisible: true,
        formatter: DocumentConfigUtility.productFormatterDocument,
        dropdownConfig: {
          title: 'Select Fixed Asset Group',
          allowSearch: true,
          searchableKey: 'name',
          style: { minWidth: 230 },
          className: 'shadow-m width-auto',
          searchApiConfig: {
            getUrl: null,
            dataParser: DocumentConfigUtility.fixedAssetGroupDataParser,
            debounceTime: 300
          },
          data: [],
          renderer: DocumentConfigUtility.fixedAssetGroupRenderer,
          onSelect: (index: any, obj: any, rowIndex: any) => {},
          button: {}
        }
      },
      {
        id: 'quantity',
        key: 'productQuantity',
        name: 'Quantity',
        type: INPUT_TYPE.NUMBER,
        width: 118,
        systemField: true,
        editable: true,
        hidden: false,
        uiVisible: true,
        textAlign: 'right',
        renderer: DocumentConfigUtility.quantityRenderer
      },
      {
        id: 'price',
        key: 'unitPrice',
        name: 'Price',
        type: INPUT_TYPE.NUMBER,
        width: 180,
        systemField: true,
        editable: true,
        hidden: false,
        uiVisible: true,
        textAlign: 'right',
        allowNegativeNumber: true,
        formatter: ({ value }: any) => value,
        dropdownConfig: {
          title: 'Price History',
          data: [],
          renderer: DocumentConfigUtility.historicalUnitPriceRenderer
        }
      },
      {
        id: 'discount',
        key: 'discount',
        name: 'Discount',
        type: INPUT_TYPE.TEXT,
        width: 110,
        systemField: true,
        editable: true,
        hidden: false,
        uiVisible: true,
        textAlign: 'right'
      },
      {
        id: 'tax',
        key: 'tax',
        name: 'Tax',
        type: INPUT_TYPE.DROPDOWN,
        width: 150,
        systemField: true,
        editable: true,
        hidden: false,
        uiVisible: true,
        renderer: DocumentConfigUtility.faTaxRenderer,
        dropdownConfig: {
          title: 'Select Tax',
          allowSearch: true,
          searchableKey: 'name',
          style: { minWidth: 150 },
          className: 'shadow-m width-auto',
          searchApiConfig: {
            getUrl: null,
            dataParser: null,
            debounceTime: 300
          },
          data: [],
          renderer: DocumentConfigUtility.taxOptionRenderer,
          onSelect: (index: any, obj: any, rowIndex: any) => {},
          button: {
            title: '+ Add New',
            className: 'bg-button text-white',
            onClick: () => {}
          }
        }
      },
      {
        id: 'taxAmount',
        key: 'taxAmount',
        name: 'Tax',
        type: INPUT_TYPE.NUMBER,
        width: 100,
        systemField: true,
        editable: true,
        hidden: false,
        uiVisible: true,
        textAlign: 'right'
      },
      {
        id: 'amount',
        key: 'totalAmount',
        name: 'Amount',
        type: INPUT_TYPE.NUMBER,
        width: 180,
        systemField: true,
        editable: false,
        hidden: false,
        uiVisible: true,
        textAlign: 'right'
      }
    ],
    [DOC_TYPE.JOB_WORK_OUT_ORDER]: [
      {
        id: 'product',
        key: 'product',
        name: 'Product',
        type: INPUT_TYPE.DROPDOWN,
        width: 210,
        systemField: true,
        editable: true,
        hidden: false,
        required: true,
        uiVisible: true,
        formatter: DocumentConfigUtility.productFormatterDocument,
        dropdownConfig: {
          title: 'Select Product',
          allowSearch: true,
          searchableKey: 'name',
          style: { minWidth: 230 },
          className: 'shadow-m width-auto',
          searchApiConfig: {
            getUrl: null,
            dataParser: DocumentConfigUtility.productDataParser,
            debounceTime: 300
          },
          data: [],
          renderer: DocumentConfigUtility.productRenderer,
          onSelect: (index: any, obj: any, rowIndex: any) => {},
          button: {
            title: '+ Add New',
            className: 'bg-button text-white',
            onClick: () => {}
          }
        }
      },
      {
        id: 'description',
        key: 'productDescription',
        name: 'Description',
        type: INPUT_TYPE.TEXT,
        width: 200,
        systemField: true,
        editable: true,
        hidden: false,
        uiVisible: true
      },
      {
        id: 'quantity',
        key: 'productQuantity',
        name: 'Quantity',
        type: INPUT_TYPE.NUMBER,
        width: 110,
        systemField: true,
        editable: true,
        hidden: false,
        uiVisible: true,
        textAlign: 'right',
        renderer: DocumentConfigUtility.quantityRenderer
      },
      {
        id: 'uom',
        key: 'uom',
        name: 'UOM',
        type: INPUT_TYPE.DROPDOWN,
        width: 78,
        systemField: true,
        editable: true,
        hidden: false,
        uiVisible: true,
        formatter: DocumentConfigUtility.uomFormatter,
        dropdownConfig: {
          title: 'Select UOM',
          allowSearch: true,
          searchableKey: 'name',
          style: { minWidth: 150 },
          className: 'shadow-m width-auto',
          data: [],
          renderer: DocumentConfigUtility.uomOptionRenderer,
          onSelect: (index: any, obj: any, rowIndex: any) => {}
        }
      },
      {
        id: 'price',
        key: 'unitPrice',
        name: 'Price',
        type: INPUT_TYPE.NUMBER,
        width: 141,
        systemField: true,
        editable: true,
        hidden: false,
        uiVisible: true,
        textAlign: 'right',
        formatter: ({ value }: any) => value,
        dropdownConfig: {
          title: 'Price History',
          data: [],
          renderer: DocumentConfigUtility.historicalUnitPriceRenderer
        }
      },
      {
        id: 'discount',
        key: 'discount',
        name: 'Discount',
        type: INPUT_TYPE.TEXT,
        width: 100,
        systemField: true,
        editable: true,
        hidden: false,
        uiVisible: true,
        textAlign: 'right'
      },
      {
        id: 'amount',
        key: 'totalAmount',
        name: 'Amount',
        type: INPUT_TYPE.NUMBER,
        width: 150,
        systemField: true,
        editable: false,
        hidden: false,
        uiVisible: true,
        textAlign: 'right'
      }
    ],
    [DOC_TYPE.EXPENSE_BILL]: [
      {
        id: 'account',
        key: 'account',
        name: 'Category',
        type: INPUT_TYPE.DROPDOWN,
        width: 274,
        systemField: true,
        editable: true,
        hidden: false,
        required: true,
        uiVisible: true,
        formatter: (obj: any) => {
          return obj.value.name;
        },
        dropdownConfig: {
          title: 'Select Account',
          allowSearch: true,
          searchableKey: 'name',
          style: { minWidth: 268 },
          className: 'shadow-m width-auto',
          searchApiConfig: {
            getUrl: null,
            dataParser: DocumentConfigUtility.productDataParser,
            debounceTime: 300
          },
          data: [],
          renderer: DocumentConfigUtility.productRenderer,
          onSelect: (index: any, obj: any, rowIndex: any) => {},
          button: {
            title: '+ Add New',
            className: 'bg-button text-white',
            onClick: () => {}
          }
        }
      },
      {
        id: 'description',
        key: 'description',
        name: 'Description',
        type: INPUT_TYPE.TEXT,
        width: 160,
        systemField: true,
        editable: true,
        hidden: false,
        uiVisible: true
      },
      {
        id: 'amount',
        key: 'amount',
        name: 'Amount',
        type: INPUT_TYPE.NUMBER,
        width: 150,
        systemField: true,
        editable: true,
        allowNegativeNumber: true,
        hidden: false,
        uiVisible: true,
        textAlign: 'right'
      },
      {
        id: 'tax',
        key: 'tax',
        name: 'Tax',
        type: INPUT_TYPE.DROPDOWN,
        width: 100,
        systemField: true,
        editable: true,
        hidden: false,
        uiVisible: true,
        textAlign: 'right',
        formatter: (obj: any) => {
          return obj.value.name;
        },
        dropdownConfig: {
          title: 'Select Tax',
          allowSearch: true,
          searchableKey: 'name',
          style: { minWidth: 150 },
          className: 'shadow-m width-auto',
          searchApiConfig: {
            getUrl: null,
            dataParser: null,
            debounceTime: 300
          },
          data: [],
          renderer: DocumentConfigUtility.taxOptionRenderer,
          onSelect: (index: any, obj: any, rowIndex: any) => {},
          button: {
            title: '+ Add New',
            className: 'bg-button text-white',
            onClick: () => {}
          }
        }
      },
      {
        id: 'taxAmount',
        key: 'taxAmount',
        name: 'Tax Amount',
        type: INPUT_TYPE.NUMBER,
        width: 130,
        systemField: true,
        editable: false,
        hidden: false,
        uiVisible: true,
        textAlign: 'right'
      },
      {
        id: 'totalAmount',
        key: 'totalAmount',
        name: 'Total Amount',
        type: INPUT_TYPE.NUMBER,
        width: 155,
        systemField: true,
        editable: false,
        hidden: false,
        uiVisible: true,
        textAlign: 'right'
      }
    ],
    [DOC_TYPE.STOCK_ADJUSTMENT]: [
      {
        id: 'product',
        key: 'product',
        name: 'Product',
        type: INPUT_TYPE.DROPDOWN,
        width: 165,
        systemField: true,
        editable: true,
        hidden: false,
        required: true,
        uiVisible: true,
        formatter: DocumentConfigUtility.productFormatterDocument,
        dropdownConfig: {
          title: 'Select Product',
          allowSearch: true,
          searchableKey: 'name',
          style: { minWidth: 230 },
          className: 'shadow-m width-auto',
          searchApiConfig: {
            getUrl: null,
            dataParser: DocumentConfigUtility.productDataParser,
            debounceTime: 300
          },
          data: [],
          renderer: DocumentConfigUtility.productRenderer,
          onSelect: (index: any, obj: any, rowIndex: any) => {},
          button: {
            title: '+ Add New',
            className: 'bg-button text-white',
            onClick: () => {}
          }
        }
      },
      {
        id: 'quantity',
        key: 'productQuantity',
        name: 'Quantity',
        type: INPUT_TYPE.NUMBER,
        width: 130,
        systemField: true,
        editable: true,
        hidden: false,
        uiVisible: true,
        textAlign: 'right',
        renderer: DocumentConfigUtility.quantityRenderer
      },
      {
        id: 'uom',
        key: 'uom',
        name: 'UOM',
        type: INPUT_TYPE.DROPDOWN,
        width: 78,
        systemField: true,
        editable: true,
        hidden: false,
        uiVisible: true,
        formatter: DocumentConfigUtility.uomFormatter,
        dropdownConfig: {
          title: 'Select UOM',
          allowSearch: true,
          searchableKey: 'name',
          style: { minWidth: 150 },
          className: 'shadow-m width-auto',
          data: [],
          renderer: DocumentConfigUtility.uomOptionRenderer,
          onSelect: (index: any, obj: any, rowIndex: any) => {}
        }
      },
      {
        id: 'price',
        key: 'unitPrice',
        name: 'Price',
        type: INPUT_TYPE.NUMBER,
        width: 141,
        systemField: true,
        editable: true,
        hidden: false,
        uiVisible: true,
        textAlign: 'right',
        formatter: ({ value }: any) => value,
        dropdownConfig: {
          title: 'Price History',
          data: [],
          renderer: DocumentConfigUtility.historicalUnitPriceRenderer
        }
      },
      {
        id: 'amount',
        key: 'totalAmount',
        name: 'Amount',
        type: INPUT_TYPE.NUMBER,
        width: 150,
        systemField: true,
        editable: false,
        hidden: false,
        uiVisible: true,
        textAlign: 'right'
      }
    ],
    [DOC_TYPE.AMORTIZATION_SCHEDULE]: [
      {
        id: 'targetAccountName',
        key: 'targetAccountName',
        name: 'Account',
        type: INPUT_TYPE.TEXT,
        width: 160,
        systemField: true,
        editable: false,
        hidden: false,
        uiVisible: true
      },
      {
        id: 'postingPeriod',
        key: 'postingPeriod',
        name: 'Posting Period',
        type: INPUT_TYPE.DROPDOWN,
        width: 140,
        systemField: true,
        editable: true,
        hidden: false,
        uiVisible: true,
        formatter: ({ value }: any) => value,
        dropdownConfig: {
          title: 'Select',
          allowSearch: true,
          data: [],
          onSelect: (index: any, obj: any, rowIndex: any) => {},
          renderer: (index: any, obj: any) => {
            return obj;
          }
        }
      },
      {
        id: 'isRecognized',
        key: 'isRecognized',
        name: 'Is Recognized',
        type: INPUT_TYPE.TEXT,
        width: 140,
        systemField: true,
        editable: false,
        hidden: false,
        uiVisible: true
      },
      {
        id: 'dateExecuted',
        key: 'dateExecuted',
        name: 'Date Executed',
        type: INPUT_TYPE.DATE,
        width: 160,
        systemField: true,
        editable: false,
        hidden: false,
        uiVisible: true
      },
      {
        id: 'jeCode',
        key: 'jeCode',
        name: 'Journal',
        type: INPUT_TYPE.TEXT,
        width: 160,
        systemField: true,
        editable: false,
        hidden: false,
        uiVisible: true
      },
      {
        id: 'amount',
        key: 'amount',
        name: 'Amount',
        type: INPUT_TYPE.TEXT,
        width: 160,
        systemField: true,
        editable: true,
        hidden: false,
        uiVisible: true
      },
      {
        id: 'totalAmortize',
        key: 'totalAmortize',
        name: 'Total Amortized',
        type: INPUT_TYPE.TEXT,
        width: 160,
        systemField: true,
        editable: false,
        hidden: false,
        uiVisible: true
      },
      {
        id: 'jeStatus',
        key: 'jeStatus',
        name: 'JE Status',
        type: INPUT_TYPE.TEXT,
        width: 160,
        systemField: true,
        editable: false,
        hidden: false,
        uiVisible: true
      }
    ],
    [DOC_TYPE.ASSET_SCHEDULE_AD_HOC]: [
      {
        name: 'Start Date',
        type: INPUT_TYPE.DATE,
        index: 0,
        key: MACHINE_SCHEDULE_ADHOC_TABLE.START_DATE,
        options: [],
        required: true,
        width: 150,
        editable: false,
        hidden: false,
        uiVisible: true,
        allowColumnEdit: false,
        allowColumnSort: true,
        formatter: null,
        allowFilter: true,
        dropdownConfig: null,
        allowAddOption: false,
        freezed: false
      },
      {
        name: 'End Date',
        type: INPUT_TYPE.DATE,
        index: 1,
        key: MACHINE_SCHEDULE_ADHOC_TABLE.END_DATE,
        options: [],
        required: true,
        width: 150,
        editable: false,
        hidden: false,
        uiVisible: true,
        allowColumnEdit: false,
        allowColumnSort: true,
        formatter: null,
        allowFilter: true,
        dropdownConfig: null,
        allowAddOption: false,
        freezed: false
      },
      {
        name: 'Actual Start Date',
        type: INPUT_TYPE.DATE,
        index: 2,
        key: MACHINE_SCHEDULE_ADHOC_TABLE.ACTUAL_START_DATE,
        options: [],
        required: true,
        width: 180,
        editable: true,
        hidden: false,
        uiVisible: true,
        allowColumnEdit: false,
        allowColumnSort: true,
        formatter: null,
        allowFilter: true,
        dropdownConfig: null,
        allowAddOption: false,
        freezed: false
      },
      {
        name: 'Actual End Date',
        type: IColumnType.DATE,
        index: 3,
        key: MACHINE_SCHEDULE_ADHOC_TABLE.ACTUAL_END_DATE,
        options: [],
        required: false,
        width: 180,
        editable: true,
        hidden: false,
        uiVisible: true,
        allowColumnEdit: false,
        allowColumnSort: true,
        formatter: null,
        allowFilter: true,
        dropdownConfig: null,
        allowAddOption: false,
        freezed: false
      },
      {
        name: 'Asset Id',
        type: IColumnType.TEXT,
        index: 4,
        key: MACHINE_SCHEDULE_ADHOC_TABLE.ASSET_ID,
        options: [],
        required: false,
        width: 120,
        editable: false,
        hidden: false,
        uiVisible: true,
        allowColumnEdit: false,
        allowColumnSort: true,
        formatter: null,
        allowFilter: true,
        dropdownConfig: null,
        allowAddOption: false,
        freezed: false
      },
      {
        name: 'Schedule Name',
        type: IColumnType.TEXT,
        index: 5,
        key: MACHINE_SCHEDULE_ADHOC_TABLE.SCHEDULE_NAME,
        options: [],
        required: false,
        width: 200,
        editable: false,
        hidden: false,
        uiVisible: true,
        allowColumnEdit: false,
        allowColumnSort: true,
        formatter: null,
        allowFilter: true,
        dropdownConfig: null,
        allowAddOption: false,
        freezed: false
      },
      {
        name: 'Assigned To',
        type: IColumnType.SELECT,
        index: 7,
        key: MACHINE_SCHEDULE_ADHOC_TABLE.ASSIGNED_TO_NAME,
        options: [],
        required: false,
        width: 150,
        editable: false,
        hidden: false,
        uiVisible: true,
        allowColumnEdit: false,
        allowColumnSort: true,
        formatter: null,
        allowFilter: true,
        dropdownConfig: null,
        allowAddOption: false,
        freezed: false
      },
      {
        name: 'Status',
        type: IColumnType.SELECT,
        index: 8,
        key: MACHINE_SCHEDULE_ADHOC_TABLE.STATUS_NAME,
        options: [],
        required: false,
        width: 120,
        editable: false,
        hidden: false,
        uiVisible: true,
        allowColumnEdit: false,
        allowColumnSort: true,
        formatter: null,
        allowFilter: true,
        dropdownConfig: null,
        allowAddOption: false,
        freezed: false
      },
      {
        name: 'Material Consumption Id',
        type: IColumnType.NUMBER,
        index: 12,
        key: MACHINE_SCHEDULE_ADHOC_TABLE.MATERIAL_CONSUMPTION_ID,
        options: [],
        required: false,
        width: 250,
        editable: false,
        hidden: false,
        uiVisible: true,
        allowColumnEdit: false,
        allowColumnSort: true,
        formatter: null,
        allowFilter: true,
        dropdownConfig: null,
        allowAddOption: false,
        freezed: false
      },
      {
        name: 'Attachments',
        type: IColumnType.FILE,
        index: 13,
        key: MACHINE_SCHEDULE_ADHOC_TABLE.ATTACHMENTS,
        options: [],
        required: false,
        width: 180,
        editable: false,
        hidden: false,
        uiVisible: true,
        allowColumnEdit: false,
        allowColumnSort: true,
        formatter: null,
        allowFilter: true,
        dropdownConfig: null,
        allowAddOption: false,
        freezed: false
      },
      {
        name: 'View Material Consumption',
        type: IColumnType.FILE,
        index: 14,
        key: MACHINE_SCHEDULE_ADHOC_TABLE.MATERIAL_CONSUMPTION,
        options: [],
        required: false,
        width: 300,
        editable: false,
        hidden: false,
        uiVisible: true,
        allowColumnEdit: false,
        allowColumnSort: true,
        formatter: null,
        allowFilter: false,
        dropdownConfig: null,
        allowAddOption: false,
        freezed: false
      },
      {
        name: 'Releated Transactions',
        type: IColumnType.FILE,
        index: 15,
        key: MACHINE_SCHEDULE_ADHOC_TABLE.RELATED_TRANSACTIONS,
        options: [],
        required: false,
        width: 300,
        editable: false,
        hidden: false,
        uiVisible: true,
        allowColumnEdit: false,
        allowColumnSort: false,
        formatter: null,
        allowFilter: false,
        dropdownConfig: null,
        allowAddOption: false,
        freezed: false
      }
    ],
    [DOC_TYPE.MACHINE_MAINTENANCE_SCHEDULE]: [
      {
        name: 'Asset Id',
        type: IColumnType.Text,
        index: 0,
        options: [],
        required: false,
        width: 210,
        editable: false,
        columnCode: 'assetId',
        hidden: false,
        uiVisible: true,
        allowColumnEdit: false,
        allowColumnSort: true,
        formatter: null,
        allowFilter: true,
        dropdownConfig: null,
        allowAddOption: false,
        freezed: false,
        key: 'assetId'
      },
      {
        name: 'Description',
        type: IColumnType.Text,
        index: 1,
        options: [],
        required: false,
        width: 210,
        editable: false,
        columnCode: 'description',
        key: 'description',
        hidden: false,
        uiVisible: true,
        allowColumnEdit: false,
        allowColumnSort: true,
        formatter: null,
        allowFilter: true,
        dropdownConfig: null,
        allowAddOption: false,
        freezed: false
      },
      {
        name: 'Location',
        type: IColumnType.SELECT,
        index: 2,
        options: [],
        required: false,
        width: 210,
        editable: false,
        columnCode: 'location',
        key: 'location',
        hidden: false,
        uiVisible: true,
        allowColumnEdit: false,
        allowColumnSort: true,
        formatter: null,
        allowFilter: true,
        dropdownConfig: null,
        allowAddOption: false,
        freezed: false
      },
      {
        name: 'Warehouse',
        type: IColumnType.SELECT,
        index: 3,
        options: [],
        required: false,
        width: 210,
        editable: false,
        columnCode: 'warehouse',
        key: 'warehouse',
        hidden: false,
        uiVisible: true,
        allowColumnEdit: false,
        allowColumnSort: true,
        formatter: null,
        allowFilter: true,
        dropdownConfig: null,
        allowAddOption: false,
        freezed: false
      },
      {
        name: 'Serial No',
        type: IColumnType.NUMBER,
        index: 9,
        options: [],
        required: false,
        width: 210,
        editable: false,
        columnCode: 'serialNumber',
        key: 'serialNumber',
        hidden: false,
        uiVisible: true,
        allowColumnEdit: false,
        allowColumnSort: true,
        formatter: null,
        allowFilter: true,
        dropdownConfig: null,
        allowAddOption: false,
        freezed: false
      },
      {
        name: 'Asset Group',
        type: IColumnType.SELECT,
        index: 19,
        options: [],
        required: false,
        width: 210,
        editable: false,
        columnCode: 'assetGroup',
        key: 'assetGroup',
        hidden: false,
        uiVisible: true,
        allowColumnEdit: false,
        allowColumnSort: true,
        formatter: null,
        allowFilter: true,
        dropdownConfig: null,
        allowAddOption: false,
        freezed: false
      },
      {
        name: 'Asset Life',
        type: IColumnType.Number,
        index: 21,
        options: [],
        required: false,
        width: 210,
        editable: false,
        columnCode: 'assetLife',
        key: 'assetLife',
        hidden: false,
        uiVisible: true,
        allowColumnEdit: false,
        allowColumnSort: true,
        formatter: null,
        allowFilter: true,
        dropdownConfig: null,
        allowAddOption: false,
        freezed: false
      },
      {
        name: 'Warranty Exp. From Date',
        type: IColumnType.Date,
        index: 10,
        options: [],
        required: false,
        width: 210,
        editable: false,
        columnCode: 'warrantyExpFromDate',
        key: 'warrantyExpFromDate',
        hidden: false,
        uiVisible: true,
        allowColumnEdit: false,
        allowColumnSort: true,
        formatter: null,
        allowFilter: true,
        dropdownConfig: null,
        allowAddOption: false,
        freezed: false
      },
      {
        name: 'Warranty Exp. End Date',
        type: IColumnType.Date,
        index: 11,
        options: [],
        required: false,
        width: 210,
        editable: false,
        columnCode: 'warrantyExpEndDate',
        key: 'warrantyExpEndDate',
        hidden: false,
        uiVisible: true,
        allowColumnEdit: false,
        allowColumnSort: true,
        formatter: null,
        allowFilter: true,
        dropdownConfig: null,
        allowAddOption: false,
        freezed: false
      },
      {
        name: 'Vendor Name',
        type: IColumnType.SELECT,
        index: 12,
        options: [],
        required: false,
        width: 210,
        editable: false,
        columnCode: 'vendorName',
        key: 'vendorName',
        hidden: false,
        uiVisible: true,
        allowColumnEdit: false,
        allowColumnSort: true,
        formatter: null,
        allowFilter: true,
        dropdownConfig: null,
        allowAddOption: false,
        freezed: false
      }
    ],
    [DOC_TYPE.MACHINE_SCHEDULE_COLUMN]: [
      {
        name: 'Schedule Name',
        type: IColumnType.TEXT,
        index: 0,
        key: 'scheduleName',
        options: [],
        required: false,
        width: 200,
        editable: false,
        hidden: false,
        uiVisible: true,
        allowColumnEdit: false,
        allowColumnSort: true,
        formatter: null,
        allowFilter: true,
        dropdownConfig: null,
        allowAddOption: false,
        freezed: false
      },
      {
        name: 'Start Date',
        type: IColumnType.DATE,
        index: 1,
        key: 'startDate',
        options: [],
        required: false,
        width: 150,
        editable: false,
        hidden: false,
        uiVisible: true,
        allowColumnEdit: false,
        allowColumnSort: true,
        formatter: null,
        allowFilter: true,
        dropdownConfig: null,
        allowAddOption: false,
        freezed: false
      },
      {
        name: 'End Date',
        type: IColumnType.DATE,
        index: 2,
        key: 'endDate',
        options: [],
        required: false,
        width: 150,
        editable: false,
        hidden: false,
        uiVisible: true,
        allowColumnEdit: false,
        allowColumnSort: true,
        formatter: null,
        allowFilter: true,
        dropdownConfig: null,
        allowAddOption: false,
        freezed: false
      },
      {
        name: 'Total Events',
        type: IColumnType.NUMBER,
        index: 3,
        key: 'totalEvents',
        options: [],
        required: false,
        width: 120,
        editable: false,
        hidden: false,
        uiVisible: true,
        allowColumnEdit: false,
        allowColumnSort: true,
        formatter: null,
        allowFilter: true,
        dropdownConfig: null,
        allowAddOption: false,
        freezed: false
      },
      {
        name: 'Event Duration',
        type: IColumnType.NUMBER,
        index: 4,
        key: 'eventDuration',
        options: [],
        required: false,
        width: 150,
        editable: false,
        hidden: false,
        uiVisible: true,
        allowColumnEdit: false,
        allowColumnSort: true,
        formatter: null,
        allowFilter: true,
        dropdownConfig: null,
        allowAddOption: false,
        freezed: false
      },
      {
        name: 'Maintenance Type',
        type: IColumnType.SELECT,
        index: 5,
        key: 'maintenanceType',
        options: [],
        required: false,
        width: 150,
        editable: false,
        hidden: false,
        uiVisible: true,
        allowColumnEdit: false,
        allowColumnSort: true,
        formatter: null,
        allowFilter: true,
        dropdownConfig: null,
        allowAddOption: false,
        freezed: false
      }
    ],
    [DOC_TYPE.PURCHASE_INWARD_QUOTATION]: [
      {
        id: 'product',
        key: 'product',
        name: 'Product',
        type: INPUT_TYPE.DROPDOWN,
        width: 156,
        systemField: true,
        editable: true,
        hidden: false,
        required: true,
        uiVisible: true,
        formatter: DocumentConfigUtility.productFormatterDocument,
        dropdownConfig: {
          title: 'Select Product',
          allowSearch: true,
          searchableKey: 'name',
          style: { minWidth: 230 },
          className: 'shadow-m width-auto',
          searchApiConfig: {
            getUrl: null,
            dataParser: DocumentConfigUtility.productDataParser,
            debounceTime: 300
          },
          data: [],
          renderer: DocumentConfigUtility.productRenderer,
          onSelect: (index: any, obj: any, rowIndex: any) => {},
          button: {
            title: '+ Add New',
            className: 'bg-button text-white',
            onClick: () => {}
          }
        }
      },
      {
        id: 'documentSequenceCode',
        key: 'documentSequenceCode',
        name: 'Product Code',
        type: INPUT_TYPE.TEXT,
        width: 160,
        systemField: true,
        editable: false,
        hidden: false,
        uiVisible: true
      },
      {
        id: 'description',
        key: 'productDescription',
        name: 'Description',
        type: INPUT_TYPE.TEXT,
        width: 160,
        systemField: true,
        editable: true,
        hidden: false,
        uiVisible: true
      },
      {
        id: 'quantity',
        key: 'productQuantity',
        name: 'Quantity',
        type: INPUT_TYPE.NUMBER,
        width: 130,
        systemField: true,
        editable: true,
        hidden: false,
        uiVisible: true,
        textAlign: 'right',
        renderer: DocumentConfigUtility.quantityRenderer
      },
      // {
      //   id: 'receivedQuantity',
      //   key: 'receivedQuantity',
      //   name: 'Received Qty',
      //   type: INPUT_TYPE.NUMBER,
      //   width: 80,
      //   systemField: true,
      //   editable: false,
      //   hidden: false,
      //   uiVisible: true,
      //   textAlign: 'right',
      //   renderer: DocumentConfigUtility.receivedAndBilledQtyRenderer
      // },
      // {
      //   id: 'balanceReceivedQuantity',
      //   key: 'balanceReceivedQuantity',
      //   name: 'Balance Received Qty',
      //   type: INPUT_TYPE.NUMBER,
      //   width: 80,
      //   systemField: true,
      //   editable: false,
      //   hidden: false,
      //   uiVisible: true,
      //   textAlign: 'right',
      //   renderer: DocumentConfigUtility.receivedAndBilledQtyRenderer
      // },
      // {
      //   id: 'billedQuantity',
      //   key: 'billedQuantity',
      //   name: 'Billed Qty',
      //   type: INPUT_TYPE.NUMBER,
      //   width: 80,
      //   systemField: true,
      //   editable: false,
      //   hidden: false,
      //   uiVisible: true,
      //   textAlign: 'right',
      //   renderer: DocumentConfigUtility.receivedAndBilledQtyRenderer
      // },
      // {
      //   id: 'balanceBilledQuantity',
      //   key: 'balanceBilledQuantity',
      //   name: 'Balance Billed Qty',
      //   type: INPUT_TYPE.NUMBER,
      //   width: 80,
      //   systemField: true,
      //   editable: false,
      //   hidden: false,
      //   uiVisible: true,
      //   textAlign: 'right',
      //   renderer: DocumentConfigUtility.receivedAndBilledQtyRenderer
      // },
      {
        id: 'uom',
        key: 'uom',
        name: 'UOM',
        type: INPUT_TYPE.DROPDOWN,
        width: 78,
        systemField: true,
        editable: true,
        hidden: false,
        uiVisible: true,
        formatter: DocumentConfigUtility.uomFormatter,
        dropdownConfig: {
          title: 'Select UOM',
          allowSearch: true,
          searchableKey: 'name',
          style: { minWidth: 150 },
          className: 'shadow-m width-auto',
          data: [],
          renderer: DocumentConfigUtility.uomOptionRenderer,
          onSelect: (index: any, obj: any, rowIndex: any) => {}
        }
      },
      {
        id: 'price',
        key: 'unitPrice',
        name: 'Price',
        type: INPUT_TYPE.NUMBER,
        width: 141,
        systemField: true,
        editable: true,
        hidden: false,
        allowNegativeNumber: true,
        uiVisible: true,
        textAlign: 'right',
        formatter: ({ value }: any) => value,
        dropdownConfig: {
          title: 'Price History',
          data: [],
          renderer: DocumentConfigUtility.historicalUnitPriceRenderer
        }
      },
      {
        id: 'discount',
        key: 'discount',
        name: 'Discount',
        type: INPUT_TYPE.TEXT,
        width: 100,
        systemField: true,
        editable: true,
        hidden: false,
        uiVisible: true,
        textAlign: 'right'
      },
      {
        id: 'tax',
        key: 'tax',
        name: 'Tax',
        type: INPUT_TYPE.DROPDOWN,
        width: 100,
        systemField: true,
        editable: true,
        hidden: false,
        uiVisible: true,
        renderer: DocumentConfigUtility.taxRenderer,
        dropdownConfig: {
          title: 'Select Tax',
          allowSearch: true,
          searchableKey: 'name',
          style: { minWidth: 150 },
          className: 'shadow-m width-auto',
          searchApiConfig: {
            getUrl: null,
            dataParser: null,
            debounceTime: 300
          },
          data: [],
          renderer: DocumentConfigUtility.taxOptionRenderer,
          onSelect: (index: any, obj: any, rowIndex: any) => {},
          button: {
            title: '+ Add New',
            className: 'bg-button text-white',
            onClick: () => {}
          }
        }
      },
      {
        id: 'taxAmount',
        key: 'taxAmount',
        name: 'Tax',
        type: INPUT_TYPE.NUMBER,
        width: 100,
        systemField: true,
        editable: true,
        hidden: false,
        uiVisible: true,
        textAlign: 'right'
      },
      {
        id: 'amount',
        key: 'totalAmount',
        name: 'Amount',
        type: INPUT_TYPE.NUMBER,
        width: 150,
        systemField: true,
        editable: false,
        hidden: false,
        uiVisible: true,
        textAlign: 'right'
      }
    ],
    [DOC_TYPE.BOM_ASSEMBLY]: [],
    [DOC_TYPE.GOODS_RECEIVED_NOTE]: [],
    [DOC_TYPE.PURCHASE_REQUISITION]: [],
    [DOC_TYPE.PURCHASE_REQUEST]: [],
    [DOC_TYPE.SALES_TAX_PAYMENT]: [],
    [DOC_TYPE.CLOSED]: [],
    [DOC_TYPE.SALES_RETURN]: [],
    [DOC_TYPE.OPENING_INVOICE]: [],
    [DOC_TYPE.OPENING_BILL]: [],
    [DOC_TYPE.DEBIT_NOTE]: [],
    [DOC_TYPE.CREDIT_NOTE]: [],
    [DOC_TYPE.EXPENSE]: [],
    [DOC_TYPE.SELL_SIDE_CLOSED]: [],
    [DOC_TYPE.BUY_SIDE_CLOSED]: [],
    [DOC_TYPE.RECEIVE_PAYMENT]: [],
    [DOC_TYPE.MAKE_PAYMENT]: [],
    [DOC_TYPE.PREPAYMENT]: [],
    [DOC_TYPE.DEPOSIT_ADVANCED_PAYMENT]: [],
    [DOC_TYPE.DEPOSIT]: [],
    [DOC_TYPE.JOURNAL_ENTRY]: [],
    [DOC_TYPE.PICKLIST]: [],
    [DOC_TYPE.PACKLIST]: [],
    [DOC_TYPE.SHIPLIST]: [],
    [DOC_TYPE.CHEQUE]: [],
    [DOC_TYPE.NORMAL_JE]: [],
    [DOC_TYPE.BOOK_SPECIFIC_JE]: [],
    [DOC_TYPE.WORK_ORDER]: [],
    [DOC_TYPE.STOCK_TRANSFER]: [],
    [DOC_TYPE.FUND_TRANSFER_JE]: [],
    [DOC_TYPE.STOCK_ISSUE]: [],
    [DOC_TYPE.STOCK_REQUEST]: [],
    [DOC_TYPE.EWAY_BILL]: [],
    [DOC_TYPE.FULFILLMENT]: [],
    [DOC_TYPE.FULFILLMENT_REPORT]: [],
    [DOC_TYPE.GOODS_RECEIPT]: [],
    [DOC_TYPE.ALLOCATION]: [],
    [DOC_TYPE.WORKORDER_OPERATION]: [],
    [DOC_TYPE.Direct_S_Expense]: [],
    [DOC_TYPE.Prepayment]: [],
    [DOC_TYPE.Advance_S_Payment]: [],
    [DOC_TYPE.Direct_S_Deposit]: [],
    [DOC_TYPE.Debit_S_Note]: [],
    [DOC_TYPE.Credit_S_Note]: [],
    [DOC_TYPE.Bill]: [],
    [DOC_TYPE.Invoice]: [],
    [DOC_TYPE.Contact]: [],
    [DOC_TYPE.Journal_S_Entry]: [],
    [DOC_TYPE.PARTY_JE]: [],
    [DOC_TYPE.FIXED_ASSET_BILL]: [],
    [DOC_TYPE.AMORTIZATION]: [],
    [DOC_TYPE.GOOD_RECEIPT]: [],
    [DOC_TYPE.PURCHASE_RETURN]: [],
    [DOC_TYPE.Payment]: [],
    [DOC_TYPE.PRODUCT]: [],
    [DOC_TYPE.Warehouse]: [],
    [DOC_TYPE.MRP_PRODUCT]: [],
    [DOC_TYPE.ADJUSTMENT]: [],
    [DOC_TYPE.OFFSET_INVOICE]: [],
    [DOC_TYPE.PRODUCT_DOCUMENT]: [],
    [DOC_TYPE.RATE_ANALYSIS]: [],
    [DOC_TYPE.WORKORDER_DOCUMENT]: [],
    [DOC_TYPE.QC_DOCUMENT]: [],
    [DOC_TYPE.PENDING_GOODS_RECEIPT]: [],
    [DOC_TYPE.PENDING_FULFILLMENT]: []
  };

  static getColumnConfig(document: DOC_TYPE): IColumn[] {
    if (this.docConfigs.hasOwnProperty(document)) {
      return this.docConfigs[document];
    } else {
      return [];
    }
  }
  static get(document: string) {
    let config = this.docConfigs[document];
    let tenantInfo = Store.getState().authInfo.currentTenantInfo.data;
    if (
      getTenantTaxSystem() === TAX_SYSTEM.US &&
      (document === DOC_TYPE.BILL ||
        document === DOC_TYPE.ORDER ||
        document === DOC_TYPE.EXPENSE_BILL ||
        document === DOC_TYPE.FA_BILL ||
        document === DOC_TYPE.FA_ORDER ||
        !tenantInfo.complianceEnabled)
    ) {
      config = config?.filter((col: any) => {
        if (col.key !== 'tax' && col.key !== 'taxAmount') {
          if (
            col.key ===
            (document === DOC_TYPE.EXPENSE_BILL
              ? 'description'
              : 'productDescription')
          ) {
            col.width = document === DOC_TYPE.EXPENSE_BILL ? 380 : 260;
          }
          return col;
        }
      });
    }
    //check if tenant no RevRec
    if (!isRevRecVisible()) {
      config = config?.filter((col: any) => {
        return col.key !== 'allocationType' && col.key !== 'basePrice';
      });
    } else {
      if (document === DOC_TYPE.QUOTE) {
        if (isSalesOrderVisible()) {
          config = config.filter((col: any) => {
            return col.key !== 'allocationType' && col.key !== 'basePrice';
          });
        }
      }
    }

    return config;
  }
  static set(document: string, config: any) {}
  static getProductURL = (
    search: string,
    tenantInfo: any,
    params: any,
    query?: any
  ) => {
    const queryArg = query ?? 'active=true,hasVariants=false';
    return (
      ApiConstants.URL.BASE.replace('v1/', 'v1') +
      ProductService.getProductsWithVariantsURL(search, queryArg, params)
    );
  };

  static getProductURLForFilter = (searchTerm: string) => {
    let queryString: string = `?search=${searchTerm}&limit=10&query=active=true,hasVariants=false`;
    return (
      ApiConstants.URL.BASE.replace('v1/', 'v1') +
      ApiConstants.URL.PRODUCT.FETCH_PRODUCTS +
      queryString
    );
  };
  static getAssetGroupURL = (search: string, tenantInfo: any) => {
    return (
      ApiConstants.URL.ASSET_BASE +
      ApiConstants.URL.FIXED_ASSET.GET_ASSET_GROUPS +
      `?search=${search}&query=active&limit=${25}&page=${0}`
    );
  };
  static getTaxURL = (search: string, date?: any) => {
    TaxService.apiConfig = { ...TaxAPIConfig };
    TaxService.apiConfig.Search = search;
    TaxService.apiConfig.Limit = 100;
    TaxService.apiConfig.Query = `status=active,taxEffectiveDate=${date}`;

    return (
      ApiConstants.URL.BASE.replace('v1/', 'v1') + TaxService.getTaxEndPoint()
    );
  };
  static getBlankRow = (docType: string) => {
    let row: any = {};
    DocumentConfigManager.docConfigs[docType].forEach((conf: any) => {
      row[conf.key] =
        conf.type === INPUT_TYPE.DROPDOWN ||
        conf.type === INPUT_TYPE.SELECT ||
        conf.type === INPUT_TYPE.MULTI_SELECT
          ? {}
          : undefined;
    });
    return row;
  };
  static getRequiredFields = (docType: string) =>
    DocumentConfigManager.docConfigs[docType]
      .filter((conf: any) => conf.required)
      .map((conf: any) => conf.key);

  static getAccountURL = (search: string, query?: any) => {
    const config: AccountAPIConfig = {
      ...AccountsService.apiConfig,
      Page: 0,
      SearchTerm: search,
      Limit: 10,
      Query: query
    };
    AccountsService.apiConfig = config;
    return ApiConstants.URL.BASE + AccountsService.getAccountEndPoint();
  };
  static getDiscountUrl = (search: string, query?: any) => {
    const config: AdditionalChargeAPIConfig = {
      ...AdditionalChargeService.apiConfig,
      Page: 0,
      SearchTerm: search,
      Limit: 10,
      Query: query
    };
    AdditionalChargeService.apiConfig = config;
    return ApiConstants.URL.BASE + AdditionalChargeService.getDiscountUrl();
  };
}
