import ApiConstants from '../../Constants/ApiConstants';
import { PAGE_SIZE } from '../../Constants/Constant';
import { COMPLIANCE_SPECIFIC_FIELD_NAME } from '../../Constants/Enum';
import Utility from '../../Utility/Utility';
import http2 from '../Interceptor2';
export interface MRPProductsAPIConfig {
  SearchTerm?: string;
  Limit?: number;
  Page?: number;
  Query?: string;
  sort?: string;
  sortDir?: string;
  QueryParam?: String;
  isOB?: boolean;
  barcode?: string;
  IsVariant?: boolean;
  FetchAttachmentDetails?: boolean;
  ProductGroupId?: any;
}

export const defaultConfig: MRPProductsAPIConfig = {
  SearchTerm: '',
  Limit: PAGE_SIZE,
  Page: 0,
  sort: 'type',
  sortDir: 'ASC',
  QueryParam: '',
  FetchAttachmentDetails: false,
  ProductGroupId: ''
};

class MRPProductsService {
  static apiConfig: MRPProductsAPIConfig = defaultConfig;
  static moduleName: COMPLIANCE_SPECIFIC_FIELD_NAME =
    COMPLIANCE_SPECIFIC_FIELD_NAME.PRODUCT;
  static abortController: any = null;

  static getProductEndPoint(
    configObject: MRPProductsAPIConfig,
    isVariant = false
  ) {
    var typeString = '';
    typeString =
      typeString + `&query=${isVariant ? '' : `isVariant=${isVariant}`}`;
    let queryString: string = `?search=${configObject.SearchTerm ?? ''}&limit=${
      configObject.Limit ?? PAGE_SIZE
    }&page=${configObject.Page}${typeString}${
      configObject.Query ? configObject.Query : ''
    }${
      configObject.QueryParam ? configObject.QueryParam : ''
    }&fetchAttachmentDetails=${
      this.apiConfig?.FetchAttachmentDetails ?? false
    }`;
    if (this.apiConfig.ProductGroupId) {
      queryString += `&productGroupId=${this.apiConfig.ProductGroupId}`;
    }
    const countryCode = Utility.getTenantSpecificApiCode(
      MRPProductsService.moduleName
    );

    const finalEndpoint: string =
      ApiConstants.URL.PRODUCT.MRP_PRODUCT_FETCH +
      (countryCode !== '' ? `${countryCode}` : '') +
      queryString;
    return finalEndpoint;
  }

  static fetchMRPProducts(avoidCancelPreviousRequest?: boolean) {
    if (!avoidCancelPreviousRequest) {
      Utility.cancelRequest(MRPProductsService.abortController);
    }
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }
    let endPoint = MRPProductsService.getProductEndPoint(this.apiConfig);

    MRPProductsService.abortController = avoidCancelPreviousRequest
      ? null
      : Utility.createAbortController();

    return http2
      .get(endPoint, {
        signal: avoidCancelPreviousRequest
          ? null
          : MRPProductsService.abortController.signal
      })
      .catch((err: any) => {
        return Promise.reject(err);
      });
  }

  static fetchMRPProductsForURL(url: string) {
    return http2
      .get(url)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (err: any) => {
          return Promise.reject(err);
        }
      )
      .catch((err: any) => {
        return Promise.reject(err);
      });
  }

  static getProductShortInfo(productCodeQuantityArray: any, toDate?: string) {
    const toDateParam = toDate;
    let finalURL = `${ApiConstants.URL.PRODUCT.MRP_PRODUCT_SHORT_INFO}`;
    if (Utility.isNotEmpty(toDateParam)) {
      finalURL = `${finalURL}?toDate=${toDateParam}`;
    }
    return http2
      .post(finalURL, productCodeQuantityArray)
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static getUsedBomListForProductId(productID: string) {
    return http2
      .post(ApiConstants.URL.PRODUCT.MRP_GET_USED_BOM_LIST_FOR_PID(productID))
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static getMRPProductByCode(productCode: string) {
    let finalURL = MRPProductsService.getProductEndPoint({
      SearchTerm: productCode,
      Limit: 25,
      Page: 0,
      Query: '',
      sort: 'createdOn',
      sortDir: 'DESC'
    });

    return http2
      .get(finalURL)
      .then((response: any) => {
        return Promise.resolve(response);
      })
      .catch((err: any) => {
        console.error('Error deleting mrp product: ', err);
      });
  }

  static getProductEndPointNew(isVariant = false) {
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
      if (
        !isVariant &&
        !this.apiConfig.Query?.toString().includes('isVariant=false')
      ) {
        this.apiConfig.Query = this.apiConfig.Query + `isVariant=false`;
      }
    }
    let queryString: string = `?search=${this.apiConfig.SearchTerm}&limit=${
      this.apiConfig.Limit
    }&page=${this.apiConfig.Page}${
      this.apiConfig.Query ? '&query=' + this.apiConfig.Query : ''
    }${this.apiConfig.QueryParam ? this.apiConfig.QueryParam : ''}`;
    queryString = this.apiConfig.barcode
      ? queryString + '&barcode=' + this.apiConfig.barcode
      : queryString;
    const countryCode = Utility.getTenantSpecificApiCode(
      MRPProductsService.moduleName
    );
    const finalEndpoint: string =
      ApiConstants.URL.PRODUCT.FETCH_PRODUCTS +
      (countryCode !== '' ? `${countryCode}` : '') +
      queryString;
    return finalEndpoint;
  }

  static getProductsByPage(country = '', isVariant = false) {
    Utility.cancelRequest(MRPProductsService.abortController);

    MRPProductsService.abortController = Utility.createAbortController();
    return http2
      .get(`${MRPProductsService.getProductEndPointNew(isVariant)}`, {
        signal: MRPProductsService.abortController.signal
      })
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static getBulkUpdateProductList() {
    if (Utility.isEmpty(this.apiConfig)) {
      this.apiConfig = defaultConfig;
    }
    let url = `?page=${this.apiConfig.Page}&limit=${
      this.apiConfig.Limit ? this.apiConfig.Limit : PAGE_SIZE
    }&searchStr=${this.apiConfig.SearchTerm ? this.apiConfig.SearchTerm : ''}`;
    return http2
      .get(
        ApiConstants.URL.BASE +
          ApiConstants.URL.PRODUCT.FETCH_BULK_UPDATE_PRODUCT_LIST +
          url
      )
      .then(
        (response: any) => {
          return Promise.resolve(response);
        },
        (error: any) => {
          return Promise.reject(error);
        }
      )
      .catch((error: any) => {
        return Promise.reject(error);
      });
  }

  static bulkUpdateBoms = (requestObject: any) => {
    return http2.post(
      ApiConstants.URL.BASE + ApiConstants.URL.PRODUCT.BULK_UPDATE_PRODUCT_LIST,
      requestObject
    );
  };

  static bulkReplaceBoms = (requestObject: any) => {
    return http2.post(
      ApiConstants.URL.BASE +
        ApiConstants.URL.PRODUCT.BULK_REPLACE_PRODUCT_LIST,
      requestObject
    );
  };

  static getBomVersionHistory = (url: string) => {
    return http2
      .get(
        ApiConstants.URL.BASE +
          ApiConstants.URL.PRODUCT.GET_BOM_VERSION_HISTORY +
          url
      )
      .then((response: any) => {
        return Promise.resolve(response);
      })
      .catch((err: any) => {
        return Promise.reject(err);
      });
  };
}

export default MRPProductsService;
