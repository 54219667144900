let id = {
    translations: {
        "component": "Komponen",
        "add": "Tambah",
        "document_type": "Jenis Dokumen",
        "select_theme_color": "Pilih Warna Tema",
        "company_logo": "Logo Perusahaan",
        "choose_file": "Pilih file",
        "your_company_name": "Nama Perusahaan Anda",
        "company_address": "Alamat Perusahaan",
        "currency": "Mata Uang",
        "no": "No",
        "terms_&_condition": "Syarat & Ketentuan",
        "notes": "Catatan",
        "date_format": "Format Tanggal",
        "line_items": "Baris detail",
        "item_name": "Nama produk",
        "bill_to": "Ditagih ke",
        "ship_to": "Dikirim ke",
        "valid_till": "Berlaku sampai",
        "due_date": "Tanggal Jatuh Tempo",
        "date": " Tanggal",
        "item_description": "Deskripsi produk",
        "discount": "Diskon",
        "tax": "Pajak",
        "delete": "Hapus",
        "add_new_item": "Tambah Produk baru",
        "signature": "Tanda tangan",
        "show_discount": "Tampilkan Diskon",
        "show_tax": "Tampilkan Pajak",
        "company_name": "Nama Perusahaan",
        "company_billing_address": "Alamat Penagihan Perusahaan",
        "billing_address_placeholder_text_value": "Nama Pelanggan\nAlamat Penagihan Pelanggan anda",
        "shipping_address_placeholder_text_value": "Nama Pelanggan\nAlamat Pengiriman Pelanggan anda",
        "terms_and_condition_placeholder_text": "Pembayaran jatuh tempo dalam 30 hari",
        "notes_placeholder_text": "Mohon lunasi sisa pembayaran tepat waktu",
        "hide": "Sembunyikan",
        "show": "Tampilkan",
        "estimate": "Estimasi",
        "quotation": "Penawaran",
        "invoice": "Tagihan Penjualan",
        "purchase_order": "Pemesanan",
        "bills": "Tagihan Pembelian",
        "total": "Total",
        "number": "Nomor",
        "edit": "Ubah",
        "products": "Produk",
        "description": "Deskripsi",
        "qty": "Jml",
        "unit_price": "Harga per unit",
        "unit price": "Harga per unit",
        "price": "Harga",
        "amount": "Jumlah",
        "subtotal": "Subtotal",
        "choose_a_template": "Pilih Template",
        "go_to_deskera": "Ke Deskera",
        "print_this_document": "Cetak dokumen ini",
        "send_email_to_clients": "Kirim E-Mail ke Pelanggan",
        "document_generator": "Generator Dokumen",
        "please_wait": "Mohon menunggu...",
        "download_as_pdf": "Unduh sebagai (.pdf)",
        "download": "Unduh",
        "print": "Cetak",
        "share": "Bagikan",
        "try_deskera": "Coba Deskera",
        "copy_link": "Salin Tautan",
        "share_on_the_web": "Bagikan di situs",
        "anyone_with_the_below_link_can_view": "Semua orang yang punya link dibawah dapat melihat",
        "send_your": "Kirim",
        "quickly": "Cepat",
        "please_sign_up_with_deskera_to_send_your": "Mohon daftar di Deskera untuk mengirimkan",
        "dont_worry_your": "Tidak perlu khawatir, anda",
        "will_still_be_here": "akan tetap ada disini.",
        "sign_up_for_free": "Daftar dengan gratis",
        "already_a_user_Sign_in": "Sudah menjadi pengguna? Masuk",
        "share_on_mail": "Bagikan dengan Email",
        "to": "Ke",
        "subject": "Subyek",
        "enter_recipient_email_address": "Masukan alamat email penerima",
        "enter_subject_here": "Masukan subyek disini",
        "send": "Kirim",
        "cancel": "Batal",
        "edit_table": "Ubah Tabel",
        "save": "Simpan",
        "buyer": "Pembeli",
        "seller": "Penjual",
        "powered_by_deskera": "Didukung oleh Deskera",
        "details": "Detail",
        "other_comments": "Komentar Lain",
        "bill": "Tagihan Pembelian",
        "order": "Pemesanan",

        "email": "Email",

        "items": "Barang",
        "services": "Jasa",
        "hours": "Jam",
        "rate": "Tarif",
        "other": "Lainnya",
        "sales_tax": "Pajak Penjualan",
        "tax_rate": "Tarif Pajak",
        "sales tax": "Pajak Penjualan",
        "tax rate": "Tarif Pajak",

        "num": " No",

        "template_update_failure_message": "Tidak dapat memperbarui pengaturan. Mohon dicoba kembali!",
        "template_save_failure_message": "Tidak dapat menyimpan pengaturan. Mohon dicoba kembali!",
        "template_update_success_message": "Template telah berhasil disimpan",
        "template_save_success_message": "Template telah berhasil disimpan",
        "additional_information": "Informasi Tambahan",
        "other_options": "Opsi Lain",

        "image": "Gambar",
        "text": "Teks",
        "watermark": "Watermark",
        "banner": "Spanduk",
        "custom_field": "Field Custom",
        "component_list": "Daftar Komponen",

        "email_address": "Alamat Email",
        "email_address_1": "Alamat Email",
        "is_not_valid": "adalah tidak valid.",

        "deskera_suites": "Deskera Suite",
        "deskera_books": "Deskera Books",
        "deskera_people": "Deskera People",
        "deskera_sales": "Deskera Sales",
        "try_now": "Coba Sekarang",

        "text_color": "Warna Teks",
        "image_ratio": "Rasio Gambar (%)",
        "text_size": "Ukuran Teks",
        "opacity": "Kegelapan",
        "font_style": "Gaya Huruf",
        "font_weight": "Ketebalan Huruf",
        "text_alignment": "Alignment Font",
        "image_alignment": "Alignment Gambar",
        "image_preview": "Tampilkan Gambar",
        "preview_banner": "Tampilkan Banner",
        "banner_selection": "Pilihan Banner",
        "duplicate": "Duplikat",
        "settings": "Pengaturan",
        "expiry": "Masa Berlaku",
        "batch_no": "Nomor Batch",

        "saving": "Menyimpan...",
        "save_template": "Simpan Template",

        "name": "Nama",

        "stock_items": "Stok Barang",

        "sending": "Mengirim...",
        "set_as_default": "Set Default",

        "no_email_subject": "Subyek custom tidak ditemukan!! Mohon masukan subyek custom dan klik simpan template",

        "expense": "Biaya",
        "deposit": "Setoran",
        "credit_note": "Nota Kredit",
        "debit_note": "Nota Debit",
        "accounts": "Akun",
        "customer_address_placeholder_text_value": "Nama Pelanggan\nAlamat Pelanggan Anda",
        "account_name_placeholder_text_value": "Kode Akun\nNama Akun",
        "payment_to": "Dibayarkan ke",
        "from_account": "Dari Akun",
        "payment_from": "Dibayarkan dari",
        "to_account": "Ke Akun",
        "payment_date": "Tanggal Pembayaran",
        "document_date": "Tanggal Dokumen",
        "account_name": "Nama Akun",
        "account_description": "Deskripsi Akun",
        "tax_amount": "Jumlah Pajak",
        "payment_amount": "Nilai Pembayaran",
        "line_amount": "Nilai baris",
        "total_tax_amount": "Total Nilai Pajak",
        "contact": "Kontak",
        "driver_name": "Nama Pengemudi",
        "transporter": "Pengangkut",
        "vehicle_no": "Nomor Kendaraan",
        "lr_no": "LR No.",
        "contact_address_placeholder_text_value": "Nama Kontak\nAlamat Kontak Anda",

        "printing": "Mencetak..",
        "downloading": "Mengunduh..",

        "incorrect_format": "Format tidak benar!",
        "few_formats_allowed": "Hanya file jpg, jpeg, png yang dapat digunakan",
        "image_missing": "Image hilang!",
        "data_missing": "Data hilang!",
        "select_image_to_proceed": "Mohon memilih gambar untuk melanjutkan",
        "no_data_to_export": "Tidak ada data untuk di-export",

        "session_expired_title": "Sesi telah berakhir!",
        "session_expired_message": "Sesi anda telah berakhir. Mohon login kembali.",
        "login": "Login",
        "generate_link_to_share": "Bagikan dokumen dengan orang",
        "burn_after_read": "Hilangkan setelah dibaca",
        "generate_link": "Buat tautan untuk dibagikan",
        "no_never": "Tidak pernah",
        "1_min": "1 Menit",
        "1_hr": "1 Jam",
        "1_day": "1 Hari",
        "1_year": "1 Tahun",

        "document_expired_title": "Masa berlaku Dokumen telah habis!",
        "document_expired_message": "Masa berlaku Dokumen ini telah berakhir. Mohon menghubungi pengirim",

        "loading": "Loading...",
        "reference_date": "Tanggal Referensi",
        "cheque_number": "Nomor Cek",
        "deposit_to": "Disetor ke",

        "password": "Dilindungi password",
        "password_empty_title": "Password Kosong!",
        "password_empty_message": "Password tidak dapat kosong. Mohon masukan password dan coba kembali!",
        "document_not_found_title": "Document tidak ditemukan!",
        "document_incorrect_password_title": "Password tidak benar!",
        "document_incorrect_password_message": "Mohon memasukan password yang benar dan coba kembali.",
        "proceed": "Lanjutkan",
        "document_enter_password_title": "Mohon masukan Password!",
        "document_enter_password_message": "Mohon masukan password untuk melanjutkan membuka dokumen ini.",

        "preparing_document_email": "Mempersiapkan dokumen untuk diemail…",
        "preparing_document_print": "Mempersiapkan dokumen untuk dicetak…",
        "loading_the_page": "Loading halaman",
        "sender": "Pengirim",
        "reply_to": "Balas ke",
        "enter_sender_name": "Masukan Nama Pengirim",
        "enter_reply_to_email": "Masukan alamat email Dibalas-ke",
        "send_a_copy_to_myself_at": "Kirim salinan ke saya sendiri di",
        "payment": "Buat Pembayaran",
        "paper_format": "Ukuran Kertas",
        "please_provide_paper_format": "Mohon memilih ukuran kertas",
        "paper_format_popup_note": "Anda masih dapat mengubah ukuran kertas pada panel pengaturan",
        "item_number": "Nomor Produk",
        "item_code": "Kode Produk",
        "sn_number": "No Seri",

        "template_saved": "Template telah berhasil disimpan!",
        "you_design_template_has_been_saved": "Template telah disimpan, Anda dapat menutup jendela ini dan kembali ke aplikasi Books.",
        "you_design_template_has_been_saved_people": "Template telah disimpan, Anda dapat menutup jendela ini dan kembali ke aplikasi People.",
        "go_to_books": "Ke Books",
        "accept_from": "Formulir Diterima",
        "payment_details": "Detail Pembayaran",
        "document_details": "detail dokumen",

        "item_tax": "Pajak Produk",
        "item_discount": "Diskon Produk",
        "uom": "Satuan",
        "unitPrice": "Harga Satuan",
        "quantity": "Kuantitas",
        "code": "Kode",
        "please_select_custom_field": "Mohon memilih field custom.",

        "preview_pdf": "Tampilkan pdf",

        "template_name": "Nama Template",
        "please_enter_template_name_to_save": "Mohon masukan nama template untuk menyimpan.",
        "custom_template": "Template custom",
        "add_new_template": "Tambahkan template custom baru",
        "vendor_name": "Nama Pedagang",

        "decimal_point": "Decimal Titik",
        "footer": "Footer",

        "save_as": "Simpan sebagai",
        "save_as_new_template": "Simpan sebagai template baru",
        "e_invoice_section": "e-Tagihan Penjualan bagian",
        "amount_before_tax": "Jumlah Sebelum Pajak",

        "order_no": "No Pesanan",
        "PURCHASE_INVOICE": 'Tagihan Pembelian',
        "PURCHASE_ORDER": 'Pemesanan',
        "SALES_INVOICE": "Tagihan Penjualan",
        "QUOTATION": "Penawaran",
        "fulfillment": "Pemenuhan",
        "ship_from": "Kirim Dari",
        "company_address_placeholder_text_value": "Nama Perusahaan\nAlamat Perusahaan Anda",
        "goods_receipt": "Penerimaan Barang",
        "required_qty": "Jumlah yang Diperlukan",
        "received_qty": "Jumlah yang Diterima",
        "committed_qty": "Jumlah yang Dikomit",
        "warehouse_code": "Kode Gudang",
        "warehouse_name": "Nama Gudang",
        "company_warehouse_name": "Nama Perusahaan/Gudang",
        "payment_footer": "Pembayaran",
        "make_payment": "Lakukan Pembayaran",
        "receive_payment": "Terima Pembayaran",
        "deposit_advpayment": "Uang muka",
        "expense_prepayment": "Pembayaran di muka",

        "delete_template": "Hapus Template",
        "delete_template_message": "Anda yakin ingin menghapus template? Setelah dihapus, template tidak dapat diambil kembali.",
        "delete_template_ok_label": "Ya. Hapus.",
        "delete_template_success_message": "Templat berhasil dihapus.",
        "delete_template_failure_message": "Tidak dapat menghapus pengaturan. Silakan coba lagi!",
        "before_tax": "Sebelum pajak",
        "outstanding": "Jumlah Outstanding",

        "signature_section": "Bagian Tanda Tangan",
        "prepared_by": "Disiapkan Oleh",
        "approved_by": "Disetujui Oleh",

        "no.": 'No.',
        "#": "#",
        "serial_batch": "Serial/Batch",
        "amount_in_words": "Jumlah Dalam Kata",
        "name_with_description": "Nama",

        "invoice_number": "Nomor Faktur",
        "bill_number": "Nomor Tagihan",
        "total_invoice_amount": "Jumlah total faktur",
        "total_bill_amount": "Total Jumlah Tagihan",
        "payment_made": "Pembayaran Dilakukan",
        "current_due_amount": "Jumlah Jatuh Tempo Saat Ini",
        "current_balance": "Saldo Saat Ini",
        "payment_medium": "Cara Pembayaran",
        "cash": "Uang",
        "prepayment": "Pembayaran di muka",
        "payment_label": "Pembayaran",
        "receipt_label": "Tanda terima",
        "total_row": "Total Baris",

        "journal_entry": "Entri Jurnal",
        "journal_entry_number": "JE nomor.",
        "account_code": "Kode Akun",
        "credit": "Kredit",
        "debit": "Debit",
        "GOOD_RECEIPT": "Penerimaan Barang",
        "MAKE_PAYMENT": "Pembayaran",
        "RECEIVE_PAYMENT": "Pembayaran",
        "CREDIT_NOTE": "Nota Kredit",
        "DEBIT_NOTE": "Nota Debit",
        "FULFILLMENT": "Pemenuhan",
        "SALES_RETURN": "Pengembalian Penjualan",
        "PURCHASE_RETURN": "Pembelian Kembali",
        "SALES_CONTRA": "Kontra Penjualan",
        "PURCHASE_CONTRA": "Kontra Pembelian",
        "BANK_TRANSFER": "Transfer Bank",
        "TDS_JE": "TDS JE",
        "ADJUSTMENT": "Penyesuaian",
        "memo": "Memo",

        "pick_list": "Daftar pilihan",
        "pack_list": "Daftar paket",
        "ship_list": "Daftar Kapal",
        "invoice_or_quotation": "Faktur/Kutipan",
        "product_code": "Kode Produk",
        "product_name": "Nama Produk",
        "barcode": "Kode batang",
        "quantity_to_pick": "Kuantitas Untuk Memilih",
        "quantity_required_for_order": "Kuantitas yang Diperlukan Untuk Pesanan",
        "picked": "Dipilih",
        "item_no": "Nomor barang",
        "unit": "Satuan",
        "packed_quantity": "Kuantitas yang Dikemas",
        "warehouse_address": "Alamat Gudang",
        "delivery": "Pengiriman",
        "carrier": "Pembawa",
        "number_of_carton": "Jumlah Karton",
        "weight": "Berat",
        "tracking_number": "Melacak nomor",
        "package": "Kemasan",
        "order_number": "Nomor pesanan",

        "custom_field_is_missing_title": "Bidang Kustom tidak ada",
        "custom_field_is_missing_message": "Bidang khusus tidak ada dalam dokumen ini. Silakan tambahkan bidang khusus dari Buku atau coba dengan dokumen lain.",

        "repeatable_header": "Tajuk Tabel yang Dapat Diulang",
        "margin": "Batas",
        "default": "Bawaan",
        "none": "Tidak ada",
        "minimum": "Minimum",
        "large": "Besar",

        "receive_by": "Terima Berdasarkan tanggal",
        "ship_by": "Kirim Berdasarkan tanggal",
        "additional_date": "Tanggal Tambahan",

        "header_footer_section": "Bagian Header Footer",
        "header": "Tajuk",
        "date_only": "Tanggal Saja",
        "title_only": "Judul Saja",
        "page_count_only": "Jumlah Halaman Saja",
        "date_title": "Tanggal Dan Judul",
        "date_page_count": "Tanggal Dan Jumlah Halaman",
        "title_page_count": "Judul Dan Jumlah Halaman",
        "date_title_space": "Tanggal Dan Judul Dengan Spasi",
        "date_page_count_space": "Tanggal Dan Jumlah Halaman Dengan Spasi",
        "title_page_count_space": "Judul Dan Jumlah Halaman Dengan Spasi",
        "date_title_page_count": "Tanggal, Judul, Jumlah Halaman",
        "custom": "Kebiasaan",
        "left": "Sisi kiri",
        "center": "Tengah",
        "right": "Sisi kanan",
        "title": "Judul",
        "page_count": "Jumlah Halaman",
        "current_page_count": "Jumlah Halaman Saat Ini",
        "total_page_count": "Jumlah Halaman Total",
        "custom_text": "Teks Khusus",
        "page": "Halaman",

        "company_phone_number": "Nomor Telepon Perusahaan",
        "company_gstin": "Perusahaan GSTIN",
        "company_state_name_and_code": "Nama Negara dan Kode Perusahaan",
        "payment_terms": "Syarat pembayaran",
        "customer_phone_number": "Nomor Telepon Pelanggan",
        "customer_gstin": "GSTIN pelanggan",
        "customer_state_name_and_code": "Nama dan Kode Negara Pelanggan",
        "print_only_message": "Ini adalah Komputer yang Dihasilkan",
        "hsn_or_sac": "HSN/SAC",
        "taxable_value": "Nilai Kena Pajak",
        "integrated_tax": "Pajak Terintegrasi",
        "central_tax": "Pajak Pusat",
        "state_tax": "Pajak Daerah",
        "cess": "CESS",
        "state_name": "Nama Negara",
        "state_code": "Kode",
        "label_state_code": "Kode Negara",

        "address_format": "Format Alamat",
        "address_1": "Alamat 1",
        "address_2": "alamat 2",
        "city": "Kota",
        "state": "negara bagian",
        "postal_code": "Kode Pos",
        "country": "Negara",
        "is_apply_address_format_to_other_addresses": "Terapkan format alamat saat ini ke semua alamat.",
        "product_grouping": "Pengelompokan Produk",
        "product_group":"Grup produk",
        "cheque": "Cek",
        "bank_transfer": "Transfer Antarbank",
        "card": "Kartu",

        "payslip": "Slip gaji",
        "earning_statement": "Laporan Penghasilan",
        "pay_period": "Periode pembayaran",
        "pay_date": "Tanggal Pembayaran",
        "ssn": "SSN",
        "taxable_marital_status": "Status Perkawinan Kena Pajak",
        "exemptions": "Pengecualian",
        "date_of_joining": "Tanggal Bergabung",
        "pf_a_c_NUMBER": "Nomor AC PF",
        "esi_number": "Nomor ESI",
        "uan_number": "Nomor UAN",
        "employee_id": "identitas pegawai",
        "designation": "Penamaan",
        "employee_net_pay": "Gaji Bersih Karyawan",
        "employee_earnings": "Penghasilan Karyawan",
        "allowances": "Tunjangan",
        "deductions": "Pengurangan",
        "employer_contribution": "Kontribusi Pengusaha",
        "employer_cpf": "CPF majikan",
        "net_salary_paid": "Gaji Bersih Dibayar",
        "employee_taxes_withhold": "Pemotongan Pajak Karyawan",
        "employer_taxes": "Pajak Majikan",
        "employee_deductions": "Potongan Karyawan",
        "summary": "Ringkasan",
        "basic_salary": "Gaji pokok",
        "net_pay": "Gaji bersih",
        "additional_earnings": "Penghasilan Tambahan",
        "gross_earnings": "Pendapatan kotor",
        "duration": "Durasi",
        "current": "Saat ini",
        "year_to_date": "Sejauh tahun ini",
        "type": "Jenis",
        "pre_tax_deductions_contributions": "Potongan / kontribusi sebelum pajak",
        "taxes": "Pajak",
        "post_tax_deductions_contributions": "Potongan/iuran setelah pajak",
        "net_pay_contributions": "Pembayaran Bersih / kontribusi",
        "check_amount": "Periksa Jumlah",
        "payslip_auto_message": "Ini adalah slip gaji yang dihasilkan sistem tidak memerlukan segel dan tanda tangan",
        "employee_address": "alamat karyawan",
        "field_configuration": "Konfigurasi Lapangan",
        "table_configuration": "Konfigurasi Tabel",
        "employee_name_address": "Nama dan Alamat Karyawan",
        "employee_bank_acc_number": "nomor rekening bank karyawan",
        "header_title": "Judul Tajuk",
        "product_custom_field": "Bidang Kustom Produk",
        "quantity_and_uom": "Kuantitas dan UOM",
        "includes_product_custom_field": "Termasuk Bidang Kustom Produk",
        "combines_product_custom_field": "Menggabungkan Bidang Kustom Produk",
        "serial_number": "Nomor seri",
        "batch_number": "Nomor batch",
        "manufacturing_date": "Tanggal pembuatan",
        "expiry_date": "Tanggal kadaluarsa",
        "override_theme_color": "Ganti Warna Tema",
        "override_global_color": "Ganti Warna Global",
        "contact_number": "Nomor telepon",
        "contact_code": "Kode Kontak",
        "blank": "Kosong",
        "pay_to": "Bayar Ke",
        "payroll_check": "Cek gaji",
        "pay_to_the": "BAYAR KEPADANYA",
        "order_of": "PESANAN",
        "dollars": "DOLAR",
        "payroll_period": "Gaji untuk periode",
        "repeatable_header_footer": "Header Footer Berulang",
        "repeatable_footer": "Footer Berulang",
        "includes_hsn_or_sac_code": "Termasuk Kode HSN/SAC",
        "eway_bill_no":  "Nomor tagihan eway",
        "eway_bill_date": "Tanggal Tagihan Eway",
        "place_of_supply":'Tempat Pasokan',
        "destination_of_supply":"Tujuan Pasokan",
        "source_of_supply":"Sumber Pasokan",
        "subTable_configuration": "Konfigurasi Sub-tabel",
        "additional_table_configuration": "Konfigurasi Tabel Tambahan",
        "select_new_type": "Pilih Tipe Baru",
        "qr_code": "Kode QR",
        "qr_string": "String QR",
        "generated_qr": "QR yang dihasilkan",
        "qr_code_with_text": "Kode QR Dengan Teks",
        "image_with_text": "Gambar Dengan Teks",
        "qr_information": "Informasi QR",
        "additional_info": "Informasi tambahan",
        "global_discount": "Diskon Global",
        "pre_tax": "Sebelum Pajak",
        "post_tax": "Setelah Pajak",
        "includes_uom_schema": "Termasuk Skema UOM",
        "table": "Meja",
        "font": "Font",
        "table_row_height": "Tinggi Baris Meja",
        "padding_horizontal": "Bantalan Horisontal",
        "padding_vertical": "Bantalan Vertikal",
        "original": "Asli",
        "original_for_recipient": "Asli Untuk Penerima",
        "duplicate_supply_of_goods": "Duplikat (Penyediaan Barang)",
        "duplicate_for_transporter": "Duplikat Untuk Transporter",
        "duplicate_supply_of_service": "Duplikat (Penyediaan Layanan)",
        "duplicate_for_supplier": "Duplikat Untuk Pemasok",
        "triplicate": "Rangkap tiga",
        "triplicate_for_supplier": "rangkap tiga untuk pemasok",
        "position": "Posisi",
        "middle": "Tengah",
        "sample_watermark": "Contoh Tanda Air",
        "extra_copy": "Salinan Ekstra",
        "quadruplicate": "Rangkap empat",
        "MAX_IMG_UPLOAD_ALERT": "Ukuran gambar tidak boleh lebih besar dari 500KB",
        "system_default": "Default Sistem",
        "qr_resize": "Ubah Ukuran QR",
        "top": "Atas",
        "bottom": "Bawah",
        "click_here_to_pay": "Klik Di Sini Untuk Membayar",
        "generated_message": "Pesan yang Dihasilkan",
        "sales_order": "Pesanan Penjualan",
        "help": "Bantuan",
        "includes_account_custom_field": "Termasuk Bidang Kustom Akun",
        "combines_account_custom_field": "Menggabungkan Bidang Kustom Akun",
        "account_custom_field": "Bidang Kustom Akun",
        "selection": "Pilihan",
        "alias_name_address": "Nama dan Alamat Alias",
        "nric_fin_wpn": "NRIC/FIN/WPN",
        "paid_days": "Days Days",
        "unpaid_days": "Hari yang tidak dibayar",
        "payslip_overtime": "Lembur",
        "payslip_LOP": "Kehilangan gaji",
        "outstanding_balance": "Saldo Luar Biasa",
        "outstanding_balance_includes_current": "Saldo Terutang (termasuk lancar)",
        "top_section_above_table": "Bagian Atas Di Atas Tabel",
        "is_group_additional_charges_tax": "Apakah pajak biaya tambahan grup",
        "supplier_invoice_no": "Nomor Faktur Pemasok",
        "default_template": "Template Bawaan",
        "contact_name": "Nama Kontak",
        "current_date": "Tanggal sekarang",
        "e_way_bill_summary": "Ringkasan Eway Bill",
        "e_way_bill_detail": "Detil Eway Bill",
        "table_column_alignment": "Perataan Kolom Tabel",
        "table_column_vertical_alignment": "Perataan Vertikal",
        "earnings_configuration": "Konfigurasi Penghasilan",
        "label_empty_field_message": "Label bidang tidak boleh kosong, harap masukkan beberapa nilai di dalamnya untuk melanjutkan.",
        "product_table": "Tabel Produk",
        "rounding_off": "Pembulatan",
        "work_order": "Pesanan Kerja",
        "order_prep_completed": "Persiapan Pesanan Selesai",
        "barcode_settings": "Pengaturan Barcode",
        "barcode_resize": "Ubah Ukuran Barcode",
        "comments": "Komentar",
        "delivery_date": "Tanggal Pengiriman",
        "estimated_time": "Waktu Perkiraan",
        "actual_quantity": "Jumlah Aktual",
        "manufacture_quantity": "Jumlah Pembuatan",
        "parent_work_order": "Pesanan Kerja Induk",
        "stock_transfer": "Transfer Stok",
        "source": "Sumber",
        "destination": "Tujuan",
        "source_address": "Alamat Sumber",
        "destination_address": "Alamat Tujuan",
        "transfer_date": "Tanggal Transfer",
        "verify_date": "Tanggal Verifikasi",
        "reason": "Alasan",
        "warehouse": "Gudang",
        "stock_adjustment": "Penyesuaian Stok",
        "source_warehouse_name": "Nama Gudang Asal",
        "source_warehouse_code": "Kode Gudang Asal",
        "destination_warehouse_name": "Nama Gudang Tujuan",
        "destination_warehouse_code": "Kode Gudang Tujuan",
        "product": "Produk",
        "show_product": "Tampilkan Produk",
        "hide_product_with_substitute": "Sembunyikan Produk dengan Pengganti",
        "substitute": "Pengganti",
        "show_all_substitute": "Tampilkan Semua Pengganti",
        "show_non_zero_substitute": "Tampilkan Pengganti yang Dialokasikan",
        "hide_substitute": "Sembunyikan Pengganti",
        "combines_line_item_global_discount": "Gabungkan Diskon Global Item Baris",
        "unit_price_before_discount": "Harga Satuan Sebelum Diskon",
        "unit_price_after_discount": "Harga Satuan Setelah Diskon",
        "discount_per_unit": "Diskon Per Unit",
        "show_all": "Tampilkan Semua",
        "percentage": "Persentase",
        "cogs": "Harga pokok penjualan",
        "na": "NA",
        "con_number": "Nomor Pesanan Pelanggan",
        "document_label": "Label Barcode",
        "expected_delivery_date": "Tanggal Pengiriman Diharapkan",
        "transaction_type": "Jenis Transaksi",
        "transaction_no": "Nomor Transaksi",
        "status": "Status",
        "qc_document": "Dokumen QC",
        "linked_po_so": "PO/SO Terhubung",
        "approver": "Pengesah",
        "sample_size": "Ukuran Sampel",
        "number_of_qty_passed": "Jumlah Qty Yang Lulus",
        "number_of_qty_rejected": "Jumlah Qty Yang Ditolak",
        "final_remark": "Catatan Akhir",
        "value": "Nilai",
        "exchange_conversion": "Konversi Pertukaran",
        "contact_address": "Kontak Alamat",
        "cheque_fields": "Bidang Cek",
        "cheque_details": "Detail Cek",
        "sales_return": "Pengembalian Penjualan",
        "fulfilled_quantity": "Jumlah yang Dipenuhi",
        "returned_quantity": "Jumlah yang Dikembalikan",
        "parent_linked_document_no": "Dokumen Induk No",
        "parent_linked_document_type": "Jenis Dokumen Induk",
        "swap_address": "Tukar Alamat",
        "vendor_address": "Alamat Vendor",
        "job_work_out_order": "Pekerjaan Keluar Pesanan",
        "machine": "Schedulatore macchina",
        "kind_of_packing": "No. & Jenis Kemasan",
        "due_on": "Jatuh tempo pada",
        "per": "Per",
        "voucher_no": "Nomor Voucher",
        "dated": "Tanggal",
        "supplier_ref_order_no": "Referensi Pemasok/Nomor Pesanan",
        "mode_term_of_payment": "Mode/Term Pembayaran",
        "dispatch_doc_no": "Nomor Dokumen Pengiriman",
        "dispatched_through": "Dikirim Melalui",
        "duration_of_process": "Durasi Proses",
        "nature_of_processing": "Sifat Pengolahan",
        "company_pan": "PAN Perusahaan",
        "authorised_signatory": "Penandatangan yang Disahkan",
        "additional_charges": "Biaya tambahan",
        "contact_no": "nomor kontak",
        "supplier_inv_no": "Nomor Inv Pemasok",
        "basic_salary_wo_lop": "Gaji Pokok tanpa Kehilangan Gaji",
        "cost": "Biaya",
        "stock_request": "Permintaan Stok",
        "target_warehouse_name": "Nama Gudang Tujuan",
        "target_warehouse_code": "Kode Gudang Tujuan",
        "selected_row": "Baris yang Dipilih",
        "selected_rack": "Rak yang Dipilih",
        "selected_bin": "Bin yang Dipilih",
        "requested_qty": "Jumlah yang Diminta",
        "created_by": "Dibuat oleh",
        "approved_date": "Tanggal Disetujui",
        "stock_issue": "Pengeluaran Stok",
        "gate_entry": "Masuk Gerbang Keamanan",
        "issued_quantity": "Jumlah yang Dikeluarkan",
        "bom_product_table": "Tabel Produk BOM",
        "list": "Daftar",
        "is_multi_product_table": "Tabel Multi Produk",
        "is_show_optional_group": "Kelompok Produk Opsional",
        "tax_registration_number": "Nomor Registrasi Pajak",
        "tax_payer_id": "ID Pembayar Pajak",
        "wo_qty": "Jumlah Pesanan Kerja",
        "adhoc_items_message": "* mewakili item AdHoc",
        "production_checklist": "Daftar Produksi",
        "product_description": "Deskripsi Produk",
        "total_quantity": "Total Kuantitas",
        "job_status": "Status Pekerjaan",
        "qc_status": "Status QC",
        "show_job_status": "Tampilkan Status Kartu Pekerjaan",
        "show_instruction": "Tampilkan Instruksi",
        "optional": "Opsional",
        "document_title": "Judul Dokumen",
        "barcode_for_row_rack_bin": "Barcode untuk Baris/Rak/Bin",
        "show_tagged_bin_warehouse": "Gudang Bin Bertanda",
        "hsn_sac_tax_table": "Tabel Pajak HSN/SAC",
        "approval_section": "Bagian Persetujuan",
        "border": "Batas",
        "spacing": "Spasi",
        "checked_by": "Diperiksa oleh",
        "authorized_by": "Disetujui oleh",
        "word_spacing": "Jarak Kata",
        "page_count_settings": "Pengaturan Jumlah Halaman",
        "single_digit": "Angka tunggal",
        "page_with_numeric": "Halaman dengan angka",
        "page_x_of_total_page": "Halaman x dari y",
        "page_x_of_total_page_slash": "halaman x / y",
        "service_requisition": "Permintaan Layanan",
        "service_description": "Deskripsi Layanan",
        "sc_no":"Nomor SC",
        "sc_date":"Tanggal SC",
        "sc_type":"Tipe SC",
        "delivery_order_doc_and_date": "No. & Tanggal Surat Pengiriman",
        "e_way_bill_doc_and_date": "No. & Tanggal E-Way Bill",
        "party_order_doc_and_date": "No. & Tanggal Pesanan Pihak",
        "mode_of_transport": "Moda Transportasi",
        "bank_details": "Detail Bank",
        "bank_name": "Nama Bank",
        "company_seal": "Stempel Perusahaan",
        "regd_office": "Kantor Terdaftar",
        "sub_total_in_words": "Sub total dalam kata",
        "rounding_off_in_words": "Pembulatan dalam kata",
        "outstanding_balance_in_words": "Saldo yang belum dibayar dalam kata",
        "outstanding_balance_includes_current_in_words": "Saldo yang belum dibayar (termasuk saat ini) dalam kata",
        "total_tax_amount_in_words": "Jumlah total pajak dalam kata",
        "before_tax_in_words": "Sebelum pajak dalam kata",
        "additional_charges_in_words": "Biaya tambahan dalam kata-kata",
        "global_discount_in_words": "Diskon global dalam kata",
        "cgst_in_words": "CGST dalam kata",
        "igst_in_words": "IGST dalam kata",
        "sgst_in_words": "SGST dalam kata",
        "person_name": "Nama orang",
        "store_officer": "Petugas toko",
        "department_holder": "Kepala Departemen",
        "purchase_department": "Departemen pembelian",
        "issue_by": "Dikeluarkan oleh",
        "paper_orientation": "Orientasi Kertas",
        "landscape": "Lanskap",
        "portrait": "Potret",
        "purchase_requisition": " Permintaan Pembelian",
        "phone": " Telepon",
        "requisition_qty": " Jumlah Permintaan",
        "stock_qty": " Jumlah Stok",
        "prev_purchase_rate": " Sebelumnya Tingkat Pembelian",
        "scheduled_date": " Tanggal yang Dijadwalkan",
        "estimate_rate": " Perkiraan Tingkat",
        "estimate_value": " Nilai Perkiraan",
        "cost_center": " Pusat biaya",
        "reserved": " Disimpan",
        "division": " Divisi",
        "department_name": " nama departemen",
        "indent_no": " nomor inden",
        "indent_date": " Tanggal Inden",
        "indent_type": " Tipe Indentasi",
        "project": " Proyek",
        "authorised_date": " Tanggal Resmi",
        "requested_by": " Diminta oleh",
        "for": " Untuk",
        "authorised_by": " Diotorisasi oleh",
        "available_qty": "Jumlah yang tersedia",
        "service_order": " Pesanan Layanan",
        "gst_no": " Nomor GST",
        "gstin_no": " Nomor GSTIN",
        "pan_no": " Nomor PAN",
        "cin_no": " Nomor CIN",
        "cont_person": " Lanjutan Orang",
        "order_date": " Tanggal pemesanan",
        "shipment_address": " Alamat Pengiriman",
        "prepared_date_time": "Tanggal dan Waktu Persiapan",
        "only": "Hanya",
        "completed": "Selesai",
        "location_detail": "Detail Lokasi",
        "transporter_detail": "Detail Pengangkut",
        "item_detail": "Detail Item",
        "material_receipt_note": "Catatan Penerimaan Bahan",
        "mrn_no": "Nomor MRN",
        "authorized": "Resmi",
        "inspected_by": "Diperiksa Oleh",
        "store_manager": "Manajer toko",
        "hod": "H.O.D",
        "account_manager": "Manajer Akuntansi",
        "po_bill_no_date": "Nomor PO / Tanggal\nNomor Tagihan / Tanggal",
        "short_excess_rejected_qty": "Jumlah Pendek / Kelebihan / Ditolak.",
        "accepted_qty": "Jumlah yang Diterima.",
        "basic_amt": "Amt Dasar.",
        "charges": "Biaya",
        "last_po": "PO terakhir",
        "cgst": "CGST",
        "sgst": "SGST",
        "igst": "IGST",
        "gst": "GST",
        "total_amount": "Jumlah total",
        "total_passed_amount": "Jumlah Total yang Lulus",
        "inspection_required": "Diperlukan Inspeksi",
        "qc_inspection": "Inspeksi QC",
        "work_order_no": "Nomor Surat Perintah Kerja",
        "work_order_date": "Tanggal Surat Perintah Kerja",
        "work_order_due_date": "Tanggal Jatuh Tempo Surat Perintah Kerja",
        "work_order_status": "Status Surat Perintah Kerja",
        "edit_table_columns": "Edit Kolom Tabel",
        "incoming": "Masuk",
        "final": "Final",
        "label": "Label",
        "both": "Keduanya",
        "inspection_report": "Laporan Inspeksi",
        "inspection_date": "Tanggal",
        "parts": "Bagian",
        "reworks": "Pekerjaan Ulang",
        "final_inspection": "Inspeksi Akhir",
        "product_listing": "Daftar Produk",
        "paper_margin": "Margin Kertas",
        "is_using_custom_margin": "Menggunakan Margin Khusus",
        "soa_customer": "Laporan Rekening",
        "soa_vendor": "Laporan Rekening - Vendor",
        "company_reg_no": "No. Registrasi Perusahaan",
        "gst_reg_no": "No. Registrasi GST",
        "from_date": "Dari Tanggal",
        "as_of_date": "Hingga Tanggal",
        "to_date": "Sampai Tanggal",
        "document_number": "Nomor Dokumen",
        "particular": "Rincian",
        "amount_in_base": "Jumlah Dalam Dasar",
        "balance_in_base": "Saldo dalam Dasar",
        "ifsc_code": "Kode IFSC",
        "purchase_request_for_quotes": "Permintaan Penawaran",
        "purchase_inward_quotation": "Kutipan Pembelian Masuk",
        "rfq_no": "No. Permintaan Penawaran",
        "show_zero": "Tampilkan Nol",
        "show_total": "Tampilkan Total",
        "draft_watermark": "Tanda Air Draf",
        "draft": "Draf",
        "rotation": "Rotasi",
        "invoice_summary": "Ringkasan Faktur",
        "bill_summary": "Ringkasan Tagihan",
        "amount_due": "Jumlah yang Harus Dibayar",
        "total_pending_balance": "Total Saldo Tertunda",
        "serial_batch_custom_fields": "Bidang kustom batch serial",
    }
};

export default id
