import {
  DKIcon,
  DKIcons,
  DKLabel,
  DKTooltipWrapper,
  DKButton,
  DKInput,
  INPUT_TYPE
} from 'deskera-ui-library';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  BOOKS_DATE_FORMAT,
  COUNTRY_CODES,
  DOCUMENT_MODE,
  DOC_TYPE,
  INPUT_VIEW_DIRECTION,
  MODULES_NAME,
  POPUP_CALLBACKS_TYPE,
  POPUP_CLICK_TYPE,
  POPUP_TYPE
} from '../../Constants/Constant';
import {
  BtnType,
  CallBackPayloadType,
  PopupClickActionType,
  UpdateCallBacksRefType
} from '../../Models/Interfaces';
import { useAppDispatch, useAppSelector } from '../../Redux/Hooks';
import { fetchChequeTemplateList } from '../../Redux/Slices/CommonDataSlice';
import DateFormatService from '../../Services/DateFormat';
import { localizedText } from '../../Services/Localization/Localization';
import NumberFormatService from '../../Services/NumberFormat';
import PaymentService from '../../Services/Payment';
import Utility, { getCapitalized } from '../../Utility/Utility';
import PopupWrapper from '../PopupWrapper';
import PrintPreview from '../Printing/PrintPreview';
import AddEmailPopUp from './AddEmailPopUp';
import EmailPaymentRecord from './EmailPaymentRecord';
import PrintCheque from './PrintCheque';
import { CustomFieldsHolder } from '../CustomFieldsHolder/CustomFieldsHolder';
import JournalService from '../../Services/Journal';
import JournalEntryRecordsDetail from '../LinkedRecords/JournalEntryRecordsDetail';
import { isTabletView } from '../../Utility/ViewportSizeUtils';
import MultiViewJournalEntry from '../MultiViewJournalEntry/MultiViewJournalEntry';
import { enableMultiViewJournalEntryChildren } from '../MultiViewJournalEntry/enableMultiViewJounalEntry';
import { activeTenantInfo } from '../../Redux/Slices/AuthSlice';
import ContactService from '../../Services/Contact';

interface PaymentRecordProps {
  documentSequenceCode?: any;
  paymentRecordList: any[] | undefined;
  documentType: DOC_TYPE;
  documentCode: string;
  contactCode: string;
  isReport?: boolean;
  closePopup: () => void;
  passingInteraction?: (callback: CallBackPayloadType) => void;
  isDocumentEmailFlow?: boolean;
  isActionDisabled?: boolean;
  paymentData?: any;
  documentDetails?: any;
  isLinkedRecordView?: boolean;
  selectedIndex?: any;
  contactId?:any
}

const refInitialState: UpdateCallBacksRefType = {
  pushDataToParent: { type: POPUP_CALLBACKS_TYPE.NONE },
  storeCallbacksRef: { updateContact: 'click' }
};

const PaymentRecordsDetail: React.FC<PaymentRecordProps> = (props) => {
  const { t, i18n } = useTranslation();
  const [showPrintPreview, setShowPrintPreview] = useState(false);

  const [recordList, setRecordList] = useState<any>(props.paymentRecordList);

  const [selectedIndex, setSelectedIndex] = useState<any>(0);

  const [addEmailPopUp, setAddEmailPopUp] = useState(false);

  const [emailPaylaod, setEmailPaylaod] = useState<any>({});

  const emailPaymentRef = useRef<UpdateCallBacksRefType>(refInitialState);

  const [feeAccountName, setFeeAccountName] = useState<string>('');

  const [showPrintCheque, setShowPrintCheque] = useState<boolean>(false);

  const [chequeData, setChequeData] = useState({});

  const [paymentRecordEmailPopup, setPaymentRecordEmailPopup] = useState(false);

  const [customField, setCustomField] = useState<any>([]);

  const [contactDetails, setContactDetails] = useState<any>(null);
  const tenantInfo = useAppSelector(activeTenantInfo);
  const [jERecords, setJERecords] = useState<any>({});
  const [showMultiViewJournalEntry, setShowMultiViewJournalEntry] =
    useState<boolean>(false);
  let module = MODULES_NAME.BILL;
  if (props.documentType === DOC_TYPE.INVOICE) {
    module = MODULES_NAME.INVOICE;
  }

  const [paymentData, setPaymentData] = useState<any>({
    accountName: '-',
    code: '-',
    id: '-',
    currency: 'INR',
    amount: '0.00',
    documentDate: '-',
    paymentType: '',
    referenceDate: '-',
    referenceNumber: '-',
    contactCode: '-',
    transactionFee: '-1',
    transactionFeeAccountName: '',
    customerOrderNumber: undefined
  });

  useEffect(() => {
    if (props.paymentData) {
      var documentCode = props.paymentData.code;
      if (documentCode === undefined || documentCode === null) {
        documentCode = props.paymentData.documentCode;
      }
      setSelectedIndex(props.selectedIndex);
    }
  }, [props]);

  useEffect(() => {
    setFeeAccountName('');
    getPaymentDetails();
  }, [selectedIndex]);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (tenantInfo.country === COUNTRY_CODES.IL && props?.contactId) {
      fetchContactInfo();
    }
  }, []);

  const fetchContactInfo = () => {
    ContactService.getContactDetailsById(
      props.contactId
    ).then((res: any) => {
      if (res) {
        setContactDetails(res);
      }
    });
  };

  const getTransactionFeeAccount = (code: any) => {
    try {
      PaymentService.getFeeAccountData(code).then((data: any) => {
        let response = data.content[0];
        setFeeAccountName(`(` + response.name + `)`);
      });
    } catch (err) {
      console.error('Error loading Fee Accounts: ', err);
    }
  };

  const getTransactionFee = (receivePaymentFeeDtoList: any) => {
    if (receivePaymentFeeDtoList.length > 0) {
      getTransactionFeeAccount(receivePaymentFeeDtoList[0].accountCode);
      let sum = 0;
      receivePaymentFeeDtoList.map((paymentFee: any) => {
        sum += paymentFee.amount;
      });
      return sum;
    } else {
      return -1;
    }
  };

  const getJERecord = (id: any) => {
    JournalService.getNormalJEByCode(id)
      .then((data: any) => {
        setJERecords(data.content?.[0] || {});
      })
      .catch((err) => {
        console.error('Error while fetching JE', err);
      });
  };

  const getPaymentDetails = () => {
    const newState = [...recordList];
    const obj = newState[selectedIndex];
    setCustomField(null);
    if (obj) {
      if (
        obj.documentType === 'RECEIVE_PAYMENT' ||
        obj.documentType === 'MAKE_PAYMENT' ||
        obj.documentType === 'DEPOSIT_ADVPAYMENT' ||
        obj.documentType === 'EXPENSE_PREPAYMENT'
      ) {
        PaymentService.fetchPaymentRecordDetails(
          obj.documentCode,
          props.documentType
        ).then((res: any) => {
          const content: any[] = res.content;
          if (content.length > 0 && newState) {
            const paymentDetails: any = content[0];

            setCustomField(paymentDetails.customField);

            if (props.isLinkedRecordView && paymentDetails?.jeCode) {
              getJERecord(paymentDetails?.jeCode);
            }
            var documentDate = Utility.isEmpty(paymentDetails?.paymentTransTime)
              ? DateFormatService.getDateFromStr(
                  paymentDetails.documentDate,
                  BOOKS_DATE_FORMAT['YYYY-MM-DD']
                )
              : DateFormatService.getDateInCountryFormat(
                  paymentDetails?.paymentTransTime,
                  tenantInfo?.timezone
                );
            var referenceDate = new Date(paymentDetails.referenceDate);
            if (
              obj.documentType === DOC_TYPE.DEPOSIT_ADVANCED_PAYMENT ||
              obj.documentType === DOC_TYPE.PREPAYMENT
            ) {
              documentDate = DateFormatService.getDateFromStr(
                obj.documentDate,
                BOOKS_DATE_FORMAT['YYYY-MM-DD']
              );
            }
            if (obj.documentType === 'RECEIVE_PAYMENT') {
              const obj1 = {
                accountName: paymentDetails.accountName,
                customerOrderNumber:
                  paymentDetails.receivePaymentItemDtoList.length > 0
                    ? paymentDetails.receivePaymentItemDtoList[0]
                        .customerOrderNumber
                    : undefined,
                code: paymentDetails.code,
                customField: paymentDetails.customField,
                linkedDocumentSequence: paymentDetails.linkedDocumentSequence,
                documentCode: paymentDetails.documentCode,
                id: paymentDetails.id,
                currency: paymentDetails.currency,
                amount: obj.amount,
                paymentType: paymentDetails.paymentType,
                referenceDate: referenceDate
                  ? DateFormatService.getDateStrFromDate(referenceDate)
                  : '-',
                referenceNumber: paymentDetails.referenceNumber
                  ? paymentDetails.referenceNumber
                  : '-',
                documentDate: documentDate
                  ? DateFormatService.getDateStrFromDate(documentDate)
                  : '-',
                contactCode: paymentDetails.contactCode,
                transactionFee:
                  paymentDetails.receivePaymentFeeDtoList.length > 0
                    ? getTransactionFee(paymentDetails.receivePaymentFeeDtoList)
                    : -1,
                journalEntryCode: paymentDetails.jeCode
              };
              setPaymentData(obj1);
            } else {
              const obj1 = {
                accountName: paymentDetails.accountName,
                code: paymentDetails.code,
                customField: paymentDetails.customField,
                linkedDocumentSequence: paymentDetails.linkedDocumentSequence,
                documentCode: paymentDetails.documentCode,
                id: paymentDetails.id,
                currency: paymentDetails.currency,
                amount: obj.amount,
                paymentType: paymentDetails.paymentType,
                referenceDate: referenceDate
                  ? DateFormatService.getDateStrFromDate(referenceDate)
                  : '-',
                referenceNumber: paymentDetails.referenceNumber
                  ? paymentDetails.referenceNumber
                  : '-',
                documentDate: documentDate
                  ? DateFormatService.getDateStrFromDate(documentDate)
                  : '-',
                contactCode: paymentDetails.contactCode,
                journalEntryCode: paymentDetails.jeCode
              };
              setPaymentData(obj1);
            }
          }
        });
      } else {
        const obj1 = {
          code: obj.code,
          linkedDocumentSequence: obj.linkedDocumentSequence,
          documentCode: obj.linkedDocumentSequence,
          id: obj.id,
          currency: obj.currency,
          amount: obj.amount,
          paymentType: obj.documentType,
          referenceDate: '',
          referenceNumber: '',
          documentDate: obj.documentDate
            ? DateFormatService.getDateStrFromDate(new Date(obj.documentDate))
            : '-'
        };
        if (
          obj.documentType === 'CREDIT_NOTE' ||
          obj.documentType === 'DEBIT_NOTE'
        ) {
          obj1['documentCode'] = obj.documentCode;
        }
        setPaymentData(obj1);
      }
    }
  };

  const catchClicks = (data: PopupClickActionType) => {
    switch (data.type) {
      case POPUP_CLICK_TYPE.CLOSE_POPUP:
        setAddEmailPopUp(false);
        break;
      case POPUP_CLICK_TYPE.SEND_PAYMENT_MAIL:
        emailPaymentRef.current.storeCallbacksRef.sendPaymentEmail();
        break;
    }
  };

  const parentChildInteraction = (passingData: CallBackPayloadType) => {
    switch (passingData.type) {
      case POPUP_CALLBACKS_TYPE.CLOSE_POPUP:
        setAddEmailPopUp(false);
        break;
      case POPUP_CALLBACKS_TYPE.SEND_PAYMENT_MAIL:
        emailPaymentRef.current.storeCallbacksRef.sendPaymentEmail =
          passingData.data;
        break;
    }
  };

  const popupBtnConfigForAddWareHouse: BtnType[] = [
    {
      title: t(`WAREHOUSES.BUTTON.CANCEL`),
      class: 'border-m mr-s',
      clickAction: POPUP_CLICK_TYPE.CLOSE_POPUP
    },
    {
      title: 'Send',
      class: 'bg-app text-white mr-ss',
      clickAction: POPUP_CLICK_TYPE.SEND_PAYMENT_MAIL
    }
  ];

  const getPaymentTypeLabel = (type: string) => {
    return type === 'BANK_TRANSFER'
      ? 'Bank Transfer'
      : type === 'CASH'
      ? 'Cash'
      : type === 'CHEQUE'
      ? `${getCapitalized(localizedText('cheque'))}`
      : type === 'CARD'
      ? 'Card'
      : type === 'CREDIT_NOTE'
      ? 'Credit Note'
      : type === 'DEBIT_NOTE'
      ? 'Debit Note'
      : type === 'SALES_INVOICE'
      ? 'Invoice Offset'
      : type === 'PURCHASE_INVOICE'
      ? 'Bill Offset'
      : type === 'OTHERS'
      ? 'Others'
      : type === undefined || type === null
      ? ''
      : type;
  };

  const getAccountLabel = () => {
    var type =
      recordList[selectedIndex] && recordList[selectedIndex].documentType
        ? recordList[selectedIndex].documentType
        : '';
    return type === 'EXPENSE_PREPAYMENT' ||
      type === 'MAKE_PAYMENT' ||
      type === 'SALES_INVOICE'
      ? 'Pay From'
      : 'Deposited To';
  };

  const getDocumentTypeForPrintRecord = () => {
    const type =
      recordList[selectedIndex] && recordList[selectedIndex].documentType
        ? recordList[selectedIndex].documentType
        : '';

    return type;
  };

  const getParentDocumentType = () => {
    return props.documentType;
  };

  const getParentDocumentCode = () => {
    return props.documentCode;
  };

  const getUid = () => {
    return recordList[selectedIndex].uid;
  };

  const printCheque = () => {
    let data: any = [];
    let _payload = {
      date: paymentData.documentDate,
      payTo: paymentData.accountName,
      amount: NumberFormatService.getNumber(paymentData.amount),
      amountWords: Utility.amountToWords(
        paymentData.amount,
        paymentData.currency
      ),
      currency: paymentData.currency,
      memo: ''
    };
    data.push(_payload);
    setChequeData(data);
    dispatch(fetchChequeTemplateList());
    setShowPrintCheque(true);
  };

  const showCustomFieldView = () => {
    return (
      <div className="column parent-width mt-l">
        <DKLabel text="Custom Fields" className="fw-m" />
        <CustomFieldsHolder
          moduleName={module}
          customFieldsList={customField || []}
          columnConfig={[]}
          documentMode={DOCUMENT_MODE.VIEW}
          columnConfigTableId={''}
          onUpdate={(list: any) => {}}
        />
      </div>
    );
  };

  const getIsraelTaxRow = () => {
    return (
      <>
        {
          <>
            <div className="row" style={{marginTop: "40px"}}>
              <div
                style={{
                  width: 180,
                  maxWidth: 200,
                  wordBreak: 'break-word'
                }}
              >
                <DKInput
                  className="parent-width"
                  title="Tax Payer Id"
                  value={contactDetails?.taxPayerIdIsrael || ''}
                  titleStyle={{ color: 'gray' }}
                  valueStyle={{ minHeight: 33 }}
                  textAlign="left"
                  type={INPUT_TYPE.TEXT}
                  onChange={(value: any) => {}}
                  canValidate={false}
                  direction={INPUT_VIEW_DIRECTION.VERTICAL}
                  required={false}
                  readOnly={true}
                />
              </div>
              <div
                style={{
                  width: 180,
                  maxWidth: 200,
                  wordBreak: 'break-word',
                  marginLeft: '10px'
                }}
              >
                <DKInput
                  className="parent-width"
                  title="Tax Registration Number"
                  value={contactDetails?.taxNumber || ''}
                  titleStyle={{ color: 'gray' }}
                  valueStyle={{ minHeight: 33 }}
                  textAlign="left"
                  type={INPUT_TYPE.TEXT}
                  onChange={(value: any) => {}}
                  canValidate={false}
                  direction={INPUT_VIEW_DIRECTION.VERTICAL}
                  required={false}
                  readOnly={true}
                />
              </div>
            </div>
          </>
        }
      </>
    );
  };

  const showJERecordView = () => {
    return (
      <div className="column parent-width mt-l">
        <DKLabel text="Journal Entry Record" className="fw-m" />
        {jERecords && (
          <JournalEntryRecordsDetail
            documentDetails={props.documentDetails}
            journalEntryDetails={jERecords || []}
            removeHorizontalPadding={true}
          />
        )}
      </div>
    );
  };

  return (
    <div className="flex parent-width justify-content-between align-self-start parent-height mt-m">
      {showPrintPreview && (
        <PrintPreview
          documentType={getDocumentTypeForPrintRecord()}
          document={{
            ...recordList[selectedIndex],
            contactCode: props.contactCode
          }}
          closePreview={(val: boolean) => setShowPrintPreview(val)}
          parentDocumentType={getParentDocumentType()}
          parentDocumentCode={getParentDocumentCode()}
          uid={getUid()}
        />
      )}

      {paymentRecordEmailPopup && (
        <EmailPaymentRecord
          documentType={getDocumentTypeForPrintRecord()}
          document={{
            ...recordList[selectedIndex],
            contactCode: props.contactCode
          }}
          closePreview={(val: boolean) => setPaymentRecordEmailPopup(val)}
          parentDocumentType={getParentDocumentType()}
          parentDocumentCode={getParentDocumentCode()}
          uid={getUid()}
          emailPaylaod={emailPaylaod}
        />
      )}
      <div className="flex parent-width flex-col px-3">
        <DKLabel
          className="fw-m text-base"
          style={{ fontSize: '20px' }}
          text="Amount Settled"
        />
        {enableMultiViewJournalEntryChildren({
          children: (
            <div className="pl-3 flex justify-end">
              <DKButton
                title={'Show GL Impact'}
                className="mr-r bg-gray-100"
                onClick={() => {
                  setShowMultiViewJournalEntry(true);
                }}
              />
            </div>
          )
        })}
        <div className="pl-3">
          <div
            className="flex justify-center items-baseline ml-3 "
            style={{ width: '80%' }}
          >
            <span className="text-base mr-2 ">
              {Utility.getCurrencySymbolFromCode(paymentData.currency)}
            </span>
            <DKLabel
              style={{ fontSize: '30px' }}
              text={NumberFormatService.getNumber(paymentData.amount)}
            />
          </div>
          <div className="mt-3 parent-width">
            <div className="flex justify-between parent-width">
              <DKLabel className="text-gray-400" text="Payment Type" />
              <div className="flex">
                <DKLabel
                  className={`${
                    paymentData.paymentType === 'CHEQUE' ? `mr-2` : ``
                  }`}
                  text={getPaymentTypeLabel(paymentData.paymentType)}
                />
                {!isTabletView() && paymentData.paymentType === 'CHEQUE' && (
                  <DKTooltipWrapper
                    content={`Print ${getCapitalized(localizedText('cheque'))}`}
                    tooltipClassName="bg-deskera-secondary width-auto"
                  >
                    <DKIcon
                      src={DKIcons.ic_printer}
                      className="ic-s cursor-hand"
                      style={{ opacity: 0.6 }}
                      title="Print"
                      onClick={() => printCheque()}
                    />
                  </DKTooltipWrapper>
                )}
              </div>
            </div>
            <div className="flex justify-between parent-width mt-1">
              <DKLabel className="text-gray-400" text="Payment Date" />
              <DKLabel text={paymentData.documentDate} />
            </div>
            {paymentData.accountName && (
              <div className="flex justify-between parent-width mt-1">
                <DKLabel className="text-gray-400" text={getAccountLabel()} />
                <DKLabel text={paymentData.accountName} />
              </div>
            )}
            {Number(paymentData.transactionFee) >= 0 && (
              <div className="flex justify-between parent-width mt-1">
                <DKLabel className="text-gray-400" text="Transaction Fee" />
                <DKLabel
                  text={`${Utility.getCurrencySymbolFromCode(
                    paymentData.currency
                  )} ${paymentData.transactionFee} ${feeAccountName}`}
                />
              </div>
            )}
          </div>
          {paymentData?.customerOrderNumber && (
            <div className="flex justify-between parent-width mt-1">
              <DKLabel className="text-gray-400" text="Customer Order Number" />
              <DKLabel text={`${paymentData?.customerOrderNumber}`} />
            </div>
          )}
        </div>
        <div className="mt-6">
          <DKLabel
            className="fw-m text-base"
            style={{ fontSize: '19px' }}
            text={`${getPaymentTypeLabel(paymentData.paymentType)} Details`}
          />
          <div className="flex justify-between mt-3 pl-3 parent-width">
            <DKLabel className="text-gray-400" text=" Reference Date" />
            <DKLabel text={paymentData.referenceDate} />
          </div>
          <div className="flex justify-between mt-1 pl-3 align-items-center parent-width">
            <DKLabel className="text-gray-400" text=" Reference Number" />
            <DKLabel
              className="ml-s word-break"
              text={paymentData.referenceNumber}
            />
          </div>
        </div>
        {tenantInfo.country === COUNTRY_CODES.IL && getIsraelTaxRow()}
        {customField && showCustomFieldView()}
        {props?.isLinkedRecordView &&
          !Utility.isEmptyObject(jERecords) &&
          showJERecordView()}
      </div>
      {showMultiViewJournalEntry && (
        <MultiViewJournalEntry
          onCancel={() => setShowMultiViewJournalEntry(false)}
          documentCode={props.documentCode}
          data={paymentData}
          jeCode={paymentData?.journalEntryCode}
        />
      )}
      {addEmailPopUp && (
        <PopupWrapper
          clickAction={catchClicks}
          type={POPUP_TYPE.POPUP}
          title={'Add Email address for Contact'}
          btnList={popupBtnConfigForAddWareHouse}
          width={'30%'}
          height={'200px'}
        >
          <AddEmailPopUp
            payload={emailPaylaod}
            passingInteraction={(callback: CallBackPayloadType) => {
              parentChildInteraction(callback);
            }}
          />
        </PopupWrapper>
      )}
      {showPrintCheque && (
        <PrintCheque
          data={chequeData}
          onCancel={() => {
            setShowPrintCheque(false);
          }}
        />
      )}
    </div>
  );
};

export default PaymentRecordsDetail;
