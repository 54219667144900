import {
  DKButton,
  DKDataGrid,
  DKIcon,
  DKIcons,
  DKInput,
  DKLabel,
  DKLine,
  DKTooltipWrapper,
  INPUT_TYPE,
  INPUT_VIEW_DIRECTION,
  showAlert,
  showToast,
  TOAST_TYPE
} from 'deskera-ui-library';
import { useEffect, useRef, useState } from 'react';
import {
  BOOKS_DATE_FORMAT,
  CUSTOM_FIELD_TYPE,
  DOC_TYPE,
  MODULES_NAME,
  QTY_ROUNDOFF_PRECISION,
  ROW_RACK_BIN_CONSTANT,
  STATUS_TYPE
} from '../../Constants/Constant';
import { useAppDispatch, useAppSelector } from '../../Redux/Hooks';
import { selectSerialTrackingProduct } from '../../Redux/Slices/SerialTrackingSlice';
import DateFormatService from '../../Services/DateFormat';
import Utility, { deepClone } from '../../Utility/Utility';

import ic_warning_red from '../../Assets/Icons/ic_warning_red.png';
import ApiConstants from '../../Constants/ApiConstants';
import { activeTenantInfo } from '../../Redux/Slices/AuthSlice';
import {
  fetchWarehouseWithRRBCombination,
  selectedWarehouseWithRRBCombination
} from '../../Redux/Slices/WarehouseSlice';
import ProductService from '../../Services/Product';
import { DynamicPopupWrapper } from '../PopupWrapper';
import ReceiveJWOProduct from '../../Components/StockManagement/StockTransfer/ReceiveJWOProduct';
import { t } from 'i18next';
import { selectBatchSerialCustomFields } from '../../Redux/Slices/CommonDataSlice';
import { getNewColumn } from '../../Components/Accounting/JournalEntry/JEHelper';
import { showAddCustomField } from '../CustomField/AddCustomField';
import {
  checkIfLineLevelCustomFieldIsValid,
  updateColumnConfigOnRowClick,
  updateRowDataWithParentCFValues
} from '../DocumentForm/NewDocumentHelper';

export default function SerialTrackedReceive(props: any) {
  const selectSerialTrackingProductData = useAppSelector(
    selectSerialTrackingProduct
  );
  const [localWarehouse, setLocalWarehouse] = useState<any[]>([]);
  const [item, setItem] = useState(props.itemDetails);
  const [saveButtonTapped, setSaveButtonTapped] = useState(false);
  const [allocatedSerialData, setAllocatedSerialData] = useState<any[]>([]);
  const allWarehouseData = useAppSelector(selectedWarehouseWithRRBCombination);

  const [productInventoryWarehouse, setProductInventoryWarehouse] = useState<
    any[]
  >([]);
  const [requiredQuantity, setRequiredQuantity] = useState<any>(
    parseInt(item?.productQuantity)
  );
  const [stocksAvailable, setStockAvailable] = useState(
    item?.product?.availableQuantity
  );
  const [pendingQuantity, setPendingQuantity] = useState(
    item.quantityRequired || Utility.pendingToBeReceivedQuantity(item)
  );
  const [totalAllocatedItem, setTotalAllocatedItem] = useState(0);
  const [advancedTrackingMetaData, setAdvancedTrackingMetaData] = useState(
    item && item.advancedTrackingMetaData
      ? [...item.advancedTrackingMetaData]
      : []
  );
  const [newSerial, setNewSerial] = useState('');
  const [selectedWarehouse, setSelectedWarehouse] = useState<any>({});
  const [unitPrice, setUnitPrice] = useState(item?.unitPrice);
  const [defaultWarehouse, setDefaultWarehouse] = useState<any>();
  const [isBOMRawProductSerialBatch, setIsBOMRawProductSerialBatch] = useState(
    Utility.isBatchOrSerialAdvanceTracking(
      props.itemDetails?.product?.bomProductsConfiguration
    )
  );

  const [rawMaterialData, setRawMaterialData] = useState(null);
  const [isNewCustomFieldAdded, setIsNewCustomFieldAdded] = useState<any>({});
  const [rawMaterialToConsumedData, setRawMaterialToConsumedData] = useState(
    props.details?.rawMaterialToConsume || []
  );
  const batchSerialCFfromStore = useAppSelector(selectBatchSerialCustomFields);
  const cfUpdatedTimeMap = useRef<any>({});

  const dispatch = useAppDispatch();

  //rrb states
  const columns = [
    {
      key: 'warehouse',
      name: 'Warehouse',
      textAlign: 'left',
      type: INPUT_TYPE.TEXT,
      width: 180,
      systemField: true,
      editable: false,
      hidden: false,
      uiVisible: true,
      formatter: (obj: any) => {
        return obj.value.name;
      }
    },
    {
      key: 'serialBatchNumber',
      name: 'Assigned Unit',
      type: INPUT_TYPE.TEXT,
      width: 140,
      textAlign: 'left',
      systemField: true,
      editable: true,
      hidden: false,
      uiVisible: true,
      renderer: (obj: any) => {
        const { rowData = {}, value = null } = obj;
        if (rowData?.invalidFields?.includes('serialBatchNumber')) {
          return (
            <div className="row justify-content-between">
              <DKLabel text={`${value}`} />
              <DKTooltipWrapper
                content={
                  Utility.isNotEmpty(value)
                    ? 'Existing Serial Number'
                    : 'Serial Number Can not be empty'
                }
              >
                <div className="row">
                  <DKIcon
                    src={ic_warning_red}
                    className="ic-xs  ml-s cursor-hand"
                    onClick={() => {}}
                  />
                </div>
              </DKTooltipWrapper>
            </div>
          );
        } else {
          return <DKLabel text={`${value}`} />;
        }
      }
    },
    {
      key: 'row',
      name: 'Select Row',
      type: INPUT_TYPE.DROPDOWN,
      textAlign: 'left',
      width: 120,
      systemField: true,
      editable: true,
      hidden: false,
      uiVisible: true,
      formatter: (obj: any) => {
        return obj?.value ? obj?.value?.name : '';
      },
      dropdownConfig: {
        allowSearch: true,
        searchableKey: 'name',
        data: [],
        renderer: (index: any, value: any) => {
          return value?.name ?? '';
        },
        onSelect: (index: any, value: any) => {}
      }
    },
    {
      key: 'rack',
      name: 'Select Rack',
      type: INPUT_TYPE.DROPDOWN,
      textAlign: 'left',
      width: 120,
      systemField: true,
      editable: true,
      hidden: false,
      uiVisible: true,
      formatter: (obj: any) => {
        return obj?.value ? obj?.value?.name : '';
      },
      dropdownConfig: {
        allowSearch: true,
        searchableKey: 'name',
        data: [],
        renderer: (index: any, value: any) => {
          return value?.name ?? '';
        },
        onSelect: (index: any, value: any) => {}
      }
    },
    {
      key: 'bin',
      name: 'Select Bin',
      type: INPUT_TYPE.DROPDOWN,
      textAlign: 'left',
      width: 120,
      systemField: true,
      editable: true,
      hidden: false,
      uiVisible: true,
      formatter: (obj: any) => {
        return obj?.value ? obj?.value?.name : '';
      },
      dropdownConfig: {
        allowSearch: true,
        searchableKey: 'name',
        data: [],
        renderer: (index: any, value: any) => {
          return value?.name ?? '';
        },
        onSelect: (index: any, value: any) => {}
      }
    }
  ];
  const [assignedSerialColumns, setAssignedSerialColumns] = useState(
    columns ?? []
  );
  const tenantInfo = useAppSelector(activeTenantInfo);
  const [productDetails, setProductDetails] = useState<any>();

  useEffect(() => {
    if (Utility.isEmpty(allWarehouseData)) {
      const config: any = {
        SearchTerm: '',
        Limit: 50,
        Page: 0,
        Query: 'active=true&allRRBDetails=true'
      };
      dispatch(fetchWarehouseWithRRBCombination(config));
    }
    ProductService.getProductsByProductIds([props.itemDetails?.productCode])
      .then((res: any) => {
        setProductDetails(res?.[0]);
      })
      .catch((err: any) => {});

    let updatedColumnsWithColumnNames = getUpdatedColumnNames();
    setAssignedSerialColumns([...updatedColumnsWithColumnNames]);
  }, []);

  useEffect(() => {
    if (Utility.isNotEmpty(batchSerialCFfromStore?.content)) {
      updateConfig(
        selectedWarehouse?.warehouseRackInfos ?? [],
        selectedWarehouse?.warehouseBinInfos ?? []
      );
      let updatedState = [...advancedTrackingMetaData];
      if (Utility.isNotEmpty(isNewCustomFieldAdded)) {
        updatedState?.forEach((serial: any) => {
          if (Utility.isEmpty(serial[isNewCustomFieldAdded.id])) {
            if (
              isNewCustomFieldAdded.fieldType.toLowerCase() ===
              INPUT_TYPE.DATE.toLowerCase()
            ) {
              serial[isNewCustomFieldAdded.id] =
                DateFormatService.getDateFromStr(
                  isNewCustomFieldAdded.defaultValue,
                  BOOKS_DATE_FORMAT['MM/DD/YYYY']
                );
            } else {
              serial[isNewCustomFieldAdded.id] =
                isNewCustomFieldAdded.defaultValue;
            }
          }
        });
        setAdvancedTrackingMetaData([...updatedState]);
      }
    }
  }, [batchSerialCFfromStore, isNewCustomFieldAdded]);

  useEffect(() => {
    if (
      Utility.isNotEmpty(props?.itemDetails?.advancedTrackingMetaData) &&
      Utility.isEmpty(isNewCustomFieldAdded)
    ) {
      let updatedData: any = [];
      props.itemDetails.advancedTrackingMetaData.forEach((data: any) => {
        let item = {
          ...data
        };
        item = addProductCustomFieldsToLineItem({ ...item });
        updatedData.push(item);
      });
      setAdvancedTrackingMetaData(updatedData);
    }
  }, [props?.itemDetails]);

  useEffect(() => {
    if (!Utility.isEmpty(localWarehouse)) {
      onWarehouseSelection(0);
    }
  }, [localWarehouse]);

  useEffect(() => {
    if (!Utility.isEmpty(allWarehouseData?.content)) {
      let filteredData = allWarehouseData?.content.filter(
        (ele: any) => ele.warehouseType === 'NONE'
      );
      if (!Utility.isEmpty(filteredData)) {
        setProductInventoryWarehouse(filteredData || []);
      }
    }
  }, [allWarehouseData]);

  useEffect(() => {
    if (!Utility.isEmpty(props.itemDetails)) {
      setAllocatedSerialData(
        props?.itemDetails?.advancedTrackingFulfilmentData?.map((v: any) => ({
          ...v,
          allocated: true,
          selected: true
        })) || []
      );
    }
  }, [props.itemDetails]);

  useEffect(() => {
    if (!Utility.isEmpty(localWarehouse) && !Utility.isEmpty(productDetails)) {
      setDefaultWarehouseAction();
    }
  }, [localWarehouse, productDetails]);

  useEffect(() => {
    // resetAllocation();
    if (!Utility.isEmpty(productInventoryWarehouse)) {
      let warehouses = [...productInventoryWarehouse];
      if (props?.targetWarehouse) {
        warehouses = warehouses.filter(
          (element: any) => element?.code === props.targetWarehouse
        );
      }
      if (
        Utility.isRRBTaggingEnabled() &&
        Utility.isNotEmpty(props.taggedWarehouse) &&
        !Utility.isEmpty(warehouses)
      ) {
        let uniqueRows =
          warehouses?.[0]?.warehouseRowInfos?.filter(
            (ele: any) => ele.code === props.taggedWarehouse?.rowCode
          ) ?? [];
        let uniqueRacks =
          warehouses?.[0]?.warehouseRackInfos?.filter(
            (ele: any) => ele.code === props.taggedWarehouse?.rackCode
          ) ?? [];
        let uniqueBins =
          warehouses?.[0]?.warehouseBinInfos?.filter(
            (ele: any) => ele.code === props.taggedWarehouse?.binCode
          ) ?? [];

        warehouses = [
          {
            ...warehouses[0],
            warehouseRowInfos: uniqueRows,
            warehouseRackInfos: uniqueRacks,
            warehouseBinInfos: uniqueBins
          }
        ];
      }
      setLocalWarehouse(warehouses);
    }
  }, [selectSerialTrackingProductData, productInventoryWarehouse]);

  const setDefaultWarehouseAction = () => {
    let defaultWarehouseData: any;
    defaultWarehouseData =
      localWarehouse.find(
        (warehouse: any) =>
          warehouse.code === productDetails?.inventory?.warehouseCode
      ) || localWarehouse[0];
    if (Utility.isEmpty(defaultWarehouseData)) {
      defaultWarehouseData =
        localWarehouse.find((warehouse: any) => warehouse.primary) ||
        localWarehouse[0];
    }
    setDefaultWarehouse({ ...defaultWarehouseData });
    setSelectedWarehouse(defaultWarehouseData);
  };

  const updateConfig = (rackOptions: any, binOptions: any) => {
    let config = [...assignedSerialColumns];
    let activeProductCustomFields = [];
    if (!Utility.isEmpty(batchSerialCFfromStore?.content)) {
      let productCf = batchSerialCFfromStore?.content?.filter((item: any) => {
        return item.status === STATUS_TYPE.ACTIVE;
      });
      activeProductCustomFields = productCf.sort(
        (field1: any, field2: any) =>
          field1.customFieldIndex - field2.customFieldIndex
      );
    }

    activeProductCustomFields?.forEach((accCF: any) => {
      let newItem: any = getNewColumn(accCF);
      const newItemInExistingColConfig = config.find(
        (config: any) => config.code === accCF.code
      );
      if (Utility.isEmpty(newItemInExistingColConfig)) {
        config.push({ ...newItem });
      }
    });

    config.forEach((conf: any) => {
      switch (conf.key) {
        case 'rack':
          conf.dropdownConfig.data = rackOptions ?? [];
          conf.dropdownConfig.renderer = (index: any, obj: any) => {
            return obj?.name ?? '';
          };
          return;

        case 'bin':
          conf.dropdownConfig.data = binOptions ?? [];
          conf.dropdownConfig.renderer = (index: any, obj: any) => {
            return obj?.name ?? '';
          };
          return;

        default:
          break;
      }
    });
    setAssignedSerialColumns([...config]);
  };

  const getRequireQuantity = () => {
    return props.itemDetails.documentUOMSchemaDefinition
      ? Utility.getUomQuantity(
          item?.productQuantity,
          props.itemDetails.documentUOMSchemaDefinition
        ) +
          `${
            props.itemDetails.documentUOMSchemaDefinition
              ? ' ' + props.itemDetails.documentUOMSchemaDefinition.name
              : ''
          }`
      : item?.productQuantity;
  };

  const onWarehouseSelection = (code: any) => {
    let selectedWarehouse = localWarehouse?.[code];
    let updateSerial: any[] = [];
    let serialData: any[] = [];
    let inventoryWarehouse = localWarehouse?.find(
      (warehouse: any) => warehouse.code === selectedWarehouse.code
    );
    let result = inventoryWarehouse?.advancedTrackingMeta?.map((item: any) => {
      return { ...item, warehouseName: inventoryWarehouse?.name };
    });
    serialData = result?.filter(
      (serial: any) =>
        serial.batchSizeFulfilled <= serial.batchSize &&
        serial.reservedQuantity <= serial.batchSize
    );

    updateSerial = serialData;
    if (
      props.itemDetails &&
      props.itemDetails.advancedTrackingFulfilmentData &&
      props.itemDetails.advancedTrackingFulfilmentData.length > 0 &&
      serialData &&
      serialData.length > 0
    ) {
      const remainingAvailableSerial = serialData.filter(
        (e: any) =>
          !props.itemDetails.advancedTrackingFulfilmentData.some(
            (a: any) => a.serialBatchNumber === e.serialBatchNumber
          )
      );
      const allocatedAvailableSerial = serialData.filter((e: any) =>
        props.itemDetails.advancedTrackingFulfilmentData.some((a: any) => {
          if (a.serialBatchNumber === e.serialBatchNumber) {
            return {
              ...e,
              selected: !e?.selected,
              qtyToFulfil: a.qtyToFulfil
            };
          }
        })
      );

      updateSerial = remainingAvailableSerial;
      if (allocatedAvailableSerial && allocatedAvailableSerial.length > 0) {
        const newArr1 =
          allocatedAvailableSerial.map((v) => ({
            ...v,
            allocated: true,
            selected: true,
            qtyToFulfil: v.batchSize
          })) || [];
      }
    }

    if (updateSerial && updateSerial.length > 0) {
      const remainingAvailableSerial = updateSerial.filter(
        (e: any) =>
          !allocatedSerialData.some(
            (a: any) => a.serialBatchNumber === e.serialBatchNumber
          )
      );
    }
  };

  const getActiveWarehouses = () => {
    if (!Utility.isEmpty(localWarehouse)) {
      return localWarehouse?.map((item: any) => item.name);
    } else {
      return [];
    }
  };

  const getLineItemCFs = (lineItem: any) => {
    let oldColConfigs = assignedSerialColumns;
    let colConfigsWithOnlyCF = oldColConfigs?.filter(
      (item: any) => item.isCustomField
    );
    let newCfs: any[] = [];
    if (!Utility.isEmpty(batchSerialCFfromStore?.content)) {
      colConfigsWithOnlyCF.forEach((colConfigItem: any) => {
        const cf: any = batchSerialCFfromStore?.content.find(
          (cfItem: any) => colConfigItem.id === cfItem.id
        );
        if (typeof cf !== 'undefined' && cf !== null) {
          let cfValue;
          if (cf.fieldType.toLowerCase() === INPUT_TYPE.DATE.toLowerCase()) {
            cfValue = DateFormatService.getDateStrFromDate(
              new Date(lineItem[cf.id]),
              BOOKS_DATE_FORMAT['MM/DD/YYYY']
            );
          } else if (cf.fieldType.toLowerCase() === 'user') {
            const tempCF = cf?.attributes?.find(
              (attr: any) => attr.code === lineItem[cf.id]?.code
            );
            if (tempCF) {
              cfValue = tempCF.code;
            }
          } else if (
            cf.fieldType.toLowerCase() === INPUT_TYPE.DROPDOWN.toLowerCase()
          ) {
            cfValue = lineItem[cf.id] ? lineItem[cf.id].value : '';
            if (cfValue === undefined) cfValue = lineItem[cf.id];
          } else {
            cfValue = lineItem[cf.id] ? lineItem[cf.id] : '';
          }

          newCfs.push({
            id: cf.id,
            code: cf.code,
            label: cf.label,
            module: 'BATCHSERIAL',
            shortName: cf.shortName,
            value: cfValue
          });
        }
      });
    }

    return newCfs;
  };
  const addProductCustomFieldsToLineItem = (lineItem: any, product?: any) => {
    let cfs: any[] = [];
    if (!Utility.isEmpty(batchSerialCFfromStore?.content)) {
      // Set default values of CFs when new line is added
      lineItem?.customField?.forEach((productCF: any) => {
        const filteredCF = batchSerialCFfromStore?.content?.find(
          (field: any) =>
            field.id === productCF.id && field.status === STATUS_TYPE.ACTIVE
        );
        if (filteredCF) {
          let cfToUpdate = {
            id: filteredCF.id,
            shortName: filteredCF.shortName,
            module: filteredCF.module,
            code: filteredCF.code,
            label: filteredCF.label,
            value: ''
          };
          let valueOfCF = '';
          if (
            typeof productCF.value !== 'undefined' &&
            productCF.value !== null &&
            productCF.value !== ''
          ) {
            if (
              filteredCF.fieldType.toLowerCase() ===
              INPUT_TYPE.DATE.toLowerCase()
            ) {
              lineItem[productCF.id] = DateFormatService.getDateFromStr(
                productCF.value,
                BOOKS_DATE_FORMAT['MM/DD/YYYY']
              );
            } else if (filteredCF.fieldType.toLowerCase() === 'user') {
              const tempCF = filteredCF?.attributes?.find(
                (attr: any) => attr.code === productCF.value
              );
              if (tempCF) {
                lineItem[productCF.id] = tempCF;
              }
            } else if (
              filteredCF.fieldType.toLowerCase() ===
              INPUT_TYPE.DROPDOWN.toLowerCase()
            ) {
              const tempCF = filteredCF?.attributes?.find(
                (attr: any) => attr.value === productCF.value
              );
              if (tempCF) {
                lineItem[productCF.id] = tempCF;
              }
            } else {
              lineItem[productCF.id] = productCF.value;
            }
            valueOfCF = productCF.value;
          } else {
            lineItem[productCF.id] = '';
          }
          cfToUpdate.value = valueOfCF;
          cfs.push(cfToUpdate);
        }
      });
    }
    return { ...lineItem, customField: cfs };
  };

  const onAddCustomFieldClicked = () => {
    const module = 'BATCHSERIAL';
    showAddCustomField(
      {
        forDocument: true,
        module: module,
        moduleNameEnum: MODULES_NAME.BATCH_SERIAL
      },
      (newCustomField) => {
        setIsNewCustomFieldAdded(newCustomField);
        updateConfig(
          selectedWarehouse?.warehouseRackInfos ?? [],
          selectedWarehouse?.warehouseBinInfos ?? []
        );
      }
    );
  };

  const onSave = () => {
    if (props.onSerialSave) {
      let serialTrackingData: any[] = [];
      const currentDate = new Date();
      const dateToday = DateFormatService.getDateStrFromDate(
        currentDate,
        BOOKS_DATE_FORMAT['DD-MM-YYYY']
      );
      serialTrackingData = advancedTrackingMetaData.map((item: any, i: any) => {
        let object = {
          acquiredCost: unitPrice * 1,
          batchSize: 1,
          committingValue: 1,
          expiryDate: dateToday,
          manufacturingDate: dateToday,
          serialBatchNumber: item.serialBatchNumber,
          warehouseCode: item.warehouse.code,
          warehouseName: item.warehouse.name,
          warehouse: item.warehouse,
          rowData: item?.rowData,
          rackData: item?.rackData,
          binData: item?.binData,
          row: item?.row,
          rowCode: item?.row?.rowCode ?? item?.row?.code,
          rack: item?.rack,
          rackCode: item?.rack?.rackCode ?? item?.rack?.code,
          bin: item?.bin,
          binCode: item?.bin?.binCode ?? item?.bin?.code,
          customField: getLineItemCFs(item)
        };
        return {
          ...object,
          advancedTrackingData: [{ ...object }]
        };
      });
      if (props?.isRRBTaggingEnabled && serialTrackingData?.length > 1) {
        let isAllBinSame = serialTrackingData.every(
          (ele) => ele.bin?.code === serialTrackingData[0]?.bin?.code
        );
        if (!isAllBinSame) {
          showAlert(
            'Error',
            'Row Rack Bin should be same for all serials when bin tagging setting is on.'
          );
          return;
        }
      }

      if (
        props?.isRRBTaggingEnabled &&
        Utility.isNotEmpty(serialTrackingData?.[0]?.bin)
      ) {
        if (
          serialTrackingData?.[0]?.bin?.taggedQuantity >=
          tenantInfo?.additionalSettings?.BIN_TAGGED_QUANTITY_THRESHOLD
        ) {
          showAlert(
            'Error',
            `${serialTrackingData?.[0]?.bin?.name} has threshold limit(${tenantInfo?.additionalSettings?.BIN_TAGGED_QUANTITY_THRESHOLD}) is reached.`
          );
          return;
        }
      }

      if (!Number.isInteger(requiredQuantity)) {
        showAlert(
          'Error',
          'Fulfillment is not allowed for this product as required quantity in decimal.'
        );
        return;
      }
      if (
        serialTrackingData.length > requiredQuantity &&
        !(
          Utility.isPurchaseToleranceSettingsEnabled(
            props.parentDocumentType,
            props.parentDocumentDetails
          ) ||
          (isAllowOverQtyReceive() &&
            Number(props?.itemDetails?.maxQtyToReceive) -
              Number(props?.itemDetails?.receivedQuantityInOrder) >=
              serialTrackingData.length)
        )
      ) {
        let errorMessage =
          'Quantity should not be more than required (' +
          requiredQuantity +
          ')';
        let { isBillLinked } = Utility.getPurchaseLinkedDocument(
          props.parentDocumentType,
          props.parentDocumentDetails
        );
        if (isBillLinked) {
          errorMessage =
            'Receiving quantity cannot be more than required quantity as the Bill is already generated for this document.';
        }
        showToast(errorMessage, TOAST_TYPE.FAILURE);
        return;
      } else if (hasErrorTracking()) {
        return;
      }
      if (
        Utility.isEmpty(rawMaterialToConsumedData) &&
        isBOMRawProductSerialBatch &&
        props.parentDocumentType &&
        props.parentDocumentType === DOC_TYPE.JOB_WORK_OUT_ORDER
      ) {
        showAlert('Warning', t('JOB_WORK_OUT.RAW_MATERIAL_ALLOCATE_ERROR'));
        return;
      }
      props.onSerialSave(
        serialTrackingData,
        false,
        serialTrackingData.length,
        rawMaterialToConsumedData
      );
    }
  };

  const autoAllocate = (quickCommit: boolean) => {
    if (props.onSerialSave) {
      if (!Number.isInteger(requiredQuantity)) {
        showAlert(
          'Error',
          'Fulfillment is not allowed for this product as required quantity in decimal.'
        );
        return;
      }
      if (item?.documentUOMSchemaDefinition) {
        props.onSerialSave([], quickCommit, item?.productQuantity);
      } else {
        props.onSerialSave([], quickCommit, item?.requiredQuantity);
      }
    }
  };

  const getInvalidFields = (
    rows: any,
    selectedRow: any,
    racks: any,
    selectedRack: any,
    bins: any,
    selectedBin: any
  ) => {
    let invalidFields: any = [];
    if (!Utility.isEmpty(rows) && Utility.isEmpty(selectedRow)) {
      invalidFields.push('row');
    }

    if (!Utility.isEmpty(racks) && Utility.isEmpty(selectedRack)) {
      invalidFields.push('rack');
    }

    if (!Utility.isEmpty(bins) && Utility.isEmpty(selectedBin)) {
      invalidFields.push('bin');
    }

    return invalidFields;
  };

  const assignSerial = (serials: string) => {
    let serial = serials.split(',');
    serial = serial?.reduce((acc: string[], serial: string) => {
      if (Utility.isNotEmpty(serial)) {
        acc.push(serial?.trim());
      }
      return acc;
    }, []);

    let duplicates = serial?.filter(
      (item: any, index: any) => serial.indexOf(item) !== index
    );

    if (!Utility.isEmpty(duplicates)) {
      showAlert('Error!', 'Serial Number can not be duplicates');
      return;
    } else {
      duplicates = [];
      duplicates = advancedTrackingMetaData?.filter((o1: any) =>
        serial.some(
          (o2: any) =>
            o1.serialBatchNumber === o2 &&
            o1.warehouse?.code === selectedWarehouse.code
        )
      );
      if (!Utility.isEmpty(duplicates)) {
        showAlert('Error!', 'Serial Number can not be duplicates');
        return;
      }
    }
    let duplicateSerial: any[] = [];
    let existingSerialWarehouse = [...selectSerialTrackingProductData];
    let filterSerialWarehouse = existingSerialWarehouse.find(
      (item: any) => item.code === selectedWarehouse.code
    );
    let existingSerial =
      (filterSerialWarehouse && filterSerialWarehouse.advancedTrackingMeta) ||
      [];

    let tmp: any = [];
    if (advancedTrackingMetaData && advancedTrackingMetaData.length > 0) {
      tmp = [...advancedTrackingMetaData];
    }
    duplicateSerial = existingSerial.filter((o1: any) =>
      serial.some((o2: any) => o1.serialBatchNumber === o2)
    );

    if (duplicateSerial && duplicateSerial.length > 0) {
      showToast('Serial Number already exists', TOAST_TYPE.FAILURE);
      return;
    }

    const selectedRow =
      selectedWarehouse?.code === productDetails?.inventory?.warehouseCode
        ? {
            code: productDetails?.inventory?.rowCode,
            name: productDetails?.inventory?.rowName
          }
        : null;
    const selectedRack =
      selectedWarehouse?.code === productDetails?.inventory?.warehouseCode
        ? {
            code: productDetails?.inventory?.rackCode,
            name: productDetails?.inventory?.rackName
          }
        : null;
    const selectedBin =
      selectedWarehouse?.code === productDetails?.inventory?.warehouseCode
        ? {
            code: productDetails?.inventory?.binCode,
            name: productDetails?.inventory?.binName
          }
        : null;

    serial.forEach((e) => {
      let b: any = {
        warehouse: selectedWarehouse,
        serialBatchNumber: e,
        batchSize: 1,
        row: selectedRow,
        rowData: selectedWarehouse?.warehouseRowInfos ?? [],
        rack: selectedRack,
        rackData: selectedWarehouse?.warehouseRackInfos ?? [],
        bin: selectedBin,
        binData: selectedWarehouse?.warehouseBinInfos ?? [],
        invalidFields: getInvalidFields(
          selectedWarehouse?.warehouseRowInfos,
          selectedRow,
          selectedWarehouse?.warehouseRackInfos,
          selectedRack,
          selectedWarehouse?.warehouseBinInfos,
          selectedBin
        )
      };
      if (!Utility.isEmpty(batchSerialCFfromStore?.content)) {
        // Set default values of CFs when new line is added
        batchSerialCFfromStore?.content?.forEach((item: any) => {
          if (item.status === STATUS_TYPE.ACTIVE) {
            if (
              typeof item.defaultValue !== 'undefined' &&
              item.defaultValue !== null &&
              item.defaultValue !== ''
            ) {
              if (
                item.fieldType.toLowerCase() === INPUT_TYPE.DATE.toLowerCase()
              ) {
                b[item.id] = DateFormatService.getDateFromStr(
                  item.defaultValue,
                  BOOKS_DATE_FORMAT['MM/DD/YYYY']
                );
              } else {
                b[item.id] = item.defaultValue;
              }
            } else {
              b[item.id] = '';
            }
          }
        });
        checkIfLineLevelCustomFieldIsValid(b, batchSerialCFfromStore?.content);
      }
      tmp.push(b);
    });

    setAdvancedTrackingMetaData(tmp);
    // setSelectedWarehouse({});
    setNewSerial('');
    let totalitem = tmp.length;
    setTotalAllocatedItem(totalitem);
    setSaveButtonTapped(false);
  };
  const getAllocatedQty = () => {
    const sumofUsedQuantities =
      advancedTrackingMetaData?.reduce((acc: any, item: any) => {
        return Number(item?.batchSize ?? 0) + Number(acc ?? 0);
      }, 0) ?? 0;
    const allocatedQty = Utility.roundingOff(
      sumofUsedQuantities ?? 0,
      QTY_ROUNDOFF_PRECISION
    );
    return allocatedQty;
  };

  const modifyRawMaterialData = () => {
    const objWithInvalidField = advancedTrackingMetaData?.find((item: any) => {
      return !Utility.isEmpty(item?.invalidFields);
    });

    const objWithInvalidFieldWarehouse = advancedTrackingMetaData?.find(
      (item: any) => {
        return Utility.isEmpty(item?.warehouse);
      }
    );
    if (objWithInvalidField || objWithInvalidFieldWarehouse) {
      return;
    }
    if (
      isAllowOverQtyReceive() &&
      getAllocatedQty() >
        Number(props?.itemDetails?.maxQtyToReceive) -
          Number(props?.itemDetails?.receivedQuantityInOrder)
    ) {
      showAlert(
        'Info',
        `Allocated quantity is more than dispatched quantity (${
          Number(props?.itemDetails?.maxQtyToReceive) -
          Number(props?.itemDetails?.receivedQuantityInOrder)
        })`
      );
      return;
    }
    if (
      !isAllowOverQtyReceive &&
      getAllocatedQty() > props?.itemDetails?.pendingQuantity
    ) {
      return;
    }
    let modifyData = {
      ...props.itemDetails,
      details: props.itemDetails
    };
    if (modifyData?.product?.bomProductsConfiguration) {
      modifyData.product.bomProductsConfiguration.forEach((bomProduct: any) => {
        bomProduct['requiredQuantity'] =
          bomProduct.quantity * getAllocatedQty();
      });
    }
    setRawMaterialData(modifyData);
  };

  const getHeader = () => {
    const disableSave =
      advancedTrackingMetaData?.length === 0 ||
      advancedTrackingMetaData?.some((data) =>
        Utility.isNotEmpty(data['invalidFields'])
      ) ||
      props?.disableBtn;
    return (
      <div className="row justify-content-between p-h-r p-v-s bg-gray1">
        <div className="row pop-header-drag-handle">
          <DKLabel
            text="Allocate Serial-Tracked Products"
            className="fw-m fs-l"
          />
        </div>
        <div className="row width-auto">
          <DKButton
            title="Cancel"
            className="bg-white border-m mr-r"
            onClick={props.onClose}
          />
          {props.parentDocumentType &&
            props.parentDocumentType === DOC_TYPE.JOB_WORK_OUT_ORDER &&
            isBOMRawProductSerialBatch && (
              <DKButton
                title={t('JOB_WORK_OUT.ALLOCATE')}
                className={`${
                  getAllocatedQty() !== 0
                    ? 'bg-button text-white'
                    : 'bg-gray1 cursor-not-allowed '
                } mr-r border-m`}
                onClick={() => {
                  if (getAllocatedQty() !== 0) {
                    modifyRawMaterialData();
                  }
                }}
              />
            )}
          <DKButton
            title={'Save'}
            disabled={disableSave}
            className={
              !disableSave ? 'bg-button text-white' : 'bg-gray-300 text-white'
            }
            onClick={() => {
              !disableSave && onSave();
            }}
          />
        </div>
      </div>
    );
  };

  const getTitleValue = (title: string, value: any, showUom = false) => {
    return !showUom ? (
      <div className="column width-auto ">
        <DKLabel text={title} className="fw-m" />
        <DKLabel text={value || '-'} className="" />
      </div>
    ) : (
      <div className="column width-auto ">
        <DKLabel text={title} className="fw-m " />
        <div className="row justify-content-end align-items-end gap-1 ">
          <DKLabel text={value >= 0 ? value : '-'} className="" />
          <DKLabel
            style={{
              height: 15
            }}
            text={`${
              Utility.getUOMForStockUOMId(item?.product?.stockUom)?.name
            }`}
            className="fs-s fw-m text-gray "
          />
        </div>
      </div>
    );
  };

  const getHeaderSection = () => {
    return (
      <div className="row align-items-start gap-2">
        <div className="column parent-width">
          <DKLabel
            text={`${props?.mrpTitle ? 'Material' : 'Product'} Name`}
            className="fs-m  fw-m "
          />
          <DKLabel text={item?.product?.name} className="fs-r " />
        </div>
        <div
          className="column"
          style={
            props.itemDetails.documentUOMSchemaDefinition
              ? { width: 550 }
              : { width: 200 }
          }
        >
          <DKLabel
            text="Inventory Details"
            className="fs-m fw-m "
            style={{ width: 160 }}
          />
          <div className="row justify-content-between" style={{ width: 200 }}>
            <DKLabel text={'Tracking Method:'} className="fw-m" />
            <DKLabel text={'Serial Number'} className="fs-r" />
          </div>
          <div className="flex">
            <DKLabel text={'Stock Available:'} className="fs-r  fw-m" />
            <DKLabel
              text={
                props.itemDetails.documentUOMSchemaDefinition
                  ? Utility.getUomQuantity(
                      stocksAvailable,
                      props.itemDetails.documentUOMSchemaDefinition
                    )
                  : stocksAvailable
              }
              className="fs-r pl-4"
            />
            {props.itemDetails.documentUOMSchemaDefinition && (
              <DKLabel
                text={props.itemDetails.documentUOMSchemaDefinition.name}
                className="fs-r pl-4"
              />
            )}
          </div>
          {props.itemDetails.documentUOMSchemaDefinition && (
            <div className="flex">
              <DKLabel
                text={'Stock Available In Base UOM:'}
                className="fs-r  fw-m"
              />
              <DKLabel text={stocksAvailable} className="fs-r pl-4" />
            </div>
          )}
          <div className="flex">
            <DKLabel text={'Serials Assigned:'} className="fs-r  fw-m" />
            <DKLabel
              text={totalAllocatedItem}
              className="fs-r pl-4 text-right"
            />
          </div>
        </div>
        <div
          className="column"
          style={
            props.itemDetails.documentUOMSchemaDefinition
              ? { width: 550 }
              : { width: 160 }
          }
        >
          {props.itemDetails.documentUOMSchemaDefinition ? (
            <DKLabel
              text="Goods Receipt Details In Base UOM"
              className="fs-r  fw-m "
            />
          ) : (
            <DKLabel text="Goods Receipt Details" className="fs-m  fw-m " />
          )}

          <div className="row justify-content-between" style={{ width: 160 }}>
            <DKLabel text={'Required Quantity:'} className="fs-r  fw-m" />
            <DKLabel
              text={isNaN(pendingQuantity) ? 0 : pendingQuantity}
              className="text-align-right pl-3"
            />
          </div>
          <div className="row justify-content-between" style={{ width: 160 }}>
            <DKLabel text={'Receiving:'} className="fs-r  fw-m" />
            <DKLabel
              text={requiredQuantity}
              className="text-aling-right pl-3"
            />
          </div>
          <div className="row justify-content-between" style={{ width: 160 }}>
            <DKLabel text={'To Assign:'} className="fs-r  fw-m" />
            <DKLabel
              text={requiredQuantity - totalAllocatedItem}
              className="fs-r pl-3"
              style={{
                color: requiredQuantity > pendingQuantity ? 'red' : 'black'
              }}
            />
          </div>
        </div>
      </div>
    );
  };

  const allocateAutomatically = () => {
    let productAvailableQuantity = localWarehouse?.reduce(
      (prev: any[], current: any) => {
        let advTracking = current?.advancedTrackingMeta?.map((item: any) => {
          return {
            ...item,
            warehouseName: current.name
          };
        });
        return [...prev, ...advTracking];
      },
      []
    );
    let serialTrackingData = productAvailableQuantity.map((item, i) => {
      return {
        qtyToFulfil: 1,
        batchSize: item?.batchSize,
        costPerUnit: Utility.roundOffToTenantDecimalScale(
          Number(item?.acquiredCost) / Number(item?.batchSize)
        ),
        serialBatchNumber: item.serialBatchNumber,
        warehouseCode: item.warehouseCode,
        warehouseName: item.warehouseName,
        destinationWarehouseCode: item?.destinationWarehouseCode,
        destinationWarehouseName: item?.destinationWarehouseName
      };
    });
    serialTrackingData = serialTrackingData.slice(0, getRequireQuantity());
    props.onSerialSave(serialTrackingData, false, serialTrackingData.length);
  };

  const isAllowOverQtyReceive = () => {
    return (
      props.parentDocumentType &&
      props.parentDocumentType === DOC_TYPE.JOB_WORK_OUT_ORDER &&
      Utility.isJWOReceiveDispatchAdditionalQtyEnable()
    );
  };
  const validateForm = () => {
    if (
      totalAllocatedItem >= requiredQuantity &&
      !(
        Utility.isPurchaseToleranceSettingsEnabled(
          props.parentDocumentType,
          props.parentDocumentDetails
        ) ||
        (isAllowOverQtyReceive() &&
          totalAllocatedItem <=
            Number(props?.itemDetails?.maxQtyToReceive) -
              Number(props?.itemDetails?.receivedQuantityInOrder))
      )
    ) {
      showToast(
        'Quantity cannot be more than the receiving quantity (' +
          requiredQuantity +
          ')',
        TOAST_TYPE.FAILURE
      );
      setSelectedWarehouse({});
      setNewSerial('');
      return false;
    } else if (Utility.isEmpty(newSerial)) {
      return false;
    } else if (Utility.isEmpty(selectedWarehouse)) {
      return false;
    } else {
      return true;
    }
  };

  const getBodySectionRG = () => {
    return (
      <div className="column parent-width gap-4">
        <div>
          <DKLabel
            text={'Assign Serial Numbers to Received stock:'}
            className="fs-r fw-m"
          />
          <DKLabel
            text={
              'Input or scan Serial Numbers to assign them to stock units you are receiving.'
            }
            className="fs-r"
          />
          {!Number.isInteger(requiredQuantity) && (
            <DKLabel
              text={
                'Received Goods is not allowed for this product as required quantity in decimal.'
              }
              className="fs-r text-red"
            />
          )}
        </div>
        <div className="row gap-2">
          <DKInput
            title="Select Warehouse"
            required={true}
            direction={INPUT_VIEW_DIRECTION.VERTICAL}
            canValidate={saveButtonTapped}
            placeholder={'Select Warehouse'}
            value={
              Utility.isEmpty(selectedWarehouse)
                ? defaultWarehouse
                : selectedWarehouse
            }
            options={getActiveWarehouses()}
            onChange={(value: any) => {
              setSelectedWarehouse(value);
            }}
            type={INPUT_TYPE.DROPDOWN}
            formatter={(obj: any) => {
              return obj.name;
            }}
            dropdownConfig={{
              title: 'Select Warehouse',
              allowSearch: true,
              searchableKey: 'name',
              searchApiConfig: {
                getUrl: (searchValue: string) => {
                  const query: string = `?limit=50&page=0&search=${searchValue}&query=active=true&allRRBDetails=true`;
                  const finalEndPoint =
                    ApiConstants.URL.BASE +
                    ApiConstants.URL.ACCOUNTS.WAREHOUSES +
                    query;
                  return finalEndPoint;
                },
                dataParser: (response: any) => {
                  let filtered = response?.content?.filter(
                    (wh: any) => wh.warehouseType === 'NONE'
                  );
                  return props?.targetWarehouse ? localWarehouse : filtered;
                },

                debounceTime: 300
              },
              renderer: (index: any, wh: any) => {
                return <DKLabel text={`${wh?.name}`} />;
              }
            }}
          />
          <DKInput
            title={'New Serial'}
            required={true}
            direction={INPUT_VIEW_DIRECTION.VERTICAL}
            canValidate={saveButtonTapped}
            value={newSerial}
            onChange={(value: any) => {
              setNewSerial(value);
            }}
            type={INPUT_TYPE.TEXT}
          />
          {!props.disableBtn && (
            <DKButton
              title={'Assign'}
              // className={'bg-button text-white mr-r'}
              className={`mt-5  ${
                Number.isInteger(requiredQuantity)
                  ? 'bg-button text-white mr-r'
                  : 'bg-gray-300 text-white mr-r'
              }`}
              onClick={() => {
                setSaveButtonTapped(true);
                if (validateForm()) assignSerial(newSerial);
              }}
              disabled={!Number.isInteger(requiredQuantity)}
            />
          )}
        </div>
      </div>
    );
  };

  const onDelete = ({ rowIndex }: any) => {
    let rows = [...advancedTrackingMetaData];
    rows.splice(rowIndex, 1);
    let totalItem = rows.reduce(
      (a: any, b: any) => +a + +parseFloat(b.batchSize),
      0
    );
    setTotalAllocatedItem(totalItem);
    setAdvancedTrackingMetaData(rows);
  };
  const onRowUpdate = (updates: any) => {
    const key = updates['columnKey'];
    const rowIndex = updates['rowIndex'];
    const item = updates['rowData'];

    let copyOfAdvancedTrackData = deepClone(advancedTrackingMetaData);
    copyOfAdvancedTrackData[rowIndex]['invalidFields'] = [];

    if (key === 'serialBatchNumber') {
      let existingSerialWarehouse = [...selectSerialTrackingProductData];
      const currentSerialBatchNumber =
        copyOfAdvancedTrackData[rowIndex]['serialBatchNumber']?.trim();
      let filterSerialWarehouse = existingSerialWarehouse.find(
        (item: any) =>
          item.code === copyOfAdvancedTrackData[rowIndex]['warehouse']['code']
      );
      //* Info- Already saved serial numbers
      let existingSerialNumbers =
        filterSerialWarehouse?.advancedTrackingMeta?.map(
          (item: any) => item.serialBatchNumber
        ) || [];

      //* Info- serial number except current row in the grid
      copyOfAdvancedTrackData.forEach((trackingData: any, index: any) => {
        if (
          index !== rowIndex &&
          trackingData['warehouse']['code'] ===
            copyOfAdvancedTrackData[rowIndex]['warehouse']['code'] &&
          trackingData.serialBatchNumber ===
            copyOfAdvancedTrackData[rowIndex]['serialBatchNumber']
        ) {
          existingSerialNumbers.push(
            copyOfAdvancedTrackData[rowIndex]['serialBatchNumber']
          );
        }
      });
      if (
        Utility.isEmptyObject(currentSerialBatchNumber) ||
        existingSerialNumbers?.includes(currentSerialBatchNumber)
      ) {
        copyOfAdvancedTrackData[rowIndex]['invalidFields'] = [
          'serialBatchNumber'
        ];
      }
    } else if (key === 'row') {
      copyOfAdvancedTrackData[rowIndex]['row'] = item[key];
      copyOfAdvancedTrackData[rowIndex]['rack'] = null;
      copyOfAdvancedTrackData[rowIndex]['bin'] = null;
      copyOfAdvancedTrackData[rowIndex]['rackData'] =
        item?.warehouse?.warehouseRackInfos?.filter((i: any) => {
          return i.rowCode === item[key].code;
        }) ?? [];

      if (Utility.isEmpty(item?.warehouse?.warehouseRackInfos)) {
        copyOfAdvancedTrackData[rowIndex]['binData'] =
          item?.warehouse?.warehouseBinInfos?.filter((i: any) => {
            return i.rowCode === item[key].code;
          }) ?? [];
      }
    } else if (key === 'rack') {
      copyOfAdvancedTrackData[rowIndex]['rack'] = item[key];
      copyOfAdvancedTrackData[rowIndex]['bin'] = null;
      copyOfAdvancedTrackData[rowIndex]['binData'] =
        item?.warehouse?.warehouseBinInfos?.filter((i: any) => {
          return i.rackCode === item[key].code;
        });
    } else if (key === 'bin') {
      copyOfAdvancedTrackData[rowIndex]['bin'] = item[key];
    } else {
      let dataToUpdate = item && item[key];
      const CFColumnConfig = assignedSerialColumns?.find(
        (cf: any) => cf?.id === key && cf.isCustomField
      );
      const filteredCF: any = batchSerialCFfromStore?.content?.find(
        (field: any) => field.id === key
      );
      let cfValue = '';
      if (!Utility.isEmpty(filteredCF)) {
        if (
          filteredCF.fieldType.toLowerCase() ===
          CUSTOM_FIELD_TYPE.USER.toLowerCase()
        ) {
          const tempCFOption = filteredCF?.attributes?.find(
            (attr: any) => attr.code === dataToUpdate.code
          );
          if (tempCFOption) {
            cfValue = tempCFOption?.code;
          }
        } else if (
          filteredCF.fieldType.toLowerCase() ===
          CUSTOM_FIELD_TYPE.DROPDOWN.toLowerCase()
        ) {
          cfValue = dataToUpdate?.value === 'None' ? null : dataToUpdate?.value;
          cfUpdatedTimeMap.current = {
            ...cfUpdatedTimeMap.current,
            [filteredCF.id]: new Date().getTime()
          };
        } else {
          cfValue =
            filteredCF.fieldType.toLowerCase() === INPUT_TYPE.DATE.toLowerCase()
              ? DateFormatService.getDateStrFromDate(
                  new Date(dataToUpdate),
                  BOOKS_DATE_FORMAT['MM/DD/YYYY']
                )
              : dataToUpdate;
        }
        if (CFColumnConfig && filteredCF) {
          const cfToUpdate = {
            id: filteredCF.id,
            shortName: filteredCF.shortName,
            module: filteredCF.module,
            code: filteredCF.code,
            label: filteredCF.label,
            value: cfValue
          };
          let existingCFs = copyOfAdvancedTrackData[rowIndex]?.customField
            ? [...copyOfAdvancedTrackData[rowIndex].customField]
            : [];
          const existingCFIndex = existingCFs.findIndex(
            (cf: any) => cf?.id === cfToUpdate?.id
          );
          if (existingCFIndex === -1) {
            existingCFs = [...existingCFs, cfToUpdate];
          } else {
            existingCFs[existingCFIndex] = cfToUpdate;
          }
          if (copyOfAdvancedTrackData[rowIndex]) {
            copyOfAdvancedTrackData[rowIndex].customField = existingCFs;
          }
        }
        if (copyOfAdvancedTrackData[rowIndex]) {
          if (
            filteredCF?.fieldType?.toLowerCase() ===
            INPUT_TYPE.DATE.toLowerCase()
          ) {
            copyOfAdvancedTrackData[rowIndex][key] = new Date(dataToUpdate);
          } else if (Utility.isObject(dataToUpdate)) {
            if (
              filteredCF?.fieldType?.toLowerCase() ===
              INPUT_TYPE.DROPDOWN.toLowerCase()
            ) {
              copyOfAdvancedTrackData[rowIndex][key] =
                dataToUpdate?.value === 'None' ? null : dataToUpdate?.value;
              const { rowData } = updateRowDataWithParentCFValues(
                dataToUpdate.value,
                { ...copyOfAdvancedTrackData[rowIndex] },
                filteredCF,
                batchSerialCFfromStore.content
              );
              copyOfAdvancedTrackData[rowIndex] = rowData;
            } else {
              copyOfAdvancedTrackData[rowIndex][key] = dataToUpdate.value;
            }
          } else {
            copyOfAdvancedTrackData[rowIndex][key] = dataToUpdate;
          }
        }
      }
    }
    copyOfAdvancedTrackData[rowIndex] = checkIfLineLevelCustomFieldIsValid(
      copyOfAdvancedTrackData[rowIndex],
      batchSerialCFfromStore?.content
    );
    copyOfAdvancedTrackData[rowIndex]['invalidFields'] = [
      ...copyOfAdvancedTrackData[rowIndex]['invalidFields'],
      ...getInvalidFields(
        copyOfAdvancedTrackData[rowIndex]['rowData'],
        copyOfAdvancedTrackData[rowIndex]['row'],
        copyOfAdvancedTrackData[rowIndex]['rackData'],
        copyOfAdvancedTrackData[rowIndex]['rack'],
        copyOfAdvancedTrackData[rowIndex]['binData'],
        copyOfAdvancedTrackData[rowIndex]['bin']
      )
    ];

    setAdvancedTrackingMetaData(copyOfAdvancedTrackData);
    updateConfig(
      copyOfAdvancedTrackData[rowIndex]['rackData'],
      copyOfAdvancedTrackData[rowIndex]['binData']
    );
  };

  const hasErrorTracking = () => {
    let hasError = false;
    const batchTrackingData = advancedTrackingMetaData.map(
      (item: any, i: any) => {
        if (item && item.invalidFields && item.invalidFields.length > 0) {
          hasError = true;
        } else {
          hasError = false;
        }
      }
    );
    return hasError;
  };

  const getUpdatedColumnNames = () => {
    let config = [...assignedSerialColumns];
    let rrbEnabled = tenantInfo?.additionalSettings?.ROW_RACK_BIN?.filter(
      (item: any) => item?.enable
    );

    const enabledRRBNames = rrbEnabled
      ?.filter((rrb: any) => rrb.enable)
      ?.map((itemRRB: any) => {
        return itemRRB?.name;
      });
    const updatedColumns: any = [];
    config?.forEach((col: any) => {
      if (
        col?.key?.toUpperCase() === ROW_RACK_BIN_CONSTANT.ROW ||
        col?.key?.toUpperCase() === ROW_RACK_BIN_CONSTANT.RACK ||
        col?.key?.toUpperCase() === ROW_RACK_BIN_CONSTANT.BIN
      ) {
        //check and push ROW RACK BIN here
        if (enabledRRBNames?.includes(col.key.toUpperCase())) {
          const foundRRB = rrbEnabled?.find(
            (rrb: any) => rrb.name === col.key.toUpperCase()
          );
          col = {
            ...col,
            name: `Select ${foundRRB?.label ?? ''}`
          };
          updatedColumns.push(col);
        }
      } else {
        updatedColumns.push(col);
      }
    });

    return updatedColumns;
  };

  const getBatchTrackingGrid = () => {
    return (
      <DKDataGrid
        title="Assigned Serials"
        needShadow={false}
        needBorder={true}
        needColumnIcons={false}
        needTrailingColumn={true}
        allowBulkOperation={false}
        allowColumnSort={false}
        allowColumnAdd={false}
        allowColumnEdit={false}
        allowRowEdit={true}
        onRowUpdate={onRowUpdate}
        currentPage={1}
        totalPageCount={1}
        columns={[
          ...assignedSerialColumns,
          {
            id: 'action',
            key: 'action',
            name: '',
            textAlign: 'right',
            type: INPUT_TYPE.BUTTON,
            width: 130,
            options: [
              {
                icon: DKIcons.ic_delete,
                className: 'p-0 mx-auto',
                onClick: (data: any) => onDelete(data)
              }
            ]
          }
        ]}
        rows={deepClone(advancedTrackingMetaData)}
        onRowClick={(data: any) => {
          let config = [...assignedSerialColumns];

          let rowInfo = data?.rowData?.warehouse?.warehouseRowInfos;
          let rackInfo = data?.rowData?.warehouse?.warehouseRackInfos;
          let binInfo = data?.rowData?.warehouse?.warehouseBinInfos;

          config.forEach((conf: any) => {
            switch (conf.key) {
              case 'row':
                conf.dropdownConfig.data = rowInfo ?? [];
                conf.dropdownConfig.renderer = (index: any, obj: any) => {
                  return obj?.name ?? '';
                };
                return;

              case 'rack':
                conf.dropdownConfig.data = data?.rowData?.row?.code
                  ? rackInfo?.filter((i: any) => {
                      return i.rowCode === data?.rowData?.row?.code;
                    }) ?? []
                  : rackInfo;
                conf.dropdownConfig.renderer = (index: any, obj: any) => {
                  return obj?.name ?? '';
                };
                return;

              case 'bin':
                conf.dropdownConfig.data = data?.rowData?.rack?.code
                  ? binInfo?.filter((i: any) => {
                      return i.rackCode === data?.rowData?.rack?.code;
                    }) ?? []
                  : data?.rowData?.row?.code
                  ? binInfo?.filter((i: any) => {
                      return i.rowCode === data?.rowData?.row?.code;
                    })
                  : binInfo;
                conf.dropdownConfig.renderer = (index: any, obj: any) => {
                  return obj?.name ?? '';
                };
                return;

              default:
                break;
            }
          });

          setAssignedSerialColumns([...config]);
          updateColumnConfigOnRowClick(
            data?.columnData,
            data?.rowData,
            assignedSerialColumns,
            batchSerialCFfromStore.content,
            cfUpdatedTimeMap.current
          );
        }}
      />
    );
  };

  return (
    <DynamicPopupWrapper>
      <div className="transparent-background" style={{ zIndex: 9998 }}>
        <div
          className="popup-window"
          style={{
            maxWidth: 800,
            width: '100%',
            // height: "85%",
            maxHeight: '95%',
            height: 565,
            padding: 0,
            paddingBottom: 60,
            overflow: 'hidden'
          }}
        >
          {getHeader()}
          <div className="column parent-size p-4 gap-2">
            {getHeaderSection()}
            <DKLine style={{ height: 2 }} />

            {getBodySectionRG()}
            <div className="column parent-width mt-2 overflow-y-scroll hide-scroll-bar dk-input-holder">
              {advancedTrackingMetaData.length > 0 && getBatchTrackingGrid()}
            </div>
            <div className="">
              <DKButton
                title={`+ Add Custom Fields`}
                onClick={() => onAddCustomFieldClicked()}
                className={` fw-m p-0 text-blue`}
                style={{ zIndex: 1, paddingLeft: 0, paddingTop: 0 }}
              />
            </div>
          </div>
        </div>
      </div>
      {rawMaterialData && (
        <ReceiveJWOProduct
          records={[]}
          selectedRecord={null}
          detail={rawMaterialData}
          document={DOC_TYPE.JOB_WORK_OUT_ORDER}
          passingInteraction={() => {}}
          onSave={(data: any) => {
            const rawMaterialData: any = [];
            data.forEach((d: any) => {
              rawMaterialData.push(...d.serialData);
            });

            setRawMaterialToConsumedData(rawMaterialData);
            setRawMaterialData(null);
          }}
          onCancel={() => {
            setRawMaterialData(null);
          }}
          allowFullScreen
        />
      )}
    </DynamicPopupWrapper>
  );
}
function fulfillmentDate(fulfillmentDate: any, arg1: any) {
  throw new Error('Function not implemented.');
}
