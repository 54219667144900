import { useEffect, useState } from 'react';
import {
  DKButton,
  DKInput,
  DKLabel,
  INPUT_TYPE,
  DKIcons,
  DKLine,
  DKSpinner,
  DKIcon,
  showAlert,
  showToast,
  TOAST_TYPE
} from 'deskera-ui-library';
import {
  DOCUMENT_MODE,
  DOC_TYPE,
  DOC_TYPE_TO_ATTACHMENT_MAP,
  FILTER_KEYS,
  INPUT_VIEW_DIRECTION,
  MODULES_NAME,
  TIME_TYPES
} from '../../../Constants/Constant';
import Utility, { deepClone } from '../../../Utility/Utility';
import { SUB_OPERATIONS_COLS } from '../Constants/MRPColumnConfigs';
import { useAppDispatch, useAppSelector } from '../../../Redux/Hooks';
import {
  selectOperations,
  selectOperationsColumnConfig,
  selectOperationsColumnConfigTableId
} from '../../../Redux/Slices/MRP/OperationSlice';
import OperationsService from '../../../Services/MRP/Operations';
import {
  fetchWorkStations,
  selectWorkstations
} from '../../../Redux/Slices/MRP/WorkstationSlice';
import AddWorkStation from '../Work stations/AddWorkStation';
import {
  fetchOperatorsList,
  selectOperators
} from '../../../Redux/Slices/MRP/OperatorSlice';
import { isEmpty } from 'lodash';
import { INPUT_TITLE_STYLE } from '../Constants/UIHelper';
import EmailEditor from '../../../SharedComponents/email_editor/EmailEditor_Quill';
import AttachmentService from '../../../Services/Attachment';
import { triggerDownload } from '../../ImportExport/utility/ExportData';
import ApiConstants from '../../../Constants/ApiConstants';
import WorkstationsService from '../../../Services/MRP/Workstations';
import { CustomFieldsHolder } from '../../../SharedComponents/CustomFieldsHolder/CustomFieldsHolder';
import { isCustomFieldsValid } from '../../../Services/CustomField';
import OperatorsService, {
  OperatorsAPIConfig
} from '../../../Services/MRP/Operators';
import MultiSelectOperatorPopup from './MultiSelectOperatorPopup';
import { checkUserPermission } from '../../Settings/GranularPermissions/GranularPermissionsHelper';
import { PERMISSIONS_BY_MODULE } from '../../../Constants/Permission';

const initialState: any = {
  Operators: [],
  OperatorsArray: []
};

const AddNewOperation = (props: any) => {
  //constants
  const isEditMode = !Utility.isEmpty(props.editableOperation);

  //states
  const [formState, setFormState] = useState<any>();
  const [updating, setUpdating] = useState(false);
  const [canValidate, setCanValidate] = useState(false);
  const [subOperationColConfig, setSubOperationsColConfig] =
    useState(SUB_OPERATIONS_COLS);
  const [workStationStateData, setWorkStationStateData] = useState<any[]>([]);
  const [showWorkStationForm, setShowWorkStationForm] = useState(false);
  const [attachments, setAttachments] = useState<any>([]);
  const [operatorList, setOperatorList] = useState<any>([]);

  //selectors
  const dispatch = useAppDispatch();
  const operationData = useAppSelector(selectOperations);
  const workstationsData = useAppSelector(selectWorkstations);
  const operatorsData = useAppSelector(selectOperators);
  const operationColumnConfig = useAppSelector(selectOperationsColumnConfig);
  const columnConfigTableId = useAppSelector(
    selectOperationsColumnConfigTableId
  );
  const [showMultiPopup, setShowMultiPopup] = useState<any>(false);
  const [operators, setOperators] = useState<any>([]);
  const [operatorsState, setOperatorsState] = useState(initialState);
  const [show, setShow] = useState<boolean>(false);

  //effects
  useEffect(() => {
    if (operatorsData && operatorsData?.content?.length > 0) {
      setOperatorList(operatorsData?.content);
      updateConfig();
      editParseData();
    } else {
      updateConfig();
      editParseData();
    }
  }, [operatorsData]);

  const editParseData = async () => {
    if (isEditMode) {
      const updatedFormState = { ...formState };
      updatedFormState.name = props.editableOperation?.name;
      updatedFormState.operationTime =
        props.editableOperation?.operationTime || 0;
      const operationTimeType = `${props.editableOperation?.operationTimeUnit}S`;
      const operationTimeTypeObjFound = TIME_TYPES.find(
        (item) => item.value === operationTimeType.toLowerCase()
      );
      let str: any = '';
      props.editableOperation?.operators?.forEach(
        (operators: any, index: any) => {
          if (index === 2) {
            str += `  etc..`;
            return;
          }
          if (index === 0) str += `${operators.operatorName} `;
          else if (index == 1) {
            str += `, ${operators.operatorName}`;
          }
        }
      );
      setOperators(str);
      updatedFormState.operationTimeType =
        operationTimeTypeObjFound ?? TIME_TYPES[1];
      updatedFormState.costPerHour = props.editableOperation?.costPerHour || 0;
      updatedFormState.fixedRate = props.editableOperation?.fixedRate || 0;
      updatedFormState.description = props.editableOperation?.description;
      updatedFormState.isCorrectiveOperation =
        props.editableOperation?.isCorrective;
      updatedFormState.isBatchSizeChecked =
        props.editableOperation?.operationJobCard?.createJobCardOnBatchSize;
      updatedFormState.batchSize =
        props.editableOperation?.operationJobCard?.batchSize;
      updatedFormState.instructions = Utility.decodeHTML(
        props.editableOperation?.instructions || ''
      );
      updatedFormState.attachmentIds = [];
      updatedFormState.attachments = [];
      updatedFormState.customField = props.editableOperation?.customField ?? [];
      updatedFormState.operationTime = Utility.convertMinutesInFormat(
        props.editableOperation?.operationTime || 0,
        updatedFormState.operationTimeType?.value?.toLowerCase(),
        true
      );

      let newOps: any[] = [];
      operationData?.content?.map((item: any) => {
        if (item.id === props.editableOperation.id) {
          item?.subOperations?.map((item2: any, index2: number) => {
            newOps.push({
              subOperation: {
                ...item2,
                name: item2?.operationName
              },
              operationTime: item2.operationTime,
              operationIndex: index2 + 1
            });
          });
        }
      });
      updatedFormState.operationTableData = newOps;

      let workStationObj = workstationsData?.content?.find((item: any) => {
        return item.id === props.editableOperation?.defaultWorkstation;
      });
      if (Utility.isEmptyObject(workStationObj)) {
        await fetchWorkStationById(
          props.editableOperation?.defaultWorkstation
        ).then((response: any) => {
          if (response && response.content) {
            workStationObj = response.content[0];
          }
        });
      }
      updatedFormState.defaultWorkstation = workStationObj;
      // const operators = props.editableOperation?.operators || [];

      const operators =
        props.editableOperation?.operators?.map((operator: any) => {
          // const operator = operators[index];
          return {
            id: operator?.operatorId,
            name: operator?.operatorName,
            workerId: operator?.workerId
          };
        }) || [];
      updatedFormState.operators = operators;
      setOperatorsState((prevState: any) => {
        return {
          ...prevState,
          Operators: operators.map((operator: any) => operator.id),
          OperatorsArray: operators
        };
      });

      fetchAttachments(props.editableOperation.id);
      setFormState({ ...updatedFormState });
    }
  };

  useEffect(() => {
    if (!Utility.isEmpty(workstationsData.content)) {
      setWorkStationStateData(workstationsData.content);
    }
  }, [workstationsData]);

  //methods
  const validateForm = () => {
    if (
      Utility.isEmptyObject(formState?.name?.trim()) ||
      Utility.isEmptyObject(formState?.defaultWorkstation)
    ) {
      return false;
    }

    if (formState?.name?.length > 800) {
      showToast(
        `Value too long for field 'Name' (maximum size 800).`,
        TOAST_TYPE.FAILURE
      );
      return false;
    }
    if (!isCustomFieldsValid(formState.customField)) {
      return false;
    }
    return true;
  };

  const fetchWorkStationById = (id: any) => {
    return WorkstationsService.fetchWorkStationById(id);
  };

  const getPayload = () => {
    const div_body = document.getElementById('operation-editor-body');
    const operators = operatorList || [];
    const operationTimeType = !Utility.isEmpty(
      formState?.operationTimeType?.value
    )
      ? formState?.operationTimeType?.value
      : TIME_TYPES[1]?.value;
    const operationTimeUnit = operationTimeType.slice(0, -1).toUpperCase();
    return {
      defaultWorkstation: formState?.defaultWorkstation?.id,
      description: formState?.description,
      name: formState?.name,
      operationTime: Utility.calculateTimeInMinutes(
        formState?.operationTime,
        operationTimeType
      ),
      operationTimeUnit: operationTimeUnit,
      operators: operatorsState?.OperatorsArray?.map((operator: any) => {
        return {
          operatorId: operator?.id,
          operatorName: operator?.name,
          workerId: operator?.workerId
        };
      }),
      instructions: getDataToSave(div_body) || '',
      attachments: formState?.attachments || [],
      attachmentIds: formState?.attachmentIds || [],
      fixedRate: isNaN(formState.fixedRate) ? 0 : Number(formState.fixedRate),
      costPerHour: isNaN(formState.costPerHour)
        ? 0
        : Number(formState.costPerHour),
      customField: formState?.customField ?? []
    };
  };

  const getDataToSave = (editor_container: any) => {
    /* picking contents from inner element for handling quill editor */
    const editor_body = editor_container.querySelector('.ql-editor');
    const html_text = editor_body
      ? replacePlaceholders(editor_body)
      : editor_container.innerHTML;

    /* sanitize text for email placeholders */
    return getEditorValue(html_text);
  };

  const replacePlaceholders = (editor_body: any) => {
    const placeholders = editor_body.getElementsByClassName(
      'custom_email_placeholder'
    );
    Array.from(placeholders).forEach((placeholder: any) => {
      const dataId = placeholder.getAttribute('data-id');
      placeholder.innerHTML = `%${dataId}%`;
    });

    return editor_body.innerHTML;
  };

  const onSave = () => {
    setCanValidate(true);
    const validated = validateForm();
    if (!validated) {
      return;
    }

    const payload = getPayload();
    setUpdating(true);
    OperationsService.addOperation(payload)
      .then((res: any) => {
        setUpdating(false);
        props.onSuccess();
      })
      .catch((err: any) => {
        setUpdating(false);
      });
  };

  const onEditSave = () => {
    setCanValidate(true);
    const validated = validateForm();
    if (!validated) {
      return;
    }

    const payload = getPayload();

    setUpdating(true);
    OperationsService.updateOperation(payload, props.editableOperation.id)
      .then((res: any) => {
        setUpdating(false);
        props.onSuccess();
      })
      .catch((err: any) => {
        setUpdating(false);
      });
  };

  const updateConfig = () => {
    let config = [...SUB_OPERATIONS_COLS];

    config.forEach((conf: any) => {
      switch (conf.key) {
        case 'subOperation':
          conf.dropdownConfig.data = operationData?.content;
          conf.dropdownConfig.renderer = (index: any, obj: any) => {
            return obj.name;
          };
          conf.formatter = (obj: any) => {
            return obj.rowData?.subOperation?.name;
          };
          break;
        default:
          break;
      }
    });

    setSubOperationsColConfig(config);
  };

  const checkForNegativeNumbers = (valueToCheck: string) => {
    if (
      isEmpty(valueToCheck) ||
      isNaN(parseFloat(valueToCheck)) ||
      parseFloat(valueToCheck) <= -1
    ) {
      return 0;
    }
    return valueToCheck;
  };
  //render methods
  const getHeader = () => {
    return (
      <div className="row justify-content-between p-h-r p-v-s bg-gray1 rounded-t">
        <div className="row width-auto">
          <DKLabel
            text={`${!isEditMode ? 'Add' : 'Edit'} Operation`}
            className="fw-m fs-l"
          />
        </div>
        <div className="row width-auto">
          <DKButton
            title={'Cancel'}
            className="bg-white border-m mr-r"
            onClick={() => {
              !updating && props.onCancel();
            }}
          />
          <div
            className={`row border-radius-m ${
              updating ? ' bg-gray1 border-m pr-2' : ' bg-button text-white'
            }`}
          >
            <DKButton
              title={'Save'}
              onClick={() => {
                if (!updating) {
                  if (!isEditMode) {
                    onSave();
                  } else {
                    onEditSave();
                  }
                }
              }}
            />
            {updating && <DKSpinner iconClassName="ic-s" />}
          </div>
        </div>
      </div>
    );
  };
  const hasViewPricePermission = checkUserPermission(
    PERMISSIONS_BY_MODULE.OPERATIONS.VIEW_PRICE
  );
  const getInputFieldsView = () => {
    return (
      <div className="column gap-4">
        <div className="column parent-width">
          <div className="row gap-2">
            <DKInput
              title="Name"
              type={INPUT_TYPE.TEXT}
              required={true}
              placeholder="Ex: Drilling"
              titleStyle={INPUT_TITLE_STYLE}
              canValidate={canValidate}
              direction={INPUT_VIEW_DIRECTION.VERTICAL}
              value={formState?.name}
              onChange={(text: any) => {
                const updatedFormState = { ...formState };
                updatedFormState.name = text;
                setFormState({ ...updatedFormState });
              }}
            />
            <div className="row">
              <DKInput
                title="Time"
                type={INPUT_TYPE.NUMBER}
                required={false}
                canValidate={canValidate}
                placeholder="1.5"
                titleStyle={INPUT_TITLE_STYLE}
                className="mr-2"
                direction={INPUT_VIEW_DIRECTION.VERTICAL}
                value={formState?.operationTime}
                onChange={(text: any) => {
                  const updatedFormState = { ...formState };
                  updatedFormState.operationTime = text;
                  setFormState({ ...updatedFormState });
                }}
                // onBlur={(e: any) =>
                //   onBlurCostUpdate(e.target.value, 'operationTime')
                // }
              />
              <DKInput
                title=""
                onChange={(value: any) => {
                  const updatedFormState = { ...formState };
                  updatedFormState.operationTimeType = value;
                  setFormState({ ...updatedFormState });
                }}
                value={formState?.operationTimeType || TIME_TYPES[1]}
                canValidate={canValidate}
                direction={INPUT_VIEW_DIRECTION.VERTICAL}
                type={INPUT_TYPE.DROPDOWN}
                formatter={(obj: any) => {
                  return Utility.convertInTitleCase(obj?.label);
                }}
                className="mt-l"
                dropdownConfig={{
                  title: '',
                  allowSearch: false,
                  searchableKey: '',
                  style: { minWidth: 150 },
                  className: 'shadow-m width-auto',
                  searchApiConfig: null,
                  data: TIME_TYPES,
                  renderer: (index: any, obj: any) => {
                    return (
                      <DKLabel
                        text={`${Utility.convertInTitleCase(obj?.label)}`}
                      />
                    );
                  }
                }}
              />
            </div>
          </div>
          {/* Temporary Commented */}
          {/* <div className="row justify-content-end text-gray mt-1">
            {Utility.getDetailTimeConvertion(
              formState?.operationTime,
              formState?.operationTimeType
            )}
          </div> */}
          {/* Temporary Commented */}
        </div>
        <div className="row align-items-start gap-2">
          <DKInput
            type={INPUT_TYPE.DROPDOWN}
            title={'Default Workstation'}
            direction={INPUT_VIEW_DIRECTION.VERTICAL}
            readOnly={false}
            value={formState?.defaultWorkstation}
            formatter={(obj: any) => {
              return obj.workstationName;
            }}
            titleStyle={INPUT_TITLE_STYLE}
            required={true}
            canValidate={canValidate}
            onChange={(obj: any) => {
              const updatedFormState = { ...formState };
              updatedFormState.defaultWorkstation = obj;
              setFormState({ ...updatedFormState });
            }}
            dropdownConfig={{
              title: '',
              allowSearch: true,
              searchableKey: 'workstationName',
              className: 'shadow-m width-auto',
              searchApiConfig: {
                getUrl: (searchValue: string) => {
                  let query = `?limit=25&page=0&search=${searchValue}&sort=&sortDir=DESC&query=`;
                  let URL =
                    ApiConstants.URL.BASE +
                    'mrp/master/workstation/search' +
                    query;
                  return URL;
                },
                dataParser: (response: any) => {
                  return response?.content ?? [];
                },
                debounceTime: 300
              },
              data: workStationStateData ?? [],
              renderer: (index: any, obj: any) => {
                return <DKLabel text={`${obj.workstationName}`} />;
              },
              button: {
                title: '+ Add Workstation',
                className: 'bg-button text-white',
                onClick: () => {
                  setShowWorkStationForm(true);
                }
              }
            }}
          />
          <div
            className={`column`}
            onClick={() => {
              setShow(!show);
            }}
          >
            {getOperatorField()}
          </div>
        </div>
        {hasViewPricePermission && (
          <>
            <div className="parent-width">
              <DKLine className="mb-m" />
              <div className="row fw-m">Operation Cost</div>
            </div>
            <div className="row gap-2">
              <DKInput
                title="Per Hour"
                type={INPUT_TYPE.NUMBER}
                required={false}
                canValidate={canValidate}
                direction={INPUT_VIEW_DIRECTION.VERTICAL}
                titleStyle={INPUT_TITLE_STYLE}
                placeholder="0.00"
                value={formState?.costPerHour}
                onChange={(text: any) => {
                  const updatedFormState = { ...formState };
                  updatedFormState.costPerHour = checkCharacterLimit(
                    checkForNegativeNumbers(text)
                  );
                  setFormState({ ...updatedFormState });
                }}
                onBlur={(e: any) =>
                  onBlurCostUpdate(e.target.value, FILTER_KEYS.COST_PER_HOUR)
                }
              />
              <DKInput
                title="Fixed"
                type={INPUT_TYPE.NUMBER}
                required={false}
                canValidate={canValidate}
                direction={INPUT_VIEW_DIRECTION.VERTICAL}
                titleStyle={INPUT_TITLE_STYLE}
                placeholder="0.00"
                value={formState?.fixedRate}
                onChange={(text: any) => {
                  const updatedFormState = { ...formState };
                  updatedFormState.fixedRate = checkCharacterLimit(
                    checkForNegativeNumbers(text)
                  );
                  setFormState({ ...updatedFormState });
                }}
                onBlur={(e: any) =>
                  onBlurCostUpdate(e.target.value, FILTER_KEYS.FIXED_RATE)
                }
              />
            </div>
          </>
        )}
      </div>
    );
  };

  const onBlurCostUpdate = (value: number, key: string) => {
    const updatedFormState = { ...formState };
    updatedFormState[key] = Utility.roundOffToTenantDecimalScale(
      checkCharacterLimit(value)
    );
    setFormState(updatedFormState);
  };

  const checkCharacterLimit = (value: any) => {
    let characterLength = value.length;
    if (characterLength > 10) {
      showToast(
        'Value must not be greater than 10 characters',
        TOAST_TYPE.FAILURE
      );
      return value.slice(0, 10);
    } else {
      return value;
    }
  };

  const uploadAttachmentToAWS = (file: File) => {
    const moduleType = DOC_TYPE_TO_ATTACHMENT_MAP[DOC_TYPE.WORKORDER_OPERATION];
    AttachmentService.attachmentConfig = {
      ...AttachmentService.attachmentConfig,
      Module: moduleType,
      EntityId: ''
    };
    if (file.size && file.size / (1024 * 1024) > 5) {
      showAlert(
        'Attachment size limit exceeded',
        'It seems the file size is more than 5 MB, Please compress the file and try again.'
      );
      return;
    }
    setUpdating(true);
    AttachmentService.uploadAttachment(file)
      .then((res) => {
        setUpdating(false);
        const newAttachments = [...attachments, res];
        setAttachments(newAttachments);
        let newAttachmentIdsArray =
          formState.attachmentIds && formState.attachmentIds.length > 0
            ? [...formState.attachmentIds, res.attachmentId]
            : [res.attachmentId];
        let newAttachmentsArray =
          formState.attachments && formState.attachments.length > 0
            ? [...formState.attachments, JSON.stringify(res.attachmentId)]
            : [JSON.stringify(res.attachmentId)];
        setFormState({
          ...formState,
          attachmentIds: newAttachmentIdsArray,
          attachments: newAttachmentsArray
        });
      })
      .catch((err) => {
        setUpdating(false);
        console.log(err);
      });
  };

  const triggerAttachmentUpload = () => {
    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.addEventListener('change', (e) => {
      const target = e.target as HTMLInputElement;
      target?.files &&
        Array.from(target.files).forEach((file: File) =>
          uploadAttachmentToAWS(file)
        );
    });
    input.click();
  };

  const triggerAttachmentDownload = (
    attachmentId: any,
    attachmentName: string
  ) => {
    AttachmentService.downloadAttachment(attachmentId)
      .then((absolutePath) => {
        triggerDownload(null, attachmentName, absolutePath);
      })
      .catch(() => {});
  };

  const removeAttachment = (attachmentId: any) => {
    setUpdating(true);
    AttachmentService.deleteAttachment(attachmentId)
      .then((res) => {
        const newAttachments = attachments.filter(
          (attachment: any) => attachmentId !== attachment.attachmentId
        );
        setAttachments(newAttachments);
        setUpdating(false);
      })
      .catch(() => {
        setUpdating(false);
      });
  };

  const getAttachments = () => {
    return (
      <div className="row justify-content-start flex-wrap gap-2">
        {attachments.map((attachment: any) => (
          <div
            className="row border-m border-radius-s p-h-r p-v-s mr-r bg-gray0 overflow-hidden"
            key={attachment.attachmentId}
            style={{
              width: '18rem'
            }}
          >
            <DKIcon
              src={DKIcons.ic_document}
              className="ic-s cursor-pointer"
              onClick={() => {
                triggerAttachmentDownload(
                  attachment.attachmentId,
                  attachment.attachmentFileName
                );
              }}
            />
            <div
              className="ml-s w-auto truncate cursor-pointer border-none py-2"
              onClick={() => {
                triggerAttachmentDownload(
                  attachment.attachmentId,
                  attachment.attachmentFileName
                );
              }}
            >
              {attachment.attachmentFileName}
            </div>
            {/* <DKButton
              title={attachment.attachmentFileName}
              className="ml-s w-auto truncate cursor-pointer border-none"
              onClick={() => {
                triggerAttachmentDownload(
                  attachment.attachmentId,
                  attachment.attachmentFileName
                );
              }}
            /> */}
            <DKIcon
              src={DKIcons.ic_delete}
              className="ic-s ml-l cursor-pointer"
              onClick={() => removeAttachment(attachment.attachmentId)}
            />
          </div>
        ))}
      </div>
    );
  };

  const fetchAttachments = (id: any) => {
    const moduleType = DOC_TYPE_TO_ATTACHMENT_MAP[DOC_TYPE.WORKORDER_OPERATION];
    const entityId = id;
    if (!entityId) return;
    AttachmentService.attachmentConfig = {
      ...AttachmentService.attachmentConfig,
      Module: moduleType,
      EntityId: entityId
    };

    AttachmentService.getAllAttachments().then((attachmentList: any) => {
      setAttachments(attachmentList);
    });
  };

  const getEditorValue = (emailBody: any) => {
    if (emailBody === '<div><br></div>') {
      return '';
    }
    const html_text = Utility.encodeHTML(emailBody);
    return html_text;
  };

  const getOperationDetailSection = () => {
    return (
      <div className="column parent-width gap-2">
        <div className="parent-width">
          <DKLine className="mt-m mb-m" />
          <div className="row fw-m">Operation Details</div>
        </div>
        <div className="row gap-2">{getInstructionsView()}</div>
        <div className="column parent-width">
          <DKButton
            title={
              <>
                + Attach Files
                <span className="text-gray fw-r ml-s">(Max 5MB)</span>
              </>
            }
            style={{ padding: 0 }}
            icon={DKIcons.ic_attachment}
            className={`text-blue mt-r fw-m`}
            // className={`${
            //   props.draftType === DraftTypes.READONLY
            //     ? 'text-gray'
            //     : 'text-blue'
            // } mt-r fw-m`}
            // disabled={props.draftType === DraftTypes.READONLY}
            onClick={triggerAttachmentUpload}
          />
          <div className="row mt-r">{getAttachments()}</div>
        </div>
      </div>
    );
  };

  const getInstructionsView = () => {
    return (
      <EmailEditor
        editorID="operation-editor-body"
        body={formState?.instructions || ''}
        isPlaceholderRequired={false}
        inputPlaceholder={`Instructions for operation`}
      />
    );
  };

  const getCustomFieldView = () => {
    return (
      <>
        <div className="parent-width">
          <DKLine className="mt-m mb-m" />
          <div className="row fw-m">Custom Fields</div>
        </div>
        <CustomFieldsHolder
          moduleName={MODULES_NAME.MRP_OPERATION}
          columnConfig={operationColumnConfig}
          documentMode={isEditMode ? DOCUMENT_MODE.EDIT : DOCUMENT_MODE.NEW}
          customFieldsList={props?.editableOperation?.customField ?? []}
          onUpdate={(cfList: any[]) => {
            const copyOfFormState = { ...formState };
            copyOfFormState.customField = cfList;
            setFormState(copyOfFormState);
          }}
          columnConfigTableId={columnConfigTableId ?? ''}
        />
      </>
    );
  };

  const getOperatorField = () => {
    return (
      <div className="" style={{ minWidth: 260 }}>
        <DKInput
          titleStyle={{ fontSize: 14, color: 'gray' }}
          className={'parent-width'}
          value={operators}
          formatter={(obj: any) => {
            return obj.name;
          }}
          title="Operators"
          type={INPUT_TYPE.TEXT}
          direction={INPUT_VIEW_DIRECTION.VERTICAL}
          required={false}
          canValidate={false}
          errorMessage=" "
          onFocus={(value: any) => {
            setShowMultiPopup(true);
          }}
        />
      </div>
    );
  };

  return (
    <div className="transparent-background">
      <div
        className="popup-window"
        style={{
          // height: '98%',
          width: '85%',
          maxWidth: '570px',
          maxHeight: '90%',
          padding: 0,
          overflow: 'visible'
        }}
      >
        {getHeader()}
        <div className="parent-size parent-width p-l show-scroll-bar overflow-y-scroll">
          {getInputFieldsView()}
          {/* {getCorrectiveOption()} */}
          {/* {getJobCardView()} */}
          {/* {getSubOperationView()} */}
          {/* <div className="row gap-2">{getDescriptionView()}</div> */}
          {getCustomFieldView()}
          <div className="row">{getOperationDetailSection()}</div>
        </div>
      </div>
      {showWorkStationForm && (
        <div style={{ height: 400 }}>
          <AddWorkStation
            workStationData={{}}
            onCancel={() => {
              setShowWorkStationForm(false);
            }}
            onSuccess={(data: any) => {
              const updatedFormState = { ...formState };
              updatedFormState.defaultWorkstation = data;
              setFormState({ ...updatedFormState });
              dispatch(fetchWorkStations());
              setShowWorkStationForm(false);
            }}
          />
        </div>
      )}
      {showMultiPopup && (
        <MultiSelectOperatorPopup
          data={
            operatorsState.OperatorsArray ? operatorsState.OperatorsArray : []
          }
          onCancel={() => {
            setShowMultiPopup(false);
          }}
          onSave={(operatorsArray: any) => {
            let str: any = '';
            operatorsArray.forEach((operators: any, index: any) => {
              if (index === 2) {
                str += `  etc..`;
                return;
              }
              if (index === 0) str += `${operators.name} `;
              else if (index == 1) {
                str += `, ${operators.name}`;
              }
            });
            setOperators(str);
            setShowMultiPopup(false);
            setOperatorsState((prevState: any) => {
              return {
                ...prevState,
                Operators: operatorsArray.map((operators: any) => operators.id),
                OperatorsArray: operatorsArray
              };
            });
          }}
        />
      )}
    </div>
  );
};

export default AddNewOperation;
