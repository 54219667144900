import React, { useEffect, useState } from 'react';
import {
  showAlert,
  DKIcon,
  DKIcons,
  showLoader,
  removeLoader,
  DKLabel,
  showToast,
  TOAST_TYPE
} from 'deskera-ui-library';
import PrintPreview from '../Printing/PrintPreview';
import {
  BOOKS_DATE_FORMAT,
  DOC_TYPE,
  POPUP_CALLBACKS_TYPE,
  TEMPLATE_CATEGORY,
  TRACKING_TYPE
} from '../../Constants/Constant';
import Utility, { deepClone } from '../../Utility/Utility';
import useConfirm from '../../Hooks/useConfirm';
import { useTranslation } from 'react-i18next';
import { isTabletView, isViewportLarge } from '../../Utility/ViewportSizeUtils';
import DateFormatService from '../../Services/DateFormat';
import {
  getDocumentNumber,
  getFulfillmentQuantity,
  getReceivedGoodsQuantity,
  getReturnedQuantity,
  getTotalQuantity,
  shouldShowPrint
} from './LinkerRecordHelper';
import FulfillmentService from '../../Services/FulfillmentService';
import { fetchSerialTrackingProducts } from '../../Redux/Slices/SerialTrackingSlice';
import { useAppDispatch, useAppSelector } from '../../Redux/Hooks';
import { selectContacts } from '../../Redux/Slices/ContactsSlice';
import { activeTenantInfo } from '../../Redux/Slices/AuthSlice';
import AttachmentService from '../../Services/Attachment';
import {
  GranularPermissionsHelper,
  checkUserPermission
} from '../../Components/Settings/GranularPermissions/GranularPermissionsHelper';
import { PERMISSIONS_BY_MODULE } from '../../Constants/Permission';
import SalesReturnService from '../../Services/SalesReturn';
import PaymentService from '../../Services/Payment';
import { fetchInvoices } from '../../Redux/Slices/InvoicesSlice';
import { fetchBills } from '../../Redux/Slices/BillsSlice';
import { getBuyDashboard } from '../../Redux/Slices/DashboardSlice';
import EmailPaymentRecord from '../PaymentRecords/EmailPaymentRecord';
import ReceivedGoodsService from '../../Services/ReceivedGoods';
import PurchaseReturnService from '../../Services/PurchaseReturn';

function LinkedRecordListView(props: any) {
  const { t, i18n } = useTranslation();
  const { confirm } = useConfirm();
  const [isDesktop, setIsDesktop] = useState(isViewportLarge());

  const [isActiveTabClicked, setIsActiveTabClicked] = useState<any>(false);

  const [fulfillmentDetails, setFulfillmentDetails] = useState<any>(
    props.fulfillmentDetails
  );
  const [fulfillmentCode, setFulfillmentCode] = useState<any>(
    props?.fulfillmentDetails?.[0]?.fulfillment_code
  );
  const [documentUOMSchemaDefinition, setDocumentUOMSchemaDefinition] =
    useState<any>(props?.fulfillmentDetails?.[0]?.documentUOMSchemaDefinition);
  const [documentDetails, setDocumentDetails] = useState<any>(
    props.documentDetails
  );
  const [fulfillmentData, setFulfillmentData] = useState<any>(
    props.data?.[0] || {}
  );
  const [showPrintPreview, setShowPrintPreview] = useState(false);
  const [attachments, setAttachments] = useState<any[]>([]);
  const [itemsDescriptionArray, setItemDescriptionArray] = useState<any[]>([]);

  const [salesReturnCode, setSalesReturnCode] = useState<any>(
    props.salesReturnsDetails?.[0]?.salesReturnCode
  );

  const [paymentRecords, setPaymentRecords] = useState<any[]>(
    props.paymentRecords
  );
  const [selectedIndex, setSelectedIndex] = useState<any>(0);
  const [emailPayload, setEmailPayload] = useState<any>({});
  const [paymentRecordEmailPopup, setPaymentRecordEmailPopup] = useState(false);
  const [addEmailPopUp, setAddEmailPopUp] = useState(false);
  const [paymentData, setPaymentData] = useState<any>({
    accountName: '-',
    code: '-',
    id: '-',
    currency: 'INR',
    amount: '0.00',
    documentDate: '-',
    paymentType: '',
    referenceDate: '-',
    referenceNumber: '-',
    contactCode: '-',
    transactionFee: '-1',
    transactionFeeAccountName: ''
  });
  const [isActionDisabled, setIsActionDisabled] = useState<boolean>(
    props?.isActionDisabled ?? false
  );
  const [feeAccountName, setFeeAccountName] = useState<string>('');
  const [showPaymentPrintPreview, setShowPaymentPrintPreview] = useState(false);

  const [receivedGoodsDetails, setReceivedGoodsDetails] = useState<any>(
    props.receivedGoodsDetails
  );
  const [receivedGoodsData, setReceivedGoodsData] = useState<any>(
    props.receivedGoodsDetails?.[0]
  );

  const documentType = props.documentType;
  const propsDocumentDetails = props.documentDetails;

  const defaultDocumentItems: any = [];

  const [documentItems, setDocumentItems] = useState(
    documentType === DOC_TYPE.BILL
      ? (propsDocumentDetails &&
          propsDocumentDetails['purchaseInvoiceProducts']) ||
          defaultDocumentItems
      : documentType === DOC_TYPE.JOB_WORK_OUT_ORDER
      ? (propsDocumentDetails &&
          propsDocumentDetails['jobWorkOutOrderItems']) ||
        defaultDocumentItems
      : (propsDocumentDetails && propsDocumentDetails['purchaseOrderItems']) ||
        defaultDocumentItems
  );
  const [receivedGoodsCode, setReceivedGoodsCode] = useState<any>(
    props.receivedGoodsDetails?.[0]?.goods_receipt_code
  );
  const [showRGPrintPreview, setShowRGPrintPreview] = useState(false);

  const [purchaseReturnCode, setPurchaseReturnCode] = useState<any>(
    props.purchaseReturnsDetails?.[0]?.purchaseReturnCode
  );

  const [journalEntryCode, setJournalEntryCode] = useState<any>(
    props.journalEntryDetails?.[0]?.jeCode
  );

  const [showSalesReturnPrintPreview, setShowSalesReturnPrintPreview] =
    useState(false);

  //
  const dispatch = useAppDispatch();
  const contactData = useAppSelector(selectContacts);
  const tenantInfo = useAppSelector(activeTenantInfo);

  useEffect(() => {
    totalItemsForFulfillment();
    if (!isActiveTabClicked) {
      initialActiveTab();
    }
  }, [props]);

  const initialActiveTab = () => {
    if (props.fulfillmentDetails && props.fulfillmentDetails.length > 0) {
      setFulfillmentCode(props?.fulfillmentDetails?.[0]?.fulfillment_code);
      props.onActiveTabChange(DOC_TYPE.FULFILLMENT);
    } else if (
      props.salesReturnsDetails &&
      props.salesReturnsDetails.length > 0
    ) {
      setSalesReturnCode(props.salesReturnsDetails?.[0]?.salesReturnCode);
      props.onActiveTabChange(DOC_TYPE.SALES_RETURN);
    } else if (
      props.receivedGoodsDetails &&
      props.receivedGoodsDetails.length > 0
    ) {
      setReceivedGoodsCode(props.receivedGoodsDetails?.[0]?.goods_receipt_code);
      props.onActiveTabChange(DOC_TYPE.BILL);
    } else if (
      props.purchaseReturnsDetails &&
      props.purchaseReturnsDetails?.length > 0
    ) {
      setPurchaseReturnCode(
        props.purchaseReturnsDetails?.[0]?.purchaseReturnCode
      );
      props.onActiveTabChange(DOC_TYPE.PURCHASE_RETURN);
    } else if (props.paymentRecords && props.paymentRecords?.length > 0) {
      setSelectedIndex(0);
      props.onActiveTabChange(DOC_TYPE.Payment);
    } else {
      setJournalEntryCode(props.journalEntryDetails?.[0]?.jeCode);
      props.onJournalEntryChange(props.journalEntryDetails?.[0]?.jeCode);
      props.onActiveTabChange(DOC_TYPE.JOURNAL_ENTRY);
    }
  };

  useEffect(() => {
    if (props.paymentRecords) {
      getPaymentDetails();
      if (
        Utility.isEmptyObject(props.fulfillmentDetails) &&
        Utility.isEmptyObject(props.salesReturnsDetails) &&
        props.receivedGoodsDetails &&
        props.receivedGoodsDetails.length === 0 &&
        props.purchaseReturnsDetails &&
        props.purchaseReturnsDetails?.length === 0 &&
        props.paymentRecords?.length > 0
      ) {
        props.onActiveTabChange(DOC_TYPE.Payment);
      }
    }
    props.setSelectedIndex(selectedIndex);
  }, [selectedIndex, props.paymentRecords]);

  const getHeader = (title: string) => {
    return (
      <div className="row bg-gray2 p-h-s p-v-xs">
        <DKLabel text={title} className="fw-m" />
      </div>
    );
  };

  /****************************** Fulfillment section *********************************/

  const totalItemsForFulfillment = () => {
    let itemsArray =
      props.documentDetails?.salesInvoiceItems ||
      props.documentDetails?.quotationItemDtoList ||
      props.documentDetails?.salesOrderItems;
    setItemDescriptionArray(itemsArray);
  };

  const deleteFulfillment = (fulfillmentData: any, deleteAll = false) => {
    let payload = {
      fulfillmentCode: fulfillmentData.parentFulfillmentCode
        ? fulfillmentData.parentFulfillmentCode
        : fulfillmentData.fulfillment_code,
      code: fulfillmentData.documentCode,
      isBulk: deleteAll
    };
    if (fulfillmentData.documentType === DOC_TYPE.INVOICE) {
      deleteInvoiceFulfillment(payload);
    } else if (fulfillmentData.documentType === DOC_TYPE.SALES_ORDER) {
      deleteSalesOrderFulfillment(payload);
    } else {
      deleteQuoteFulfillment(payload);
    }
  };

  const deleteInvoiceFulfillment = (payload: any) => {
    showLoader();
    FulfillmentService.deleteInvoiceFulfillment(payload)
      .then((res: any) => {
        props.isDeleted(true);
        updateFulfillmentRecords(payload);
        removeLoader();
      })
      .catch((error: any) => {
        props.isDeleted(false);
        removeLoader();
      });
  };

  const deleteSalesOrderFulfillment = (payload: any) => {
    showLoader();
    const newPayload = {
      ...payload,
      documentSequenceCode: documentDetails.documentSequenceCode
    };
    FulfillmentService.deleteSalesOrderFulfillment(newPayload)
      .then((res: any) => {
        props.isDeleted(true);
        updateFulfillmentRecords(payload);
        removeLoader();
      })
      .catch((error: any) => {
        props.isDeleted(false);
        removeLoader();
      });
  };

  const deleteQuoteFulfillment = (payload: any) => {
    const newPayload = {
      ...payload,
      documentSequenceCode: documentDetails.documentSequenceCode
    };
    showLoader();
    FulfillmentService.deleteQuoteFulfillment(newPayload)
      .then((res: any) => {
        props.isDeleted(true);
        updateFulfillmentRecords(payload);
        removeLoader();
      })
      .catch((error: any) => {
        props.isDeleted(false);
        removeLoader();
      });
  };

  const updateFulfillmentRecords = (payload: any) => {
    let fulfillmentDetailsData = [...fulfillmentDetails];
    let fulfillmentData = fulfillmentDetailsData.find((item: any) => {
      const itemCode = item.parentFulfillmentCode
        ? item.parentFulfillmentCode
        : item.fulfillment_code;
      return itemCode === payload.fulfillmentCode;
    });

    const indexRecord = fulfillmentDetailsData.indexOf(fulfillmentData);
    fulfillmentData.fulfillmentItems.forEach((element: any) => {
      if (
        element.advancedTrackingType === TRACKING_TYPE.BATCH ||
        element.advancedTrackingType === TRACKING_TYPE.SERIAL
      ) {
        dispatch(
          fetchSerialTrackingProducts({
            productCode: element?.productCode,
            enableQCWarehouse: false
          })
        );
      }
    });
    if (indexRecord > -1) {
      fulfillmentDetailsData.splice(indexRecord, 1);
      if (fulfillmentDetailsData && fulfillmentDetailsData.length > 0) {
        setFulfillmentDetails((prevState: any[]) => fulfillmentDetailsData);
        setFulfillmentCode(fulfillmentDetailsData[0]?.fulfillment_code);
        getFulfillmentDetails(fulfillmentDetailsData[0]?.fulfillment_code);
      } else {
        closePopup();
      }
    }
  };

  const getFulfillmentDetails = (fulfillmentCode: any) => {
    let fulfillmentData = props.fulfillmentDetails?.find(
      (item: any) => item.fulfillment_code === fulfillmentCode
    );
    setFulfillmentCode(fulfillmentData.fulfillment_code);
    setDocumentUOMSchemaDefinition(fulfillmentData.documentUOMSchemaDefinition);
    setFulfillmentData(fulfillmentData);
  };

  const closePopup = () => {
    if (props.passingInteraction) {
      props.passingInteraction({
        type: POPUP_CALLBACKS_TYPE.CLOSE_POPUP
      });
    }
  };

  const printFulfillment = (fulfillmentData: any) => {
    setShowPrintPreview(true);
  };

  const fetchAttachments = (id?: any) => {
    let moduleType = DOC_TYPE.FULFILLMENT;

    const entityId = id
      ? id
      : fulfillmentDetails?.[0]?.id || fulfillmentDetails?.[0]?.entityId;

    if (!entityId) return;

    AttachmentService.attachmentConfig = {
      ...AttachmentService.attachmentConfig,
      Module: moduleType,
      EntityId: entityId
    };

    AttachmentService.getAllAttachments().then((attachmentList: any) => {
      setAttachments(attachmentList);
    });
  };
  let allowToDeleteFulfillmentRec = true;
  if (DOC_TYPE.SALES_ORDER === props.documentType) {
    allowToDeleteFulfillmentRec = checkUserPermission(
      PERMISSIONS_BY_MODULE.SALES_ORDER.FULFILL
    );
  }
  const getFulfillmentListContainer = (list: any, index: number) => {
    return (
      <div
        className={`flex cursor-hand p-2 flex-col hover:bg-blue-100 ${
          props.fulfillmentDetails?.length - 1 !== index ? 'border-b' : ''
        } ${
          props.activeTab === DOC_TYPE.FULFILLMENT &&
          fulfillmentCode === list.fulfillment_code
            ? ' bg-blue-100 '
            : ''
        }`}
        onClick={() => {
          setFulfillmentData(null);
          setTimeout(() => {
            getFulfillmentDetails(list.fulfillment_code);
            fetchAttachments(list.id);
          }, 0);
          props.onFulfillmentChange(list.fulfillment_code);
          props.onActiveTabChange(DOC_TYPE.FULFILLMENT);
          setIsActiveTabClicked(true);
        }}
      >
        <div className="text-black fs-r ml-0 flex row-responsive justify-content-between">
          <span className="pr-2  font-medium">{list.fulfillment_code}</span>
          <div className="flex">
            {!isTabletView() && (
              <span className="align-items-end pt-1 mr-2">
                <DKIcon
                  src={DKIcons.ic_printer}
                  className="ic-s cursor-hand"
                  title="Print"
                  onClick={() => printFulfillment(list)}
                />
              </span>
            )}

            {allowToDeleteFulfillmentRec && (
              <span className="align-items-end pt-1">
                <DKIcon
                  src={DKIcons.ic_delete}
                  className="ic-s cursor-hand"
                  title="Delete"
                  onClick={() => {
                    let buttons: any[] = [];
                    if (list.isBulkFulfillment) {
                      buttons = [
                        {
                          title: 'No',
                          className: 'bg-gray2 border-m ',
                          onClick: () => {}
                        },
                        {
                          title: 'Delete',
                          className: 'bg-red text-white ml-r',
                          onClick: () => {
                            deleteFulfillment(list, false);
                          }
                        },
                        {
                          title: 'Delete All',
                          className: 'bg-red text-white ml-r',
                          onClick: () => {
                            deleteFulfillment(list, true);
                          }
                        }
                      ];

                      showAlert(
                        'Delete Fulfillment Record',
                        'This record contains multiple fulfillment transactions, Do you want to delete all transactions or only for this document?',
                        buttons
                      );
                    } else {
                      buttons = [
                        {
                          title: 'No',
                          className: 'bg-gray2 border-m ',
                          onClick: () => {}
                        },
                        {
                          title: 'Delete',
                          className: 'bg-red text-white ml-r',
                          onClick: () => {
                            deleteFulfillment(list, false);
                          }
                        }
                      ];

                      showAlert(
                        'Delete Fulfillment Record',
                        t(`CONFIRMATION_POPUP.SURE_DELETE_TEXT`),
                        buttons
                      );
                    }
                  }}
                />
              </span>
            )}
          </div>
        </div>
        <div className="flex row-responsive justify-content-between mt-1">
          <span className="text-gray mr-1">
            {DateFormatService.getFormattedDateString(
              list.fulfillmentDate,
              BOOKS_DATE_FORMAT['DD-MM-YYYY']
            )}
          </span>
          <span className="align-items-end text-gray">
            {getFulfillmentQuantity(list.fulfillmentItems)} /{' '}
            {getTotalQuantity(itemsDescriptionArray)} Item(s)
          </span>
        </div>
      </div>
    );
  };

  const getFulfillmentRecordSection = () => {
    return (
      <div className="border-m">
        {getHeader('Fulfillment Records')}
        {props.fulfillmentDetails &&
          props.fulfillmentDetails.map((item: any, index: any) => {
            return getFulfillmentListContainer(item, index);
          })}
      </div>
    );
  };

  /****************************** Fulfillment section *********************************/

  /****************************** Sales Order section *********************************/
  const getSalesReturnDetails = (salesReturnCode: any) => {
    let salesReturnsData = props.salesReturnsDetails.find(
      (item: any) => item.salesReturnCode === salesReturnCode
    );
    setSalesReturnCode(salesReturnsData.salesReturnCode);
    setDocumentUOMSchemaDefinition(
      salesReturnsData.documentUOMSchemaDefinition
    );
    // setSalesReturnsDetails(salesReturnsData);
    props.onActiveTabChange(DOC_TYPE.SALES_RETURN);
  };

  const deleteSalesReturn = (fulfillmentData: any, deleteAll = false) => {
    let payload = {
      salesReturnCode: fulfillmentData.salesReturnCode
    };
    deleteInvoiceReturn(payload);
  };

  const deleteInvoiceReturn = (payload: any) => {
    showLoader();
    SalesReturnService.deleteSalesReturn(payload.salesReturnCode)
      .then((res: any) => {
        props.isDeleted(true);
        removeLoader();
      })
      .catch((error: any) => {
        props.isDeleted(false);
        if (error && error['status'] === 500) {
          showAlert(
            'Error!',
            'Sales return can not be deleted,product quantity will go in negative'
          );
        } else {
          if (error && error['data'] && error['data']['errorMessage']) {
            showAlert('Error!', error['data']['errorMessage']);
          } else {
            showAlert('Error!', 'Unable to delete sales returns record.');
          }
        }
        removeLoader();
      });
  };

  const getSalesReturnPrintPreview = (data: any) => {
    return (
      <PrintPreview
        documentType={DOC_TYPE.SALES_RETURN}
        document={{ ...data, documentCode: data.salesReturnCode }}
        closePreview={(val: boolean) => {
          setShowPrintPreview(val);
          setShowSalesReturnPrintPreview(val);
        }}
      />
    );
  };

  const salesReturnRecordListContainer = (item: any, index: number) => {
    return (
      <div
        className={`flex cursor-hand p-2 flex-col hover:-100 ${
          props.salesReturnsDetails?.length - 1 !== index ? 'border-b' : ''
        }
          ${
            props.activeTab === DOC_TYPE.SALES_RETURN &&
            salesReturnCode === item.salesReturnCode
              ? ' bg-blue-100 '
              : ''
          }`}
        onClick={() => {
          getSalesReturnDetails(item.salesReturnCode);
          props.onSalesOrderChange(item.salesReturnCode);
          setIsActiveTabClicked(true);
        }}
      >
        {showSalesReturnPrintPreview && getSalesReturnPrintPreview(item)}
        <div className="text-black fs-r ml-0 flex justify-content-between">
          <span className="pr-2  font-medium">{item.salesReturnCode}</span>
          <div className="flex">
            <span className="align-items-end pt-1 pr-1">
              <DKIcon
                src={DKIcons.ic_printer}
                className="ic-s cursor-hand"
                title="Print"
                onClick={() => {
                  setShowSalesReturnPrintPreview(true);
                }}
              />
            </span>
            {GranularPermissionsHelper.hasPermissionFor(
              PERMISSIONS_BY_MODULE.PURCHASE_SELL_RETURN.DELETE
            ) && (
              <span className="align-items-end pt-1">
                <DKIcon
                  src={DKIcons.ic_delete}
                  className="ic-s cursor-hand"
                  title="Delete"
                  onClick={() => {
                    let buttons: any[] = [];
                    if (item.isBulkFulfillment) {
                      buttons = [
                        {
                          title: 'No',
                          className: 'bg-gray2 border-m ',
                          onClick: () => {}
                        },
                        {
                          title: 'Delete',
                          className: 'bg-red text-white ml-r',
                          onClick: () => {
                            deleteSalesReturn(item, false);
                          }
                        },
                        {
                          title: 'Delete All',
                          className: 'bg-red text-white ml-r',
                          onClick: () => {
                            deleteSalesReturn(item, true);
                          }
                        }
                      ];

                      showAlert(
                        'Are you sure you want to delete this Sales Return?',
                        'Note: Any issued Credit Notes or Direct Expenses need to be deleted separately',
                        buttons
                      );
                    } else {
                      buttons = [
                        {
                          title: 'No',
                          className: 'bg-gray2 border-m ',
                          onClick: () => {}
                        },
                        {
                          title: 'Delete',
                          className: 'bg-red text-white ml-r',
                          onClick: () => {
                            deleteSalesReturn(item, false);
                          }
                        }
                      ];

                      showAlert(
                        'Are you sure you want to delete this Sales Return?',
                        'Note: Any issued Credit Notes or Direct Expenses need to be deleted separately',
                        buttons
                      );
                    }
                  }}
                />
              </span>
            )}
          </div>
        </div>
        <div className="flex justify-content-between mt-1">
          <span className="text-gray mr-1">
            {DateFormatService.getFormattedDateString(
              item.salesReturnDate,
              BOOKS_DATE_FORMAT['DD-MM-YYYY']
            )}
          </span>
          <span className="align-items-end text-gray text-align-right">
            {getReturnedQuantity(item.salesReturnItems)} {} Item(s) returned
          </span>
        </div>
      </div>
    );
  };

  const getSalesReturnRecordSection = () => {
    return (
      <div className="border-m">
        {getHeader('Sales Returns Records')}
        {props.salesReturnsDetails &&
          props.salesReturnsDetails.map((item: any, index: any) => {
            return salesReturnRecordListContainer(item, index);
          })}
      </div>
    );
  };

  /****************************** Sales Order section *********************************/

  /****************************** Payment section *********************************/
  const makeEmailPayLoad = (
    email: any,
    contactName: any,
    contactAddress: any
  ) => {
    let tenantName = '';
    if (tenantInfo && tenantInfo.data) {
      tenantName = tenantInfo.data.name ? tenantInfo.data.name : '';
    }

    let documentType: any = '';
    switch (props.documentType) {
      case DOC_TYPE.INVOICE:
        documentType = 'Invoice';
        break;
      case DOC_TYPE.BILL:
        documentType = 'Bill';
        break;
    }

    const a = {
      application: 'ERP',
      body: `Hello%2C%3Cbr%2F%3E%3Cbr%2F%3EThank%20you%20for%20reaching%20out%20to%20us.%3Cbr%2F%3E%3Cbr%2F%3EPlease%20find%20the%20${documentType}%20%5B${documentDetails?.documentSequenceCode}%5D%20attached%20with%20this%20mail%2C%20in%20response%20to%20your%20inquiry.%3Cbr%2F%3E%3Cbr%2F%3EKindly%20review%20the%20same%20and%20contact%20us%20for%20any%20further%20queries%20or%20details.%20We%20look%20forward%20to%20doing%20business%20with%20you.%3Cbr%2F%3E%3Cbr%2F%3EThanks%20%26%20Regards%2C%3Cbr%2F%3E%5BIN%20org%5D`,
      category: 'PAYMENT',
      exportDocumentRequest: {
        categoryName: 'PAYMENT',
        documentNumber: paymentData.code,
        fileName: 'PAYMENT.DOCX',
        headers: [
          {
            contactCode: paymentData.contactCode,
            contactName: contactName,
            contactBillingAddress: contactAddress,
            items: [
              {
                SNo: 1,
                Description: `${documentType} # ${documentDetails?.documentSequenceCode}`,
                Total: paymentData.currency + ' ' + paymentData.amount
              }
            ],
            checkDate: '',
            refDate: paymentData.referenceDate,
            exchangeRate: '',
            tenantCurrency: paymentData.currency,
            docCurrSymbol: paymentData.currency,
            tranCurrSymbol: paymentData.currency,
            amtPaidTranCurrency:
              paymentData.currency + ' ' + paymentData.amount,
            whtAmount: '',
            companyName: tenantName,
            docMemo: '',
            receiptNo: paymentData.code,
            date: paymentData.documentDate,
            refNumber: paymentData.referenceNumber,
            totalAmount: paymentData.currency + ' ' + paymentData.amount,
            paymentDescription: `${documentType} # ${documentDetails?.documentSequenceCode}`,
            bankName: paymentData.accountName,
            paymentMode: paymentData.paymentType,
            amtPaidDocCurrency: paymentData.currency + ' ' + paymentData.amount
          }
        ],
        moduleName: 'ERP',
        templateId: 4
      },
      subject: `Confirmation of payment for ${documentType} ${documentDetails?.documentSequenceCode}`,
      to: email
    };
    setEmailPayload(a);
    return a;
  };
  const emailFunction = (code: any, i: any) => {
    if (i !== selectedIndex) {
      return;
    }
    let email: string = '';
    let contactName: string = '';
    let contactAddress: string = '';

    if (contactData && contactData.content && contactData.content.length > 0) {
      contactData.content.forEach((contact: any) => {
        if (contact.code === code) {
          email = contact.emailId ? contact.emailId : '';
          contactName = contact.name ? contact.name : '';
          contactAddress =
            contact.shippingAddress && contact.shippingAddress.length > 0
              ? contact.shippingAddress[0].state &&
                contact.shippingAddress[0].country
                ? contact.shippingAddress[0].state +
                  ',' +
                  contact.shippingAddress[0].country
                : ''
              : '';
        }
      });
    }

    if (props?.isDocumentEmailFlow) {
      makeEmailPayLoad(email, contactName, contactAddress);
      setPaymentRecordEmailPopup(true);
      return;
    }

    if (!email) {
      makeEmailPayLoad('', contactName, contactAddress);
      setAddEmailPopUp(true);
    } else {
      const payload = makeEmailPayLoad(email, contactName, contactAddress);
      PaymentService.emailPayment(payload)
        .then((response) => {
          showToast('Mail sent successfully', TOAST_TYPE.SUCCESS);
          setAddEmailPopUp(false);
        })
        .catch((error) => {
          console.error('Error sending mail: ', error);
        });
    }
  };

  const deletePaymentRecords = (record: any, i: any) => {
    let lastElement = false;
    if (paymentRecords && paymentRecords?.length <= 1) {
      lastElement = true;
    }
    showLoader();

    let paymentCode = record.documentCode;
    let docCode =
      props?.documentDetails?.salesInvoiceCode ??
      props?.documentDetails?.purchaseInvoiceCode;
    if (
      record.documentType === DOC_TYPE.INVOICE ||
      record.documentType === DOC_TYPE.BILL
    ) {
      paymentCode = record.contraDocumentCode;
      docCode = record.documentCode;
    }
    PaymentService.deletePaymentRecord(
      paymentCode,
      docCode,
      record.documentType,
      record.uid
    ).then(
      (res: any) => {
        const updatedState: any = deepClone(paymentRecords);
        updatedState.splice(i, 1);
        setPaymentRecords([...updatedState]);
        if (
          record.documentType === DOC_TYPE.BILL ||
          record.documentType === DOC_TYPE.DEPOSIT_ADVANCED_PAYMENT ||
          record.documentType === DOC_TYPE.CREDIT_NOTE ||
          record.documentType === DOC_TYPE.RECEIVE_PAYMENT
        ) {
          dispatch(fetchInvoices());
        } else {
          dispatch(fetchBills());
          dispatch(getBuyDashboard());
        }

        setSelectedIndex(0);
        lastElement === true ? closePopup() : setSelectedIndex(0);
        props.fetchCurrentDocument();
        // showAlert('Success!', 'Payment record deleted successfully');
        removeLoader();
      },
      (err) => {
        showAlert('Error!', 'Payment is in use, cannot be deleted');
        removeLoader();
      }
    );
  };

  const getTransactionFeeAccount = (code: any) => {
    try {
      PaymentService.getFeeAccountData(code).then((data: any) => {
        let response = data.content[0];
        setFeeAccountName(`(` + response.name + `)`);
      });
    } catch (err) {
      console.error('Error loading Fee Accounts: ', err);
    }
  };

  const getTransactionFee = (receivePaymentFeeDtoList: any) => {
    if (receivePaymentFeeDtoList.length > 0) {
      getTransactionFeeAccount(receivePaymentFeeDtoList[0].accountCode);
      let sum = 0;
      receivePaymentFeeDtoList.map((paymentFee: any) => {
        sum += paymentFee.amount;
      });
      return sum;
    } else {
      return -1;
    }
  };

  const getPaymentDetails = () => {
    const newState = deepClone(props.paymentRecords);
    const obj = newState[selectedIndex];
    // setCustomField(null);
    if (obj) {
      if (
        obj.documentType === 'RECEIVE_PAYMENT' ||
        obj.documentType === 'MAKE_PAYMENT' ||
        obj.documentType === 'DEPOSIT_ADVPAYMENT' ||
        obj.documentType === 'EXPENSE_PREPAYMENT'
      ) {
        PaymentService.fetchPaymentRecordDetails(
          obj.documentCode,
          props.documentType
        ).then((res: any) => {
          const content: any[] = res.content;
          if (content.length > 0 && newState) {
            const paymentDetails: any = content[0];

            // setCustomField(paymentDetails.customField);
            var documentDate = new Date(paymentDetails.documentDate);
            var referenceDate = new Date(paymentDetails.referenceDate);
            if (
              obj.documentType === DOC_TYPE.DEPOSIT_ADVANCED_PAYMENT ||
              obj.documentType === DOC_TYPE.PREPAYMENT
            ) {
              documentDate = new Date(obj.documentDate);
            }
            if (obj.documentType === 'RECEIVE_PAYMENT') {
              const obj1 = {
                accountName: paymentDetails.accountName,
                code: paymentDetails.code,
                customField: paymentDetails.customField,
                linkedDocumentSequence: paymentDetails.linkedDocumentSequence,
                documentCode: paymentDetails.documentCode,
                id: paymentDetails.id,
                currency: paymentDetails.currency,
                amount: obj.amount,
                paymentType: paymentDetails.paymentType,
                referenceDate: referenceDate
                  ? DateFormatService.getDateStrFromDate(referenceDate)
                  : '-',
                referenceNumber: paymentDetails.referenceNumber
                  ? paymentDetails.referenceNumber
                  : '-',
                documentDate: documentDate
                  ? DateFormatService.getDateStrFromDate(documentDate)
                  : '-',
                contactCode: paymentDetails.contactCode,
                transactionFee:
                  paymentDetails.receivePaymentFeeDtoList.length > 0
                    ? getTransactionFee(paymentDetails.receivePaymentFeeDtoList)
                    : -1
              };
              setPaymentData(obj1);
              props.onPaymentRecordChange(obj1);
            } else {
              const obj1 = {
                accountName: paymentDetails.accountName,
                code: paymentDetails.code,
                customField: paymentDetails.customField,
                linkedDocumentSequence: paymentDetails.linkedDocumentSequence,
                documentCode: paymentDetails.documentCode,
                id: paymentDetails.id,
                currency: paymentDetails.currency,
                amount: obj.amount,
                paymentType: paymentDetails.paymentType,
                referenceDate: referenceDate
                  ? DateFormatService.getDateStrFromDate(referenceDate)
                  : '-',
                referenceNumber: paymentDetails.referenceNumber
                  ? paymentDetails.referenceNumber
                  : '-',
                documentDate: documentDate
                  ? DateFormatService.getDateStrFromDate(documentDate)
                  : '-',
                contactCode: paymentDetails.contactCode
              };
              setPaymentData(obj1);
              props.onPaymentRecordChange(obj1);
            }
          }
        });
      } else {
        const obj1 = {
          code: obj.code,
          linkedDocumentSequence: obj.linkedDocumentSequence,
          documentCode: obj.linkedDocumentSequence,
          id: obj.id,
          currency: obj.currency,
          amount: obj.amount,
          paymentType: obj.documentType,
          referenceDate: '',
          referenceNumber: '',
          documentDate: obj.documentDate
            ? DateFormatService.getDateStrFromDate(new Date(obj.documentDate))
            : '-'
        };
        if (
          obj.documentType === 'CREDIT_NOTE' ||
          obj.documentType === 'DEBIT_NOTE'
        ) {
          obj1['documentCode'] = obj.documentCode;
        }
        setPaymentData(obj1);
        props.onPaymentRecordChange(obj1);
      }
    }
  };

  const getDocumentTypeForPrintRecord = () => {
    const type =
      props.paymentRecords[selectedIndex] &&
      props.paymentRecords[selectedIndex].documentType
        ? props.paymentRecords[selectedIndex].documentType
        : '';

    return type;
  };

  const paymentRecordListContainer = (item: any, index: number) => {
    return (
      <div
        className={`flex cursor-hand p-2 flex-col hover:-100 ${
          props.paymentRecords?.length - 1 !== index ? 'border-b' : ''
        }
          ${
            props.activeTab === DOC_TYPE.Payment && selectedIndex === index
              ? ' bg-blue-100 '
              : ''
          }`}
        onClick={() => {
          setSelectedIndex(index);
          props.onActiveTabChange(DOC_TYPE.Payment);
          setIsActiveTabClicked(true);
        }}
      >
        <div className="text-black row-responsive fs-r ml-0 flex justify-content-between ">
          <DKLabel
            className="pr-2  font-medium"
            text={getDocumentNumber(item)}
          />
          <div className="flex" style={{ marginTop: '3px' }}>
            {!props.isReport && (
              <>
                {shouldShowPrint(item) && (
                  <>
                    <DKIcon
                      style={{ marginRight: '5px' }}
                      src={DKIcons.ic_email}
                      className="ic-s cursor-hand"
                      title="Email"
                      onClick={() => {
                        if (isActionDisabled) {
                          showAlert(
                            'Action Prevented.',
                            'You cannot perform this action because it is read-only popup.'
                          );
                          return;
                        }
                        emailFunction(paymentData.contactCode, index);
                      }}
                    />
                    {!isTabletView() && (
                      <DKIcon
                        style={{ marginRight: '3px' }}
                        src={DKIcons.ic_printer}
                        className="ic-s cursor-hand"
                        title="Print"
                        onClick={() => {
                          if (isActionDisabled) {
                            showAlert(
                              'Action Prevented.',
                              'You cannot perform this action because it is read-only popup.'
                            );
                            return;
                          }
                          setShowPaymentPrintPreview(true);
                        }}
                      />
                    )}
                  </>
                )}

                <DKIcon
                  src={DKIcons.ic_delete}
                  className="ic-s cursor-hand"
                  title="Delete"
                  onClick={async () => {
                    if (isActionDisabled) {
                      showAlert(
                        'Action Prevented.',
                        'You cannot perform this action because it is read-only popup.'
                      );
                      return;
                    }
                    const isConfirmed = await confirm(
                      t(`CONFIRMATION_POPUP.SURE_DELETE_TEXT`)
                    );
                    if (isConfirmed) {
                      deletePaymentRecords(item, index);
                    }
                  }}
                />
              </>
            )}
          </div>
        </div>
        <div className="flex justify-content-between">
          <span className="text-black-light fs-r">{}</span>
        </div>
      </div>
    );
  };

  const getPaymentRecordSection = () => {
    return (
      <div className="border-m">
        {getHeader('Payment Records')}
        {props.paymentRecords &&
          props.paymentRecords.map((record: any, index: any) => {
            return paymentRecordListContainer(record, index);
          })}
      </div>
    );
  };
  /****************************** Payment section *********************************/

  /******************************** Receive good records ************************************/

  const updateReceivedGoodsRecords = (payload: any) => {
    let receivedGoodsDetailsData = [...receivedGoodsDetails];
    let receivedGoodsData = receivedGoodsDetailsData.find((item: any) => {
      const itemCode = item.parentGrCode
        ? item.parentGrCode
        : item.goods_receipt_code;
      return itemCode === payload.rgCode;
    });

    const indexRecord = receivedGoodsDetailsData.indexOf(receivedGoodsData);
    receivedGoodsData.items.forEach((element: any) => {
      if (
        element.advancedTrackingType === TRACKING_TYPE.BATCH ||
        element.advancedTrackingType === TRACKING_TYPE.SERIAL
      ) {
        dispatch(
          fetchSerialTrackingProducts({
            productCode: element?.productCode,
            enableQCWarehouse: false
          })
        );
      }
    });
    if (indexRecord > -1) {
      receivedGoodsDetailsData.splice(indexRecord, 1);
      if (receivedGoodsDetailsData && receivedGoodsDetailsData.length > 0) {
        setReceivedGoodsDetails((prevState: any[]) => receivedGoodsDetailsData);
        setReceivedGoodsCode(receivedGoodsDetailsData[0].goods_receipt_code);
        // getReceivedGoodsDetails(receivedGoodsDetailsData[0].goods_receipt_code);
      } else {
        closePopup();
      }
    }
  };

  const deleteReceivedGoods = (item: any, deleteAll: boolean = false) => {
    let payload = {
      rgCode: item.parentGrCode ? item.parentGrCode : item.goods_receipt_code,
      code:
        item.documentType === DOC_TYPE.JOB_WORK_OUT_ORDER
          ? item.id
          : item.documentCode,
      isBulk: deleteAll
    };
    if (item.documentType === DOC_TYPE.BILL) {
      deleteBillReceivedGoods(payload);
    } else if (item.documentType === DOC_TYPE.JOB_WORK_OUT_ORDER) {
      deleteJWOReceivedGoods(payload);
    } else {
      deletePoReceivedGoods(payload);
    }
  };

  const deleteBillReceivedGoods = (payload: any) => {
    showLoader();
    ReceivedGoodsService.deleteBillReceivedGoods(payload)
      .then((res: any) => {
        props.isDeleted(true);
        updateReceivedGoodsRecords(payload);
        removeLoader();
      })
      .catch((error: any) => {
        props.isDeleted(false);
        removeLoader();
      });
  };

  const deletePoReceivedGoods = (payload: any) => {
    showLoader();
    ReceivedGoodsService.deletePoReceivedGoods(payload)
      .then((res: any) => {
        props.isDeleted(true);
        updateReceivedGoodsRecords(payload);
        removeLoader();
      })
      .catch((error: any) => {
        props.isDeleted(false);
        removeLoader();
      });
  };

  const deleteJWOReceivedGoods = (payload: any) => {
    showLoader();
    ReceivedGoodsService.deleteJWOReceivedGoods(payload)
      .then((res: any) => {
        props.isDeleted(true);
        updateReceivedGoodsRecords(payload);
        removeLoader();
      })
      .catch((error: any) => {
        props.isDeleted(false);
        removeLoader();
      });
  };

  const getRGTotalQuantity = () => {
    let totalGoodsReceived = 0;
    const items = receivedGoodsDetails?.[0]?.items;
    documentItems.forEach((item: any) => {
      totalGoodsReceived += item.documentUOMSchemaDefinition
        ? item['uomQuantity']
        : item['productQuantity'];
    });

    return totalGoodsReceived;
  };

  const getReceivedGoodsDetails = (receivedGoodsCode: any) => {
    let receivedGoodsData = props.receivedGoodsDetails?.find(
      (item: any) => item.goods_receipt_code === receivedGoodsCode
    );
    setReceivedGoodsCode(receivedGoodsData?.goods_receipt_code);
    setDocumentUOMSchemaDefinition(
      receivedGoodsData?.documentUOMSchemaDefinition
    );
    setReceivedGoodsData(receivedGoodsData);
    props.onActiveTabChange(DOC_TYPE.BILL);
  };

  const getReceivedGoodsContainer = (item: any, index: any) => {
    return (
      <div
        className={`flex cursor-hand p-2 flex-col hover:-100 ${
          props.receivedGoodsDetails?.length - 1 !== index ? 'border-b' : ''
        }
          ${
            props.activeTab === DOC_TYPE.BILL &&
            receivedGoodsCode === item.goods_receipt_code
              ? ' bg-blue-100 '
              : ''
          }`}
        onClick={() => {
          setReceivedGoodsData(null);
          setTimeout(() => {
            getReceivedGoodsDetails(item.goods_receipt_code);
            fetchAttachments(item.id);
            props.onReceivedGoodRecordChange(item.goods_receipt_code);
          }, 0);
          setIsActiveTabClicked(true);
        }}
      >
        <div className="text-black fs-r ml-0 flex row-responsive justify-content-between ">
          <span className="pr-2  font-medium">{item.goods_receipt_code}</span>
          <div className="flex">
            {!isTabletView() &&
              props.documentType !== DOC_TYPE.JOB_WORK_OUT_ORDER && (
                <span className="align-items-end pt-1 mr-2">
                  <DKIcon
                    src={DKIcons.ic_printer}
                    className="ic-s cursor-hand"
                    title="Print"
                    onClick={() => setShowRGPrintPreview(true)}
                  />
                </span>
              )}

            <span className="align-items-end pt-1">
              <DKIcon
                src={DKIcons.ic_delete}
                className="ic-s cursor-hand"
                title="Delete"
                onClick={() => {
                  let buttons: any[] = [];
                  if (item.isBulkGR) {
                    buttons = [
                      {
                        title: 'No',
                        className: 'bg-gray2 border-m ',
                        onClick: () => {}
                      },
                      {
                        title: 'Delete',
                        className: 'bg-red text-white ml-r',
                        onClick: () => {
                          deleteReceivedGoods(item, false);
                        }
                      },
                      {
                        title: 'Delete All',
                        className: 'bg-red text-white ml-r',
                        onClick: () => {
                          deleteReceivedGoods(item, true);
                        }
                      }
                    ];

                    showAlert(
                      'Delete Received Goods Record',
                      'This record contains multiple received goods transactions, Do you want to delete all transactions or only for this document?',
                      buttons
                    );
                  } else {
                    buttons = [
                      {
                        title: 'No',
                        className: 'bg-gray2 border-m ',
                        onClick: () => {}
                      },
                      {
                        title: 'Delete',
                        className: 'bg-red text-white ml-r',
                        onClick: () => {
                          deleteReceivedGoods(item, false);
                        }
                      }
                    ];

                    showAlert(
                      'Delete Received Goods Record',
                      t(`CONFIRMATION_POPUP.SURE_DELETE_TEXT`),
                      buttons
                    );
                  }
                }}
              />
            </span>
          </div>
        </div>
        <div className="flex row-responsive justify-content-between pt-1">
          <span className="text-gray mr-1">
            {DateFormatService.getFormattedDateString(
              item.receiptDate,
              BOOKS_DATE_FORMAT['DD-MM-YYYY']
            )}
          </span>
          <span className="align-items-end text-gray">
            {getReceivedGoodsQuantity(item.items)} / {getRGTotalQuantity()}{' '}
            Item(s)
          </span>
        </div>
      </div>
    );
  };

  const getReceivedGoodSection = () => {
    return (
      <div className="border-m">
        {getHeader('Received Goods Records')}
        {props.receivedGoodsDetails &&
          props.receivedGoodsDetails.map((record: any, index: any) => {
            return getReceivedGoodsContainer(record, index);
          })}
      </div>
    );
  };

  /******************************** Receive good records ************************************/

  /****************************** Purchase Return section *********************************/
  const getPurchaseReturnDetails = (purchaseReturnCode: any) => {
    let purchaseReturnsData = props.purchaseReturnsDetails?.find(
      (item: any) => item.purchaseReturnCode === purchaseReturnCode
    );
    setPurchaseReturnCode(purchaseReturnsData.purchaseReturnCode);
    setDocumentUOMSchemaDefinition(
      purchaseReturnsData.documentUOMSchemaDefinition
    );
    // setSalesReturnsDetails(salesReturnsData);
    props.onActiveTabChange(DOC_TYPE.PURCHASE_RETURN);
  };

  const deletePurchaseReturn = (
    purchaseReturnDetails: any,
    deleteAll = false
  ) => {
    let payload = {
      purchaseReturnCode: purchaseReturnDetails.purchaseReturnCode
    };
    deletePurchaseReturnData(payload);
  };

  const deletePurchaseReturnData = (payload: any) => {
    showLoader();
    PurchaseReturnService.deletePurchaseReturn(payload.purchaseReturnCode)
      .then((res: any) => {
        props.isDeleted(true);
        removeLoader();
      })
      .catch((error: any) => {
        props.isDeleted(false);
        if (error && error['status'] === 500) {
          showAlert(
            'Error!',
            'Purchase return can not be deleted,product quantity will go in negative'
          );
        } else {
          if (error && error['data'] && error['data']['errorMessage']) {
            showAlert('Error!', error['data']['errorMessage']);
          } else {
            showAlert('Error!', 'Unable to delete purchase returns record.');
          }
        }
        removeLoader();
      });
  };

  const purchaseReturnRecordListContainer = (item: any, index: number) => {
    return (
      <div
        className={`flex cursor-hand p-2 flex-col hover:-100  ${
          props.purchaseReturnsDetails?.length - 1 !== index ? 'border-b' : ''
        }
          ${
            props.activeTab === DOC_TYPE.PURCHASE_RETURN &&
            purchaseReturnCode === item.purchaseReturnCode
              ? ' bg-blue-100 '
              : ''
          }`}
        onClick={() => {
          getPurchaseReturnDetails(item.purchaseReturnCode);
          props.onPurchaseOrderChange(item.purchaseReturnCode);
          setIsActiveTabClicked(true);
        }}
      >
        <div className="text-black fs-r ml-0 flex justify-content-between">
          <span className="pr-2  font-medium">{item.purchaseReturnCode}</span>
          <div className="flex">
            {GranularPermissionsHelper.hasPermissionFor(
              PERMISSIONS_BY_MODULE.PURCHASE_SELL_RETURN.DELETE
            ) && (
              <span className="align-items-end pt-1">
                <DKIcon
                  src={DKIcons.ic_delete}
                  className="ic-s cursor-hand"
                  title="Delete"
                  onClick={() => {
                    let buttons: any[] = [];
                    if (item.isBulkFulfillment) {
                      buttons = [
                        {
                          title: 'No',
                          className: 'bg-gray2 border-m ',
                          onClick: () => {}
                        },
                        {
                          title: 'Delete',
                          className: 'bg-red text-white ml-r',
                          onClick: () => {
                            deletePurchaseReturn(item, false);
                          }
                        },
                        {
                          title: 'Delete All',
                          className: 'bg-red text-white ml-r',
                          onClick: () => {
                            deletePurchaseReturn(item, true);
                          }
                        }
                      ];

                      showAlert(
                        'Are you sure you want to delete this Purchase Return?',
                        'Note: Any issued Debit Notes or Direct Deposits need to be deleted separately',
                        buttons
                      );
                    } else {
                      buttons = [
                        {
                          title: 'No',
                          className: 'bg-gray2 border-m ',
                          onClick: () => {}
                        },
                        {
                          title: 'Delete',
                          className: 'bg-red text-white ml-r',
                          onClick: () => {
                            deletePurchaseReturn(item, false);
                          }
                        }
                      ];

                      showAlert(
                        'Are you sure you want to delete this Purchase Return?',
                        'Note: Any issued Debit Notes or Direct Deposit need to be deleted separately',
                        buttons
                      );
                    }
                  }}
                />
              </span>
            )}
          </div>
        </div>
        <div className="flex justify-content-between mt-1">
          <span className="text-gray mr-1">
            {DateFormatService.getFormattedDateString(
              item.purchaseReturnDate,
              BOOKS_DATE_FORMAT['DD-MM-YYYY']
            )}
          </span>
          <span className="align-items-end text-gray text-align-right">
            {getReturnedQuantity(item.purchaseReturnItems)} {} Item(s) returned
          </span>
        </div>
      </div>
    );
  };

  const getPurchaseReturnRecordSection = () => {
    return (
      <div className="border-m">
        {getHeader('Purchase Return Records')}
        {props.purchaseReturnsDetails &&
          props.purchaseReturnsDetails.map((item: any, index: any) => {
            return purchaseReturnRecordListContainer(item, index);
          })}
      </div>
    );
  };

  /****************************** Purchase Return section *********************************/

  /****************************** Journal Entry section *********************************/
  const journalEntryRecordListContainer = (item: any, index: number) => {
    return (
      <div
        className={`flex cursor-hand p-2 flex-col hover:-100 ${
          props.journalEntryDetails?.length - 1 !== index ? 'border-b' : ''
        }
          ${
            props.activeTab === DOC_TYPE.JOURNAL_ENTRY &&
            journalEntryCode === item.jeCode
              ? ' bg-blue-100 '
              : ''
          }`}
        onClick={() => {
          setJournalEntryCode(item.jeCode);
          props.onActiveTabChange(DOC_TYPE.JOURNAL_ENTRY);
          props.onJournalEntryChange(item.jeCode);
          setIsActiveTabClicked(true);
        }}
      >
        <div className="text-black row-responsive fs-r ml-0 flex justify-content-between ">
          <DKLabel className="pr-2  font-medium" text={item.jeCode} />
          <div className="flex">
            <span className="text-gray mr-1">
              {DateFormatService.getFormattedDateString(
                item.jeDate,
                BOOKS_DATE_FORMAT['DD-MM-YYYY']
              )}
            </span>
          </div>
        </div>
        <div className="flex justify-content-between">
          <span className="text-black-light fs-r">{}</span>
        </div>
      </div>
    );
  };

  const getJournalEntryRecordSection = () => {
    return (
      <div className="border">
        {getHeader('Journal Entry Records')}
        {props.journalEntryDetails &&
          props.journalEntryDetails.map((record: any, index: any) => {
            return journalEntryRecordListContainer(record, index);
          })}
      </div>
    );
  };
  /****************************** Journal Entry section *********************************/

  const getPrintPreview = (docType: any, data: any) => {
    return (
      <PrintPreview
        documentType={docType}
        document={{ ...data }}
        closePreview={(val: boolean) => {
          setShowPrintPreview(val);
          setShowRGPrintPreview(val);
        }}
      />
    );
  };

  return (
    <div
      className="flex parent-height align-self-start pl-xs pr-m overflow-y-auto show-scroll-bar"
      style={{
        width: !isDesktop ? '40%' : '30%',
        borderRight: '1px solid rgba(0, 0, 0, 0.12)',
        minHeight: 450,
        maxHeight: 600
      }}
    >
      {showPrintPreview &&
        getPrintPreview(TEMPLATE_CATEGORY.FULFILLMENT, {
          ...documentDetails,
          documentCode: fulfillmentCode
        })}

      {showPaymentPrintPreview && (
        <PrintPreview
          documentType={getDocumentTypeForPrintRecord()}
          document={{
            ...props.paymentRecords[selectedIndex],
            contactCode: props.contactCode
          }}
          closePreview={(val: boolean) => setShowPaymentPrintPreview(val)}
          parentDocumentType={props.documentType}
          parentDocumentCode={props.documentCode}
          uid={props.paymentRecords[selectedIndex].uid}
        />
      )}
      {paymentRecordEmailPopup && (
        <EmailPaymentRecord
          documentType={getDocumentTypeForPrintRecord()}
          document={{
            ...props.paymentRecords[selectedIndex],
            contactCode: props.contactCode
          }}
          closePreview={(val: boolean) => setPaymentRecordEmailPopup(val)}
          parentDocumentType={props.documentType}
          parentDocumentCode={props.documentCode}
          uid={props.paymentRecords[selectedIndex].uid}
          emailPaylaod={emailPayload}
        />
      )}
      {showRGPrintPreview &&
        getPrintPreview(TEMPLATE_CATEGORY.GOODS_RECEIPT, {
          ...documentDetails,
          documentCode: receivedGoodsCode
        })}

      <div className="column parent-width pt-2">
        {!Utility.isEmptyObject(props.fulfillmentDetails) && (
          <div className="flex flex-col w-full mb-m">
            {getFulfillmentRecordSection()}
          </div>
        )}
        {!Utility.isEmptyObject(props.salesReturnsDetails) && (
          <div className="flex flex-col w-full mb-m">
            {getSalesReturnRecordSection()}
          </div>
        )}
        {!Utility.isEmptyObject(props.receivedGoodsDetails) && (
          <div className="flex flex-col w-full mb-m">
            {getReceivedGoodSection()}
          </div>
        )}
        {!Utility.isEmptyObject(props.purchaseReturnsDetails) && (
          <div className="flex flex-col w-full mb-m">
            {getPurchaseReturnRecordSection()}
          </div>
        )}
        {!Utility.isEmptyObject(props.paymentRecordDetails) && (
          <div className="flex flex-col w-full mb-m">
            {getPaymentRecordSection()}
          </div>
        )}
        {!Utility.isEmptyObject(props.journalEntryDetails) && (
          <div className="flex flex-col w-full mb-m">
            {getJournalEntryRecordSection()}
          </div>
        )}
      </div>
    </div>
  );
}

export default LinkedRecordListView;
