import { DKButton, DKLabel, INPUT_TYPE, DKDataGrid } from 'deskera-ui-library';
import { useEffect, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../Redux/Hooks';
import Utility, {
  convertBooksDateFormatToUILibraryFormat,
  deepClone
} from '../../../Utility/Utility';
import { selectBatchSerialCustomFields } from '../../../Redux/Slices/CommonDataSlice';
import { getCustomFieldDataForDisplayTable } from '../../../SharedComponents/CustomFieldsHolder/BatchSerialCustomFieldsHelper';

export default function StockTransferDetailPopup(props: any) {
  const dispatch = useAppDispatch();
  const [detailState, setDetailState] = useState<any[]>([]);
  const [isBatch, setIsBatch] = useState(false);
  const [customFields, setCustomFields] = useState<any>([]);
  const batchSerialCFfromStore = useAppSelector(selectBatchSerialCustomFields);
  const [cfColumnConfig, setCfColumnConfig] = useState<any>([]);
  const [customFieldColumns, setCustomFieldColumns] = useState<any>([]);

  const batchConfig = [
    {
      name: 'Quantity Transferred',
      index: 2,
      options: null,
      required: false,
      editable: false,
      hidden: false,
      uiVisible: true,
      systemField: true,
      columnCode: 'qtyToTransfer',
      id: 'qtyToTransfer',
      datasource: [],
      key: 'qtyToTransfer',
      lineItem: false,
      type: INPUT_TYPE.TEXT,
      width: 180
    },
    {
      name: 'Source Batch Number',
      index: 0,
      options: null,
      required: false,
      editable: false,
      hidden: false,
      uiVisible: true,
      systemField: true,
      columnCode: 'sourceSerialBatchNumber',
      id: 'sourceSerialBatchNumber',
      datasource: [],
      key: 'sourceSerialBatchNumber',
      lineItem: false,
      type: INPUT_TYPE.TEXT,
      width: 200
    },
    {
      name: 'Target Batch Number',
      index: 1,
      options: null,
      required: false,
      editable: false,
      hidden: false,
      uiVisible: true,
      systemField: true,
      columnCode: 'destSerialBatchNumber',
      id: 'destSerialBatchNumber',
      datasource: [],
      key: 'destSerialBatchNumber',
      lineItem: false,
      type: INPUT_TYPE.TEXT,
      width: 200
    },
    {
      name: 'Source Warehouse',
      index: 1,
      options: null,
      required: false,
      editable: false,
      hidden: false,
      uiVisible: true,
      systemField: true,
      columnCode: 'srcWarehouseName',
      id: 'srcWarehouseName',
      datasource: [],
      key: 'srcWarehouseName',
      lineItem: false,
      type: INPUT_TYPE.TEXT,
      width: 220
    },
    {
      name: 'Target Warehouse',
      index: 3,
      options: null,
      required: false,
      editable: false,
      hidden: false,
      uiVisible: true,
      systemField: true,
      columnCode: 'destWarehouseName',
      id: 'destWarehouseName',
      datasource: [],
      key: 'destWarehouseName',
      lineItem: false,
      type: INPUT_TYPE.TEXT,
      width: 200
    }
  ];
  const serialConfig = [
    {
      name: 'Quantity Transferred',
      index: 2,
      options: null,
      required: false,
      editable: false,
      hidden: false,
      uiVisible: true,
      systemField: true,
      columnCode: 'qtyToTransfer',
      id: 'qtyToTransfer',
      datasource: [],
      key: 'qtyToTransfer',
      lineItem: false,
      type: INPUT_TYPE.TEXT,
      width: 220
    },
    {
      name: 'Serial Number',
      index: 0,
      options: null,
      required: false,
      editable: false,
      hidden: false,
      uiVisible: true,
      systemField: true,
      columnCode: 'sourceSerialBatchNumber',
      id: 'sourceSerialBatchNumber',
      datasource: [],
      key: 'sourceSerialBatchNumber',
      lineItem: false,
      type: INPUT_TYPE.TEXT,
      width: 240
    },
    {
      name: 'Source Warehouse',
      index: 1,
      options: null,
      required: false,
      editable: false,
      hidden: false,
      uiVisible: true,
      systemField: true,
      columnCode: 'srcWarehouseName',
      id: 'srcWarehouseName',
      datasource: [],
      key: 'srcWarehouseName',
      lineItem: false,
      type: INPUT_TYPE.TEXT,
      width: 220
    },
    {
      name: 'Target Warehouse',
      index: 1,
      options: null,
      required: false,
      editable: false,
      hidden: false,
      uiVisible: true,
      systemField: true,
      columnCode: 'destWarehouseName',
      id: 'destWarehouseName',
      datasource: [],
      key: 'destWarehouseName',
      lineItem: false,
      type: INPUT_TYPE.TEXT,
      width: 240
    }
  ];
  const normalConfig = [
    {
      name: 'Quantity',
      index: 0,
      options: null,
      required: false,
      editable: false,
      hidden: false,
      uiVisible: true,
      systemField: true,
      columnCode: 'quantity',
      id: 'quantity',
      datasource: [],
      key: 'quantity',
      lineItem: false,
      type: INPUT_TYPE.TEXT,
      width: 220
    },
    {
      name: 'Source Warehouse',
      index: 1,
      options: null,
      required: false,
      editable: false,
      hidden: false,
      uiVisible: true,
      systemField: true,
      columnCode: 'srcWarehouseName',
      id: 'srcWarehouseName',
      datasource: [],
      key: 'srcWarehouseName',
      lineItem: false,
      type: INPUT_TYPE.TEXT,
      width: 220
    },
    {
      name: 'Target Warehouse',
      index: 1,
      options: null,
      required: false,
      editable: false,
      hidden: false,
      uiVisible: true,
      systemField: true,
      columnCode: 'destWarehouseName',
      id: 'destWarehouseName',
      datasource: [],
      key: 'destWarehouseName',
      lineItem: false,
      type: INPUT_TYPE.TEXT,
      width: 220
    }
  ];
  useEffect(() => {
    if (props.detail && props.detail.length > 0) {
      let x = deepClone(props.detail);
      let updatedData =
        x &&
        x.map((row: any) => {
          return {
            ...row,
            ...getCustomFieldDataForDisplayTable(
              row?.customField ?? [],
              customFieldColumns,
              batchSerialCFfromStore?.content
            )
          };
        });
      setDetailState(updatedData);
    } else if (!Utility.isEmpty(props.normalDetail)) {
      let x = deepClone(props.normalDetail);
      setDetailState([x]);
    }
  }, [props.detail, props.normalDetail, customFieldColumns]);

  useEffect(() => {
    setCustomFields(batchSerialCFfromStore?.content);
  }, [batchSerialCFfromStore]);

  useEffect(() => {
    if (!Utility.isEmpty(customFields)) {
      const cfFieldsSet: any = new Set(
        customFields
          ?.filter((cfData: any) => cfData.status === 'ACTIVE')
          ?.map((cf: any) => cf.label)
      );
      const cfList: any = customFields?.filter(
        (cfData: any) => cfData.status === 'ACTIVE'
      );

      let copyColumnConfigs = cfColumnConfig;
      if (Utility.isEmpty(copyColumnConfigs)) {
        cfList.forEach((cfield: any, index: number) => {
          copyColumnConfigs.push({
            name: cfield.label,
            type: cfield.fieldType.toLowerCase(),
            index: copyColumnConfigs.length + index + 1,
            options: null,
            required: false,
            width: 180,
            editable: false,
            hidden: false,
            uiVisible: true,
            systemField: false,
            columnCode: cfield.label,
            key: cfield.label,
            textAlign: 'left',
            allowFilter: false
          });
        });
      }
      setCfColumnConfig(copyColumnConfigs);
      setCustomFieldColumns(Array.from(cfFieldsSet));
    }
  }, [customFields]);

  const getColumnConfig = () => {
    if (isBatch) {
      return batchConfig;
    } else if (props.isNormal) {
      return normalConfig;
    } else {
      return serialConfig;
    }
  };

  const getLineItemGrid = () => {
    return (
      <>
        <DKDataGrid
          needShadow={false}
          needBorder={true}
          needColumnIcons={false}
          needTrailingColumn={false}
          allowColumnSort={false}
          allowColumnAdd={false}
          allowColumnEdit={false}
          allowRowEdit={false}
          columns={
            props.isNormal
              ? getColumnConfig()
              : getColumnConfig().concat(cfColumnConfig)
          }
          rows={detailState}
          onRowUpdate={() => {}}
          currentPage={1}
          totalPageCount={1}
          title={''}
          allowBulkOperation={false}
        />
      </>
    );
  };

  const getHeader = () => {
    return (
      <div className="row justify-content-between p-h-r p-v-s bg-gray1">
        <div className="row width-auto">
          <DKLabel
            text={
              props.state === 'STOCK_ISSUE'
                ? 'Stock Issue Detail'
                : 'Stock Transfer Detail'
            }
            className="fw-m fs-l"
          />
        </div>
        <div className="row width-auto">
          <DKButton
            title={'Cancel'}
            className="bg-white border-m mr-r"
            onClick={props.onClose}
          />
          <div className={`row ${'border-radius-m border-m'}`}></div>
        </div>
      </div>
    );
  };

  return (
    <div className="transparent-background">
      <div
        className="popup-window"
        style={{
          maxWidth: 910,
          width: '100%',
          maxHeight: '95%',
          height: 'auto',
          padding: 0,
          paddingBottom: 60,
          overflowY: 'visible'
        }}
      >
        {getHeader()}
        <div
          className="column p-h-xl parent-width parent-height"
          style={{
            overflowY: 'scroll',
            overflowX: 'hidden',
            scrollbarWidth: 'none'
          }}
        >
          <div
            id="popup-container"
            className="column parent-width parent-height pt-xl"
            style={{
              pointerEvents: 'auto'
            }}
          >
            <div className="m-v-r parent-width">
              {!isBatch && !props.isNormal && (
                <div className="row">
                  <DKLabel
                    className="font-medium fs-r "
                    text={'Total Serial Transferred:'}
                  ></DKLabel>{' '}
                  <DKLabel
                    className="ml-2 font-medium fs-r "
                    text={props?.detail?.length || 0}
                  ></DKLabel>
                </div>
              )}
              <div className="m-v-r parent-width">{getLineItemGrid()}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
